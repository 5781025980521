<template>
  <div class="relative overflow-hidden pt-16 pb-32">
    <div aria-hidden="true" class="absolute inset-x-0 top-0 h-48 bg-gradient-to-b from-gray-100" />
    <div class="relative">
      <div class="lg:mx-auto lg:grid lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-2 lg:gap-24 lg:px-8">
        <div class="mx-auto max-w-xl px-6 lg:mx-0 lg:max-w-none lg:py-16 lg:px-0">
          <div>
            <div>
              <span class="flex h-12 w-12 items-center justify-center rounded-md bg-gradient-to-b from-blue-dark to-blue">
                <DevicePhoneMobileIcon class="h-6 w-6 text-white" aria-hidden="true" />
              </span>
            </div>
            <div class="mt-6">
              <h2 class="text-3xl font-bold tracking-tight text-blue">Get the Prayer Storm App.</h2>
              <p class="mt-4 text-lg text-gray-500">Install the app and access Prayer Storm's content on your phone. Plus, be informed when we 'go live' with notifications and easily find the links to your in-person tickets when you come and join us to pray! + More Soon</p>
            </div>
          </div>
          <div class="mt-8 border-t border-gray-200 pt-8 flex gap-3">
            <div class="flex-1">
              <a href="https://apps.apple.com/om/app/prayer-storm/id1558653869">
                <img src="/img/apps/app-store.svg"  class="w-full" />
              </a>
            </div>
            <div class="flex-1">
              <a href="https://play.google.com/store/apps/details?id=org.prayerstorm.app&hl=en_GB&gl=US">
                <img src="/img/apps/play-store.svg" class="w-full" />
              </a>
            </div>
          </div>
        </div>
        <div class="mt-12 sm:mt-16 lg:mt-0">
          <div class="-mr-48 pl-6 md:-mr-16 lg:relative lg:m-0 lg:h-full lg:px-0">
            <img class="w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:left-0 lg:h-full lg:w-auto lg:max-w-none" src="/img/apps/app-screens.png" alt="App Screens" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { DevicePhoneMobileIcon } from '@heroicons/vue/24/outline'
export default {
  components: {
    DevicePhoneMobileIcon
  }
}
</script>
