<template>
<div class="flex flex-col justify-center py-12 sm:px-6 lg:px-8">
  <div class="sm:mx-auto sm:w-full sm:max-w-md">
    <h2 class="mt-2 text-center text-3xl font-bold text-gray-900">
      Sign up for an account
    </h2>
    <p class="mt-2 text-center text-sm text-gray-600 max-w">
      <router-link :to="signInUrl" class="font-medium text-blue hover:text-blue-dark">
        Log in to your account
      </router-link>
    </p>
  </div>

  <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
    <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
      <form class="space-y-6" id="sign-up-form" @submit.prevent="signUp">
        <div>
          <label for="email" class="block text-sm font-medium text-gray-700">
            Email address
          </label>
          <div class="mt-1">
            <input v-model="signUpForm.email" id="email" name="email" type="email" autocomplete="email" required class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
          </div>
        </div>

        <div>
          <label for="email" class="block text-sm font-medium text-gray-700">
            Username (a-z 0-9 _ - only)
          </label>
          <div class="mt-1">
            <input v-model="signUpForm.username" id="username" name="username" type="text" autocomplete="off" required class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
          </div>
        </div>

        <div>
          <label for="password" class="block text-sm font-medium text-gray-700">
            Password
          </label>
          <div class="mt-1">
            <input v-model="signUpForm.password" id="password" name="password" type="password" autocomplete="off" required class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
          </div>
        </div>

        <div>
          <label for="passwordConfirmation" class="block text-sm font-medium text-gray-700">
            Confirm Password
          </label>
          <div class="mt-1">
            <input v-model="signUpForm.passwordConfirmation" id="passwordConfirmation" name="passwordConfirmation" type="password" autocomplete="off" required class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
          </div>
        </div>

         <div class="rounded-md bg-red-50 p-4" v-show="formError.message !== ''">
          <div class="flex">
            <div class="flex-shrink-0">
              <!-- Heroicon name: solid/x-circle -->
              <svg class="h-5 w-5 text-red-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clip-rule="evenodd" />
              </svg>
            </div>
            <div class="ml-3">
              <h3 class="text-sm font-medium text-red-800">
                {{formError.message}}
              </h3>
              <div class="mt-2 text-sm text-red-700">
                <ul class="list-disc pl-5 space-y-1">
                  <li v-for="(err, index) in formError.errors" :key="index">
                    {{err.error}}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div>
          <button type="submit" :disabled="signUpSubmitted" class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue hover:bg-blue-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
            Sign up
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
</template>

<script>
import axios from 'axios'
import responseHelper from '../helpers/response.helper'
export default {
  name: 'SignUp',
  data: () => ({
    formError: responseHelper.noError,
    signUpSubmitted: false,
    signUpForm: {
      email: '',
      username: '',
      password: '',
      passwordConfirmation: '',
      rememberMe: false
    }
  }),
  methods: {
    signUp () {
      this.signUpSubmitted = true
      this.formError = responseHelper.noError
      axios.post('auth/register', {
        email: this.signUpForm.email,
        username: this.signUpForm.username,
        password: this.signUpForm.password,
        passwordConfirmation: this.signUpForm.passwordConfirmation
      }).then((response) => {
        if (response?.data?.success && response?.data?.result) {
          this.$store.commit('login', response.data.result)
          if (this.$route.query && this.$route.query.return) {
            this.$router.push({ path: this.$route.query.return })
          } else {
            this.$router.push({ path: '/' })
          }
        } else {
          this.formError = responseHelper.unexpectedResult
        }
        // Handle the response
      }).catch((error) => {
        this.formError = responseHelper.getMessage(error)
      }).finally(() => {
        this.signUpSubmitted = false
      })
    }
  },
  computed: {
    signInUrl: function () {
      if (this.$route.query && this.$route.query.return) {
        return '/sign-in?return=' + encodeURIComponent(this.$route.query.return)
      } else {
        return '/sign-in'
      }
    }
  }
}
</script>
