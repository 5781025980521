import axios from 'axios'
const membershipStore = {
  namespaced: true,
  state: () => ({
    memberships: undefined,
    subscriptions: undefined,
    donationSubscriptions: undefined
  }),
  mutations: {
    setMemberships (state, payload) {
      state.memberships = payload.memberships
      state.subscriptions = payload.subscriptions
    },
    setDonations (state, payload) {
      state.donationSubscriptions = payload.subscriptions
    },
    updateSubscription (state, payload) {
      if (payload && state.subscriptions) {
        const idx = state.subscriptions.findIndex(obj => obj.id === payload.id)
        if (idx >= 0) {
          state.subscriptions[idx] = payload
        }
      }
    }
  },
  actions: {
    async loadMemberships (context) {
      const response = await axios.get('account/memberships')
      if (response.data && response.data.result) {
        context.commit('setMemberships', response.data.result)
      }
    },
    async loadDonations (context) {
      const response = await axios.get('account/donations')
      if (response.data && response.data.result) {
        context.commit('setDonations', response.data.result)
      }
    },
    async cancelSubscription (context, payload) {
      const response = await axios.post('account/subscription/cancel', {
        subscriptionId: payload.subscriptionId
      })
      if (response.data && response.data.result) {
        context.commit('updateSubscription', response.data.result)
      }
    },
    async manageSubscription (context, payload) {
      const response = await axios.post('account/subscription/manage', {
        subscriptionId: payload.subscriptionId
      })
      if (response.data && response.data.result) {
        return response.data.result
      }
      return undefined
    }
  }
}
export default membershipStore
