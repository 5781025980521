<template>
  <div class="bg-white" v-if="attendState.eTicket">
    <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
      <div class="mx-auto max-w-4xl py-16">
        <h1 v-if="!attendState.eTicket.waitingListUrl" class="text-3xl font-bold tracking-tight text-gray-900">Order Your Ticket</h1>

        <div v-if="attendState.eTicket.title.includes('Wait') || attendState.eTicket.title.includes('wait')" class="text-red-500 font-bold mt-4">
          SPECIAL INFORMATION ON HOW THE WAITING LIST WILL WORK:<br /> <br />
          These waiting list tickets are for those that have not been able to access the GENERAL ADMISSION tickets. If general admission tickets become available you will be made aware directly.<br /> <br />
          We will be attempting to make some overflow areas/rooms available at the event. You will need to queue outside prior to 7pm. ONLY after 7pm, will we begin to allow those that have waiting list tickets to enter. If space is still available in the main auditorium then you will be given access, otherwise the overflow areas/rooms will be made available.
        </div>

        <form class="mt-12">
          <section aria-labelledby="cart-heading">
            <h2 id="cart-heading" class="sr-only">Ticket Details</h2>

            <ul role="list" class="divide-y divide-gray-200 border-t border-b border-gray-200">
              <li class="grid grid-cols-1 md:grid-cols-2 gap-4 py-6 sm:py-10">
                <div class="" v-if="attendState.eTicket.imageUrl">
                  <img :src="attendState.eTicket.imageUrl" alt="Event Image" class="aspect-video w rounded-lg object-cover object-center" />
                </div>

                <div class="">
                  <h3 class="text-md font-bold">
                    {{ attendState.eTicket.eventTitle }}
                  </h3>
                  <p class="mt-1 text-sm text-gray-500">
                    <span class="text-sm text-gray-600 px-2 py-1 inline-flex text-md leading-5 f rounded-full bg-gray-100">
                      {{ attendState.eTicket.title }}
                    </span>
                    <span class="block mt-1 font-bold pl-2">£ {{ Number.parseFloat(attendState.eTicket.price).toFixed(2) }}</span>
                  </p>
                  <p v-if="attendState.eTicket.ticketType === ticketTypes.INPERSON" class="mt-1 text-sm text-gray-500 italic" >
                    These tickets grant the holder in-person attendance to this event at the location advertised.
                  </p>
                </div>
              </li>
            </ul>
          </section>

          <div>
            <div class="pt-6 text-right">
              <p class="text-red-600 text-sm">All fields are required</p>
            </div>
            <div class="pb-6">
              <div class="grid grid-cols-1 sm:grid-cols-2 gap-y-3 sm:gap-x-4">
                <div>
                  <h3 class="text-md font-bold">
                    Order Information
                  </h3>
                  <p class="mt-1 text-sm text-gray-500">Please provide details of the person making the order.</p>
                  <p class="mt-1 text-sm text-gray-500 italic">QR code tickets will be sent to this email address prior to the event, please ensure it is correct.</p>
                </div>
                <div class="grid grid-cols-1 gap-y-3 sm:grid-cols-2 sm:gap-x-4">
                  <div class="sm:col-span-2">
                    <label for="email-address" class="block text-sm font-medium text-gray-700">Email address</label>
                    <div class="mt-1">
                        <input v-model="checkoutAttendState.email" type="email" id="email-address" name="email-address" autocomplete="email" class="block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"/>
                    </div>
                  </div>

                  <div>
                    <label for="first-name" class="block text-sm font-medium text-gray-700">First name</label>
                    <div class="mt-1">
                      <input v-model="checkoutAttendState.firstName" type="text" id="first-name" name="first-name" autocomplete="given-name" class="block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
                    </div>
                  </div>

                  <div>
                    <label for="last-name" class="block text-sm font-medium text-gray-700">Last name</label>
                    <div class="mt-1">
                      <input v-model="checkoutAttendState.lastName" type="text" id="last-name" name="last-name" autocomplete="family-name" class="block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
                    </div>
                  </div>

                  <div class="sm:col-span-2">
                    <label for="tel" class="block text-sm font-medium text-gray-700">Telephone</label>
                    <div class="mt-1">
                      <input v-model="checkoutAttendState.tel" id="tel" name="tel" type="tel" autocomplete="tel" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue focus:ring-blue sm:text-sm" />
                    </div>
                  </div>
                  <div class="sm:col-span-2">
                    <label for="country" class="block text-sm font-medium text-gray-700">Country</label>
                    <div class="mt-1">
                      <select v-model="checkoutAttendState.country" id="country" name="country" class="mt-1 block w-full border border-gray-300 rounded-md focus:outline-none focus:ring-blue focus:border-blue sm:text-sm">
                        <option value="">Please specify...</option>
                        <option value="AF">Afghanistan</option>
                        <option value="AL">Albania</option>
                        <option value="DZ">Algeria</option>
                        <option value="AS">American Samoa</option>
                        <option value="AD">Andorra</option>
                        <option value="AO">Angola</option>
                        <option value="AI">Anguilla</option>
                        <option value="AQ">Antarctica</option>
                        <option value="AG">Antigua and Barbuda</option>
                        <option value="AR">Argentina</option>
                        <option value="AM">Armenia</option>
                        <option value="AW">Aruba</option>
                        <option value="AU">Australia</option>
                        <option value="AT">Austria</option>
                        <option value="AZ">Azerbaijan</option>
                        <option value="BS">Bahamas</option>
                        <option value="BH">Bahrain</option>
                        <option value="BD">Bangladesh</option>
                        <option value="BB">Barbados</option>
                        <option value="BY">Belarus</option>
                        <option value="BE">Belgium</option>
                        <option value="BZ">Belize</option>
                        <option value="BJ">Benin</option>
                        <option value="BM">Bermuda</option>
                        <option value="BT">Bhutan</option>
                        <option value="BO">Bolivia (Plurinational State of)</option>
                        <option value="BQ">Bonaire, Saint Eustatius and Saba</option>
                        <option value="BA">Bosnia and Herzegovina</option>
                        <option value="BW">Botswana</option>
                        <option value="BV">Bouvet Island</option>
                        <option value="BR">Brazil</option>
                        <option value="IO">British Indian Ocean Territory</option>
                        <option value="BN">Brunei Darussalam</option>
                        <option value="BG">Bulgaria</option>
                        <option value="BF">Burkina Faso</option>
                        <option value="BI">Burundi</option>
                        <option value="CV">Cabo Verde</option>
                        <option value="KH">Cambodia</option>
                        <option value="CM">Cameroon</option>
                        <option value="CA">Canada</option>
                        <option value="KY">Cayman Islands</option>
                        <option value="CF">Central African Republic</option>
                        <option value="TD">Chad</option>
                        <option value="CL">Chile</option>
                        <option value="CN">China</option>
                        <option value="CX">Christmas Island</option>
                        <option value="CC">Cocos (Keeling) Islands</option>
                        <option value="CO">Colombia</option>
                        <option value="KM">Comoros</option>
                        <option value="CG">Congo</option>
                        <option value="CD">Congo (Democratic Republic of the)</option>
                        <option value="CK">Cook Islands</option>
                        <option value="CR">Costa Rica</option>
                        <option value="HR">Croatia</option>
                        <option value="CU">Cuba</option>
                        <option value="CW">Curaçao</option>
                        <option value="CY">Cyprus</option>
                        <option value="CZ">Czech Republic</option>
                        <option value="CI">Côte d'Ivoire</option>
                        <option value="DK">Denmark</option>
                        <option value="DJ">Djibouti</option>
                        <option value="DM">Dominica</option>
                        <option value="DO">Dominican Republic</option>
                        <option value="EC">Ecuador</option>
                        <option value="EG">Egypt</option>
                        <option value="SV">El Salvador</option>
                        <option value="GQ">Equatorial Guinea</option>
                        <option value="ER">Eritrea</option>
                        <option value="EE">Estonia</option>
                        <option value="SZ">Eswatini (Kingdom of)</option>
                        <option value="ET">Ethiopia</option>
                        <option value="FK">Falkland Islands (Malvinas)</option>
                        <option value="FO">Faroe Islands</option>
                        <option value="FJ">Fiji</option>
                        <option value="FI">Finland</option>
                        <option value="FR">France</option>
                        <option value="GF">French Guiana</option>
                        <option value="PF">French Polynesia</option>
                        <option value="TF">French Southern Territories</option>
                        <option value="GA">Gabon</option>
                        <option value="GM">Gambia</option>
                        <option value="GE">Georgia</option>
                        <option value="DE">Germany</option>
                        <option value="GH">Ghana</option>
                        <option value="GI">Gibraltar</option>
                        <option value="GR">Greece</option>
                        <option value="GL">Greenland</option>
                        <option value="GD">Grenada</option>
                        <option value="GP">Guadeloupe</option>
                        <option value="GU">Guam</option>
                        <option value="GT">Guatemala</option>
                        <option value="GG">Guernsey</option>
                        <option value="GN">Guinea</option>
                        <option value="GW">Guinea-Bissau</option>
                        <option value="GY">Guyana</option>
                        <option value="HT">Haiti</option>
                        <option value="HM">Heard Island and McDonald Islands</option>
                        <option value="HN">Honduras</option>
                        <option value="HK">Hong Kong</option>
                        <option value="HU">Hungary</option>
                        <option value="IS">Iceland</option>
                        <option value="IN">India</option>
                        <option value="ID">Indonesia</option>
                        <option value="IR">Iran (Islamic Republic of)</option>
                        <option value="IQ">Iraq</option>
                        <option value="IE">Ireland (Republic of)</option>
                        <option value="IM">Isle of Man</option>
                        <option value="IL">Israel</option>
                        <option value="IT">Italy</option>
                        <option value="JM">Jamaica</option>
                        <option value="JP">Japan</option>
                        <option value="JE">Jersey</option>
                        <option value="JO">Jordan</option>
                        <option value="KZ">Kazakhstan</option>
                        <option value="KE">Kenya</option>
                        <option value="KI">Kiribati</option>
                        <option value="KP">Korea (Democratic People's Republic of)</option>
                        <option value="KR">Korea (Republic of)</option>
                        <option value="XK">Kosovo</option>
                        <option value="KW">Kuwait</option>
                        <option value="KG">Kyrgyzstan</option>
                        <option value="LA">Lao People's Democratic Republic</option>
                        <option value="LV">Latvia</option>
                        <option value="LB">Lebanon</option>
                        <option value="LS">Lesotho</option>
                        <option value="LR">Liberia</option>
                        <option value="LY">Libya</option>
                        <option value="LI">Liechtenstein</option>
                        <option value="LT">Lithuania</option>
                        <option value="LU">Luxembourg</option>
                        <option value="MO">Macao</option>
                        <option value="MG">Madagascar</option>
                        <option value="MW">Malawi</option>
                        <option value="MY">Malaysia</option>
                        <option value="MV">Maldives</option>
                        <option value="ML">Mali</option>
                        <option value="MT">Malta</option>
                        <option value="MH">Marshall Islands</option>
                        <option value="MQ">Martinique</option>
                        <option value="MR">Mauritania</option>
                        <option value="MU">Mauritius</option>
                        <option value="YT">Mayotte</option>
                        <option value="MX">Mexico</option>
                        <option value="FM">Micronesia (Federated States of)</option>
                        <option value="MD">Moldova (Republic of)</option>
                        <option value="MC">Monaco</option>
                        <option value="MN">Mongolia</option>
                        <option value="ME">Montenegro</option>
                        <option value="MS">Montserrat</option>
                        <option value="MA">Morocco</option>
                        <option value="MZ">Mozambique</option>
                        <option value="MM">Myanmar</option>
                        <option value="NA">Namibia</option>
                        <option value="NR">Nauru</option>
                        <option value="NP">Nepal</option>
                        <option value="NL">Netherlands</option>
                        <option value="NC">New Caledonia</option>
                        <option value="NZ">New Zealand</option>
                        <option value="NI">Nicaragua</option>
                        <option value="NE">Niger</option>
                        <option value="NG">Nigeria</option>
                        <option value="NU">Niue</option>
                        <option value="NF">Norfolk Island</option>
                        <option value="MK">North Macedonia (Republic of)</option>
                        <option value="MP">Northern Mariana Islands</option>
                        <option value="NO">Norway</option>
                        <option value="OM">Oman</option>
                        <option value="PK">Pakistan</option>
                        <option value="PW">Palau</option>
                        <option value="PS">Palestine (State of)</option>
                        <option value="PA">Panama</option>
                        <option value="PG">Papua New Guinea</option>
                        <option value="PY">Paraguay</option>
                        <option value="PE">Peru</option>
                        <option value="PH">Philippines</option>
                        <option value="PN">Pitcairn</option>
                        <option value="PL">Poland</option>
                        <option value="PT">Portugal</option>
                        <option value="PR">Puerto Rico</option>
                        <option value="QA">Qatar</option>
                        <option value="RO">Romania</option>
                        <option value="RU">Russian Federation</option>
                        <option value="RW">Rwanda</option>
                        <option value="RE">Réunion</option>
                        <option value="BL">Saint Barthélemy</option>
                        <option value="SH">Saint Helena, Ascension and Tristan da Cunha</option>
                        <option value="KN">Saint Kitts and Nevis</option>
                        <option value="LC">Saint Lucia</option>
                        <option value="MF">Saint Martin (French part)</option>
                        <option value="PM">Saint Pierre and Miquelon</option>
                        <option value="VC">Saint Vincent and the Grenadines</option>
                        <option value="WS">Samoa</option><option value="SM">San Marino</option>
                        <option value="ST">Sao Tome and Principe</option>
                        <option value="SA">Saudi Arabia</option>
                        <option value="SN">Senegal</option>
                        <option value="RS">Serbia</option>
                        <option value="SC">Seychelles</option>
                        <option value="SL">Sierra Leone</option>
                        <option value="SG">Singapore</option>
                        <option value="SX">Sint Maarten (Dutch part)</option>
                        <option value="SK">Slovakia</option>
                        <option value="SI">Slovenia</option>
                        <option value="SB">Solomon Islands</option>
                        <option value="SO">Somalia</option>
                        <option value="ZA">South Africa</option>
                        <option value="GS">South Georgia and the South Sandwich Islands</option>
                        <option value="SS">South Sudan</option>
                        <option value="ES">Spain</option>
                        <option value="LK">Sri Lanka</option>
                        <option value="SD">Sudan</option>
                        <option value="SR">Suriname</option>
                        <option value="SJ">Svalbard and Jan Mayen</option>
                        <option value="SE">Sweden</option>
                        <option value="CH">Switzerland</option>
                        <option value="SY">Syrian Arab Republic</option>
                        <option value="TW">Taiwan, Province of China</option>
                        <option value="TJ">Tajikistan</option>
                        <option value="TZ">Tanzania (United Republic of)</option>
                        <option value="TH">Thailand</option>
                        <option value="TL">Timor-Leste</option>
                        <option value="TG">Togo</option>
                        <option value="TK">Tokelau</option>
                        <option value="TO">Tonga</option>
                        <option value="TT">Trinidad and Tobago</option>
                        <option value="TN">Tunisia</option>
                        <option value="TR">Turkey</option>
                        <option value="TM">Turkmenistan</option>
                        <option value="TC">Turks and Caicos Islands</option>
                        <option value="TV">Tuvalu</option>
                        <option value="UG">Uganda</option>
                        <option value="UA">Ukraine</option>
                        <option value="AE">United Arab Emirates</option>
                        <option value="GB">United Kingdom of Great Britain and Northern Ireland</option>
                        <option value="UM">United States Minor Outlying Islands</option>
                        <option value="US">United States of America</option>
                        <option value="UY">Uruguay</option>
                        <option value="UZ">Uzbekistan</option>
                        <option value="VU">Vanuatu</option>
                        <option value="VA">Vatican City State</option>
                        <option value="VE">Venezuela (Bolivarian Republic of)</option>
                        <option value="VN">Vietnam</option>
                        <option value="VG">Virgin Islands (British)</option>
                        <option value="VI">Virgin Islands (U.S.)</option>
                        <option value="WF">Wallis and Futuna</option>
                        <option value="EH">Western Sahara</option>
                        <option value="YE">Yemen</option>
                        <option value="ZM">Zambia</option>
                        <option value="ZW">Zimbabwe</option>
                        <option value="AX">Åland Islands</option>
                      </select>
                    </div>
                  </div>
                  <div class="sm:col-span-2">
                    <label for="address-one" class="block text-sm font-medium text-gray-700">Address Line 1</label>
                    <div class="mt-1">
                      <input v-model="checkoutAttendState.addressOne" type="text" name="address-one" id="address-one" autocomplete="off" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue focus:ring-blue sm:text-sm" />
                    </div>
                  </div>
                  <div class="sm:col-span-2">
                    <label for="address-two" class="block text-sm font-medium text-gray-700">Address Line 2</label>
                    <div class="mt-1">
                      <input v-model="checkoutAttendState.addressTwo" type="text" name="address-two" id="address-two" autocomplete="off" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue focus:ring-blue sm:text-sm" />
                    </div>
                  </div>
                  <div class="sm:col-span-2">
                    <label for="town-city" class="block text-sm font-medium text-gray-700">Town/City</label>
                    <div class="mt-1">
                      <input v-model="checkoutAttendState.townCity" type="text" name="town-city" id="town-city" autocomplete="off" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue focus:ring-blue sm:text-sm" />
                    </div>
                  </div>
                  <div class="sm:col-span-2">
                    <label for="county" class="block text-sm font-medium text-gray-700">County/State</label>
                    <div class="mt-1">
                      <input v-model="checkoutAttendState.county"  type="text" name="county" id="county" autocomplete="off" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue focus:ring-blue sm:text-sm" />
                    </div>
                  </div>
                  <div class="sm:col-span-2">
                    <label for="post-code" class="block text-sm font-medium text-gray-700">Post/Zip Code</label>
                    <div class="mt-1">
                      <input v-model="checkoutAttendState.postCode" type="text" name="post-code" id="post-code" autocomplete="off" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue focus:ring-blue sm:text-sm" />
                    </div>
                  </div>

                  <div class="sm:col-span-2">
                    <label for="heard-from" class="block text-sm font-medium text-gray-700">Where did you hear about this event?</label>
                    <div class="mt-1">
                      <select v-model="checkoutAttendState.heardFrom" id="heard-from" name="heard-from" class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-light-blue-500 focus:border-light-blue-500 sm:text-sm">
                        <option value="0">Please Specify...</option>
                        <option value="8">Prayer Storm's App</option>
                        <option value="2">Prayer Storm's Website</option>
                        <option value="3">Prayer Storm's Social Media</option>
                        <option value="4">Other Social Media</option>
                        <option value="5">Church</option>
                        <option value="6">Ministry/Organistion</option>
                        <option value="7">Friend/Family</option>
                        <option value="1">Other</option>
                      </select>
                    </div>
                  </div>

                  <div class="sm:col-span-2">
                    <label for="heard-from" class="block text-sm font-medium text-gray-700">Are you a current Prayer Storm Partner? Partners give recurring financial support.</label>
                    <div class="mt-1">
                      <select v-model="checkoutAttendState.isPartner" id="donation-type" name="donation-type" class="block w-full text-sm rounded-md border-gray-300 py-2 px-3 shadow-sm focus:border-blue focus:ring-blue">
                        <option value="">Please specify...</option>
                        <option value="0">No I'm not a partner</option>
                        <option value="1">Yes I'm a current partner</option>
                      </select>
                    </div>
                  </div>

                </div>
              </div>
            </div>

            <div v-if="attendState.eTicket.sessionAttendance" class="mt-6 border-t border-gray-200 py-6">
              <div class="grid grid-cols-1 sm:grid-cols-2 gap-y-3 sm:gap-x-4">
                <div class="">
                  <h3 class="text-md font-bold">
                    Attendance
                  </h3>
                  <p class="mt-1 text-sm text-gray-500 italic">We want to ensure events are fully booked. Please only select the sessions that you will attend.</p>
                  <p class="mt-1 text-sm text-gray-500 font-bold text-red-500">Please select the sessions that you would like to attend.</p>
                  <p class="mt-1 text-sm text-gray-500">If you have any exceptional circumnstances around your attendance for this event, then please let us know in the attendance notes.</p>
                </div>
                <div class="">
                  <div class="mt-4 bg-white border border-gray-200 rounded-lg shadow-sm">
                    <dl class="py-6 px-4 sace-y-6p sm:px-6">
                      <div>
                        <div class="border-b border-gray-200" v-for="option in attendState.attendanceOptions" :key="option.id">
                          <dt class="text-sm py-4 hover:bg-gray-100 cursor-pointer" @click.prevent="toggleAttendance(option)">
                            <CheckIcon v-if="option.attend" class="h-4 w-4 inline-block mr-2"></CheckIcon>
                            <XMarkIcon v-else class="h-4 w-4 inline-block mr-2"></XMarkIcon>
                            <!-- <input v-model="option.attend" type="checkbox" autocomplete="off" class="inline-block h-4 w-4 mr-2" /> -->
                            {{ option.title }} - {{ $filters.dateTimeMed(option.publishedAt) }}
                          </dt>
                        </div>
                        <div class="flex items-center justify-between pt-6">
                          <dt class="text-base font-medium">
                            {{ attendanceSelectionCount }} (of {{ attendState.attendanceOptions.length }}) Sessions Selected
                          </dt>
                        </div>
                      </div>
                    </dl>
                  </div>
                  <div class="mt-3">
                    <label for="attendance-notes" class="block text-sm font-medium text-gray-700">Attendance Notes</label>
                    <div class="mt-1">
                      <textarea v-model="checkoutAttendState.attendanceNotes" type="text" name="attendance-notes" id="attendance-" autocomplete="off" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue focus:ring-blue sm:text-sm" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-else class="mt-6 border-t border-gray-200 py-6">
              <div class="grid grid-cols-1 sm:grid-cols-2 gap-y-3 sm:gap-x-4">
                <div class="">
                  <h3 class="text-md font-bold">
                    Attendance
                  </h3>
                  <p class="mt-1 text-sm text-gray-500 italic">Specifying individual session attendance is not required for this event. <strong>However, we want to ensure that our events are available to people that can attend.</strong></p>
                  <p class="mt-1 text-sm text-gray-500 font-bold text-red-500">PLEASE NOTE: It is expected that you are registering to attend all sessions.</p>
                  <p class="mt-1 text-sm text-gray-500">If for any reason there are exceptional circumnstances and you cannot attend <strong>all sessions and the full session times</strong> for this event then please let us know in the attendance notes.</p>
                </div>
                <div class="">
                  <div class="mt-4 bg-white border border-gray-200 rounded-lg shadow-sm">
                    <dl class="py-6 px-4 sace-y-6p sm:px-6">
                      <div>
                        <p v-for="option in attendState.attendanceOptions" :key="option.id" class="mr-2 mt-2 px-2 py-1 rounded-full bg-gray-100 text-gray-600 text-sm inline-block">{{$filters.dateTimeMed(option.publishedAt)}}</p>
                      </div>
                    </dl>
                  </div>
                  <div class="mt-3">
                    <label for="attendance-notes" class="block text-sm font-medium text-gray-700">Attendance Notes</label>
                    <div class="mt-1">
                      <textarea v-model="checkoutAttendState.attendanceNotes" type="text" name="attendance-notes" id="attendance-" autocomplete="off" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue focus:ring-blue sm:text-sm" />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="mt-6 border-t border-gray-200 py-6">
              <div class="grid grid-cols-1 sm:grid-cols-2 gap-y-3 sm:gap-x-4">
                <div class="">
                  <h3 class="text-md font-bold">
                    Tickets
                  </h3>
                  <p class="mt-1 text-sm text-gray-500">We require the ticket details of each person attending for event safety.</p>
                  <p class="mt-1 text-sm text-gray-500 italic">Please only buy additional tickets for named people. We reserve the right to cancel any generic bookings that we notice.</p>
                  <p v-if="attendState.eTicket.childrenPerOrder > 0" class="mt-1 text-sm text-red-500 font-bold">PLEASE NOTE: For child tickets, it is expected that parent/s or guardian/s are available to supervise all children in attendance at all times. Children remain your responsibility at all times. Please ensure that standard tickets are added for all parent/s and guardian/s.</p>
                </div>
                <div class="">
                  <div class="mt-4 bg-white border border-gray-200 rounded-lg shadow-sm">
                    <dl class="pb-6 pt-6 px-4 space-y-6 sm:px-6">
                      <div>
                        <div class="flex justify-between">
                          <h2 class="font-bold">Standard ({{ attendState.delegates.length }})</h2>
                          <button v-if="attendState.delegates.length < attendState.eTicket.ticketsPerOrder"  type="button" @click.prevent="addStandard" class="relative -ml-px items-center gap-x-1.5 rounded-md bg-white px-2 py-1 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:z-10 hover:bg-gray-50 focus:z-10 inline-flex">
                            <UserPlusIcon class="-ml-0.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                          </button>
                          <span v-else>{{ attendState.eTicket.ticketsPerOrder }} max.</span>
                        </div>
                        <div v-for="index in checkoutAttendState.quantity" :key="index" class="pt-3">
                          <div class="mt-1 bg-gray-100 rounded-md p-2">
                            <div class="text-sm font-bold">
                              {{ attendState.delegates[index-1].firstName}} {{ attendState.delegates[index-1].lastName}}
                            </div>
                            <div class="text-sm">
                              {{ attendState.delegates[index-1].email}}
                            </div>
                            <div class="text-sm">
                              {{ attendState.delegates[index-1].church}} {{ attendState.delegates[index-1].churchTownCity}}
                            </div>
                            <div class="text-right">
                              <button type="button" @click.prevent="editDelegate(index-1)" class="relative -ml-px items-center gap-x-1.5 rounded-md bg-white px-2 py-1 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:z-10 hover:bg-gray-50 focus:z-10 inline-flex">edit</button>
                              <button type="button" @click.prevent="removeDelegate(index-1)" class="relative ml-1 items-center gap-x-1.5 rounded-md bg-white px-2 py-1 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:z-10 hover:bg-gray-50 focus:z-10 inline-flex">remove</button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <hr class="my-6">
                      <div v-if="attendState.eTicket.childrenPerOrder > 0">
                        <div class="flex justify-between">
                          <h2 class="font-bold">Child ({{ attendState.childDelegates.length }})</h2>
                          <button v-if="attendState.eTicket.childrenPerOrder > 0 && attendState.childDelegates.length < attendState.eTicket.childrenPerOrder" type="button" @click.prevent="addChild" class="relative ml-2 items-center gap-x-1.5 rounded-md bg-white px-2 py-1 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:z-10 hover:bg-gray-50 focus:z-10 inline-flex">
                            <UserPlusIcon class="-ml-0.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                          </button>
                          <span v-else>{{ attendState.eTicket.childrenPerOrder }} max.</span>
                        </div>
                        <div v-for="index in checkoutAttendState.childQuantity" :key="index" class="pt-3">
                          <div class="mt-1 bg-gray-100 rounded-md p-2">
                            <div class="text-sm font-bold">
                              {{ attendState.childDelegates[index-1].firstName}} {{ attendState.childDelegates[index-1].lastName}}
                            </div>
                            <div class="text-sm">
                              {{ attendState.childDelegates[index-1].age}} years old ({{ attendState.childDelegates[index-1].gender}})
                            </div>
                            <div class="text-right">
                              <button type="button" @click.prevent="editChildDelegate(index-1)" class="relative -ml-px items-center gap-x-1.5 rounded-md bg-white px-2 py-1 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:z-10 hover:bg-gray-50 focus:z-10 inline-flex">edit</button>
                              <button type="button" @click.prevent="removeChildDelegate(index-1)" class="relative ml-1 items-center gap-x-1.5 rounded-md bg-white px-2 py-1 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:z-10 hover:bg-gray-50 focus:z-10 inline-flex">remove</button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div v-else>
                        <div>
                          <h2 class="font-bold">Children</h2>
                          <p class="text-sm">There is no provision for children at this event</p>
                        </div>
                      </div>
                    </dl>
                  </div>
                </div>
              </div>
            </div>

            <div v-if="attendState.eTicket.donation" class="mt-6 border-t border-gray-200 py-6">
              <div class="grid grid-cols-1 sm:grid-cols-2 gap-y-3 sm:gap-x-4">
                <div class="">
                  <h3 class="text-md font-bold">
                    Support Prayer Storm
                  </h3>
                  <p class="mt-1 text-sm text-gray-500">This event is free and only possible from the help and support of our donors and partners.</p>
                </div>
                <div>
                  <div class="mt-4 bg-white border border-gray-200 rounded-lg shadow-sm">
                    <div class="py-6 px-4 sace-y-6p sm:px-6">
                      <div class="flex items-center justify-between mb-4 text-sm font-bold">
                        Would you like to donate or partner?
                      </div>
                      <div>
                        <select v-model="checkoutAttendState.donationType" id="donation-type" name="donation-type" class="block w-full text-sm rounded-md border-gray-300 py-2 px-3 shadow-sm focus:border-blue focus:ring-blue">
                          <option value="">Please specify...</option>
                          <option value="23">Donate (One-off Gift)</option>
                          <option value="24">Partner (Recurring Support)</option>
                          <option value="0">No thank you</option>
                        </select>
                      </div>
                      <div v-if="checkoutAttendState.donationType && (checkoutAttendState.donationType != '0')" class="mt-3 text-sm">
                        Thank you, please complete your order and we will help you to setup your support.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="mt-6 border-t border-gray-200 py-6">
              <div class="grid grid-cols-1 sm:grid-cols-2 gap-y-3 sm:gap-x-4">
                <div>
                  <h3 class="text-md font-bold">
                    Order Summary
                  </h3>
                  <p class="mt-1 text-sm text-gray-500">Please review your order.</p>
                </div>
                <div>
                  <div class="mt-4 bg-white border border-gray-200 rounded-lg shadow-sm">
                    <dl class="py-6 px-4 sace-y-6p sm:px-6">
                      <div v-if="attendState.eTicket.hasMemberPrice && $filters.arrayItemsExist(attendState.profile.activeMemberships)">
                        <div class="flex items-center justify-between">
                          <dt class="text-sm mb-6">
                            {{ attendState.eTicket.title }} - Subscriber
                            <p class="text-sm text-gray-600 text-center mt-1">available as you are a current subscriber</p>
                          </dt>
                          <dd class="text-sm font-medium text-gray-900">
                            {{toMemberPrice(attendState.eTicket)}}
                          </dd>
                        </div>
                        <div class="flex items-center justify-between border-t border-gray-200 pt-6">
                          <dt class="text-base font-medium">
                            Total
                          </dt>
                          <dd class="text-base font-medium text-gray-900">
                            {{toMemberPrice(attendState.eTicket)}}
                          </dd>
                        </div>
                      </div>
                      <div v-else-if="attendState.eTicket.isEarlyBird">
                        <div class="flex items-center justify-between">
                          <dt class="text-sm mb-6">
                            {{ attendState.eTicket.title }} - Early Bird
                            <p class="text-sm text-gray-600 text-center mt-1">available until {{attendState.eTicket.earlyBirdDateTime.toLocaleString(DateTime.DATE_MED)}} then £{{Number.parseFloat(attendState.eTicket.price).toFixed(2)}}</p>
                          </dt>
                          <dd class="text-sm font-medium text-gray-900">
                            £{{Number.parseFloat(attendState.eTicket.earlyBirdPrice).toFixed(2)}}
                          </dd>
                        </div>
                        <div class="flex items-center justify-between border-t border-gray-200 pt-6">
                          <dt class="text-base font-medium">
                            Total
                          </dt>
                          <dd class="text-base font-medium text-gray-900">
                            £{{ Number.parseFloat(attendState.eTicket.earlyBirdPrice).toFixed(2) }}
                          </dd>
                        </div>
                      </div>
                      <div v-else>
                        <div v-if="attendState.eTicket.sessionAttendance" class="flex items-center justify-between">
                          <dt class="text-sm mb-6">
                            Attending {{ attendanceSelectionCount }} (of {{ attendState.attendanceOptions.length }}) Sessions
                          </dt>
                        </div>
                        <div class="flex items-center justify-between mb-6 text-sm font-bold">
                          {{ attendState.eTicket.eventTitle }}:  {{ attendState.eTicket.title }}
                        </div>
                        <div class="flex items-center justify-between mb-6 text-sm">
                          <div>
                            Standard Tickets (£{{Number.parseFloat(attendState.eTicket.price).toFixed(2)}}) x {{checkoutAttendState.quantity}}
                          </div>
                          <div>
                            £{{(Number.parseFloat(attendState.eTicket.price).toFixed(2) * checkoutAttendState.quantity).toFixed(2)}}
                          </div>
                        </div>
                        <div v-if="attendState.eTicket.childrenPerOrder > 0" class="flex items-center justify-between mb-6 text-sm">
                          <div>
                            Child Tickets (£{{Number.parseFloat(attendState.eTicket.price).toFixed(2)}}) x {{checkoutAttendState.childQuantity}}
                          </div>
                          <div>
                            £{{(Number.parseFloat(attendState.eTicket.price).toFixed(2) * checkoutAttendState.childQuantity).toFixed(2)}}
                          </div>
                        </div>
                        <div class="flex items-center justify-between border-t border-gray-200 pt-6 mt-3">
                          <dt class="text-base font-medium">
                            Total
                          </dt>
                          <dd class="text-base font-medium text-gray-900">
                            £{{(Number.parseFloat(attendState.eTicket.price).toFixed(2) * (checkoutAttendState.quantity + checkoutAttendState.childQuantity)).toFixed(2)}}
                          </dd>
                        </div>
                      </div>
                    </dl>

                    <div class="border-t border-gray-200 py-6 px-4 sm:px-6">
                      <ErrorMessage :formError="checkoutAttendState.error" class="mb-6"></ErrorMessage>
                      <button @click.prevent="checkout" :disabled="checkoutAttendState.executing" type="submit" class="disabled:opacity-50 w-full bg-blue border border-transparent rounded-md shadow-sm py-3 px-4 text-base font-medium text-white hover:bg-blue-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-indigo-500">Continue</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
<TransitionRoot as="template" :show="formState.openAddDelegate">
  <Dialog as="div" class="relative z-10" @close="formState.openAddDelegate = false">
    <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
      <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
    </TransitionChild>

    <div class="fixed inset-0 z-10 overflow-y-auto">
      <div class="flex min-h-full justify-center p-4 text-center items-center sm:p-0">
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
          <div class="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 w-full sm:max-w-sm sm:p-6">
            <div>
              <h3 v-if="formState.delegateEditIndex < 0" class="text-lg font-medium leading-6 text-gray-900">Add Ticket</h3>
              <h3 v-else class="text-lg font-medium leading-6 text-gray-900">Update Ticket</h3>
              <div class="grid grid-cols-1 gap-y-3 pt-3" id="add-delegate-form">
                <div>
                  <label class="block text-sm font-medium text-gray-700">First name</label>
                  <div class="mt-1">
                    <input v-model="formState.delegateFirstName" type="text" name="add-first-name" id="add-first-name" autocomplete="given-name" class="block w-full text-sm rounded-md border-gray-300 py-2 px-3 shadow-sm focus:border-blue focus:ring-blue" />
                  </div>
                </div>
                <div>
                  <label class="block text-sm font-medium text-gray-700">Last name</label>
                  <div class="mt-1">
                    <input v-model="formState.delegateLastName" type="text" name="add-last-name" id="add-last-name" autocomplete="family-name" class="block w-full text-sm rounded-md border-gray-300 py-2 px-3 shadow-sm focus:border-blue focus:ring-blue" />
                  </div>
                </div>
                <div>
                  <label class="block text-sm font-medium text-gray-700">Email</label>
                  <div class="mt-1">
                    <input v-model="formState.delegateEmail" type="text" name="add-email" id="add-email" autocomplete="email" class="block w-full text-sm rounded-md border-gray-300 py-2 px-3 shadow-sm focus:border-blue focus:ring-blue" />
                  </div>
                </div>
                <div>
                  <label class="block text-sm font-medium text-gray-700">Church/Ministry Name</label>
                  <div class="mt-1">
                    <input v-model="formState.delegateChurch" type="text" name="add-church" id="add-church" class="block w-full text-sm rounded-md border-gray-300 py-2 px-3 shadow-sm focus:border-blue focus:ring-blue" />
                  </div>
                </div>
                <div v-if="attendState.eTicket.delegateTypes === 3">
                  <label class="block text-sm font-medium text-gray-700">Church/Ministry Position/Role</label>
                  <div class="mt-1">
                    <input v-model="formState.delegatePosition" type="text" name="add-church-position" id="add-church-position" class="block w-full text-sm rounded-md border-gray-300 py-2 px-3 shadow-sm focus:border-blue focus:ring-blue" />
                  </div>
                </div>
                <div>
                  <label class="block text-sm font-medium text-gray-700">Church/Ministry Town/City</label>
                  <div class="mt-1">
                    <input v-model="formState.delegateChurchTownCity" type="text" name="add-church-town-city" id="add-church-town-city" class="block w-full text-sm rounded-md border-gray-300 py-2 px-3 shadow-sm focus:border-blue focus:ring-blue" />
                  </div>
                </div>
              </div>
            </div>
            <ErrorMessage :formError="formState.addDelegateError" class="mt-6"></ErrorMessage>
            <div class="mt-6">
              <button v-if="formState.delegateEditIndex < 0" :disabled="formState.addDelegateAction" type="button" class="disabled:opacity-50 inline-flex w-full justify-center rounded-md border border-transparent bg-blue px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-blue-dark focus:outline-none focus:ring-2 focus:ring-blue focus:ring-offset-2 sm:text-sm" @click.prevent="addDelegate">Add Ticket</button>
              <button v-else :disabled="formState.addDelegateAction" type="button" class="disabled:opacity-50 inline-flex w-full justify-center rounded-md border border-transparent bg-blue px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-blue-dark focus:outline-none focus:ring-2 focus:ring-blue focus:ring-offset-2 sm:text-sm" @click.prevent="updateStandard">Update Ticket</button>
            </div>
            <div class="mt-3">
              <button type="button" class="inline-flex w-full justify-center rounded-md border border-transparent px-4 py-2 text-base font-medium text-blue shadow-sm hover:text-blue-dark focus:outline-none focus:ring-2 focus:ring-blue focus:ring-offset-2 sm:text-sm" @click.prevent="formState.openAddDelegate = false">Close</button>
            </div>
          </div>
        </TransitionChild>
      </div>
    </div>
  </Dialog>
</TransitionRoot>
<TransitionRoot as="template" :show="formState.openAddChildDelegate">
  <Dialog as="div" class="relative z-10" @close="formState.openAddChildDelegate = false">
    <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
      <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
    </TransitionChild>

    <div class="fixed inset-0 z-10 overflow-y-auto">
      <div class="flex min-h-full justify-center p-4 text-center items-center sm:p-0">
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
          <div class="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 w-full sm:max-w-sm sm:p-6">
            <div>
              <h3 v-if="formState.childDelegateEditIndex < 0" class="text-lg font-medium leading-6 text-gray-900">Add Child Ticket</h3>
              <h3 v-else class="text-lg font-medium leading-6 text-gray-900">Update Child Ticket</h3>
              <div class="grid grid-cols-1 gap-y-3 pt-3" id="add-delegate-form">
                <div>
                  <label class="block text-sm font-medium text-gray-700">First name</label>
                  <div class="mt-1">
                    <input v-model="formState.childFirstName" type="text" name="add-child-first-name" id="add-child-first-name" autocomplete="given-name" class="block w-full text-sm rounded-md border-gray-300 py-2 px-3 shadow-sm focus:border-blue focus:ring-blue" />
                  </div>
                </div>
                <div>
                  <label class="block text-sm font-medium text-gray-700">Last name</label>
                  <div class="mt-1">
                    <input v-model="formState.childLastName" type="text" name="add-child-last-name" id="add-child-last-name" autocomplete="family-name" class="block w-full text-sm rounded-md border-gray-300 py-2 px-3 shadow-sm focus:border-blue focus:ring-blue" />
                  </div>
                </div>
                <div>
                  <label class="block text-sm font-medium text-gray-700">Age (years old)</label>
                  <div class="mt-1">
                    <input v-model="formState.childAge" type="number" name="add-child-age" id="add-child-age" class="block w-full text-sm rounded-md border-gray-300 py-2 px-3 shadow-sm focus:border-blue focus:ring-blue" />
                  </div>
                </div>
                <div>
                  <label class="block text-sm font-medium text-gray-700">Gender</label>
                  <div class="mt-1">
                    <select v-model="formState.childGender" id="add-child-gender" name="add-child-gender" class="block w-full text-sm rounded-md border-gray-300 py-2 px-3 shadow-sm focus:border-blue focus:ring-blue">
                      <option value="">Please specify...</option>
                      <option value="M">Male</option>
                      <option value="F">Female</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <ErrorMessage :formError="formState.addChildDelegateError" class="mt-6"></ErrorMessage>
            <div class="mt-6">
              <button v-if="formState.childDelegateEditIndex < 0" :disabled="formState.addChildDelegateAction" type="button" class="disabled:opacity-50 inline-flex w-full justify-center rounded-md border border-transparent bg-blue px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-blue-dark focus:outline-none focus:ring-2 focus:ring-blue focus:ring-offset-2 sm:text-sm" @click.prevent="addChildDelegate">Add Child Ticket</button>
              <button v-else :disabled="formState.addChildDelegateAction" type="button" class="disabled:opacity-50 inline-flex w-full justify-center rounded-md border border-transparent bg-blue px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-blue-dark focus:outline-none focus:ring-2 focus:ring-blue focus:ring-offset-2 sm:text-sm" @click.prevent="updateChild">Update Child Ticket</button>
            </div>
            <div class="mt-3">
              <button type="button" class="inline-flex w-full justify-center rounded-md border border-transparent px-4 py-2 text-base font-medium text-blue shadow-sm hover:text-blue-dark focus:outline-none focus:ring-2 focus:ring-blue focus:ring-offset-2 sm:text-sm" @click.prevent="formState.openAddChildDelegate = false">Close</button>
            </div>
          </div>
        </TransitionChild>
      </div>
    </div>
  </Dialog>
</TransitionRoot>
</template>

<script>
import { mapGetters } from 'vuex'
import { defineComponent, onMounted, computed, reactive } from 'vue'
// import store from '../store'
import { useETickets } from '@/composables/useETickets'
import responseHelper from '@/helpers/response.helper'
import { useRoute } from 'vue-router'
import ErrorMessage from '@/components/ErrorMessage.vue'
import { DateTime } from 'luxon'
import { ticketTypes } from '../lib/constants'
import {
  Dialog,
  TransitionChild,
  TransitionRoot
  // Menu,
  // MenuButton,
  // MenuItem,
  // MenuItems
} from '@headlessui/vue'

import {
  XMarkIcon,
  CheckIcon,
  UserPlusIcon
} from '@heroicons/vue/24/outline'

export default defineComponent({
  components: {
    // ArrowRightCircleIcon,
    ErrorMessage,
    XMarkIcon,
    CheckIcon,
    UserPlusIcon,
    Dialog,
    TransitionChild,
    TransitionRoot
  },
  setup () {
    const route = useRoute()
    // const router = useRouter()
    const { attendState, searchAttendViaPaymentLink, checkoutAttendState, checkoutAttend } = useETickets()

    const formState = reactive({
      delegateEditIndex: -1,
      openAddDelegate: false,
      addDelegateAction: false,
      addDelegateError: responseHelper.noError,

      delegateFirstName: '',
      delegateLastName: '',
      delegateEmail: '',
      delegateChurch: '',
      delegateChurchTownCity: '',

      childDelegateEditIndex: -1,
      openAddChildDelegate: false,
      addChildDelegateAction: false,
      addChildDelegateError: responseHelper.noError,

      childFirstName: '',
      childLastName: '',
      childAge: 0,
      childGender: ''
    })

    // if (!store.getters.isLoggedIn) {
    //   console.log('Attend,,, not logged in')
    //   router.push('/sign-in?return=' + encodeURIComponent(route.path))
    // }

    onMounted(() => {
      searchAttendViaPaymentLink({
        id: route.params.id
      }).then((result) => {
        if (result) {
          checkoutAttendState.email = result.profile.email
          checkoutAttendState.firstName = result.profile.firstName
          checkoutAttendState.lastName = result.profile.lastName
        }
      })
    })

    const addDelegate = () => {
      if (checkoutAttendState.quantity < attendState.eTicket.ticketsPerOrder) {
        if (validateDelegate()) {
          checkoutAttendState.quantity++
          attendState.delegates.push({
            firstName: formState.delegateFirstName,
            lastName: formState.delegateLastName,
            email: formState.delegateEmail,
            church: formState.delegateChurch,
            churchTownCity: formState.delegateChurchTownCity,
            position: formState.delegatePosition ?? undefined
          })
          formState.openAddDelegate = false
        }
      }
    }
    const validateDelegate = () => {
      formState.addDelegateError = responseHelper.noError

      if (!formState.delegateFirstName || formState.delegateFirstName.length > 30) {
        formState.addDelegateError = responseHelper.createError('Validation failed', ['First name is required. 30 characters max'])
        return false
      }
      if (!formState.delegateLastName || formState.delegateLastName.length > 30) {
        formState.addDelegateError = responseHelper.createError('Validation failed', ['Last name is required. 30 characters max'])
        return false
      }
      if (!validateEmail(formState.delegateEmail)) {
        formState.addDelegateError = responseHelper.createError('Validation failed', ['A valid email is required'])
        return false
      }
      if (formState.delegateChurch && formState.delegateChurch.length > 30) {
        formState.addDelegateError = responseHelper.createError('Validation failed', ['Church/Ministry Name is 30 characters max'])
        return false
      }
      if (formState.delegateChurchTownCity && formState.delegateChurchTownCity.length > 30) {
        formState.addDelegateError = responseHelper.createError('Validation failed', ['Church/Ministry Town/City is 30 characters max'])
        return false
      }
      if (attendState.eTicket.delegateTypes === 3) {
        if (!formState.delegateChurch || formState.delegateChurch.length < 3) {
          formState.addDelegateError = responseHelper.createError('Validation failed', ['Church/Ministry Name is required between 2 and 30 characters'])
          return false
        }
        if (!formState.delegatePosition || formState.delegatePosition.length < 3 || formState.delegatePosition.length > 30) {
          formState.addDelegateError = responseHelper.createError('Validation failed', ['Church/Ministry Position/Role is required between 2 and 30 characters'])
          return false
        }
        if (!formState.delegateChurchTownCity || formState.delegateChurchTownCity.length < 3 || formState.delegateChurchTownCity.length > 30) {
          formState.addDelegateError = responseHelper.createError('Validation failed', ['Church/Ministry Town/City is required between 2 and 30 characters'])
          return false
        }
      }
      return true
    }
    const validateEmail = (email) => {
      return String(email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
    }

    const addChildDelegate = () => {
      if (checkoutAttendState.childQuantity < attendState.eTicket.childrenPerOrder) {
        if (validateChildDelegate()) {
          checkoutAttendState.childQuantity++
          attendState.childDelegates.push({
            firstName: formState.childFirstName,
            lastName: formState.childLastName,
            age: formState.childAge,
            gender: formState.childGender
          })
          formState.openAddChildDelegate = false
        }
      }
    }
    const validateChildDelegate = () => {
      formState.addChildDelegateError = responseHelper.noError

      if (!formState.childFirstName || formState.childFirstName.length > 30) {
        formState.addChildDelegateError = responseHelper.createError('Please correct', ['First name is required. 30 characters max'])
        return false
      }
      if (!formState.childLastName || formState.childLastName.length > 30) {
        formState.addChildDelegateError = responseHelper.createError('Please correct', ['Last name is required. 30 characters max'])
        return false
      }
      if (!isInt(formState.childAge)) {
        formState.addChildDelegateError = responseHelper.createError('Please correct', ['Age (whole years) is required. Less than 18'])
        return false
      }
      var age = parseInt(formState.childAge)
      if (age < 0 || age > 17) {
        formState.addChildDelegateError = responseHelper.createError('Please correct', ['Age (whole years) is required. Less than 18'])
        return false
      }
      if (!formState.childGender || (formState.childGender !== 'M' && formState.childGender !== 'F')) {
        formState.addChildDelegateError = responseHelper.createError('Please correct', ['Gender is required.'])
        return false
      }

      return true
    }
    const isInt = (value) => {
      if (isNaN(value)) {
        return false
      }
      var x = parseFloat(value)
      return (x | 0) === x
    }

    const editDelegate = (index) => {
      formState.delegateFirstName = attendState.delegates[index].firstName
      formState.delegateLastName = attendState.delegates[index].lastName
      formState.delegateEmail = attendState.delegates[index].email
      formState.delegateChurch = attendState.delegates[index].church
      formState.delegateChurchTownCity = attendState.delegates[index].churchTownCity
      formState.delegateEditIndex = index
      formState.openAddDelegate = true
    }
    const editChildDelegate = (index) => {
      formState.childFirstName = attendState.childDelegates[index].firstName
      formState.childLastName = attendState.childDelegates[index].lastName
      formState.childAge = attendState.childDelegates[index].age
      formState.childGender = attendState.childDelegates[index].gender
      formState.childDelegateEditIndex = index
      formState.openAddChildDelegate = true
    }
    const removeDelegate = (index) => {
      if (checkoutAttendState.quantity > 0) {
        console.log(attendState.delegates, index)
        checkoutAttendState.quantity--
        attendState.delegates.splice(index, 1)
      }
    }
    const removeChildDelegate = (index) => {
      if (checkoutAttendState.childQuantity > 0) {
        console.log(attendState.childDelegates, index)
        checkoutAttendState.childQuantity--
        attendState.childDelegates.splice(index, 1)
      }
    }

    const addStandard = () => {
      if (checkoutAttendState.quantity < attendState.eTicket.ticketsPerOrder) {
        // reset form
        formState.delegateFirstName = ''
        formState.delegateLastName = ''
        formState.delegateEmail = ''
        formState.delegateChurch = ''
        formState.delegateChurchTownCity = ''
        formState.addDelegateError = responseHelper.noError
        // show dialog
        formState.delegateEditIndex = -1
        formState.openAddDelegate = true
      }
    }
    const addChild = () => {
      if (checkoutAttendState.childQuantity < attendState.eTicket.childrenPerOrder) {
        // reset form
        formState.childFirstName = ''
        formState.childLastName = ''
        formState.childAge = ''
        formState.childGender = ''
        formState.addChildDelegateError = responseHelper.noError
        // show dialog
        formState.childDelegateEditIndex = -1
        formState.openAddChildDelegate = true
      }
    }
    const updateStandard = () => {
      if (formState.delegateEditIndex >= 0) {
        if (validateDelegate()) {
          const index = formState.delegateEditIndex
          // udpate delegate
          attendState.delegates[index].firstName = formState.delegateFirstName
          attendState.delegates[index].lastName = formState.delegateLastName
          attendState.delegates[index].email = formState.delegateEmail
          attendState.delegates[index].church = formState.delegateChurch
          attendState.delegates[index].churchTownCity = formState.delegateChurchTownCity
          // close dialog
          formState.openAddDelegate = false
        }
      }
    }
    const updateChild = () => {
      if (formState.childDelegateEditIndex >= 0) {
        if (validateChildDelegate()) {
          const index = formState.childDelegateEditIndex
          // udpate child delegate
          attendState.childDelegates[index].firstName = formState.childFirstName
          attendState.childDelegates[index].lastName = formState.childLastName
          attendState.childDelegates[index].age = formState.childAge
          attendState.childDelegates[index].gender = formState.childGender
          // close dialog
          formState.openAddChildDelegate = false
        }
      }
    }
    const removeStandard = (idx) => {
      if (checkoutAttendState.quantity > 1) {
        checkoutAttendState.quantity--
      }
      console.log('removeStandard', idx)
    }
    const removeChild = (idx) => {
      if (checkoutAttendState.childQuantity > 1) {
        checkoutAttendState.childQuantity--
      }
      console.log('removeChild', idx)
    }

    const checkout = () => {
      checkoutAttendState.delegates = attendState.delegates.slice(0, checkoutAttendState.quantity)
      checkoutAttendState.childDelegates = attendState.childDelegates.slice(0, checkoutAttendState.childQuantity)
      checkoutAttendState.attendanceOptions = attendState.attendanceOptions.slice(0)
      checkoutAttendState.paymentLinkId = route.params.id
      checkoutAttend(attendState.eTicket)
        .then((result) => {
          if (result) {
            window.location.replace(result.sessionUrl)
          } else {
            console.log('invalid result')
          }
        })
        .catch(() => { })
    }

    const toMemberPrice = (eTicket) => {
      let priceString
      if (eTicket.memberPrice > 0) {
        priceString = '£' + Number.parseFloat(eTicket.memberPrice).toFixed(2)
      } else {
        priceString = 'FREE'
      }
      return priceString
    }

    const attendanceSelectionCount = computed(() => {
      var attendCount = 0
      if (attendState && attendState.attendanceOptions.length > 0) {
        for (var i = 0; i < attendState.attendanceOptions.length; i++) {
          if ('attend' in attendState.attendanceOptions[i] && attendState.attendanceOptions[i].attend === true) {
            attendCount++
          }
        }
      }
      return attendCount
    })

    const toggleAttendance = (option) => {
      if ('attend' in option) {
        option.attend = !option.attend
      } else {
        option.attend = true
      }
    }

    return {
      toggleAttendance,
      attendanceSelectionCount,
      ticketTypes,
      checkoutAttendState,
      attendState,
      checkout,
      DateTime,
      toMemberPrice,
      addStandard,
      removeStandard,
      addChild,
      removeChild,
      formState,
      addDelegate,
      addChildDelegate,
      editDelegate,
      editChildDelegate,
      removeDelegate,
      removeChildDelegate,
      updateStandard,
      updateChild
    }
  },
  computed: {
    ...mapGetters([
      'isLoggedIn'
    ])
  }
})
</script>
