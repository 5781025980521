<template>
<main>
  <LiveNow></LiveNow>
  <div class="bg-black bg-contain bg-no-repeat bg-right-top" style="background-image:linear-gradient(to left bottom, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2), rgba(42, 131, 161, 0.4), rgba(42, 131, 161, .6));">
    <div class="max-w-7xl mx-auto py-24 px-4 sm:px-6 lg:px-8">
      <div class="sm:flex sm:flex-col align-center">
        <h1 class="text-3xl font-extrabold text-white text-center">We Arise</h1>
      </div>
    </div>
  </div>
  <div class="flex flex-col justify-center py-12 sm:px-6 lg:px-8">
    <div class="sm:mx-auto sm:w-full sm:max-w-3xl">
      <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">

    <p class="lyrics">Our wrestle is not against flesh and blood but against principalities, against powers, against the rulers of the darkness of this age, against<br>
                                                      spiritual hosts of wickedness in heavenly places and the weapons of our warfare are not physical weapons but they are mighty in God for<br>
                                                      pulling down strongholds, casting down arguments and every high thing that exalts itself against the knowledge of God, bringing every thought<br>
                                                      into captivity to the obedience of Christ, therefore we put on God’s complete armour of light, Praying in the Spirit on every occasion, in every<br>
                                                      season with all manner of prayers…</p>

    <p class="lyrics"><i>We arise in the Spirit<br>We arise in the Spirit<br>We arise, we arise<br>In the Spirit</i></p>

    <p class="lyrics">We will not forfeit our inheritance in God by cohabiting with the enemy we are called to evict<br>
                                                      We make a declaration of war and there will be no truce…<br>
                                                      We declare war on complacency, addictions and sin cycles,<br>
                                                      We declare war on compromise, lukewarmness and prayelessness<br>
                                                      We declare war on, depression, discouragement, doubt, distress, disappointment and disillusionment!<br>
                                                      We Arise In The Spirit!!!</p>

    <p class="lyrics"><i>We arise in the Spirit<br>We arise in the Spirit<br>We arise, we arise<br>In the Spirit</i></p>

    <p class="lyrics">Father in the Name of Jesus<br>
                                                      We take authority over distractions<br>
                                                      Expose deceptions<br>
                                                      Silence accusations<br>
                                                      Refuse and resist manipulations<br>
                                                      We Intercept, prohibit and nullify all satanic projections<br>
                                                      By the Fire of the Holy Spirit we destroy diabolical associations<br>
                                                      By the blood of Jesus we cleanse all contaminations<br>
                                                      We superimpose the decrees of heaven over the oracles of darkness.<br>
                                                      We call all things into divine alignment.</p>

    <p class="lyrics">We will not conform to the world and it’s systems we change the system<br>
                                                      We are a counter cultural resistance to the moral decline of this day.<br>
                                                      We are not concerned about being celebrities on the earth we are a voice in the heavens… shifting principalities and powers, advancing God’s<br>
                                                      kingdom purposes, preparing the way of the Lord…</p>

    <p class="lyrics">We are end time warriors in an end time army, gaining ground for the Kingdom of God in our generation.<br>
                                                      We take up our positions of war and we sharpen our swords<br>
                                                      We take up the shield of faith as we stand in our battle ranks rightly aligned next to one another ready to advance<br>
                                                      We are fierce, we are focused and we are on fire<br>
                                                      We understand the severity of the battle<br>
                                                      We are the army of God and we will not retreat and we will not surrender!</p>

    <p class="composer">Lisa Burrell, Rebecca Aladiran, James Aladiran<br>
                                                      © 2017 Prayer Storm Music</p>

      </div>
    </div>
  </div>
</main>

</template>
<script>
import LiveNow from '@/components/LiveNow.vue'
export default {
  components: {
    LiveNow
  },
  setup () {
    return {
    }
  }
}
</script>

<style scoped>
.lyrics {
  @apply mt-8 font-medium text-gray-700
}
.composer {
  @apply mt-8 text-sm mb-10 text-gray-700
}
</style>
