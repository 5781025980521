<template>
  <div v-if="donationState.sessionUrl || donationState.donation" class="relative bg-gray-900">
    <div class="absolute inset-0 bg-cover" aria-hidden="true" style="background-image: linear-gradient(to left bottom, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url('https://pslive.blob.core.windows.net/images/event/e4096880-dabd-11ee-85ee-2b4ea12a5736-event.jpg');" />
    <!-- <img src="" alt="" class="absolute inset-0 -z-10 h-full w-full object-cover" /> -->
    <div class="relative px-6 lg:px-8">
      <div class="mx-auto max-w-2xl py-24 sm:py-32">
        <div class="text-center">
          <h1 class="text-4xl font-bold tracking-tight text-white sm:text-6xl" id="setup-heading">Please Setup Your Support Payment</h1>
          <div v-if="donationState.sessionUrl">
            <p class="mt-6 text-lg leading-8 text-gray-300">
              Your browser should auto redirect to our external payment provider. If not, then please click the link below to setup your online payment.
            </p>
            <p class="mt-6 text-lg leading-8 text-gray-300">
              <a :href="donationState.sessionUrl" class="border-2 border-blue rounded-md bg-blue px-3 py-2 text-xl font-semibold text-white shadow-sm hover:bg-blue-dark focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue">Redirect to Setup Payment -></a>
            </p>
          </div>
          <div v-else>
            <p class="mt-6 text-lg text-gray-300">Please setup your support via your online/telephone banking system. <br />Please use the following details:</p>
            <h2 class="mt-6 text-lg text-white font-bold">UK Bank Transfer Details</h2>
            <p class="mt-3 text-lg text-gray-300">
              Bank <br/><strong class="text-white">Lloyds Bank</strong> <br/>
              Account Name <br/><strong class="text-white">Prayer Storm</strong> <br/>
              Sort Code <br/><strong class="text-white">77 19 32</strong> <br/>
              Account Number <br/><strong class="text-white">19156168</strong> <br/>
              Amount <br/><strong class="text-white">{{ donationState.donation.currency }} {{ donationState.donation.donationAmount }}</strong> <br/>
              Frequency <br/><strong class="text-white">{{ donationState.donation.donationTypeDescription }}</strong> <br/>
              Payment Reference <br/><strong class="text-white">PSD{{ donationState.donation.donationType }}{{ donationState.donation.id }}</strong> <br/>
            </p>
            <h2 class="mt-6 text-lg text-white font-bold">International Bank Transfer Details</h2>
            <p class="mt-3 text-lg text-gray-300">
              BIC <br/><strong>LOYDGB21N95</strong> <br/>
              IBAN <br/><strong>GB83LOYD77193219156168</strong> <br/>
              Amount <br/><strong class="text-white">{{ donationState.donation.currency }} {{ donationState.donation.donationAmount }}</strong> <br/>
              Frequency <br/><strong class="text-white">{{ donationState.donation.donationTypeDescription }}</strong> <br/>
              Payment Reference <br/><strong class="text-white">PSD{{ donationState.donation.donationType }}{{ donationState.donation.id }}</strong> <br/>
            </p>
            <p class="mt-6 text-lg text-gray-300">Thank you for your donation to Prayer Storm. <br />We receive your gifts with gratitude.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <div class="relative bg-gray-900">
      <div class="absolute inset-0 bg-cover" aria-hidden="true" style="background-image: linear-gradient(to left bottom, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), url('https://pslive.blob.core.windows.net/images/event/e4096880-dabd-11ee-85ee-2b4ea12a5736-event.jpg');" />
      <!-- <img src="" alt="" class="absolute inset-0 -z-10 h-full w-full object-cover" /> -->
      <div class="relative px-6 lg:px-8">
        <div class="mx-auto max-w-4xl py-12 sm:py-24">
          <div class="text-center">
            <h1 class="text-4xl font-bold tracking-tight text-white sm:text-5xl">Prayer Storm Conference Update 2</h1>

            <div class="mt-6">
              <div style="padding:56.25% 0 0 0;position:relative;"><iframe src="https://player.vimeo.com/video/908735545?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" style="position:absolute;top:0;left:0;width:100%;height:100%;" title="Prayer Storm Conf. 2024 Update"></iframe></div>
            </div>
            <div class="mt-10 flex items-center justify-center gap-x-6">
              <button @click.prevent="scrollToInput('donate-form')" class="border-2 border-blue rounded-md bg-blue px-3 py-2 text-xl font-semibold text-white shadow-sm hover:bg-blue-dark focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue">Donate &amp; Support Now</button>
            </div>
            <p class="mt-10 text-lg leading-8 text-white font-bold">Thank you for your support of Prayer Storm Conference 2024.</p>
            <p class="mt-6 text-lg leading-8 text-white">Prayer Storm Conference 2024 Venue:</p>
            <p class="mt-3 text-lg leading-8 text-white font-bold">The Sheridan Suite, 371 Oldham Road, Miles Platting, Manchester, M40 8RR, UK</p>
            <p class="mt-6 text-lg leading-8 text-gray-300 italic">
              Please complete the form below to setup your support. After completing our record below, you will be redirected to our external payment provider to complete the payment setup. If you have any issues completing the setup or any card verification steps then please feel free to contact finance@prayerstorm.org
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="overflow-hidden bg-white py-16 px-4 sm:px-6 lg:px-8 lg:py-24">
      <div class="relative mx-auto max-w-xl">
        <svg class="absolute left-full translate-x-1/2 transform" width="404" height="404" fill="none" viewBox="0 0 404 404" aria-hidden="true">
          <defs>
            <pattern id="85737c0e-0916-41d7-917f-596dc7edfa27" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
              <rect x="0" y="0" width="4" height="4" class="text-gray-200" fill="currentColor" />
            </pattern>
          </defs>
          <rect width="404" height="404" fill="url(#85737c0e-0916-41d7-917f-596dc7edfa27)" />
        </svg>
        <svg class="absolute right-full bottom-0 -translate-x-1/2 transform" width="404" height="404" fill="none" viewBox="0 0 404 404" aria-hidden="true">
          <defs>
            <pattern id="85737c0e-0916-41d7-917f-596dc7edfa27" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
              <rect x="0" y="0" width="4" height="4" class="text-gray-200" fill="currentColor" />
            </pattern>
          </defs>
          <rect width="404" height="404" fill="url(#85737c0e-0916-41d7-917f-596dc7edfa27)" />
        </svg>
        <div class="">
          <form class="grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8" id="donate-form" @submit.prevent>
            <div class="text-center sm:col-span-2">
              <h2 class="text-xl font-bold tracking-tight text-gray-900 sm:text-2xl">Conference Support Details</h2>
            </div>
            <!-- <div class="sm:col-span-2">
              <label for="donation-method" class="block text-sm font-medium text-gray-700">How do you want to give?</label>
              <div class="relative mt-1 rounded-md shadow-sm">
                <select v-model="donationState.method" id="donation-method" name="donation-method" class="mt-1 block w-full border border-gray-300 rounded-md py-3 px-4 focus:outline-none focus:ring-blue focus:border-blue">
                  <option value="">Please select...</option>
                  <option value="1">Bank Transfer</option>
                  <option value="2">Online Card Payment (via Stripe)</option>
                </select>
              </div>
            </div> -->
            <div>
              <label for="donation-currency" class="block text-sm font-medium text-gray-700">Currency</label>
              <div class="relative mt-1 rounded-md shadow-sm">
                <select v-model="donationState.currency" id="donation-currency" name="donation-currency" class="mt-1 block w-full border border-gray-300 rounded-md py-3 px-4 focus:outline-none focus:ring-blue focus:border-blue">
                  <option v-for="item in currencies" :key="item.value" :value="item.value">{{item.text}}</option>
                </select>
              </div>
            </div>
            <!-- <div>
              <label for="donation-type" class="block text-sm font-medium text-gray-700">Frequency</label>
              <div class="relative mt-1 rounded-md shadow-sm">
                <select v-model="donationState.donationType" id="donation-type" name="donation-type" class="mt-1 block w-full border border-gray-300 rounded-md py-3 px-4 focus:outline-none focus:ring-blue focus:border-blue">
                  <option value="">Please select...</option>
                  <option value="1">One-off</option>
                  <option value="2">Weekly</option>
                  <option value="3">Monthly</option>
                  <option value="4">Quarterly</option>
                  <option value="5">Yearly</option>
                </select>
              </div>
            </div> -->
            <div>
              <label for="donation-amount" class="block text-sm font-medium text-gray-700">Amount</label>
              <div class="flex relative mt-1 rounded-md shadow-sm">
                <span class="inline-flex items-center rounded-l-md border border-r-0 border-gray-300 bg-gray-50 py-3 px-4 text-gray-500">{{donationState.currency}}</span>
                <input v-model="donationState.amount" type="number" name="donation-amount" id="donation-amount" class="block w-full min-w-0 flex-1 rounded-none rounded-r-md border-gray-300 focus:border-blue focus:ring-blue" />
              </div>
            </div>
            <div class="text-center sm:col-span-2">
              <h2 class="pt-4 text-xl font-bold tracking-tight text-gray-900 sm:text-2xl">Your Details</h2>
            </div>
            <div>
              <label for="first-name" class="block text-sm font-medium text-gray-700">First name</label>
              <div class="mt-1">
                <input v-model="donationState.firstName" type="text" name="first-name" id="first-name" autocomplete="given-name" class="block w-full rounded-md border-gray-300 py-3 px-4 shadow-sm focus:border-blue focus:ring-blue" />
              </div>
            </div>
            <div>
              <label for="last-name" class="block text-sm font-medium text-gray-700">Last name</label>
              <div class="mt-1">
                <input v-model="donationState.lastName" type="text" name="last-name" id="last-name" autocomplete="family-name" class="block w-full rounded-md border-gray-300 py-3 px-4 shadow-sm focus:border-blue focus:ring-blue" />
              </div>
            </div>
            <div class="sm:col-span-2">
              <label for="email" class="block text-sm font-medium text-gray-700">Email</label>
              <div class="mt-1">
                <input v-model="donationState.email" id="email" name="email" type="email" autocomplete="email" class="block w-full rounded-md border-gray-300 py-3 px-4 shadow-sm focus:border-blue focus:ring-blue" />
              </div>
            </div>
            <div class="sm:col-span-2">
              <label for="tel" class="block text-sm font-medium text-gray-700">Telephone</label>
              <div class="mt-1">
                <input v-model="donationState.tel" id="tel" name="tel" type="tel" autocomplete="tel" class="block w-full rounded-md border-gray-300 py-3 px-4 shadow-sm focus:border-blue focus:ring-blue" />
              </div>
            </div>
            <div class="sm:col-span-2">
              <label for="country" class="block text-sm font-medium text-gray-700">Country</label>
              <div class="mt-1">
                <select v-model="donationState.country" id="country" name="country" class="mt-1 block w-full border border-gray-300 rounded-md py-3 px-4 focus:outline-none focus:ring-blue focus:border-blue">
                  <option value="">Please specify...</option>
                  <option value="AF">Afghanistan</option>
                  <option value="AL">Albania</option>
                  <option value="DZ">Algeria</option>
                  <option value="AS">American Samoa</option>
                  <option value="AD">Andorra</option>
                  <option value="AO">Angola</option>
                  <option value="AI">Anguilla</option>
                  <option value="AQ">Antarctica</option>
                  <option value="AG">Antigua and Barbuda</option>
                  <option value="AR">Argentina</option>
                  <option value="AM">Armenia</option>
                  <option value="AW">Aruba</option>
                  <option value="AU">Australia</option>
                  <option value="AT">Austria</option>
                  <option value="AZ">Azerbaijan</option>
                  <option value="BS">Bahamas</option>
                  <option value="BH">Bahrain</option>
                  <option value="BD">Bangladesh</option>
                  <option value="BB">Barbados</option>
                  <option value="BY">Belarus</option>
                  <option value="BE">Belgium</option>
                  <option value="BZ">Belize</option>
                  <option value="BJ">Benin</option>
                  <option value="BM">Bermuda</option>
                  <option value="BT">Bhutan</option>
                  <option value="BO">Bolivia (Plurinational State of)</option>
                  <option value="BQ">Bonaire, Saint Eustatius and Saba</option>
                  <option value="BA">Bosnia and Herzegovina</option>
                  <option value="BW">Botswana</option>
                  <option value="BV">Bouvet Island</option>
                  <option value="BR">Brazil</option>
                  <option value="IO">British Indian Ocean Territory</option>
                  <option value="BN">Brunei Darussalam</option>
                  <option value="BG">Bulgaria</option>
                  <option value="BF">Burkina Faso</option>
                  <option value="BI">Burundi</option>
                  <option value="CV">Cabo Verde</option>
                  <option value="KH">Cambodia</option>
                  <option value="CM">Cameroon</option>
                  <option value="CA">Canada</option>
                  <option value="KY">Cayman Islands</option>
                  <option value="CF">Central African Republic</option>
                  <option value="TD">Chad</option>
                  <option value="CL">Chile</option>
                  <option value="CN">China</option>
                  <option value="CX">Christmas Island</option>
                  <option value="CC">Cocos (Keeling) Islands</option>
                  <option value="CO">Colombia</option>
                  <option value="KM">Comoros</option>
                  <option value="CG">Congo</option>
                  <option value="CD">Congo (Democratic Republic of the)</option>
                  <option value="CK">Cook Islands</option>
                  <option value="CR">Costa Rica</option>
                  <option value="HR">Croatia</option>
                  <option value="CU">Cuba</option>
                  <option value="CW">Curaçao</option>
                  <option value="CY">Cyprus</option>
                  <option value="CZ">Czech Republic</option>
                  <option value="CI">Côte d'Ivoire</option>
                  <option value="DK">Denmark</option>
                  <option value="DJ">Djibouti</option>
                  <option value="DM">Dominica</option>
                  <option value="DO">Dominican Republic</option>
                  <option value="EC">Ecuador</option>
                  <option value="EG">Egypt</option>
                  <option value="SV">El Salvador</option>
                  <option value="GQ">Equatorial Guinea</option>
                  <option value="ER">Eritrea</option>
                  <option value="EE">Estonia</option>
                  <option value="SZ">Eswatini (Kingdom of)</option>
                  <option value="ET">Ethiopia</option>
                  <option value="FK">Falkland Islands (Malvinas)</option>
                  <option value="FO">Faroe Islands</option>
                  <option value="FJ">Fiji</option>
                  <option value="FI">Finland</option>
                  <option value="FR">France</option>
                  <option value="GF">French Guiana</option>
                  <option value="PF">French Polynesia</option>
                  <option value="TF">French Southern Territories</option>
                  <option value="GA">Gabon</option>
                  <option value="GM">Gambia</option>
                  <option value="GE">Georgia</option>
                  <option value="DE">Germany</option>
                  <option value="GH">Ghana</option>
                  <option value="GI">Gibraltar</option>
                  <option value="GR">Greece</option>
                  <option value="GL">Greenland</option>
                  <option value="GD">Grenada</option>
                  <option value="GP">Guadeloupe</option>
                  <option value="GU">Guam</option>
                  <option value="GT">Guatemala</option>
                  <option value="GG">Guernsey</option>
                  <option value="GN">Guinea</option>
                  <option value="GW">Guinea-Bissau</option>
                  <option value="GY">Guyana</option>
                  <option value="HT">Haiti</option>
                  <option value="HM">Heard Island and McDonald Islands</option>
                  <option value="HN">Honduras</option>
                  <option value="HK">Hong Kong</option>
                  <option value="HU">Hungary</option>
                  <option value="IS">Iceland</option>
                  <option value="IN">India</option>
                  <option value="ID">Indonesia</option>
                  <option value="IR">Iran (Islamic Republic of)</option>
                  <option value="IQ">Iraq</option>
                  <option value="IE">Ireland (Republic of)</option>
                  <option value="IM">Isle of Man</option>
                  <option value="IL">Israel</option>
                  <option value="IT">Italy</option>
                  <option value="JM">Jamaica</option>
                  <option value="JP">Japan</option>
                  <option value="JE">Jersey</option>
                  <option value="JO">Jordan</option>
                  <option value="KZ">Kazakhstan</option>
                  <option value="KE">Kenya</option>
                  <option value="KI">Kiribati</option>
                  <option value="KP">Korea (Democratic People's Republic of)</option>
                  <option value="KR">Korea (Republic of)</option>
                  <option value="XK">Kosovo</option>
                  <option value="KW">Kuwait</option>
                  <option value="KG">Kyrgyzstan</option>
                  <option value="LA">Lao People's Democratic Republic</option>
                  <option value="LV">Latvia</option>
                  <option value="LB">Lebanon</option>
                  <option value="LS">Lesotho</option>
                  <option value="LR">Liberia</option>
                  <option value="LY">Libya</option>
                  <option value="LI">Liechtenstein</option>
                  <option value="LT">Lithuania</option>
                  <option value="LU">Luxembourg</option>
                  <option value="MO">Macao</option>
                  <option value="MG">Madagascar</option>
                  <option value="MW">Malawi</option>
                  <option value="MY">Malaysia</option>
                  <option value="MV">Maldives</option>
                  <option value="ML">Mali</option>
                  <option value="MT">Malta</option>
                  <option value="MH">Marshall Islands</option>
                  <option value="MQ">Martinique</option>
                  <option value="MR">Mauritania</option>
                  <option value="MU">Mauritius</option>
                  <option value="YT">Mayotte</option>
                  <option value="MX">Mexico</option>
                  <option value="FM">Micronesia (Federated States of)</option>
                  <option value="MD">Moldova (Republic of)</option>
                  <option value="MC">Monaco</option>
                  <option value="MN">Mongolia</option>
                  <option value="ME">Montenegro</option>
                  <option value="MS">Montserrat</option>
                  <option value="MA">Morocco</option>
                  <option value="MZ">Mozambique</option>
                  <option value="MM">Myanmar</option>
                  <option value="NA">Namibia</option>
                  <option value="NR">Nauru</option>
                  <option value="NP">Nepal</option>
                  <option value="NL">Netherlands</option>
                  <option value="NC">New Caledonia</option>
                  <option value="NZ">New Zealand</option>
                  <option value="NI">Nicaragua</option>
                  <option value="NE">Niger</option>
                  <option value="NG">Nigeria</option>
                  <option value="NU">Niue</option>
                  <option value="NF">Norfolk Island</option>
                  <option value="MK">North Macedonia (Republic of)</option>
                  <option value="MP">Northern Mariana Islands</option>
                  <option value="NO">Norway</option>
                  <option value="OM">Oman</option>
                  <option value="PK">Pakistan</option>
                  <option value="PW">Palau</option>
                  <option value="PS">Palestine (State of)</option>
                  <option value="PA">Panama</option>
                  <option value="PG">Papua New Guinea</option>
                  <option value="PY">Paraguay</option>
                  <option value="PE">Peru</option>
                  <option value="PH">Philippines</option>
                  <option value="PN">Pitcairn</option>
                  <option value="PL">Poland</option>
                  <option value="PT">Portugal</option>
                  <option value="PR">Puerto Rico</option>
                  <option value="QA">Qatar</option>
                  <option value="RO">Romania</option>
                  <option value="RU">Russian Federation</option>
                  <option value="RW">Rwanda</option>
                  <option value="RE">Réunion</option>
                  <option value="BL">Saint Barthélemy</option>
                  <option value="SH">Saint Helena, Ascension and Tristan da Cunha</option>
                  <option value="KN">Saint Kitts and Nevis</option>
                  <option value="LC">Saint Lucia</option>
                  <option value="MF">Saint Martin (French part)</option>
                  <option value="PM">Saint Pierre and Miquelon</option>
                  <option value="VC">Saint Vincent and the Grenadines</option>
                  <option value="WS">Samoa</option><option value="SM">San Marino</option>
                  <option value="ST">Sao Tome and Principe</option>
                  <option value="SA">Saudi Arabia</option>
                  <option value="SN">Senegal</option>
                  <option value="RS">Serbia</option>
                  <option value="SC">Seychelles</option>
                  <option value="SL">Sierra Leone</option>
                  <option value="SG">Singapore</option>
                  <option value="SX">Sint Maarten (Dutch part)</option>
                  <option value="SK">Slovakia</option>
                  <option value="SI">Slovenia</option>
                  <option value="SB">Solomon Islands</option>
                  <option value="SO">Somalia</option>
                  <option value="ZA">South Africa</option>
                  <option value="GS">South Georgia and the South Sandwich Islands</option>
                  <option value="SS">South Sudan</option>
                  <option value="ES">Spain</option>
                  <option value="LK">Sri Lanka</option>
                  <option value="SD">Sudan</option>
                  <option value="SR">Suriname</option>
                  <option value="SJ">Svalbard and Jan Mayen</option>
                  <option value="SE">Sweden</option>
                  <option value="CH">Switzerland</option>
                  <option value="SY">Syrian Arab Republic</option>
                  <option value="TW">Taiwan, Province of China</option>
                  <option value="TJ">Tajikistan</option>
                  <option value="TZ">Tanzania (United Republic of)</option>
                  <option value="TH">Thailand</option>
                  <option value="TL">Timor-Leste</option>
                  <option value="TG">Togo</option>
                  <option value="TK">Tokelau</option>
                  <option value="TO">Tonga</option>
                  <option value="TT">Trinidad and Tobago</option>
                  <option value="TN">Tunisia</option>
                  <option value="TR">Turkey</option>
                  <option value="TM">Turkmenistan</option>
                  <option value="TC">Turks and Caicos Islands</option>
                  <option value="TV">Tuvalu</option>
                  <option value="UG">Uganda</option>
                  <option value="UA">Ukraine</option>
                  <option value="AE">United Arab Emirates</option>
                  <option value="GB">United Kingdom of Great Britain and Northern Ireland</option>
                  <option value="UM">United States Minor Outlying Islands</option>
                  <option value="US">United States of America</option>
                  <option value="UY">Uruguay</option>
                  <option value="UZ">Uzbekistan</option>
                  <option value="VU">Vanuatu</option>
                  <option value="VA">Vatican City State</option>
                  <option value="VE">Venezuela (Bolivarian Republic of)</option>
                  <option value="VN">Vietnam</option>
                  <option value="VG">Virgin Islands (British)</option>
                  <option value="VI">Virgin Islands (U.S.)</option>
                  <option value="WF">Wallis and Futuna</option>
                  <option value="EH">Western Sahara</option>
                  <option value="YE">Yemen</option>
                  <option value="ZM">Zambia</option>
                  <option value="ZW">Zimbabwe</option>
                  <option value="AX">Åland Islands</option>
                </select>
              </div>
            </div>
            <div class="sm:col-span-2">
              <label for="address-one" class="block text-sm font-medium text-gray-700">Address Line 1</label>
              <div class="mt-1">
                <input v-model="donationState.addressOne" type="text" name="address-one" id="address-one" autocomplete="off" class="block w-full rounded-md border-gray-300 py-3 px-4 shadow-sm focus:border-blue focus:ring-blue" />
              </div>
            </div>
            <div class="sm:col-span-2">
              <label for="address-two" class="block text-sm font-medium text-gray-700">Address Line 2</label>
              <div class="mt-1">
                <input v-model="donationState.addressTwo" type="text" name="address-two" id="address-two" autocomplete="off" class="block w-full rounded-md border-gray-300 py-3 px-4 shadow-sm focus:border-blue focus:ring-blue" />
              </div>
            </div>
            <div class="sm:col-span-2">
              <label for="town-city" class="block text-sm font-medium text-gray-700">Town/City</label>
              <div class="mt-1">
                <input v-model="donationState.townCity" type="text" name="town-city" id="town-city" autocomplete="off" class="block w-full rounded-md border-gray-300 py-3 px-4 shadow-sm focus:border-blue focus:ring-blue" />
              </div>
            </div>
            <div class="sm:col-span-2">
              <label for="county" class="block text-sm font-medium text-gray-700">County/State</label>
              <div class="mt-1">
                <input v-model="donationState.county"  type="text" name="county" id="county" autocomplete="off" class="block w-full rounded-md border-gray-300 py-3 px-4 shadow-sm focus:border-blue focus:ring-blue" />
              </div>
            </div>
            <div class="sm:col-span-2">
              <label for="post-code" class="block text-sm font-medium text-gray-700">Post/Zip Code</label>
              <div class="mt-1">
                <input v-model="donationState.postCode" type="text" name="post-code" id="post-code" autocomplete="off" class="block w-full rounded-md border-gray-300 py-3 px-4 shadow-sm focus:border-blue focus:ring-blue" />
              </div>
            </div>
            <div class="text-center sm:col-span-2">
              <h2 class="text-xl font-bold tracking-tight text-gray-900 sm:text-2xl">Gift Aid</h2>
            </div>
            <div class="sm:col-span-2">
              <label for="donation-tax-payer" class="block text-sm text-gray-700">Are you a U.K. (United Kingdom) tax payer?</label>
              <div class="relative mt-1 rounded-md shadow-sm">
                <select v-model="donationState.ukTax" autocomplete="off" id="donation-tax-payer" name="donation-tax-payer" class="mt-1 block w-full border border-gray-300 rounded-md py-3 px-4 focus:outline-none focus:ring-blue focus:border-blue">
                  <option value="">Please specify...</option>
                  <option value="1">Yes - I am a UK tax payer</option>
                  <option value="0">No - I am not</option>
                </select>
              </div>
            </div>
            <div v-show="donationState.ukTax === '1'" class="sm:col-span-2">
              <label for="donation-gift-aid" id="donation-gift-aid-label"  class="block text-sm font-medium text-gray-700">Would you like to <strong>Gift Aid</strong> your donations? <br/> It will increase your donation by <strong>25% at no extra cost to you</strong>?</label>
              <div class="relative mt-1 rounded-md shadow-sm">
                <select v-model="donationState.giftAid" @change="scrollToInput('donation-gift-aid-label')" id="donation-gift-aid" name="donation-gift-aid" class="mt-1 block w-full border border-gray-300 rounded-md py-3 px-4 focus:outline-none focus:ring-blue focus:border-blue">
                  <option value="">Please specify...</option>
                  <option value="1">Yes - add Gift Aid</option>
                  <option value="0">No - don't add Gift Aid</option>
                </select>
              </div>
            </div>
            <div v-show="donationState.ukTax === '1'" class="sm:col-span-2">
              <label for="previous" class="block text-sm font-medium text-gray-700">Have you previously submitted a Gift Aid declaration to Prayer Storm? This could be online, or via a giving envelope.</label>
              <div class="mt-1">
                <select v-model="donationState.previous" id="previous" name="previous" class="mt-1 block w-full border border-gray-300 rounded-md py-3 px-4 focus:outline-none focus:ring-blue focus:border-blue">
                  <option value="">Please specify...</option>
                  <option value="1">Yes - I have submitted Gift Aid details before</option>
                  <option value="0">No - This is my first Gift Aid declaration</option>
                </select>
              </div>
            </div>
            <div v-show="donationState.giftAid === '1' && donationState.ukTax === '1'" class="sm:col-span-2 rounded-md bg-gray-100 p-5">
              <div class="flex items-end mt-3">
                <p class="flex-1 text-lg leading-6 text-gray-500 font-bold">
                  Prayer Storm<br />
                  Reg. Charity No: 1161853
                </p>
                <img src="/img/gift-aid/gift-aid-it.png" class="w-1/4 object-right"/>
              </div>
              <p class='text-sm text-gray-700 mt-6 font-bold'>BOOST YOUR DONATION BY 25P OF GIFT AID FOR EVERY £1 YOU DONATE</p>
              <p class="text-sm text-gray-700 pt-1">Gift Aid is reclaimed by the charity from the tax you pay in the current tax year.</p>
              <p class='text-sm text-gray-700 pt-1 font-bold'>YOUR ADDRESS IS NEEDED ABOVE TO IDENTIFY YOU AS A CURRENT UK TAXPAYER.</p>
              <p class='text-lg text-gray-700 mt-6'><strong>Gift Aid Declaration</strong></p>
              <p class='text-sm text-gray-700 pt-3'>I want to Gift Aid my donation and any donations I make in the future or have made in the past 4 years to Prayer Storm.</p>
              <p class='text-sm text-gray-700 pt-5'>I am a UK tax payer and understand that if I pay less Income Tax and/or Capital Gains Tax than the amount of Gift Aid claimed on all my donations in that tax year it is my responsibility to pay any difference.</p>
              <p class='text-sm text-gray-700 pt-3'>I will notify Prayer Storm if I want to cancel this declaration, if I change my name or home address, or if I no longer pay sufficient tax on my income and/or capital gains.</p>
              <p class='text-sm text-gray-700 pt-3'>If you pay Income Tax at the higher or additional rate and want to receive the additional tax relief due to you, you must include all your Gift Aid donations on your Self-Assessment tax return or ask HM Revenue and Customs to adjust your tax code.</p>
              <p class='text-sm text-gray-700 pt-5 font-bold'>In order to Gift Aid, you must complete the form above and confirm the following statements.</p>
              <fieldset class="space-y-5">
                <legend class="sr-only">Gift Aid Declaration</legend>
                <div class="relative flex items-start">
                  <div class="flex h-5 items-center">
                    <input v-model="donationState.agreeOne" id="agree-one" aria-describedby="agree-one-description" name="agree-one" type="checkbox" class="h-4 w-4 rounded border-gray-300 text-blue focus:ring-blue" />
                  </div>
                  <div class="ml-3 text-sm">
                    <p id="agree-one-description" class="text-gray-700">This donation is my own money. It has not come from anyone else e.g. a business, friends, family or a collection.</p>
                  </div>
                </div>
                <div class="relative flex items-start">
                  <div class="flex h-5 items-center">
                    <input v-model="donationState.agreeTwo" id="agree-two" aria-describedby="agree-two-description" name="agree-two" type="checkbox" class="h-4 w-4 rounded border-gray-300 text-blue focus:ring-blue" />
                  </div>
                  <div class="ml-3 text-sm">
                    <p id="agree-two-description" class="text-gray-700">This donation is not the proceeds from the sale of goods or provision of services e.g. a cake sale, auction or car wash.</p>
                  </div>
                </div>
                <div class="relative flex items-start">
                  <div class="flex h-5 items-center">
                    <input v-model="donationState.agreeThree" id="agree-three" aria-describedby="agree-three-description" name="agree-three" type="checkbox" class="h-4 w-4 rounded border-gray-300 text-blue focus:ring-blue" />
                  </div>
                  <div class="ml-3 text-sm">
                    <p id="agree-three-description" class="text-gray-700">I am not receiving any benefits from this donation e.g. entry to an event, raffle or sweepstakes.</p>
                  </div>
                </div>
              </fieldset>
            </div>
            <div class="text-center sm:col-span-2">
              <h2 class="text-xl font-bold tracking-tight text-gray-900 sm:text-2xl">Notes</h2>
            </div>
            <div class="sm:col-span-2">
              <label for="message" class="block text-sm font-medium text-gray-700">Are there any additional notes that you would like to make in relation to this donation, past donations, or further information that you think may help us in identifying your previous donations or gift aid declarations?</label>
              <div class="mt-1">
                <textarea v-model="donationState.message" id="message" name="message" rows="4" class="block w-full rounded-md border-gray-300 py-3 px-4 shadow-sm focus:border-blue focus:ring-blue" />
              </div>
            </div>
            <div class="sm:col-span-2">
              <ErrorMessage :formError="donationState.error" class="mb-6"></ErrorMessage>
              <SuccessMessage :successMessage="donationState.result" class="mb-6"></SuccessMessage>
              <button type="button" @click.prevent="submitForm" :disabled="donationState.executing || donationState.result !== ''" class="disabled:opacity-50 inline-flex w-full items-center justify-center rounded-md border border-transparent bg-blue px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-blue-dark focus:outline-none focus:ring-2 focus:ring-blue focus:ring-offset-2">Continue: Setup Payment <span aria-hidden="true">&rarr;</span></button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, reactive } from 'vue'
import { useRoute } from 'vue-router'
import ErrorMessage from '@/components/ErrorMessage.vue'
import SuccessMessage from '@/components/SuccessMessage.vue'
import responseHelper from '../helpers/response.helper'
import { useCurrencies } from '../composables/useCurrencies'
import store from '../store'

export default defineComponent({
  components: {
    ErrorMessage,
    SuccessMessage
  },
  setup () {
    const route = useRoute()
    const { currencies } = useCurrencies()

    const donationState = reactive({
      donation: undefined,
      sessionUrl: '',
      firstName: store.getters.getFirstName,
      lastName: store.getters.getLastName,
      email: store.getters.getEmail,
      tel: '',
      country: '',
      addressOne: '',
      addressTwo: '',
      townCity: '',
      county: '',
      postCode: '',
      currency: 'GBP',
      donationType: '1',
      amount: '',
      method: '2',
      message: '',
      previous: '',
      ukTax: '',
      giftAid: '',
      agreeOne: false,
      agreeTwo: false,
      agreeThree: false,
      executing: false,
      error: responseHelper.noError,
      result: '',
      checkoutId: 0,
      campaignId: 1
    })

    if (route.params.checkoutSession) {
      store.dispatch('donation/setupDonation', {
        session: route.params.checkoutSession
      }).then((data) => {
        if (data) {
          donationState.checkoutId = data.id
          donationState.email = data.email
          donationState.firstName = data.firstName
          donationState.lastName = data.lastName
          donationState.addressOne = data.addressOne
          donationState.addressTwo = data.addressTwo
          donationState.townCity = data.townCity
          donationState.county = data.county
          donationState.postCode = data.postCode
          donationState.country = data.country
          donationState.tel = data.tel
        }
      }).catch((error) => {
        console.log(error)
      }).finally(() => {
      })
    }

    const submitForm = () => {
      // gift aid should not be set if not uk tax payer
      if (donationState.ukTax === '0') {
        donationState.giftAid = '0'
        donationState.previous = '0'
      }

      // if gift aid, then check all agreements have been made
      if (donationState.giftAid === '1') {
        if (!donationState.agreeOne || !donationState.agreeTwo || !donationState.agreeThree) {
          donationState.error = responseHelper.createError('Agreement Needed', ['Please read and confirm the Gift Aid declarations.'])
          return
        }
      }

      // check not executing action already
      if (donationState.executing) {
        return
      }

      // execute the remote action
      donationState.executing = true
      donationState.error = responseHelper.noError
      store.dispatch('donation/submitDonationCampaign', donationState)
        .then((response) => {
          if (response.sessionUrl) {
            donationState.sessionUrl = response.sessionUrl
            scrollToInput('setup-heading')
            window.location.replace(response.sessionUrl)
          } else if (response.donation) {
            donationState.donation = response.donation
            scrollToInput('setup-heading')
          } else {
            donationState.result = ''
            donationState.error = responseHelper.unexpectedResult
          }
        }).catch((error) => {
          donationState.error = responseHelper.getMessage(error)
        }).finally(() => {
          donationState.executing = false
        })
    }

    const scrollToInput = (elementId) => {
      setTimeout(() => {
        const scrollTo = document.getElementById(elementId)
        scrollTo.scrollIntoView({ behavior: 'smooth' })
      }, 200)
    }

    return {
      route,
      scrollToInput,
      donationState,
      submitForm,
      currencies
    }
  }
})
</script>
