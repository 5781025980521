<template>
<div>
<div class="bg-black" style="background-image:linear-gradient(to left bottom, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6), rgba(42, 131, 161, 0.4), rgba(42, 131, 161, .6)), url('/img/banners/ps-banner-4.jpg');">
  <div class="max-w-7xl mx-auto py-12 lg:py-24 px-4 sm:px-6 lg:px-8">
    <div class="sm:flex sm:flex-col sm:align-center">
      <h1 class="text-3xl font-extrabold text-white sm:text-center">Membership Plans</h1>
      <p class="mt-5 text-xl text-gray-400 sm:text-center">Become a member today! All plans help support the work of Prayer Storm.</p>
      <!-- <div class="relative self-center mt-6 bg-gray-100 rounded-lg p-0.5 flex sm:mt-8">
        <button type="button" class="relative w-1/2 bg-white border-gray-200 rounded-md shadow-sm py-2 text-sm font-medium text-gray-900 whitespace-nowrap focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:z-10 sm:w-auto sm:px-8">Monthly billing</button>
        <button type="button" class="ml-0.5 relative w-1/2 border border-transparent rounded-md py-2 text-sm font-medium text-gray-700 whitespace-nowrap focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:z-10 sm:w-auto sm:px-8">Yearly billing</button>
      </div> -->
    </div>
    <div class="mt-12 space-y-4 sm:mt-16 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-6 lg:max-w-4xl lg:mx-auto">
      <div v-for="membership in membershipsState.memberships" :key="membership.id" class="border border-gray-700 rounded-lg shadow-sm divide-y divide-gray-700 bg-black bg-opacity-50">
        <div class="p-6 bg-cover bg-right" :style="{ 'background-image': 'linear-gradient(to left bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0), rgba(0, 0, 0, 0), rgba(42, 131, 161, 0.2), rgba(42, 131, 161, .6)), url(' + getImage(membership.imageUrl, membership.title) + ')' }">
          <h2 class="text-2xl leading-6 font-bold text-white">{{ membership.title }}</h2>
          <p class="mt-1 text-lg text-gray-400">{{ membership.membershipType }}</p>
          <div v-if="membership.id == 1" class="mt-4">
            <p class="mt-1">
              <span class="text-2xl font-extrabold text-white">FREE</span>
            </p>
            <p class="mt-1">
              <span class="text-2xl font-extrabold text-gray-400">FOREVER</span>
            </p>
            <span v-if="isLoggedIn" class="mt-8 block w-full border-2 rounded-md py-2 text-md font-semibold text-white text-center border-white flex justify-center">Enjoy your Membership <CheckCircleIcon class="flex-shrink-0 h-5 w-5 ml-2" aria-hidden="true" /></span>
            <router-link v-else to="/sign-up" class="mt-8 block w-full bg-blue border-2 border-blue rounded-md py-2 text-md font-semibold text-white text-center hover:border-white flex justify-center">Sign Up Now <ArrowRightCircleIcon class="flex-shrink-0 h-5 w-5 ml-2" aria-hidden="true" /></router-link>
          </div>
          <div v-else class="mt-4">
            <p class="mt-1">
              <span class="text-2xl font-extrabold text-white">£ {{ membership.monthlyPrice }}</span>
              <span class="text-base font-medium text-gray-400"> / monthly</span>
            </p>
            <p class="mt-1">
              <span class="text-2xl font-extrabold text-white">£ {{ membership.yearlyPrice }}</span>
              <span class="text-base font-medium text-gray-400"> / yearly</span>
            </p>
            <span v-if="membership.isActive" class="mt-8 block w-full border-2 rounded-md py-2 text-md font-semibold text-white text-center border-white flex justify-center">Enjoy your Membership <CheckCircleIcon class="flex-shrink-0 h-5 w-5 ml-2" aria-hidden="true" /></span>
            <router-link v-else :to="linkToSub(membership.id)" class="mt-8 block w-full bg-blue border-2 border-blue rounded-md py-2 text-md font-semibold text-white text-center hover:border-white flex justify-center">Subscribe Now <ArrowRightCircleIcon class="flex-shrink-0 h-5 w-5 ml-2" aria-hidden="true" /></router-link>
          </div>
        </div>
        <div class="pt-6 pb-8 px-6">
          <h3 class="text-md font-bold text-gray-400 tracking-wide uppercase">What's included</h3>
          <ul class="mt-6 space-y-4">
            <li v-for="benefit in membership.benefits" :key="benefit.id" class="flex space-x-3">
              <CheckCircleIcon class="flex-shrink-0 h-5 w-5 text-green-500" aria-hidden="true" />
              <span class="text-sm text-white">{{ benefit.benefit }}</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
</div>
</template>

<script>
import { useMemberships } from '@/composables/useMemberships'
import { mapGetters } from 'vuex'
import { useRoute } from 'vue-router'
import { computed, onMounted } from 'vue'
import { ArrowRightCircleIcon, CheckCircleIcon } from '@heroicons/vue/20/solid'
export default {
  name: 'MembershipList',
  components: {
    ArrowRightCircleIcon,
    CheckCircleIcon
  },
  setup () {
    const route = useRoute()
    const { membershipsState, searchMemberships, getImage } = useMemberships()

    onMounted(searchMemberships)

    const linkToSub = computed(() => {
      return (membershipId) => {
        if (route.query.return) {
          return '/subscription/' + membershipId + '?return=' + encodeURIComponent(route.query.return)
        } else {
          return '/subscription/' + membershipId
        }
      }
    })
    return {
      membershipsState,
      getImage,
      linkToSub
    }
  },
  computed: {
    ...mapGetters([
      'isLoggedIn'
    ])
  }
}
</script>

<style scoped>
</style>
