import { reactive, computed } from 'vue'
import axios from 'axios'
import store from '@/store'
import { ensureLoggedInOrRedirect } from '@/lib/util'
import { DateTime } from 'luxon'

const imagesPath = process.env.VUE_APP_BLOB_CONTAINER + '/images/'

const contentState = reactive({
  loading: false,
  content: {
    comingSoon: [],
    events: [],
    latest: [],
    latestSchools: [],
    latestConferences: [],
    latestAudio: [],
    youtubeVideos: [],
    promotions: []
  }
})
const subscriptionState = reactive({
  subscriptions: [],
  eTickets: [],
  inPersonETickets: []
})
const contentDetailState = reactive({
  loading: false,
  dataActions: {
    register: false
  },
  content: {
    item: undefined,
    sessionItems: undefined,
    attendItems: undefined,
    onlineMemberships: undefined,
    onlineETickets: undefined,
    inPersonETickets: undefined,
    loggedIn: false,
    activeMemberships: undefined,
    activeETickets: undefined,
    activeSubscription: false,
    activeETicket: false,
    activeInPersonETicket: false,
    liveSession: undefined,
    watchOnline: false,
    liveStream: false,
    onDemand: false,
    attend: false,
    onDemandSessionItems: [],
    nextUpSessionItems: [],
    earliestSessionDate: undefined,
    latestSessionDate: undefined,
    showFAQs: false
  },
  tabs: [
    { name: 'Watch Online', href: '#', current: true },
    { name: 'Attend In-Person', href: '#', current: false }
  ]
})
const resetContentDetailState = () => {
  contentDetailState.content.item = undefined
  contentDetailState.content.sessionItems = undefined
  contentDetailState.content.attendItems = undefined
  contentDetailState.content.onlineMemberships = undefined
  contentDetailState.content.onlineETickets = undefined
  contentDetailState.content.inPersonETickets = undefined
  contentDetailState.content.loggedIn = false
  contentDetailState.content.activeMemberships = undefined
  contentDetailState.content.activeETickets = undefined
  contentDetailState.content.activeSubscription = false
  contentDetailState.content.activeETicket = false
  contentDetailState.content.activeInPersonETicket = false
  contentDetailState.content.liveSession = undefined
  contentDetailState.content.watchOnline = false
  contentDetailState.content.liveStream = false
  contentDetailState.content.onDemand = false
  contentDetailState.content.attend = false
  contentDetailState.content.onDemandSessionItems = []
  contentDetailState.content.nextUpSessionItems = []
  contentDetailState.content.earliestSessionDate = undefined
  contentDetailState.content.latestSessionDate = undefined
  contentDetailState.content.showFAQs = false
  contentDetailState.dataActions.register = false
  contentDetailState.tabs[0].current = true
  contentDetailState.tabs[1].current = false
}
const audioState = reactive({
  loading: false,
  content: {
    audio: []
  }
})
const schoolsState = reactive({
  loading: false,
  content: {
    schools: []
  }
})
const conferencesState = reactive({
  loading: false,
  content: {
    conferences: []
  }
})
const comingSoonState = reactive({
  loading: false,
  content: {
    comingSoon: []
  }
})
const latestVideosState = reactive({
  loading: false,
  content: {
    latestVideos: []
  }
})
const eventsState = reactive({
  loading: false,
  content: {
    events: []
  }
})

export function useContent () {
  const searchContentDetail = async (req, intitialReq) => {
    contentDetailState.loading = true
    // pretasks if intitialReq
    if (intitialReq) {
      resetContentDetailState()
    }
    try {
      const response = await axios.post('pub/content/details', req)
      contentDetailState.content.item = response.data.result.item
      contentDetailState.content.sessionItems = response.data.result.sessionItems
      contentDetailState.content.attendItems = response.data.result.attendItems
      contentDetailState.content.onlineMemberships = response.data.result.onlineMemberships
      contentDetailState.content.onlineETickets = response.data.result.onlineETickets
      contentDetailState.content.inPersonETickets = response.data.result.inPersonETickets
      contentDetailState.content.loggedIn = response.data.result.loggedIn
      contentDetailState.content.activeMemberships = response.data.result.activeMemberships
      contentDetailState.content.activeETickets = response.data.result.activeETickets
      contentDetailState.content.activeSubscription = response.data.result.activeSubscription
      contentDetailState.content.activeETicket = response.data.result.activeETicket
      contentDetailState.content.activeInPersonETicket = response.data.result.activeInPersonETicket
      contentDetailState.content.liveSession = undefined
      contentDetailState.content.watchOnline = false
      contentDetailState.content.liveStream = false
      contentDetailState.content.onDemand = false
      contentDetailState.content.attend = false
      contentDetailState.content.onDemandSessionItems = []
      contentDetailState.content.nextUpSessionItems = []
      contentDetailState.content.earliestSessionDate = undefined
      contentDetailState.content.latestSessionDate = undefined
      if (contentDetailState.content.sessionItems) {
        contentDetailState.content.sessionItems.forEach(element => {
          const elementDate = DateTime.fromISO(element.publishedAt)
          if (!contentDetailState.content.earliestSessionDate || (elementDate < contentDetailState.content.earliestSessionDate)) {
            contentDetailState.content.earliestSessionDate = elementDate
          }
          if (!contentDetailState.content.latestSessionDate || (elementDate > contentDetailState.content.latestSessionDate)) {
            contentDetailState.content.latestSessionDate = elementDate
          }
          if (element.liveStream) {
            contentDetailState.content.watchOnline = true
            contentDetailState.content.liveStream = true
          }
          if (element.onDemand) {
            contentDetailState.content.watchOnline = true
            contentDetailState.content.onDemand = true
          }
          if (element.status === 1) {
            contentDetailState.content.nextUpSessionItems.push(element)
          }
          if (element.status === 2) {
            contentDetailState.content.liveSession = element
            contentDetailState.content.onDemandSessionItems.unshift(element)
            contentDetailState.content.nextUpSessionItems.push(element)
          }
          if (element.status === 3 || element.status === 4) {
            contentDetailState.content.onDemandSessionItems.unshift(element)
          }
        })
      }
      if (contentDetailState.content.attendItems) {
        contentDetailState.content.attendItems.forEach(element => {
          const elementDate = DateTime.fromISO(element.publishedAt)
          if (!contentDetailState.content.earliestSessionDate || (elementDate < contentDetailState.content.earliestSessionDate)) {
            contentDetailState.content.earliestSessionDate = elementDate
          }
          if (!contentDetailState.content.latestSessionDate || (elementDate > contentDetailState.content.latestSessionDate)) {
            contentDetailState.content.latestSessionDate = elementDate
          }
          if (element.attend) {
            contentDetailState.content.attend = true
          }
        })
      }
      // posttasks if intitialReq
      if (intitialReq) {
        if (contentDetailState.content.item &&
          ((contentDetailState.content.item.slug === 'prayer-storm-kids-conference') ||
            (contentDetailState.content.item.slug === 'nazarite-school') ||
            (contentDetailState.content.item.slug === 'all-day-prayer') ||
            (contentDetailState.content.item.slug === 'summer-conference'))) {
          contentDetailState.tabs[1].current = true
          contentDetailState.tabs[0].current = false
        }

        if (contentDetailState.content.item &&
          ((contentDetailState.content.item.slug === 'prayer-storm-kids-conference') ||
            (contentDetailState.content.item.slug === 'nazarite-school') ||
            (contentDetailState.content.item.slug === 'summer-conference'))) {
          contentDetailState.content.showFAQs = true
        }
      }
    } catch (err) {
      contentDetailState.loading = false
      throw err
    }
  }

  const clearContentDetail = async () => {
    contentDetailState.content.item = undefined
  }

  const searchContent = async () => {
    contentState.loading = true
    const response = await axios.get('pub/content')
    contentState.content.comingSoon = response.data.result.comingSoon
    contentState.content.events = response.data.result.events
    contentState.content.latest = response.data.result.latest
    contentState.content.schools = response.data.result.latestSchools
    contentState.content.conferences = response.data.result.latestConferences
    contentState.content.audio = response.data.result.latestAudio
    contentState.content.youtubeVideos = response.data.result.youtubeVideos
    contentState.content.promotions = response.data.result.promotions
    contentState.loading = false
  }

  const searchAudio = async () => {
    audioState.loading = true
    const response = await axios.get('pub/content/audio')
    audioState.content.audio = response.data.result.audio
    audioState.loading = false
  }
  const searchConferences = async () => {
    conferencesState.loading = true
    const response = await axios.get('pub/content/conferences')
    conferencesState.content.conferences = response.data.result.conferences
    conferencesState.loading = false
  }
  const searchComingSoon = async () => {
    comingSoonState.loading = true
    const response = await axios.get('pub/content/comingsoon')
    comingSoonState.content.comingSoon = response.data.result.comingSoon
    comingSoonState.loading = false
  }
  const searchLatestVideos = async () => {
    latestVideosState.loading = true
    const response = await axios.get('pub/content/latestvideos')
    latestVideosState.content.latestVideos = response.data.result.latestVideos
    latestVideosState.loading = false
  }
  const searchEvents = async () => {
    eventsState.loading = true
    const response = await axios.get('pub/content/events')
    eventsState.content.events = response.data.result.events
    eventsState.loading = false
  }
  const searchSchools = async () => {
    schoolsState.loading = true
    const response = await axios.get('pub/content/schools')
    schoolsState.content.schools = response.data.result.schools
    schoolsState.loading = false
  }

  const getContentImage = computed(() => {
    return (item) => {
      // check the type of the item, return special images for certain types
      if (item.type === 'audio') return 'url("/img/content/ps-podcast-480-360.jpg")'
      if (item.type === 'youtube') return `url("https://i.ytimg.com/vi/${item.guid}/hqdefault.jpg")`
      if (item.youtubeGuid) return `url("https://i.ytimg.com/vi/${item.youtubeGuid}/hqdefault.jpg")`
      if (item.vimeoPicture) return `url("${item.vimeoPicture}_640x360?r=pad")`

      if (item.imageUrl) {
        // if the image url has protocol, return it
        if (item.imageUrl.indexOf('http') === 0) {
          return `url("${item.imageUrl}")`
        }
        // otherwise, return the image url with the images path
        return `url("${imagesPath}${item.imageUrl}")`
      }

      // return a random image
      const x = Math.floor(Math.random() * 5) + 1
      return `linear-gradient(to left bottom, rgba(42, 131, 161, 0.5), rgba(42, 131, 161, 0.5), rgba(42, 131, 161, 0.2)), url("/img/banners/ps-banner-${x}.jpg")`
    }
  })

  const getContentImageUrl = computed(() => {
    return (item) => {
      // return 'http://127.0.0.1:8080/img/banners/ps-banner-1.jpg'
      // check the type of the item, return special images for certain types
      if (item.type === 'audio') return '/img/content/ps-podcast-480-360.jpg'
      if (item.type === 'youtube') return `https://i.ytimg.com/vi/${item.guid}/hqdefault.jpg`
      if (item.youtubeGuid) return `https://i.ytimg.com/vi/${item.youtubeGuid}/hqdefault.jpg`
      if (item.vimeoPicture) return `${item.vimeoPicture}_640x360?r=pad`

      if (item.imageUrl) {
        // if the image url has protocol, return it
        if (item.imageUrl.indexOf('http') === 0) {
          return item.imageUrl
        }
        // otherwise, return the image url with the images path
        return `${imagesPath}${item.imageUrl}`
      }

      // if no image url, return a random banner
      const x = Math.floor(Math.random() * 5) + 1
      return `/img/banners/ps-banner-${x}.jpg`
    }
  })

  const getContentLink = computed(() => {
    return (item) => {
      if (item.type === 'event') {
        return { name: 'Event', params: { type: item.type, guid: item.guid, slug: item.slug } }
      } else if (item.type === 'conference') {
        return { name: 'Conference', params: { type: item.type, guid: item.guid, slug: item.slug } }
      } else if (item.type === 'school') {
        return { name: 'School', params: { type: item.type, guid: item.guid, slug: item.slug } }
      } else if (item.type === 'audio' || item.type === 'youtube') {
        return { name: 'Content', params: { type: item.type, guid: item.guid } }
      }
      return ''
    }
  })

  const goContent = async (item, router, route) => {
    if (item.type === 'event') {
      router.push({ name: 'Event', params: { type: item.type, guid: item.guid, slug: item.slug } })
    } else if (item.type === 'conference') {
      router.push({ name: 'Conference', params: { type: item.type, guid: item.guid, slug: item.slug } })
    } else if (item.type === 'school') {
      router.push({ name: 'School', params: { type: item.type, guid: item.guid, slug: item.slug } })
    } else if (item.type === 'audio' || item.type === 'youtube') {
      router.push({ name: 'Content', params: { type: item.type, guid: item.guid } })
    } else if (item.type === 'session') {
      let dataResult
      let contentDetail
      try {
        // show the loading modal
        store.commit('global/setLoadingModal', {
          open: true
        })

        // load the session
        const result = await store.dispatch('content/viewContent', {
          type: item.type,
          guid: item.guid
        })

        dataResult = result.data.result
        contentDetail = result.data.result.item

        if (dataResult.loggedIn === false) {
          ensureLoggedInOrRedirect(router, route)
          return
        }

        // check live or on-demand
        if (contentDetail.status !== 2 && contentDetail.status !== 4) {
          store.commit('global/setErrorModal', {
            title: 'Not Available',
            description: 'This content is not yet available.',
            open: true
          })
          return
        }

        // determine if user has access to content
        var hasAccess = (contentDetail.publicAccess || dataResult.activeSubscription || dataResult.activeETicket)
        // check if user has access to content
        if (!hasAccess) {
          store.commit('global/setAccessModal', {
            title: 'Get Access',
            description: 'Your account does not have access to this content, please view the event to see access options.',
            open: true,
            contentDetail: contentDetail
          })
          return
        }
      } catch (err) {
        console.log(err)
        contentDetail = undefined
        // show err modal
      } finally {
        store.commit('global/setLoadingModal', {
          open: false
        })
      }

      if (contentDetail === undefined) {
        store.commit('global/setErrorModal', {
          title: 'Unknown Issue',
          description: 'There was an issue processing your request. Please try again.',
          open: true
        })
      } else {
        if (contentDetail.status === 2) {
          if (contentDetail.vimeo && !contentDetail.youtube && !contentDetail.facebook) {
            router.push('/live/session/' + contentDetail.guid)
          } else {
            store.commit('global/setWatchOnModal', {
              prayerstorm: contentDetail.vimeo,
              prayerstormGuid: contentDetail.guid,
              youtube: contentDetail.youtube,
              facebook: contentDetail.facebook,
              open: true
            })
          }
        } else if (contentDetail.status === 4) {
          if (!contentDetail.type) {
            contentDetail.type = 'session'
          }
          router.push('/content/' + contentDetail.type + '/' + contentDetail.guid)
        }
      }
    }
  }

  return {
    contentState,
    subscriptionState,
    contentDetailState,
    audioState,
    conferencesState,
    comingSoonState,
    latestVideosState,
    eventsState,
    schoolsState,
    searchContentDetail,
    searchContent,
    searchLatestVideos,
    searchAudio,
    searchConferences,
    searchComingSoon,
    searchEvents,
    searchSchools,
    clearContentDetail,
    getContentImage,
    getContentImageUrl,
    getContentLink,
    goContent
  }
}
