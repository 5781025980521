<template>
  <div class="profile-page">
    <h1>This is a profile page</h1>
  </div>
</template>

<script>
export default {
  name: 'Profile'
}
</script>

<style scoped>
</style>
