<template>
<LifeOnFireVideo></LifeOnFireVideo>

 <div class="">
    <div class="max-w-2xl mx-auto py-6 md:py-12 sm:px-6 lg:max-w-7xl">
      <div class="lg:grid lg:grid-cols-2 lg:gap-x-12 lg:items-start">
        <!-- Product info -->
        <div class="mt-6 px-4 sm:px-0 lg:mt-0">
          <a class="relative card-zoom cursor-pointer" @click.prevent="showVideo">
            <div class="card-zoom-image" style="background-image: url('/img/resources/life-on-fire-trailer.jpg');"></div>
            <div class="card-zoom-text flex">
              <h1 class="m-auto"><PlayIcon class="w-20 h-20 text-white" /></h1>
            </div>
          </a>
          <!-- <h1 class="mt-10 text-3xl font-extrabold tracking-tight text-blue">{{ lof.name }}</h1> -->

          <div class="mt-6">
            <h3 class="sr-only">Description</h3>

            <div class="text-base text-gray-700 space-y-6" v-html="lof.description" />
          </div>
        </div>

        <!-- Image gallery -->
        <TabGroup as="div" class="flex flex-col-reverse px-4 sm:px-0">
          <!-- Image selector -->
          <div class="mt-6 w-full max-w-2xl mx-auto lg:max-w-none">
            <TabList class="grid grid-cols-4 gap-6">
              <Tab v-for="image in lof.images" :key="image.id" class="relative h-24 bg-white rounded-lg flex items-center justify-center text-sm font-medium uppercase text-gray-900 cursor-pointer hover:bg-gray-50 focus:outline-none focus:ring focus:ring-offset-4 focus:ring-opacity-50" v-slot="{ selected }">
                <span class="sr-only">
                  {{ image.name }}
                </span>
                <span class="absolute inset-0 rounded-md overflow-hidden">
                  <img :src="image.src" alt="" class="w-full h-full object-center object-cover" />
                </span>
                <span :class="[selected ? 'ring-indigo-500' : 'ring-transparent', 'absolute inset-0 rounded-lg ring-2 ring-offset-2 pointer-events-none']" aria-hidden="true" />
              </Tab>
            </TabList>
          </div>

          <TabPanels class="w-full aspect-w-1 aspect-h-1 mt-6 lg:mt-0">
            <TabPanel v-for="image in lof.images" :key="image.id">
              <img :src="image.src" :alt="image.alt" class="w-full h-full object-center object-cover sm:rounded-xl" />
            </TabPanel>
          </TabPanels>
        </TabGroup>
      </div>
    </div>
  </div>

  <TransitionRoot as="template" :show="open">
    <Dialog as="div" class="fixed z-10 inset-0 overflow-y-auto" @close="open = false">
      <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
          <DialogOverlay class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
          <div class="inline-block my-auto bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 align-middle w-full sm:max-w-5xl sm:w-full sm:p-6">
            <div class="relative" style="padding-top: 56.25%">
              <iframe class="absolute inset-0 w-full h-full" src="https://www.youtube.com/embed/mgYNXVDrllY?autoplay=1&rel=0" frameborder="0" allow="fullscreen;autoplay;"></iframe>
            </div>
            <div class="relative text-right pt-3">
              <a class="font-bold" href="#" @click.prevent="open = false">X CLOSE</a>
            </div>
          </div>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>

</template>

<script>
import { defineComponent, ref } from 'vue'
import LifeOnFireVideo from '@/components/LifeOnFireVideo.vue'
import { PlayIcon } from '@heroicons/vue/20/solid'
import {
  Tab,
  TabGroup,
  TabList,
  TabPanel,
  TabPanels,
  Dialog,
  DialogOverlay,
  TransitionChild,
  TransitionRoot
} from '@headlessui/vue'

const lof = {
  name: 'Life On Fire',
  price: '$140',
  rating: 4,
  images: [
    {
      id: 1,
      name: 'Endorsement 1',
      src: '/img/resources/endorsement-le.jpg',
      alt: ''
    },
    {
      id: 2,
      name: 'Endorsement 2',
      src: '/img/resources/endorsement-kw.jpg',
      alt: ''
    },
    {
      id: 3,
      name: 'Endorsement 3',
      src: '/img/resources/endorsement-jg.jpg',
      alt: ''
    },
    {
      id: 4,
      name: 'Endorsement 4',
      src: '/img/resources/endorsement-rh.jpg',
      alt: ''
    }
  ],
  description: `
    <p class="font-bold">In these last days, God is raising up a new breed of believers to carry the spirit of Elijah and confront the spirit of Jezebel that has gripped our society.</p>
    <p>In Life On Fire, James Aladiran lays bare the spiritual battles that still rage today. Through an engaging exposition of scripture, he reveals how we can destroy the stranglehold of seduction over our generation and help to usher in the greatest move of the Holy Spirit the world has ever seen.</p>
    <p>What is coming is nothing short of a fight for the soul of the nation. Are you ready?</p>
  `,
  details: [
    {
      name: 'Features',
      items: [
        'Multiple strap configurations',
        'Spacious interior with top zip',
        'Leather handle and tabs',
        'Interior dividers',
        'Stainless strap loops',
        'Double stitched construction',
        'Water-resistant'
      ]
    }
  ]
}

const product = {
  name: 'Basic Tee',
  price: '$35',
  href: '#',
  breadcrumbs: [
    { id: 1, name: 'Women', href: '#' },
    { id: 2, name: 'Clothing', href: '#' }
  ],
  images: [
    {
      id: 1,
      imageSrc: '/img/resources/life-on-fire-cover.png',
      imageAlt: "Back of women's Basic Tee in black.",
      primary: true
    },
    {
      id: 2,
      imageSrc: 'https://tailwindui.com/img/ecommerce-images/product-page-01-product-shot-01.jpg',
      imageAlt: "Side profile of women's Basic Tee in black.",
      primary: false
    },
    {
      id: 3,
      imageSrc: 'https://tailwindui.com/img/ecommerce-images/product-page-01-product-shot-02.jpg',
      imageAlt: "Front of women's Basic Tee in black.",
      primary: false
    }
  ],
  colors: [
    { name: 'Black', bgColor: 'bg-gray-900', selectedColor: 'ring-gray-900' },
    { name: 'Heather Grey', bgColor: 'bg-gray-400', selectedColor: 'ring-gray-400' }
  ],
  sizes: [
    { name: 'Book', inStock: true },
    { name: 'Kindle', inStock: true },
    { name: 'Audible', inStock: true }
  ],
  description: `
    <p>The Basic tee is an honest new take on a classic. The tee uses super soft, pre-shrunk cotton for true comfort and a dependable fit. They are hand cut and sewn locally, with a special dye technique that gives each tee it's own look.</p>
    <p>Looking to stock your closet? The Basic tee also comes in a 3-pack or 5-pack at a bundle discount.</p>
  `,
  details: [
    'Only the best materials',
    'Ethically and locally made',
    'Pre-washed and pre-shrunk',
    'Machine wash cold with similar colors'
  ]
}

export default defineComponent({
  name: 'LifeOnFire',
  components: {
    LifeOnFireVideo,
    Tab,
    TabGroup,
    TabList,
    TabPanel,
    TabPanels,
    Dialog,
    DialogOverlay,
    TransitionChild,
    TransitionRoot,
    PlayIcon
  },
  setup () {
    const open = ref(false)

    const showVideo = () => {
      open.value = true
    }
    return {
      lof,
      open,
      showVideo,
      product
    }
  }
})
</script>

<style scoped>
.video-container {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    height: 0;
}
.video-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
}

.card-zoom {
  @apply relative flex items-center justify-center overflow-hidden shadow-xl w-full rounded-xl;
  padding-bottom: 56.25%; /* 16:9 */
}
.card-zoom-image {
  @apply absolute inset-0 transition-all duration-500 ease-in-out transform bg-center bg-cover;
}
.card-zoom-text {
  @apply absolute inset-0 text-3xl font-black transition-all duration-500 ease-in-out transform scale-110 text-gray-50 opacity-60;
}
.card-zoom:hover .card-zoom-image {
  @apply scale-110;
}
.card-zoom:hover .card-zoom-text {
  @apply scale-100;
}
</style>
