<template>
<div v-if="contentDetailState.content.item" class="mx-auto max-w-7xl">
  <div>
    <Disclosure as="div" class="py-6" v-slot="{ open }">
      <dt>
        <DisclosureButton class="flex w-full items-start justify-between text-left text-gray-900">
          <span class="text-xl sm:text-2xl font-semibold tracking-tight text-blue-dark">What is the Nazarite School of Prayer?</span>
          <span class="ml-6 flex h-7 items-center">
            <PlusSmallIcon v-if="!open" class="h-6 w-6" aria-hidden="true" />
            <MinusSmallIcon v-else class="h-6 w-6" aria-hidden="true" />
          </span>
        </DisclosureButton>
      </dt>
      <DisclosurePanel as="dd" class="mt-2 pr-12">
        <p class="text-sm mt-2 text-gray-600">
          'Nazarite' means separated unto God. In the book of Numbers, God prepared the Israelites to go through the wilderness and take the promised land. This included the Nazarite vow: a process of preparation to fulfill Gods purposes.
        </p>
        <p class="text-sm mt-2 text-gray-600">
          Throughout history, Nazarites like Samson, Samuel, and Elijah appear when God is doing a fresh work. They are God's shock troops, operating behind enemy lines. Perhaps the most famous Nazarite, John the Baptist, articulated the call of all Nazarites as "Prepare the way for the Lord."
        </p>
        <p class="text-sm mt-2 text-gray-600">
          The 'Nazarite School of Prayer' is a six-week journey of separating and preparing ourselves to enter God's presence and fulfill His purposes. Based on the principles of Numbers 6, we fast things that distract us from God and pick up things that help us to focus on Him. Creating a modern wilderness of preparation.
        </p>
        <p class="text-sm mt-2 text-gray-600">
          If you want a breakthrough in your intimacy with God, if you desire to be part of a fresh move of His Spirit, join us on our Nazarite journey.
        </p>
        <p class="text-sm mt-2 text-gray-600">
          The core elements of the course include...
        </p>

        <p class="text-sm mt-2 text-gray-600">
          <strong>Nazarite Commitments:</strong> Each Nazarite will ask the Holy Spirit what He wants them to lay down and to pick up, during the six weeks. This often includes putting down Social Media and T.V. while picking up the Bible and prayer. However, God might surprise you! It's not a religious ritual, so only do what He asks of you. More guidance will be given on the first Friday event.
        </p>
        <p class="text-sm mt-2 text-gray-600">
          <strong>Nazarite Event, Every Friday from 9 pm- 12:30 am:</strong> Because we follow the leading of the Holy Spirit each week is different, but they usually include worship, prayer, teaching, communion, and time with your Nazarite prayer partners. The first meeting is Friday the 14th of April and the last 26th of May (a total of seven Fridays over six weeks). Events are not live streamed and, to help us keep momentum, weekly attendance is required. However, please prioritise your local church commitments.
        </p>
        <p class="text-sm mt-2 text-gray-600">
          <strong>Nazarite Teaching:</strong> During these events, a weekly teaching will unpack the theology and practice of being a New Covenant Nazarite in a contemporary context.
        </p>
        <p class="text-sm mt-2 text-gray-600">
          <strong>Communion:</strong> The Nazarites grew their hair to remember their covenant with God. (Numbers 6:5 & 15:38) As communion is the New Testament version of this reminder, (Luke 22:19) we encourage people to take communion as regularly as possible.
        </p>
        <p class="text-sm mt-2 text-gray-600">
          <strong>Nazarite Prayer Partners:</strong> During the Friday events, we break into small, gender-specific groups to ask each other how our commitments are going and how God is working in our lives. Privacy in these groups is paramount and long-term friendships often emerge from them.
        </p>
        <p class="text-sm mt-2 text-gray-600">
          <strong>Devotions:</strong> You can choose to receive a short, Nazarite-themed devotional to keep you focused and inspired. These are great to do with communion.
        </p>
      </DisclosurePanel>
    </Disclosure>
  </div>
</div>
</template>

<script>
import { defineComponent, computed } from 'vue'
import { useContent } from '@/composables/useContent'
import { MinusSmallIcon, PlusSmallIcon } from '@heroicons/vue/24/outline'
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue'

export default defineComponent({
  components: {
    MinusSmallIcon,
    PlusSmallIcon,
    Disclosure,
    DisclosureButton,
    DisclosurePanel
  },
  setup () {
    const { contentDetailState } = useContent()

    const goToElementById = (elementId) => {
      const scrollTo = document.getElementById(elementId)
      scrollTo.scrollIntoView({ behavior: 'smooth' })
    }

    const dateItems = computed(() => {
      const items = []
      if (contentDetailState.content.sessionItems) {
        contentDetailState.content.sessionItems.forEach((item) => {
          items.push(item)
        })
      }
      if (contentDetailState.content.attendItems) {
        contentDetailState.content.attendItems.forEach((item) => {
          const itemAlreadyExists = items.findIndex(exists => exists.id === item.id)
          if (itemAlreadyExists < 0) {
            items.push(item)
          }
        })
      }
      return items
    })

    return {
      goToElementById,
      contentDetailState,
      dateItems
    }
  }
})

</script>
