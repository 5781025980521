<template>
  <TransitionRoot as="template" :show="accessModal.open">
    <Dialog as="div" class="relative z-10" @close="accessModal.open = false">
      <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100"
        leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
        <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
      </TransitionChild>

      <div class="fixed inset-0 z-10 overflow-y-auto">
        <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <TransitionChild as="template" enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
            <div class="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
              <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div class="sm:flex sm:items-start">
                  <div class="text-left">
                    <DialogTitle as="h3" class="text-lg font-medium leading-6 text-gray-900">{{ accessModal.title }}</DialogTitle>
                    <div class="mt-2">
                      <p class="text-sm text-gray-500">{{ accessModal.description }}</p>
                    </div>
                    <div v-if="accessModal.contentDetail" class="mt-3 flex gap-3">
                      <div class="flex-1">
                        <img :src="getContentImageUrl({imageUrl:accessModal.contentDetail.eventImageUr})" class="w-full rounded-lg" />
                      </div>
                      <div class="flex-1">
                        <h2 class="text-md font-bold leading-6 text-gray-900 pb-2">{{ accessModal.contentDetail.eventTitle }}</h2>
                        <button @click.prevent="navigateToEvent(accessModal.contentDetail)" class="disabled:opacity-50 inline-flex w-full items-center justify-center rounded-md border border-transparent bg-blue px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-blue-dark focus:outline-none focus:ring-2 focus:ring-blue focus:ring-offset-2">View Event</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                <!-- <button type="button"
                  class="inline-flex w-full justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                  @click="accessModal.open = false">Deactivate</button> -->
                <button type="button"
                  class="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                  @click="accessModal.open = false" ref="cancelButtonRef">Close</button>
              </div>
            </div>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import { defineComponent, computed } from 'vue'
import { useStore } from 'vuex'
import { useRouter, useRoute } from 'vue-router'
import { Dialog, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { useContent } from '@/composables/useContent'

export default defineComponent({
  components: {
    Dialog,
    // DialogPanel,
    DialogTitle,
    TransitionChild,
    TransitionRoot
  },
  setup () {
    const { getContentImageUrl, goContent } = useContent()
    const router = useRouter()
    const route = useRoute()
    const store = useStore()
    const accessModal = computed(() => store.state.global.accessModal)
    const navigateToEvent = async (contentDetail) => {
      // close the modal
      store.state.global.accessModal.open = false
      goContent({
        type: 'event',
        guid: contentDetail.eventId.toString(),
        slug: contentDetail.eventSlug
      }, router, route)
    }
    return {
      accessModal,
      getContentImageUrl,
      navigateToEvent
    }
  }
})
</script>
