<template>
<div class="flex flex-col justify-center py-12 sm:px-6 lg:px-8">
  <div class="sm:mx-auto sm:w-full sm:max-w-md">
    <img class="mx-auto h-12 w-auto" src="@/assets/logo-icon.svg" alt="Workflow">
    <h2 class="mt-6 text-center text-3xl font-bold text-gray-900">
      Mailing List Sign Up
    </h2>
    <p class="mt-2 text-center text-sm text-gray-600 max-w">
      If you only want to sign up to our mailing list, without an online account then please use this form. Or...
      <router-link :to="signUpUrl" class="font-medium text-blue hover:text-blue-dark">
        Sign up for an account here
      </router-link>
    </p>
  </div>

  <div class="mt-8 sm:mx-auto sm:w-full">
      <iframe width="540" height="360" src="https://my.sendinblue.com/users/subscribe/js_id/2oiwr/id/1" frameborder="0" scrolling="auto" allowfullscreen style="display: block;margin-left: auto;margin-right: auto;"></iframe>
  </div>
</div>
</template>

<script>
export default {
  data: () => ({
    signUpUrl: '/sign-up'
  }),
  methods: {
  }
}
</script>
