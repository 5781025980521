<template>
<main>
    <LiveNow></LiveNow>
    <InviteList></InviteList>
</main>
</template>

<script>
import LiveNow from '@/components/LiveNow.vue'
import InviteList from '@/components/InviteList.vue'
export default {
  name: 'InviteTwo',
  components: {
    LiveNow,
    InviteList
  },
  setup () {
    return {
    }
  }
}
</script>
