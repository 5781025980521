<template>
  <TransitionRoot as="template" :show="watchOnModal.open">
  <Dialog as="div" class="fixed z-10 inset-0 overflow-y-auto" @close="watchOnModal.open = false">
    <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
      <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
        <DialogOverlay class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
      </TransitionChild>

      <!-- This element is to trick the browser into centering the modal contents. -->
      <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
      <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
        <div class="inline-block my-auto bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 align-middle w-full sm:max-w-xl sm:w-full sm:p-6">
          <div class="relative flex justify-evenly pb-2">
            <div class="flex-1">
              <h2 class="font-bold">Watch on:</h2>
            </div>
            <div>
              <a class="font-bold" href="#" @click.prevent="watchOnModal.open = false">X</a>
            </div>
          </div>
          <div class="relative flex items-center justify-evenly space-x-5">
            <a v-if="watchOnModal.youtube" href="https://www.youtube.com/prayerstormtv" class="inline-block text-gray-400 hover:text-red-500 focus:outline-none">
              <svg class="h-12 w-12 mx-auto text-red-500" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                <path fill-rule="evenodd" d="M19.615 3.184c-3.604-.246-11.631-.245-15.23 0-3.897.266-4.356 2.62-4.385 8.816.029 6.185.484 8.549 4.385 8.816 3.6.245 11.626.246 15.23 0 3.897-.266 4.356-2.62 4.385-8.816-.029-6.185-.484-8.549-4.385-8.816zm-10.615 12.816v-8l8 3.993-8 4.007z" clip-rule="evenodd" />
              </svg>
              <span class="mx-auto">YouTube</span>
            </a>
            <a v-if="watchOnModal.facebook" href="https://www.facebook.com/prayerstorm/" class="inline-block text-gray-400 hover:text-blue-500 focus:outline-none">
              <svg class="h-12 w-12 mx-auto text-blue-500" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                <path fill-rule="evenodd" d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z" clip-rule="evenodd" />
              </svg>
              <span class="mx-auto">Facebook</span>
            </a>
            <a v-if="watchOnModal.prayerstorm" href="#" @click.prevent="navigateToWatchOnPrayerStorm(watchOnModal.prayerstormGuid)" class="inline-block text-gray-400 hover:text-blue-dark focus:outline-none">
              <img class="mx-auto h-12 w-auto pb-2" src="@/assets/logo-icon.svg" alt="Prayer Storm">
              <span class="mx-auto">Prayer Storm</span>
            </a>
          </div>
        </div>
      </TransitionChild>
    </div>
  </Dialog>
</TransitionRoot>
</template>

<script>
import { defineComponent, computed } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { Dialog, DialogOverlay, TransitionChild, TransitionRoot } from '@headlessui/vue'

export default defineComponent({
  components: {
    Dialog,
    DialogOverlay,
    TransitionChild,
    TransitionRoot
  },
  setup () {
    const router = useRouter()
    const store = useStore()
    const watchOnModal = computed(() => store.state.global.watchOnModal)
    const navigateToWatchOnPrayerStorm = async (prayerstormGuid) => {
      // close the modal
      store.state.global.watchOnModal.open = false
      router.push('/live/session/' + prayerstormGuid)
    }
    return {
      watchOnModal,
      navigateToWatchOnPrayerStorm
    }
  }
})
</script>
