<template>
  <div class="relative bg-white rounded-lg">
    <div class="h-48 sm:h-64 lg:absolute lg:left-0 lg:h-full lg:w-1/2 p-2">
      <img class="h-full w-full object-cover rounded-lg" src="/img/banners/ps-gold-benefits.jpg" alt="Support team" />
    </div>
    <div class="relative mx-auto max-w-7xl px-4 py-8 sm:py-12 sm:px-6 lg:py-16">
      <div class="mx-auto max-w-2xl lg:mr-0 lg:ml-auto lg:w-1/2 lg:max-w-none lg:pl-10">
        <!-- <div>
          <div class="flex h-12 w-12 items-center justify-center rounded-md bg-blue text-white">
            <StarIcon class="h-6 w-6" aria-hidden="true"></StarIcon>
          </div>
        </div> -->
        <h2 class="mt-6 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Exclusive Prayer Resources</h2>
        <p class="mt-6 text-lg text-gray-500">Gold Membership with Prayer Storm gives access to an exclusive set of prayer resources. These resources continue to grow on a month by month basis. It's a great way to support the ministry and be equipped for effective prayer and prayer leadership at the same time!</p>
        <div class="mt-8 overflow-hidden">
          <dl class="-mx-8 -mt-8 flex flex-wrap">
            <div class="flex flex-col px-8 pt-8">
              <dt class="order-2 text-base font-medium text-gray-500">Conferences & Schools</dt>
              <dd class="order-1 text-2xl font-bold text-blue sm:text-3xl sm:tracking-tight">15+</dd>
            </div>
            <div class="flex flex-col px-8 pt-8">
              <dt class="order-2 text-base font-medium text-gray-500">Sessions</dt>
              <dd class="order-1 text-2xl font-bold text-blue sm:text-3xl sm:tracking-tight">80+</dd>
            </div>
            <div class="flex flex-col px-8 pt-8">
              <dt class="order-2 text-base font-medium text-gray-500">Hours</dt>
              <dd class="order-1 text-2xl font-bold text-blue sm:text-3xl sm:tracking-tight">130+</dd>
            </div>
          </dl>
        </div>
        <!-- <div class="mt-8 overflow-hidden">
          <a href="/content/membership" class="font-bold text-blue text-3xl">See What's Included <ArrowRightCircleIcon class="h-6 w-6 inline" aria-hidden="true"></ArrowRightCircleIcon></a>
        </div> -->
        <div class="overflow-hidden mt-4 md:flex">
          <a href="#" @click.prevent="scrollToElement('membership-upgrade')" class="mt-4 block w-full md:inline-block md:w-fit bg-blue border-2 border-blue rounded-md py-2 text-md font-semibold text-white text-center hover:bg-blue-dark flex justify-center">Subscribe Now  <ArrowDownCircleIcon class="h-5 w-5 inline ml-2" aria-hidden="true"></ArrowDownCircleIcon></a>
          <router-link to="/membership" @click.prevent="scrollToElement('membership-included')" class="mt-4 block w-full md:ml-4 md:inline-block md:w-fit py-2 px-2 md:px-4 rounded-md text-center text-blue font-bold text-sm md:text-lg hover:text-blue-dark">What's Included?</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { StarIcon } from '@heroicons/vue/24/outline'
import { ArrowDownCircleIcon } from '@heroicons/vue/20/solid'
export default {
  components: {
    // StarIcon,
    ArrowDownCircleIcon
    // ArrowRightCircleIcon
  },
  setup () {
    const scrollToElement = (elementId) => {
      setTimeout(() => {
        const scrollTo = document.getElementById(elementId)
        scrollTo.scrollIntoView({ behavior: 'smooth' })
      }, 100)
    }

    return {
      scrollToElement
    }
  }
}
</script>
