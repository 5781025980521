<template>
<aside class="py-6 px-2 sm:px-6 lg:col-span-3 lg:py-0 lg:px-0">
  <!--large screen nav-->
  <nav class="space-y-1">
    <router-link v-for="item in navigation" :key="item.name" :to="item.href" :class="[isCurrent(item.href) ? 'bg-gray-50 text-blue-dark hover:text-blue-dark hover:bg-white' : 'text-gray-900 hover:text-gray-900 hover:bg-gray-50', 'group rounded-md px-3 py-2 flex items-center text-sm font-medium']" :aria-current="isCurrent(item.href) ? 'page' : undefined">
      <component :is="item.icon" :class="[item.current ? 'text-blue group-hover:text-blue' : 'text-gray-400 group-hover:text-gray-500', 'flex-shrink-0 -ml-1 mr-3 h-6 w-6']" aria-hidden="true" />
      <span class="truncate">{{ item.name }}</span>
    </router-link>
  </nav>
</aside>
</template>

<script>
import { defineComponent, computed } from 'vue'
import { useRoute } from 'vue-router'
import {
  // EyeOffIcon,
  KeyIcon,
  Bars3Icon,
  UserIcon,
  UserCircleIcon,
  StarIcon,
  ArrowPathIcon
} from '@heroicons/vue/24/outline'

const createNavItem = (name, href, icon) => {
  return {
    name: name,
    href: href,
    icon: icon
  }
}

export default defineComponent({
  name: 'SettingsNavBar',
  setup () {
    const route = useRoute()

    const navigation = [
      createNavItem('Profile', '/settings/profile', UserIcon),
      createNavItem('Avatar', '/settings/avatar', UserCircleIcon),
      createNavItem('Password', '/settings/password', KeyIcon),
      createNavItem('Membership', '/settings/membership', StarIcon),
      createNavItem('Donations', '/settings/donations', ArrowPathIcon)
    ]

    const isCurrent = (path) => {
      return path === route.fullPath
    }

    const activeNav = computed(() => {
      let activeItem = null
      navigation.forEach(element => {
        if (isCurrent(element.href)) {
          activeItem = element
        }
      })
      return activeItem
    })

    return {
      navigation,
      isCurrent,
      activeNav,
      Bars3Icon
    }
  }
})
</script>
