<template>
<main>
  <LiveNow></LiveNow>
  <div class="bg-black bg-contain bg-no-repeat bg-right-top" :style="headerStyle">
    <div class="max-w-7xl mx-auto py-24 px-4 sm:px-6 lg:px-8">
      <div class="sm:flex sm:flex-col align-center">
        <h1 class="text-3xl font-extrabold text-white text-center">Invite {{nameFull}}</h1>
      </div>
    </div>
  </div>
  <InviteForm :nameShort="nameShort" :nameFull="nameFull" :isPrayerStormMusic="isPrayerStormMusic" :isJames="isJames" :additionalMessage="additionalMessage"></InviteForm>
</main>
</template>

<script>
import { useRoute } from 'vue-router'
import LiveNow from '@/components/LiveNow.vue'
import InviteForm from '@/components/InviteForm.vue'
export default {
  name: 'InvitePS',
  components: {
    LiveNow,
    InviteForm
  },
  setup () {
    const route = useRoute()
    const name = route.params.name
    let nameShort
    let nameFull
    let isPrayerStormMusic = false
    let isJames = false
    let additionalMessage = ''
    switch (name.toLowerCase()) {
      case 'james':
        nameShort = 'James'
        nameFull = 'James Aladiran'
        isJames = true
        break

      case 'rebecca':
        nameShort = 'Rebecca'
        nameFull = 'Rebecca Aladiran'
        isPrayerStormMusic = true
        break

      case 'matt':
        nameShort = 'Matt'
        nameFull = 'Matt Varah Wilson'
        additionalMessage = 'Matt is unavailable for bookings on a Sunday morning due to commitments in his local church.'
        break
    }

    const bgImg = nameShort.toLowerCase() + '-bg'
    const headerStyle = `background-image:linear-gradient(to left bottom, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2), rgba(42, 131, 161, 0.4), rgba(42, 131, 161, .6)), url('/img/invite/${bgImg}.jpg');`
    return {
      nameShort,
      nameFull,
      isPrayerStormMusic,
      isJames,
      headerStyle,
      additionalMessage
    }
  }
}
</script>
