import axios from 'axios'
const eventStore = {
  namespaced: true,
  state: () => ({
    eventsForCategory: {}
  }),
  mutations: {
    setEventsForCategory (state, payload) {
      state.eventsForCategory[payload.categoryId] = payload.events
    }
  },
  actions: {
    async eventsForCategory (context, payload) {
      const response = await axios.get('pub/content/events/category/' + payload.categoryId)
      if (response.data && response.data.result) {
        context.commit('setEventsForCategory', response.data.result)
      }
    }
  }
}
export default eventStore
