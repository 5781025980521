<template>
<div class="flex flex-col justify-center py-12 sm:px-6 lg:px-8">
  <div class="sm:mx-auto sm:w-full sm:max-w-lg">
    <img class="mx-auto h-12 w-auto" src="@/assets/logo-icon.svg" alt="Workflow">
    <h2 class="mt-6 text-center text-3xl font-bold text-gray-900">
      Donation Complete
    </h2>
  </div>
  <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-lg">
    <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
      <div class="text-center">
        <p>Thank you for your donation to Prayer Storm's Summer Conference. We look forward to seeing you there!</p>
        <p class="mt-2">We really appreciate the support that you have given.</p>
      </div>
    </div>
  </div>
</div>
</template>
