<template>
  <div class="bg-blue" style="background-image:url('/img/banners/ps-banner-4.jpg');">
    <div class="relative bg-no-repeat bg-cover" style="background-image: linear-gradient(to left bottom, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4), rgba(42, 131, 161, 0.4), rgba(42, 131, 161, 0.4));">
      <div class="relative">
        <div class="relative max-w-7xl mx-auto">
          <div class="video-container">
            &nbsp;
            <!-- <iframe src="/background.html" frameborder="0" class="w-full"></iframe> -->
          </div>
          <div class="absolute flex flex-wrap bottom-0 left-0 right-0 text-white pointer-events-none h-50" data-height="250" data-front="" data-style="triangle" data-position="bottom">
            <svg class="w-full" aria-hidden="true" fill="#ffffff" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 100" preserveAspectRatio="none">
              <polygon points="501 53.27 0.5 0.56 0.5 100 1000.5 100 1000.5 0.66 501 53.27"></polygon>
            </svg>
          </div>
          <div class="absolute flex flex-wrap bottom-0 left-0 right-0 text-white pointer-events-none" >
            <div class="m-auto content-center rounded-lg text-center">
              <div class="pb-6 font-bold hidden sm:block">
                <h1 class="text-3xl hidden md:block">We Declare War</h1>
                <div class="pb-3 hidden md:block">From Prayer Storm Music</div>
                <button class="m-auto pointer-events-auto font-bold text-md border border-white p-2 rounded-md bg-black bg-opacity-70" @click="more">Buy Now</button>
              </div>
              <img class="h-48 md:h-72 lg:h-96 mx-auto" src="/img/resources/we-declare-war-cover.jpg"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div id="video-foot"></div>
</template>

<script>
import { defineComponent } from '@vue/runtime-core'

export default defineComponent({
  name: 'LifeOnFireVideo',
  components: {
  },
  setup () {
    const more = () => {
      const scrollTo = document.getElementById('video-foot')
      scrollTo.scrollIntoView({ behavior: 'smooth' })
    }
    return {
      more
    }
  }
})
</script>

<style scoped>
.video-container {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    height: 0;
}
.video-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
}
</style>
