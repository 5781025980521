<template>
  <div class="bg-white py-12 sm:py-6">
    <div class="mx-auto max-w-5xl px-6">
      <div class="mx-auto max-w-xl text-center">
        <h2 class="text-2xl font-bold">FAQs</h2>
      </div>
      <dl class="mt-4 space-y-6 divide-y divide-gray-900/10">
        <Disclosure as="div" v-for="faq in faqs" :key="faq.question" class="pt-6" v-slot="{ open }">
          <dt>
            <DisclosureButton class="flex w-full items-start justify-between text-left text-gray-900">
              <span class="text-base font-semibold leading-7">{{ faq.question }}</span>
              <span class="ml-6 flex h-7 items-center">
                <PlusSmallIcon v-if="!open" class="h-6 w-6" aria-hidden="true" />
                <MinusSmallIcon v-else class="h-6 w-6" aria-hidden="true" />
              </span>
            </DisclosureButton>
          </dt>
          <DisclosurePanel as="dd" class="mt-2 pr-12">
            <p class="text-base leading-7 text-gray-600" v-html="faq.answer"></p>
          </DisclosurePanel>
        </Disclosure>
      </dl>
    </div>
  </div>
</template>

<script setup>
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue'
import { MinusSmallIcon, PlusSmallIcon } from '@heroicons/vue/24/outline'

const faqs = [
  {
    question: 'How can I financially support this event?',
    answer: 'Prayer Storm is a non profit organisation and aims to make tickets free or as affordable as possible. If you would like to support this event and help make it possible via financial support, you can do so here: <a href="/support/3/summer-conference" target="_blank" rel="noopener noreferrer" class="text-blue underline font-bold">Prayer Storm Summer Conference Support</a>.'
  },
  {
    question: 'Where can I order tickets?',
    answer: '\'In-Person\' attendance tickets are available to register via this event page above. PLEASE NOTE: Only people with a valid QR code ticket and a delegate name upon the ticket will be permitted entrance to attend.'
  },
  // {
  //   question: 'Can I be added to the waiting list?',
  //   answer: 'Unfortunately the waiting list is now closed due to a high number of people already on the list. If you are unable to attend in person, you can still watch the conference online via our YouTube channel - PrayerStormTV. PLEASE NOTE: Only people with a valid QR code ticket and a delegate name upon the ticket will be permitted entrance to attend.'
  // },
  // {
  //   question: 'I\'m already on the waiting list, can I attend?',
  //   answer: 'If you are on the waiting list you will not be able to attend unless space becomes avialable and you have ordered a \'General Admission\' ticket. PLEASE NOTE: Only people with a valid QR code ticket and a delegate name upon the ticket will be permitted entrance to attend.'
  // },
  {
    question: 'Where is the venue?',
    answer: 'The Sheridan Suite, 371 Oldham Road, Miles Platting, Manchester, M40 8RR, UK'
  },
  {
    html: true,
    question: 'What hotels are nearby?',
    answer: 'Please do not call The Sheridan Suite with enquiries. They have kindly provided a list of nearby hotels for your convenience.<br /><a href="https://pslive.blob.core.windows.net/files/sheridan-hotels.pdf" target="_blank" rel="noopener noreferrer" class="text-blue underline font-bold">Click here to view the list of nearby hotels</a>.'
  },
  {
    question: 'When is this taking place?',
    answer: 'The conference will take place between Tuesday 25th and Thursday 27th June, 2024. Please see the schedule for more details'
  },
  {
    question: 'What is the time/duration of each session?',
    answer: 'Each session is estimated to 2.5 - 3 hours. Morning sessions 10am-12.30pm, afternoon sessions 2.30-5pm and evening sessions 7-10pm. All UK timezone (BST). End times may vary. PLEASE NOTE: If you are travelling from abroad, your web browser may show you the dates in your local time zone. Please ensure you attend for the UK times.'
  },
  // {
  //   question: 'What is the schedule and format?',
  //   answer: 'There are two ways to take part in this event. Firstly, this conference is available to attend in-person by purchasing a "General Admission" conference ticket. Secondly, it will also be livestreamed so that you can watch it online via our YouTube channel - PrayerStormTV.'
  // },
  // {
  //   question: 'Can I get a refund?',
  //   answer: 'Please refer to our <a href="https://docs.prayerstorm.org/reference/events/tickets/cancellations-and-refunds" target="_blank" rel="noopener noreferrer" class="text-blue underline font-bold">Cancellations and Refunds Policy</a>.'
  // },
  // {
  //   question: 'Can\'t afford the ticket price?',
  //   answer: 'Prayer Storm is a non profit organisation. We do not charge ticket prices to generate income and we aim to keep ticket costs as low as possible to enable as many people to attend our events. Many of our events and live streams are free, but we do need to try and recover some of the costs involved in running our larger conferences and events. We do realise though, that the full ticket cost may still be an issue for some. If this is the case, then please email us at info@prayerstorm.org'
  // },
  {
    question: 'Can I bring my children?',
    answer: 'Due to the limited seating capacity, we are dissuading parents from bringing their children, to allow for as many adults to attend.'
  },
  {
    question: 'Can you provide a letter for my visa?',
    answer: 'Unfortunately we are unable to provide letters or documentation for visa applications.'
  },
  {
    question: 'Can you provide information for my travel arrangements?',
    answer: 'We cannot provide travel arrangements for individuals attending.'
  }
]
</script>
