<template>
<main>
  <div class="">
    <div class="bg-white p-4 shadow sm:rounded-lg">
      <p v-show="!formState.loadError && !formState.registration">Loading registrations, please wait...</p>
      <ErrorMessage :formError="formState.loadError" class="mt-6"></ErrorMessage>

      <div v-if="formState.registrations">
        <div class="pb-4">
          Total Delegates: {{ totals.totalDelegates }}<br/>
          Total Scanned In: {{ totals.totalTicketsReg }}<br/>
          Total Cancelled: {{ totals.totalCancelled }}<br/>
        </div>
        <div class="pb-4">
          <table class="min-w-full">
            <tbody class="divide-y divide-gray-200">
              <tr>
                <td>
                  <input type="text" v-model="filterText" class="w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" />
                </td>
                <td class="pl-4">
                  <button class="" @click.prevent="searchRegistrations"><MagnifyingGlassIcon class="h-8 w-8" /></button>
                </td>
                <td class="pl-4">
                  <button class="" @click.prevent="clearFilter"><XMarkIcon class="h-8 w-8" /></button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <table class="min-w-full">
          <thead>
            <tr class="border-t border-gray-300 bg-gray-200">
              <th class="px-3 py-3 uppercase tracking-wider text-left" >
                Name
              </th>
              <th class="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell">BOOK. EMAIL</th>
              <th class="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell">REG</th>
            </tr>
          </thead>
          <tbody class="divide-y divide-gray-200">
            <tr class="bg-white hover:bg-gray-100 cursor-pointer" v-for="item in formState.filteredRegistrations" :key="item.guid" @click="selectRegistration(item.guid)">
              <td class="pl-3 py-3 text-left">
                <span class="font-bold">{{item.firstName}} {{item.lastName}}</span>
                <span class="font-bold text-red-500 ml-2" v-if="item.cancelledAt || item.cancelled">CANCELLED</span>
                <dl class="font-normal md:hidden">
                  <dd class="mt-1 truncate text-gray-500 sm:hidden">{{ item.email }}</dd>
                </dl>
              </td>
              <td class="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
                <span class="inline-block">{{item.email}}</span>
              </td>
              <td class="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
                <span class="inline-block">
                  <CheckCircleIcon v-if="item.registered" class="h-8 w-8" />
                  <XMarkIcon v-else class="h-8 w-8" />
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</main>
</template>

<script>
import axios from 'axios'
import { defineComponent, onMounted, reactive, ref, computed } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import responseHelper from '@/helpers/response.helper'
// import SuccessMessage from '@/components/SuccessMessage.vue'
import ErrorMessage from '@/components/ErrorMessage.vue'
import { XMarkIcon, MagnifyingGlassIcon, CheckCircleIcon } from '@heroicons/vue/20/solid'

export default defineComponent({
  components: {
    // SuccessMessage,
    ErrorMessage,
    MagnifyingGlassIcon,
    XMarkIcon,
    CheckCircleIcon
  },
  setup () {
    const route = useRoute()
    const router = useRouter()
    const recordId = ref(route.params.recordId)

    const filterText = ref('')

    const popUpState = reactive({
      open: false
    })

    const formState = reactive({
      selectedRegistration: undefined,
      registrations: undefined,
      filteredRegistrations: undefined,
      loadError: responseHelper.noError,
      formSubmitted: false,
      formError: responseHelper.noError,
      successMessage: ''
    })

    onMounted(() => {
      axios.post(
        'pub/registrations/list',
        {
          recordId: recordId.value
        }
      ).then((response) => {
        if (response?.data?.success && response?.data?.result) {
          formState.registrations = response.data.result.registrations
          formState.filteredRegistrations = response.data.result.registrations
        } else {
          formState.loadError = responseHelper.unexpectedResult
        }
      }).catch((error) => {
        formState.loadError = responseHelper.getMessageV2(error)
      })
    })

    const searchRegistrations = async () => {
      formState.filteredRegistrations = filteredRegistrations()
    }
    const clearFilter = async () => {
      filterText.value = ''
      formState.filteredRegistrations = filteredRegistrations()
    }
    const selectRegistration = async (guid) => {
      router.push('/registration-ticket/verify/' + guid)

      // formState.selectedRegistration = undefined
      // formState.selectedRegistrationSessions = []
      // axios.post(
      //   'pub/registrations/details',
      //   {
      //     guid: guid
      //   }
      // ).then((response) => {
      //   if (response?.data?.success && response?.data?.result) {
      //     formState.selectedRegistration = response.data.result.registration
      //     popUpState.open = true
      //   }
      // }).catch((error) => {
      //   formState.loadError = responseHelper.getMessageV2(error)
      // })
    }

    const filteredRegistrations = () => {
      if (!formState.registrations) {
        return []
      }

      if (!filterText.value) {
        return formState.registrations
      }

      const regFilter = filterText.value.toUpperCase()
      return formState.registrations.filter((item) => {
        return (item.firstName.toUpperCase().includes(regFilter) || item.lastName.toUpperCase().includes(regFilter) || item.email.toUpperCase().includes(regFilter))
      })
    }
    const totals = computed(() => {
      let totalDelegates = 0
      let totalCancelled = 0
      let totalTicketsReg = 0

      if (formState.registrations) {
        for (let i = 0; i < formState.registrations.length; i++) {
          totalDelegates++
          if (formState.registrations[i].cancelledAt || formState.registrations[i].cancelled) {
            totalCancelled++
          }
          if (formState.registrations[i].registered) {
            totalTicketsReg++
          }
        }
      }

      return {
        totalDelegates: totalDelegates,
        totalCancelled: totalCancelled,
        totalTicketsReg: totalTicketsReg
      }
    })

    return {
      totals,
      formState,
      searchRegistrations,
      filterText,
      clearFilter,
      popUpState,
      selectRegistration
    }
  }
})
</script>
