<template>
<section aria-labelledby="profileHeading">
  <form id="profile-form" @submit.prevent="updateProfile">
    <div class="shadow sm:rounded-md sm:overflow-hidden">
      <div class="bg-white py-6 px-4 sm:p-6">
        <div>
          <h2 id="profileHeading" class="text-lg leading-6 font-medium text-gray-900">Profile</h2>
          <p class="mt-1 text-sm text-gray-500">This information <em>will be displayed publicly</em> so be careful what you share.</p>
        </div>

        <ErrorMessage :formError="loadError" class="mt-6"></ErrorMessage>

        <div class="mt-6 grid grid-cols-4 gap-6">
          <div class="col-span-4 md:col-span-2">
            <label for="username" class="block text-sm font-medium text-gray-700">Username</label>
            <div class="mt-1 rounded-md shadow-sm flex">
              <!-- <span class="bg-gray-50 border border-r-0 border-gray-300 rounded-l-md px-3 inline-flex items-center text-gray-500 sm:text-sm">
                beta.prayerstorm.org/profile/
              </span> -->
              <input v-model="profileForm.username" type="text" name="username" id="username" autocomplete="off" class="focus:ring-light-blue-500 focus:border-light-blue-500 flex-grow block w-full min-w-0 rounded-none rounded-r-md rounded-l-md sm:text-sm border-gray-300" />
            </div>
          </div>

          <div class="col-span-4 md:col-span-2">
            <label for="url" class="block text-sm font-medium text-gray-700">URL</label>
            <input v-model="profileForm.website" type="text" name="url" id="url" autocomplete="off" class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-light-blue-500 focus:border-light-blue-500 sm:text-sm" />
          </div>

          <div class="col-span-4">
            <label for="displayName" class="block text-sm font-medium text-gray-700">For any comments you make, please specify your preferred display name</label>
            <RadioGroup v-model="profileForm.displayName" name="displayName" id="displayName">
              <RadioGroupLabel class="sr-only">
                Display Name Options
              </RadioGroupLabel>
              <div class="mt-1 bg-white rounded-md -space-y-px">
                <RadioGroupOption as="template" v-for="(setting, settingIdx) in displayNameOptions" :key="setting.value" :value="setting.value" v-slot="{ checked, active }">
                  <div :class="[settingIdx === 0 ? 'rounded-tl-md rounded-tr-md' : '', settingIdx === displayNameOptions.length - 1 ? 'rounded-bl-md rounded-br-md' : '', checked ? 'bg-indigo-50 border-indigo-200' : 'border-gray-200', 'relative border p-4 flex cursor-pointer focus:outline-none']">
                    <span :class="[checked ? 'bg-indigo-600 border-transparent' : 'bg-white border-gray-300', active ? 'ring-2 ring-offset-2 ring-indigo-500' : '', 'h-4 w-4 mt-0.5 cursor-pointer rounded-full border flex items-center justify-center']" aria-hidden="true">
                      <span class="rounded-full bg-white w-1.5 h-1.5" />
                    </span>
                    <div class="ml-3 flex flex-col">
                      <RadioGroupLabel as="span" :class="[checked ? 'text-indigo-900' : 'text-gray-900', 'block text-sm font-medium']">
                        {{ setting.name }}
                      </RadioGroupLabel>
                    </div>
                  </div>
                </RadioGroupOption>
              </div>
            </RadioGroup>
          </div>

        <div class="col-span-4">
          <label for="messageable" class="block text-sm font-medium text-gray-700">Do you want to allow messaging via the Prayer Circles app?</label>
          <RadioGroup v-model="profileForm.messageable" name="messageable" id="messageable">
            <RadioGroupLabel class="sr-only">
              Messaging Options
            </RadioGroupLabel>
            <div class="mt-1 bg-white rounded-md -space-y-px">
              <RadioGroupOption as="template" v-for="(setting, settingIdx) in messageableOptions" :key="setting.value" :value="setting.value" v-slot="{ checked, active }">
                <div :class="[settingIdx === 0 ? 'rounded-tl-md rounded-tr-md' : '', settingIdx === messageableOptions.length - 1 ? 'rounded-bl-md rounded-br-md' : '', checked ? 'bg-indigo-50 border-indigo-200' : 'border-gray-200', 'relative border p-4 flex cursor-pointer focus:outline-none']">
                  <span :class="[checked ? 'bg-indigo-600 border-transparent' : 'bg-white border-gray-300', active ? 'ring-2 ring-offset-2 ring-indigo-500' : '', 'h-4 w-4 mt-0.5 cursor-pointer rounded-full border flex items-center justify-center']" aria-hidden="true">
                    <span class="rounded-full bg-white w-1.5 h-1.5" />
                  </span>
                  <div class="ml-3 flex flex-col">
                    <RadioGroupLabel as="span" :class="[checked ? 'text-indigo-900' : 'text-gray-900', 'block text-sm font-medium']">
                      {{ setting.name }}
                    </RadioGroupLabel>
                  </div>
                </div>
              </RadioGroupOption>
            </div>
          </RadioGroup>
        </div>

          <div class="col-span-4">
            <div>
              <label for="about" class="block text-sm font-medium text-gray-700">
                About
              </label>
              <div class="mt-1">
                <textarea v-model="profileForm.about" id="about" name="about" rows="3" class="shadow-sm focus:ring-light-blue-500 focus:border-light-blue-500 mt-1 block w-full sm:text-sm border-gray-300 rounded-md" />
              </div>
              <p class="mt-2 text-sm text-gray-500">
                Brief description for your profile.
              </p>
            </div>
          </div>
        </div>

        <hr class="my-6">
        <div>
          <h2 id="accountHeading" class="text-lg leading-6 font-medium text-gray-900">Account</h2>
          <p class="mt-1 text-sm text-gray-500">This information relates to your account details.</p>
        </div>
        <div class="mt-6 grid grid-cols-4 gap-6">
          <div class="col-span-4 sm:col-span-2">
            <label for="firstName" class="block text-sm font-medium text-gray-700">First name</label>
            <input v-model="profileForm.firstName" type="text" name="firstName" id="firstName" autocomplete="off" class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-light-blue-500 focus:border-light-blue-500 sm:text-sm" />
          </div>

          <div class="col-span-4 sm:col-span-2">
            <label for="lastName" class="block text-sm font-medium text-gray-700">Last name</label>
            <input v-model="profileForm.lastName" type="text" name="lastName" id="lastName" autocomplete="off" class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-light-blue-500 focus:border-light-blue-500 sm:text-sm" />
          </div>

          <div class="col-span-4 sm:col-span-2">
            <label for="email" class="block text-sm font-medium text-gray-700">Email address</label>
            <input disabled v-model="profileForm.email" type="text" name="email" id="email" autocomplete="off" class="bg-gray-200 mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-light-blue-500 focus:border-light-blue-500 sm:text-sm" />
          </div>
        </div>

        <hr class="my-6">
        <div>
          <h2 id="prayerLeadersHeading" class="text-lg leading-6 font-medium text-gray-900">Prayer Leader's Network</h2>
          <p class="mt-1 text-sm text-gray-500">This information <em>will not be displayed publicly</em>, but it enables you to let us know if you are the prayer leader of community such as church, youth group or prayer group/event. We use this information to communicate relevant information and events to prayer leaders.</p>
        </div>
        <div class="mt-6 grid grid-cols-4 gap-6">
          <div class="col-span-4 md:col-span-2">
            <label for="communityName" class="block text-sm font-medium text-gray-700">Prayer Leader</label>
            <div class="relative flex items-start mt-2">
              <div class="flex items-center h-5">
                <input v-model="profileForm.isPrayerLeader" aria-describedby="is-prayer-leader-description" name="isPrayerLeader" id="isPrayerLeader" type="checkbox" class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded" />
              </div>
              <div class="ml-3 text-sm">
                <p id="is-prayer-leader-description" class="text-gray-500">Tick if you are a prayer leader of a community.</p>
              </div>
            </div>
          </div>

          <div class="col-span-4 md:col-span-2">
            <label for="communityName" class="block text-sm font-medium text-gray-700">Community Name</label>
            <input v-model="profileForm.communityName" :disabled="!profileForm.isPrayerLeader" type="text" name="communityName" id="communityName" autocomplete="off" class="disabled:opacity-50 mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-light-blue-500 focus:border-light-blue-500 sm:text-sm" />
          </div>

          <div class="col-span-4 md:col-span-2">
            <label for="communityTownCity" class="block text-sm font-medium text-gray-700">Community Town/City</label>
            <input v-model="profileForm.communityTownCity" :disabled="!profileForm.isPrayerLeader" type="text" name="communityTownCity" id="communityTownCity" autocomplete="off" class="disabled:opacity-50 mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-light-blue-500 focus:border-light-blue-500 sm:text-sm" />
          </div>

          <div class="col-span-4 md:col-span-2">
            <label for="communityCountry" class="block text-sm font-medium text-gray-700">Community Country</label>
            <input v-model="profileForm.communityCountry" :disabled="!profileForm.isPrayerLeader" type="text" name="country" id="communityCountry" autocomplete="off" class="disabled:opacity-50 mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-light-blue-500 focus:border-light-blue-500 sm:text-sm" />
          </div>
        </div>

        <hr class="my-6">
        <div>
          <h2 id="deleteAccountHeading" class="text-lg leading-6 font-medium text-red-500">Delete Account</h2>
          <p class="mt-1 text-sm text-gray-500">Caution. If you would like to delete your account the please click here: <router-link to="/settings/delete" class="text-red-500">Delete Account</router-link></p>
        </div>

        <ErrorMessage :formError="formError" class="mt-6"></ErrorMessage>
        <SuccessMessage :successMessage="successMessage" class="mt-6"></SuccessMessage>
      </div>
      <div class="px-4 py-3 bg-gray-50 text-right sm:px-6">
        <button type="submit" :disabled="profileFormSubmitted || loadData" class="disabled:opacity-50 bg-blue border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-blue-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue">
          Save
        </button>
      </div>
    </div>
  </form>
</section>
</template>

<script>
import axios from 'axios'
import { mapGetters } from 'vuex'
import responseHelper from '@/helpers/response.helper'
import SuccessMessage from '@/components/SuccessMessage.vue'
import ErrorMessage from '@/components/ErrorMessage.vue'
import { RadioGroup, RadioGroupLabel, RadioGroupOption } from '@headlessui/vue'

export default {
  components: {
    RadioGroup,
    RadioGroupLabel,
    RadioGroupOption,
    SuccessMessage,
    ErrorMessage
  },
  data: () => ({
    displayNameOptions: [
      { name: 'Username (Default)', value: 0 },
      { name: 'First and last name', value: 1 }
    ],
    messageableOptions: [
      { name: 'Enable Messaging', value: true },
      { name: 'Disable Messaging', value: false }
    ],
    successMessage: '',
    formError: responseHelper.noError,
    profileFormSubmitted: false,
    loadData: false,
    loadError: responseHelper.noError,
    profileForm: {
      username: '',
      about: '',
      website: '',
      email: '',
      firstName: '',
      lastName: '',
      isPrayerLeader: false,
      communityName: '',
      communityTownCity: '',
      communityCountry: '',
      displayName: 0,
      messageable: true
    }
  }),
  methods: {
    updateProfile () {
      this.profileFormSubmitted = true
      this.formError = responseHelper.noError
      this.successMessage = ''
      axios.post('settings/profile', this.profileForm)
        .then((response) => {
          if (response?.data?.success && response?.data?.result) {
            this.successMessage = response.data.result.message
          } else {
            this.formError = responseHelper.unexpectedResult
          }
          // Handle the response
        }).catch((error) => {
          this.formError = responseHelper.getMessage(error)
        }).finally(() => {
          this.profileFormSubmitted = false
        })
    },
    loadProfile () {
      this.loadData = true
      this.loadError = responseHelper.noError
      axios.get('settings/profile')
        .then((response) => {
          if (response?.data?.success && response?.data?.result) {
            this.profileForm = response.data.result
            this.loadData = false
          } else {
            this.loadError = responseHelper.loadingUnexpectedResult
          }
          // Handle the response
        }).catch(() => {
          this.loadError = responseHelper.loadingUnexpectedResult
        })
    }
  },
  computed: {
    ...mapGetters([
      'getAvatar'
    ])
  },
  mounted () {
    this.loadProfile()
  }
}
</script>
