<template>
  <div class="relative max-w-7xl mx-auto pt-4 px-4 sm:px-6">
    <div class="relative text-white pointer-events-none" >
      <div class="relative text-center z-10 pt-10">
        <h1 class="text-4xl md:text-6xl font-bold text-white">Life on Fire</h1>
        <p class="text-xl font-bold text-white">By James Aladiran</p>
      </div>

      <div class="relative px-8 pb-8 z-10">
        <h2 id="related-heading" class="text-xl font-bold text-white pt-4 md:pt-0">Available as:</h2>
        <div class="mt-8 grid grid-cols-1 gap-y-12 sm:grid-cols-2 md:grid-cols-3 sm:gap-x-6 xl:gap-x-8">
          <div v-for="product in relatedProducts" :key="product.id">
            <div class="relative">
              <div class="relative h-72 w-full overflow-hidden rounded-lg">
                <img :src="product.imageSrc" :alt="product.imageAlt" class="h-full mx-auto" />
              </div>
              <div class="relative mt-4">
                <h3 class="text-lg font-bold text-white">{{ product.name }}</h3>
                <p class="mt-1 text-sm text-white">{{ product.color }}</p>
              </div>
              <div class="absolute inset-x-0 top-0 flex h-72 items-end justify-end overflow-hidden rounded-lg p-4">
                <div aria-hidden="true" class="absolute inset-x-0 bottom-0 h-36 bg-gradient-to-t from-black opacity-50" />
                <p v-if="product.price" class="relative text-lg font-semibold text-white">{{ product.price }}</p>
              </div>
            </div>
            <div class="mt-6">
              <a :href="product.href" class="relative pointer-events-auto flex items-center justify-center rounded-md border border-transparent bg-white px-8 py-2 text-lg font-bold text-blue hover:bg-blue hover:text-white hover:border-white">
                Order {{ product.name }}
              </a>
            </div>
          </div>
        </div>
      </div>

      <iframe src="/background-lof.html" frameborder="0" class="absolute top-0 w-full h-full rounded-xl"></iframe>
    </div>
  </div>
</template>

<script>
import { defineComponent } from '@vue/runtime-core'

const relatedProducts = [
  {
    id: 1,
    name: 'Book',
    href: 'https://prayerstormshop.bigcartel.com/product/life-on-fire',
    imageSrc: '/img/resources/lof-book.png',
    imageAlt: 'Book',
    price: '£10',
    color: 'Available from our shop'
  },

  {
    id: 1,
    name: 'eBook',
    href: 'https://www.amazon.co.uk/dp/B093DN6L51/',
    imageSrc: '/img/resources/lof-kindle.png',
    imageAlt: 'Kindle',
    price: '',
    color: 'Available on Kindle'
  },

  {
    id: 1,
    name: 'Audio',
    href: 'https://www.amazon.co.uk/Life-Fire-Becoming-Person-Prayer/dp/B0CJLZ9K63/',
    imageSrc: '/img/resources/lof-audible.png',
    imageAlt: 'Audible',
    price: '',
    color: 'Available on Audible'
  }
  // More products...
]

export default defineComponent({
  name: 'LifeOnFireVideo',
  components: {
  },
  setup () {
    return {
      relatedProducts
    }
  }
})
</script>

<style scoped>
.video-container {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    height: 0;
}
.video-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
}
</style>
