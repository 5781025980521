<template>
  <NavBar></NavBar>
  <router-view :key="$route.fullPath"/>
  <Footer></Footer>
  <Notification></Notification>
  <WatchOnModal></WatchOnModal>
  <AccessModal></AccessModal>
  <ErrorModal></ErrorModal>
  <LoadingModal></LoadingModal>
</template>

<script>
import { defineComponent } from 'vue'
import NavBar from '@/components/NavBar.vue'
import Footer from '@/components/Footer.vue'
import WatchOnModal from '@/components/global/WatchOnModal.vue'
import AccessModal from '@/components/global/AccessModal.vue'
import ErrorModal from '@/components/global/ErrorModal.vue'
import LoadingModal from '@/components/global/LoadingModal.vue'
import Notification from '@/components/Notification.vue'
export default defineComponent({
  name: 'App',
  components: {
    NavBar,
    Footer,
    Notification,
    WatchOnModal,
    AccessModal,
    ErrorModal,
    LoadingModal
  }
})
</script>
