<template>
<main>
  <LiveNow></LiveNow>
  <div class="bg-black bg-contain bg-no-repeat bg-right-top" style="background-image:linear-gradient(to left bottom, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2), rgba(42, 131, 161, 0.4), rgba(42, 131, 161, .6));">
    <div class="max-w-7xl mx-auto py-24 px-4 sm:px-6 lg:px-8">
      <div class="sm:flex sm:flex-col align-center">
        <h1 class="text-3xl font-extrabold text-white text-center">We Declare War</h1>
      </div>
    </div>
  </div>
  <div class="flex flex-col justify-center py-12 sm:px-6 lg:px-8">
    <div class="sm:mx-auto sm:w-full sm:max-w-3xl">
      <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">

        <p class="lyrics">We Declare War</p>

        <p class="lyrics">I can hear the sound of uprising<br>
                                                        Can you feel the rumbles of a revolution?<br>
                                                        I can hear the sound of your people<br>
                                                        Rise and take a stand for our nation</p>

        <p class="lyrics">The dry ground is crying out<br>
                                                        It’s ready for the rain<br>
                                                        Pour out your Spirit, Lord<br>
                                                        Do it again</p>

        <p class="lyrics">The dry bones wait for the breath<br>
                                                        Let’s prophesy<br>
                                                        Train up this army<br>
                                                        Let the warriors arise</p>

        <p class="lyrics">Wake up the warriors<br>
                                                        Raise up the warriors<br>
                                                        Rise up from the dead<br>
                                                        Awake from your slumber</p>

        <p class="lyrics">Awake, arise, warrior bride</p>

        <p class="composer">Rebecca Aladiran, James Aladiran, Lloyd Gordon<br>
                                                        © 2017 Prayer Storm Music</p>

      </div>
    </div>
  </div>
</main>

</template>
<script>
import LiveNow from '@/components/LiveNow.vue'
export default {
  name: 'LyricsWeDeclareWar',
  components: {
    LiveNow
  },
  setup () {
    return {
    }
  }
}
</script>

<style scoped>
.lyrics {
  @apply mt-8 font-medium text-gray-700
}
.composer {
  @apply mt-8 text-sm mb-10 text-gray-700
}
</style>
