<template>
  <div v-if="panels">
    <div class="mx-auto">
      <swiper
        :modules="swiperOptions.swiperModules"
        :slides-per-view="swiperOptions.slidesPerView"
        :space-between="swiperOptions.spaceBetween"
        :breakpoints="swiperOptions.breakpoints"
        :autoplay="swiperOptions.autoplay"
        :pagination="swiperOptions.pagination"
        @swiper="onSwiper"
        @slideChange="onSlideChange"
        class=""
      >
        <swiper-slide v-for="item in panels" :key="item.guid">
          <a @click.prevent="promoClick(item)">
            <div class="relative bg-feature-img cursor-pointer pb-32" v-bind:style="{ 'backgroundImage': getContentImage(item) }">
              <div class="absolute inset-x-0 bottom-0 text-white pl-3 pb-2">
                &nbsp;
              </div>
            </div>
          </a>
        </swiper-slide>
        <swiper-slide>
          <div class="relative">
            <iframe src="/background.html" frameborder="0" class="absolute inset-0 h-full w-full"></iframe>
            <div class="relative bg-feature-img pointer-events-none">
              <div class="absolute inset-0 text-white">
                <div class="relative h-full">
                  <div class="absolute inset-x-0 bottom-4 sm:bottom-8 text-center">
                    <button class="pointer-events-auto font-bold hover:bg-blue text-sm border border-white p-2 rounded-md" @click="joinTheMovement">Join the Movement</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </swiper-slide>
      </swiper>
      <div class="swiper-pagination"></div>
    </div>
  </div>

  <TransitionRoot as="template" :show="open">
    <Dialog as="div" class="fixed z-10 inset-0 overflow-y-auto" @close="open = false">
      <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
          <DialogOverlay class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
          <div v-if="selectedItem" class="inline-block my-auto bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 align-middle w-full sm:max-w-5xl sm:w-full sm:p-6">
            <div class="relative">
              <a :href="selectedItem.linkUrl">
                <img class="w-full" :src="getContentImageUrl(selectedItem)" />
              </a>
              <h2 class="font-bold text-2xl text-blue pt-4">{{selectedItem.title}}</h2>
              <div v-if="selectedItem.description" class="pt-1 popup-content" v-html="selectedItem.description"></div>
              <div v-if="selectedItem.linkUrl" class="pt-4">
                <a class="bg-blue hover:bg-blue-dark py-2 px-3 rounded-md text-sm text-white font-bold" :href="selectedItem.linkUrl">More &gt;</a>
              </div>
            </div>
            <div class="relative text-right pt-3">
              <a class="font-bold" href="#" @click.prevent="open = false">X CLOSE</a>
            </div>
          </div>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
<script>
import { ref, watch } from 'vue'
import { useContent } from '@/composables/useContent'
import { Swiper, SwiperSlide } from 'swiper/vue'
import SwiperCore, { Autoplay, Pagination } from 'swiper'
import 'swiper/swiper-bundle.min.css'
import {
  Dialog,
  DialogOverlay,
  TransitionChild,
  TransitionRoot
} from '@headlessui/vue'

SwiperCore.use([Autoplay, Pagination])

export default {
  name: 'HomeFeatured',
  props: [
    'title',
    'links',
    'allLink',
    'panels',
    'allText'
  ],
  components: {
    Swiper,
    SwiperSlide,
    Dialog,
    DialogOverlay,
    TransitionChild,
    TransitionRoot
  },
  setup (props, context) {
    const { getContentImage, getContentImageUrl, getContentLink } = useContent()
    const open = ref(false)
    const selectedItem = ref(null)
    let autoShowPromotion = localStorage.getItem('autoShowPromotion') ? parseInt(localStorage.getItem('autoShowPromotion')) : 0

    watch(
      () => props.panels,
      () => {
        if (props.panels) {
          for (let index = 0; index < props.panels.length; index++) {
            if (props.panels[index].autoShow &&
              props.panels[index].promotionType === 2 &&
              autoShowPromotion !== props.panels[index].id
            ) {
              // show popup if popup type
              autoShowPromotion = props.panels[index].id
              localStorage.setItem('autoShowPromotion', autoShowPromotion.toString())
              selectedItem.value = props.panels[index]
              open.value = true
            }
          }
        }
      }
    )

    const promoClick = (item) => {
      if (!item) {
        return
      }

      if (item.promotionType === 1) {
        // navigate to link if promotion is link
        if (item.linkUrl) {
          window.location.href = item.linkUrl
        }
      } else if (item.promotionType === 2) {
        // show popup if popup type
        selectedItem.value = item
        open.value = true
      }
    }

    const swiperOptions = {
      slidesPerView: 1,
      spaceBetween: 12,
      breakpoints: {
      },
      autoplay: {
        delay: 8000,
        disableOnInteraction: false
      },
      pagination: {
        clickable: true,
        dynamicButtons: true,
        el: '.swiper-pagination'
      },
      swiperModules: [Autoplay, Pagination]
    }

    const getAllText = () => {
      if (this.allText) {
        return this.allText
      } else {
        return 'View all'
      }
    }

    const onSwiper = (swiper) => {
      // console.log(swiper)
    }
    const onSlideChange = () => {
      // console.log('slide change')
    }

    const joinTheMovement = () => {
      const scrollTo = document.getElementById('mailing-list')
      scrollTo.scrollIntoView({ behavior: 'smooth' })
    }

    return {
      getContentImage,
      getContentImageUrl,
      getContentLink,
      getAllText,
      onSwiper,
      onSlideChange,
      swiperOptions,
      promoClick,
      open,
      selectedItem,
      joinTheMovement
    }
  }
}
</script>

<style scoped>
.bg-feature-img {
  background-size: cover;
  background-position: center;
  padding-bottom: 37%;
}

.text-ellipsis-2 {
  text-overflow:ellipsis;
  overflow:hidden;
  display: -webkit-box !important;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: normal;
}
</style>
