<template>
<main>
  <LiveNow></LiveNow>
  <div class="bg-black bg-contain bg-no-repeat bg-right-top" style="background-image:linear-gradient(to left bottom, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2), rgba(42, 131, 161, 0.4), rgba(42, 131, 161, .6));">
    <div class="max-w-7xl mx-auto py-24 px-4 sm:px-6 lg:px-8">
      <div class="sm:flex sm:flex-col align-center">
        <h1 class="text-3xl font-extrabold text-white text-center">Big Ben</h1>
      </div>
    </div>
  </div>
  <div class="flex flex-col justify-center py-12 sm:px-6 lg:px-8">
    <div class="sm:mx-auto sm:w-full sm:max-w-3xl">
      <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">

        <p class="lyrics">This is the sound of Big Ben. The famous bell from the Houses of Parliament’s clock tower in London.</p>

        <p class="lyrics">As we play this sound, we release a prophetic declaration over the United Kingdom<br>
                                                        It is time for chains to be broken. Chains of immorality, chains of witchcraft, Chains of deception, chains of addictions, chains of darkness be<br>
                                                        broken in Jesus Name!</p>

        <p class="lyrics">For this purpose was the son of God manifested that He might destroy the works of the devil. Father in the name of Jesus we declare the works<br>
                                                        the devil are destroyed one the UK.</p>

        <p class="lyrics">We dethrone every other god, we dismantle idols, we break down barriers and walls of opposition set up against the purposes and the plans of<br>
                                                        God in this land. We call for the lightnings and thunders of God to strike down and destroy altars of wickedness. We prepare the way of the<br>
                                                        Lord, let mountains be removed, the valleys be filled, let the crooked path be made straight for the glory of the Lord will be revealed and all<br>
                                                        flesh will see it. Lift up your heads, O you gates! And be lifted up, you everlasting doors! And the King of glory will come in. Who is this King of<br>
                                                        glory? The LORD strong and mighty, The LORD mighty in battle. The LORD of hosts, He is the King of glory.</p>

        <p class="lyrics">We enthrone Jesus! we declare You right over this land. This Nation belongs to Jesus.<br>
                                                        We call for the government of the United Kingdom to come into alignment with the government of heaven.</p>

        <p class="lyrics">Father let Your Kingdom come let Your will be done on earth as it is in heaven.<br>
                                                        We release a sound of awakening across this land. United Kingdom hear the word of the Lord it is time for awakening, we sound the alarm!</p>

        <p class="lyrics">The UK sets the time for the rest of the world.<br>
                                                        As we play this sound we call to the Nations to the earth… to come into alignment with the purposes and plans of God.<br>
                                                        We release this sound from this land to the Nations of the earth! It is time for awakening. For the earth will be filled with the knowledge of the<br>
                                                        glory of the Lord as the waters cover the sea. Father You said it will come to pass afterwards that You will pour out Your Spirit on all flesh; our<br>
                                                        sons and our daughters will prophecy, our old men will dream dreams and our young men will see visions… We say do it Lord</p>

        <p class="lyrics">As you listen to this record, I want to invite you to begin to intercede for the Nation<br>
                                                        I want you to begin to intercede for your community for your family<br>
                                                        Let us begin to release the life and light of God into everything that has been dead and dieing<br>
                                                        We prophecy the resurrection life of God we say it is time for awakening<br>
                                                        We say dry bones come to life<br>
                                                        Letter army of God arise the nations of the earth<br>
                                                        We prepare the way of the Lord<br>
                                                        It is time for awakening<br>
                                                        Father invade the earth with Your glory<br>
                                                        Just as You promised!</p>
      </div>
    </div>
  </div>
</main>

</template>
<script>
import LiveNow from '@/components/LiveNow.vue'
export default {
  components: {
    LiveNow
  },
  setup () {
    return {
    }
  }
}
</script>

<style scoped>
.lyrics {
  @apply mt-8 font-medium text-gray-700
}
.composer {
  @apply mt-8 text-sm mb-10 text-gray-700
}
</style>
