import { reactive } from 'vue'

const state = reactive({
  show: false,
  title: 'Successfully saved!',
  detail: 'Anyone with the link can now view this file.'
})

export function useNotification () {
  const closeNotification = () => {
    state.show = false
  }

  const showNotification = (title, detail, autoClose) => {
    state.title = title
    state.detail = detail
    state.show = true
    if (autoClose) {
      setTimeout(() => {
        closeNotification()
      }, autoClose)
    }
  }

  return {
    state: state,
    closeNotification: closeNotification,
    showNotification: showNotification
  }
}
