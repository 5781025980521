<template>
<main>
  <LiveNow></LiveNow>
  <div class="bg-black bg-contain bg-no-repeat bg-right-top" style="background-image:linear-gradient(to left bottom, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2), rgba(42, 131, 161, 0.4), rgba(42, 131, 161, .6));">
    <div class="max-w-7xl mx-auto py-24 px-4 sm:px-6 lg:px-8">
      <div class="sm:flex sm:flex-col align-center">
        <h1 class="text-3xl font-extrabold text-white text-center">Love of God</h1>
      </div>
    </div>
  </div>
  <div class="flex flex-col justify-center py-12 sm:px-6 lg:px-8">
    <div class="sm:mx-auto sm:w-full sm:max-w-3xl">
      <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">

        <p class="lyrics">Deepest of oceans are shallow to your love<br>
                                                        Highest of mountains are so low to your love<br>
                                                        No wonder the angels adore you, they adore you<br>
                                                        No wonder your people they love you, how we love you</p>

        <p class="lyrics">Your love, full of passion, oh<br>
                                                        Your love, so abandoned, oh<br>
                                                        Your love, so inspiring, oh<br>
                                                        There’s no deeper love<br>
                                                        Than your love</p>

        <p class="lyrics">Deepest emotions are shallow to your love<br>
                                                        Highest of honours are so low to your love<br>
                                                        No wonder my bones waste away when I’m silent<br>
                                                        When all of the throne room are lost deep in worship</p>

        <p class="lyrics">Your love, full of passion, oh<br>
                                                                Your love, so abandoned, oh<br>
                                                                Your love, so inspiring, oh<br>
                                                                There’s no greater love<br>
                                                                Than your love</p>

        <p class="lyrics">Oh, the love of God<br>
                                                                The love of God</p>

        <p class="composer">Rebecca Aladiran, James Aladiran, Lloyd Gordon, Mark Beswick<br>
                                                                © 2017 Prayer Storm Music</p>

      </div>
    </div>
  </div>
</main>

</template>
<script>
import LiveNow from '@/components/LiveNow.vue'
export default {
  components: {
    LiveNow
  },
  setup () {
    return {
    }
  }
}
</script>

<style scoped>
.lyrics {
  @apply mt-8 font-medium text-gray-700
}
.composer {
  @apply mt-8 text-sm mb-10 text-gray-700
}
</style>
