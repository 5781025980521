<template>
<div class="flex flex-col justify-center py-12 sm:px-6 lg:px-8">
  <div class="sm:mx-auto sm:w-full sm:max-w-md">
    <img class="mx-auto h-12 w-auto" src="@/assets/logo-icon.svg" alt="Workflow">
    <h2 class="mt-6 text-center text-3xl font-bold text-gray-900">
      Reset your password
    </h2>
    <p class="mt-2 text-center text-sm text-gray-600 max-w">
      Or
      <router-link to="/sign-in" class="font-medium text-blue hover:text-blue-dark">
        sign in here
      </router-link>
    </p>
  </div>

  <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
    <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
      <ErrorMessage :formError="resetPasswordState.load.error" class="mt-6"></ErrorMessage>

      <div v-if="isLoggedIn">
        You are logged in. You canot reset a password.
      </div>
      <div v-else-if="resetPasswordState.load.executing">
        Loading...
      </div>
      <div v-else-if="loadSuccessful">
        <form class="space-y-6" id="reset-password-form" @submit.prevent="resetPassword">
          <div>
            <label for="password" class="block text-sm font-medium text-gray-700">
              New Password
            </label>
            <div class="mt-1">
              <input v-model="resetPasswordState.newPassword" id="newPassword" name="newPassword" type="password" autocomplete="off" required class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
            </div>
          </div>

          <div>
            <label for="newPasswordConfirmation" class="block text-sm font-medium text-gray-700">
              Confirm New Password
            </label>
            <div class="mt-1">
              <input v-model="resetPasswordState.newPasswordConfirmation" id="newPasswordConfirmation" name="newPasswordConfirmation" type="password" autocomplete="off" required class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
            </div>
          </div>

          <ErrorMessage :formError="resetPasswordState.reset.error" class="mt-6"></ErrorMessage>
          <SuccessMessage :successMessage="resetPasswordState.reset.successMessage" class="mt-6"></SuccessMessage>
          <div>
            <button type="submit" class="disabled:opacity-50 w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue hover:bg-blue-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
              Reset
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import { computed, defineComponent, onMounted } from 'vue'
import { mapGetters } from 'vuex'
import { useRoute } from 'vue-router'
import { useAuth } from '@/composables/useAuth'
import ErrorMessage from '@/components/ErrorMessage.vue'
import SuccessMessage from '@/components/SuccessMessage.vue'
// import axios from 'axios'
// import responseHelper from '../helpers/response.helper'
export default defineComponent({
  name: 'ResetPassword',
  components: {
    ErrorMessage,
    SuccessMessage
  },
  setup () {
    // set composables
    const route = useRoute()
    const { resetPasswordState, resetPasswordCheck, resetPasswordConfirm } = useAuth()
    resetPasswordState.key = route.params.key

    const resetPassword = () => {
      if (!resetPasswordState.reset.executing && !resetPasswordState.reset.complete) {
        resetPasswordConfirm({
          key: resetPasswordState.key,
          newPassword: resetPasswordState.newPassword,
          newPasswordConfirmation: resetPasswordState.newPasswordConfirmation
        })
      }
    }

    onMounted(() => {
      resetPasswordCheck({
        key: resetPasswordState.key
      })
    })

    const loadSuccessful = computed(() => {
      if (!resetPasswordState.load.executing && resetPasswordState.load.error.errors.length === 0) {
        return true
      }
      return false
    })
    return {
      loadSuccessful,
      resetPasswordState,
      resetPassword
    }
  },
  computed: {
    ...mapGetters([
      'isLoggedIn'
    ])
  }
})
</script>
