<template>
<main>
  <div class="bg-white">
    <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
      <div class="mx-auto max-w-4xl py-8">
        <p v-show="!formState.loadError && !formState.registration">Loading registration details, please wait...</p>
        <ErrorMessage :formError="formState.loadError" class="mt-6"></ErrorMessage>

        <div v-if="formState.registration && formState.registration.checkout">
          <div class="py-6">
            <div class="grid grid-cols-1 sm:grid-cols-2 gap-y-3 sm:gap-x-4">
              <div class="">
                <h3 class="text-md font-bold">
                  Booking Details
                </h3>
                <p class="mt-1 text-sm text-gray-500">The person making the order</p>
              </div>
              <div class="">
                <div class="mt-4 bg-white border border-gray-200 rounded-lg shadow-sm">
                  <dl class="py-6 px-4 sace-y-6p sm:px-6">
                    <div>
                      <p class="mr-2 mt-2 px-2 text-sm font-bold">{{formState.registration.checkout[0].firstName}} {{formState.registration.checkout[0].lastName}}</p>
                      <p class="mr-2 mt-2 px-2 text-sm ">{{formState.registration.checkout[0].email}}</p>
                      <p class="mr-2 mt-2 px-2 text-sm font-bold">Order Id / Ticket Id</p>
                      <p class="mr-2 mt-2 px-2 text-sm "># {{formState.registration.checkout[0].checkoutId}} / {{formState.registration.checkout[0].guid}}</p>
                      <div v-if="formState.registration.checkout[0].confirmedAt">
                        <p class="mr-2 mt-2 px-2 text-sm font-bold">Confirmed At</p>
                        <p class="mr-2 mt-2 px-2 text-sm ">{{$filters.dateLon(formState.registration.checkout[0].confirmedAt)}}</p>
                      </div>
                      <div v-if="formState.registration.checkout[0].cancelledAt">
                        <p class="mr-2 mt-2 px-2 text-sm font-bold text-red-500">Cancelled At</p>
                        <p class="mr-2 mt-2 px-2 text-sm ">{{$filters.dateLon(formState.registration.checkout[0].cancelledAt)}}</p>
                      </div>
                      <div v-if="formState.registration.checkout[0].isPartner">
                        <p class="mr-2 mt-2 px-2 text-sm font-bold text-green-500">SPECIFIED AS PARTNER</p>
                      </div>
                      <div v-if="formState.registration.checkout[0].country?.toLowerCase() !== 'gb'">
                        <p class="mr-2 mt-2 px-2 text-sm font-bold text-blue">** {{ formState.registration.checkout[0].country }} - INTERNATIONAL **</p>
                      </div>
                    </div>
                  </dl>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-if="formState.registration && formState.registration.attendanceOptions" class="py-6">
          <div class="grid grid-cols-1 sm:grid-cols-2 gap-y-3 sm:gap-x-4">
            <div class="">
              <h3 class="text-md font-bold">
                Session Attendance
              </h3>
              <p class="mt-1 text-sm text-gray-500">The sessions that have been selected on this order.</p>
            </div>
            <div class="">
              <div class="mt-4 bg-white border border-gray-200 rounded-lg shadow-sm">
                <dl class="py-6 px-4 sace-y-6p sm:px-6">
                  <div>
                    <div class="border-b border-gray-200" v-for="option in formState.registration.attendanceOptions" :key="option.id">
                      <dt class="text-sm py-4">
                        <QuestionMarkCircleIcon v-if="option.attending == null" class="h-5 w-5 text-red-500 inline-block mr-2"></QuestionMarkCircleIcon>
                        <CheckIcon v-else-if="option.attending == true" class="h-4 w-4 text-green-500 inline-block mr-2"></CheckIcon>
                        <XMarkIcon v-else class="h-4 w-4 text-red-500 inline-block mr-2"></XMarkIcon>
                        <!-- <input v-model="option.attending" type="checkbox" autocomplete="off" class="inline-block h-4 w-4 mr-2" /> -->
                        {{ option.title }} - {{ $filters.dateTimeMed(option.publishedAt) }}
                      </dt>
                    </div>
                  </div>
                </dl>
              </div>
            </div>
          </div>
        </div>

        <div v-if="formState.registration && formState.registration.delegates">
          <div class="py-6">
            <div class="grid grid-cols-1 sm:grid-cols-2 gap-y-3 sm:gap-x-4">
              <div class="">
                <h3 class="text-md font-bold">
                  Delegates
                </h3>
                <p class="mt-1 text-sm text-gray-500">
                  The delegates on this order
                  <span class="font-bold" v-if="formState.registration.checkout"># {{ formState.registration.checkout[0].checkoutId }}</span>
                </p>
                <div v-if="formState.registration.checkout[0].isPartner">
                  <p class="mr-2 mt-2 px-2 text-sm font-bold text-green-500">SPECIFIED AS PARTNER</p>
                </div>
              </div>
              <div class="">
                <div class="mt-4 bg-white border border-gray-200 rounded-lg shadow-sm">
                  <div class="py-6 px-4 space-y-6 border-b border-gray-200 sm:px-6">
                    <div v-for="item in formState.registration.delegates" :key="item.id" @click.prevent="changeRegistered(item)" class="bg-white hover:bg-gray-100 cursor-pointer">
                      <span :class="[item.cancelled ? 'line-through' : '', 'font-bold']">{{item.firstName}} {{item.lastName}} ({{item.ticketTitle}}) #{{item.id}}</span>
                      <span class="inline flex ml-5" v-if="item.registered"> <CheckCircleIcon class="flex-shrink-0 h-5 w-5 mr-2" aria-hidden="true" /> Registered</span>
                      <span class="inline flex ml-5" v-else> <XMarkIcon class="flex-shrink-0 h-5 w-5 mr-2" aria-hidden="true" /> Not Yet Registered</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-if="eventIdList > 0">
          <div class="py-6">
            <div class="grid grid-cols-1 sm:grid-cols-2 gap-y-3 sm:gap-x-4">
              <div class="">
                <a :href="'/registration-ticket/list/' + eventIdList">&lt;- Back to List</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</main>
</template>

<script>
import axios from 'axios'
import { defineComponent, onMounted, computed, reactive, ref } from 'vue'
import { useRoute } from 'vue-router'
import responseHelper from '@/helpers/response.helper'
// import SuccessMessage from '@/components/SuccessMessage.vue'
import ErrorMessage from '@/components/ErrorMessage.vue'
import { CheckCircleIcon, XMarkIcon, CheckIcon, QuestionMarkCircleIcon } from '@heroicons/vue/20/solid'

export default defineComponent({
  components: {
    // SuccessMessage,
    ErrorMessage,
    CheckCircleIcon,
    XMarkIcon,
    QuestionMarkCircleIcon,
    CheckIcon
  },
  setup () {
    const route = useRoute()
    const registrationGuid = ref(route.params.guid)

    const formState = reactive({
      registration: undefined,
      loadError: responseHelper.noError,
      formSubmitted: false,
      formError: responseHelper.noError,
      successMessage: ''
    })

    const changeRegistered = async (item) => {
      axios.post(
        'pub/registrations/register',
        {
          guid: registrationGuid.value,
          delegateId: item.id
        }
      ).then((response) => {
        if (response?.data?.success && response?.data?.result) {
          formState.registration = response.data.result
        } else {
          formState.loadError = responseHelper.unexpectedResult
        }
      }).catch((error) => {
        formState.loadError = responseHelper.getMessageV2(error)
      })
    }

    onMounted(() => {
      axios.post(
        'pub/registrations/verify',
        {
          guid: registrationGuid.value
        }
      ).then((response) => {
        if (response?.data?.success && response?.data?.result) {
          formState.registration = response.data.result
        } else {
          formState.loadError = responseHelper.unexpectedResult
        }
      }).catch((error) => {
        formState.loadError = responseHelper.getMessageV2(error)
      })
    })

    const eventIdList = computed(() => {
      var eventId = 0
      if (formState && formState.registration && formState.registration.delegates.length > 0) {
        for (var i = 0; i < formState.registration.delegates.length; i++) {
          eventId = formState.registration.delegates[i].eventId
        }
      }
      return eventId
    })

    return {
      formState,
      registrationGuid,
      changeRegistered,
      eventIdList
    }
  }
})
</script>
