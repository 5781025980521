<template>
<section aria-labelledby="avatarHeading">
  <div class="shadow sm:rounded-md sm:overflow-hidden">
    <div class="bg-white py-6 px-4 sm:p-6">
      <div>
        <h2 id="avatarHeading" class="text-lg leading-6 font-medium text-gray-900">Avatar</h2>
        <p class="mt-1 text-sm text-gray-500">Change your avatar image below.</p>
      </div>

      <div v-show="!imageSrc" class="relative rounded-full overflow-hidden w-64 h-64 mx-auto mt-6">
        <img v-if="getAvatar" class="relative rounded-full w-64 h-64" :src="getAvatar" alt="">
        <UserIcon v-else class="relative w-64 h-64" aria-hidden="true" />
      </div>

      <div v-show="imageSrc" class="w-64 h-64 object-fill mx-auto mt-6">
        <img class="block max-w-full" ref="img" :src="imageSrc" />
      </div>
      <div class="flex justify-center content-end mt-2">
        <button v-if="!imageSrc" class="disabled:opacity-50 bg-blue border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-blue-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue" @click="$refs.imageInput.click()" type="button">Select Image</button>
        <button v-else class="disabled:opacity-50 bg-blue border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-blue-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue" @click="handleUpload" type="button">Upload Image</button>
        <button v-if="imageSrc" class="ml-2 disabled:opacity-50 bg-gray-500 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500" @click="fileCleared">Clear</button>
        <input ref="imageInput" type="file" style="display: none;" accept=".jpg,.jpeg,.png" @change="fileChanged" />
      </div>
      <div class="my-2 align-baseline text-center">
        <span>Selected File:</span>
        <span v-if="selectedFile">{{ selectedFile.name }}</span>
      </div>
    </div>
  </div>
</section>
</template>

<script>
import { defineComponent, ref, watchEffect, onMounted, onUnmounted, watch } from 'vue'
import Cropper from 'cropperjs'
import 'cropperjs/dist/cropper.css'
import axios from 'axios'
import { mapGetters } from 'vuex'
import store from '@/store'
import { useNotification } from '@/composables/useNotification'
import {
  UserIcon
} from '@heroicons/vue/24/outline'

export default defineComponent({
  name: 'SettingsAvatar',
  components: {
    UserIcon
  },
  setup () {
    const { showNotification } = useNotification()
    const imageInput = ref(null)
    const selectedFile = ref(null)
    const imageSrc = ref(null)
    const img = ref(null)
    const fileReader = new FileReader()
    let cropper = null
    let mimeType = ''

    fileReader.onload = (event) => {
      imageSrc.value = event.target.result
    }

    const handleUpload = () => {
      cropper.getCroppedCanvas({
        width: 256,
        height: 256,
        imageSmoothingQuality: 'high'
      }).toBlob((blob) => {
        const formData = new FormData()
        formData.append('image', blob, 'avatar.jpg')
        axios
          .post('/upload/avatar/0', formData)
          .then((response) => {
            if (response?.data?.success && response?.data?.result) {
              store.commit('setAvatar', response.data.result.imageUrl)
              showNotification('Image saved!', 'Your avatar should now be available.', 3600)
            }
            fileCleared(null)
            // router.push('/settings/profile')
          })
          .catch(function (error) {
            console.log(error)
          })
      }, mimeType)
    }

    const fileChanged = (e) => {
      const files = e.target.files
      if (files.length) {
        selectedFile.value = files[0]
        mimeType = selectedFile.value.type
      }
    }

    const fileCleared = (_) => {
      selectedFile.value = null
    }

    onMounted(() => {
      cropper = new Cropper(img.value, {
        aspectRatio: 1,
        minCropBoxWidth: 256,
        minCropBoxHeight: 256,
        viewMode: 3,
        dragMode: 'move',
        background: false,
        cropBoxMovable: false,
        cropBoxResizable: false
      })
    })

    onUnmounted(() => {
      cropper.destroy()
    })

    watchEffect(() => {
      if (selectedFile.value) {
        fileReader.readAsDataURL(selectedFile.value)
      } else {
        imageSrc.value = null
      }
    })

    watch(
      imageSrc,
      () => {
        if (imageSrc.value) {
          cropper.replace(imageSrc.value)
        }
      },
      {
        flush: 'post'
      })

    return {
      imageInput,
      selectedFile,
      fileChanged,
      fileCleared,
      imageSrc,
      img,
      handleUpload
    }
  },
  computed: {
    ...mapGetters([
      'getAvatar'
    ])
  }
})
</script>
