<template>
<main>
  <LiveNow></LiveNow>
  <div class="bg-black bg-cover bg-no-repeat" style="background-image: url('https://source.unsplash.com/AC6qkD4Jv9w');">
    <div style="background-image:linear-gradient(to left bottom, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2), rgba(42, 131, 161, 0.4), rgba(42, 131, 161, .6))">
        <div class="max-w-7xl mx-auto py-24 px-4 sm:px-6 lg:px-8">
        <div class="sm:flex sm:flex-col align-center">
            <h1 class="text-3xl font-extrabold text-white text-center">Freedom Prayer</h1>
        </div>
        </div>
    </div>
    <div class="max-w-2xl mx-auto py-6 px-4 md:py-12 sm:px-6 lg:max-w-7xl lg:px-8">
      <div class="lg:grid lg:grid-cols-2 lg:gap-x-12 lg:items-start">
        <div class="mt-6 px-4 sm:px-0 lg:mt-0">
          <div class="video-container">
            <iframe title="Prayer For Anxiety, Fear, Depression &amp; Confusion | James Aladiran" src="https://www.youtube.com/embed/kH42CVK3SGs?feature=oembed" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen="" style="opacity: 1; visibility: visible;"></iframe>
          </div>
        </div>

        <div class="mt-6 px-4 sm:px-0 lg:mt-0 text-white">
          <h2 class="text-xl font-bold">This is a prayer recorded for people struggling with anxiety, depression and fear. I encourage you to just pray along.</h2>
          <hr class="my-6" />
          <div class="font-bold">
            <Disclosure v-slot="{ open }">
              <DisclosureButton class="py-2">
                <span>View the script to pray along</span>
                <ChevronRightIcon :class="open ? 'transform rotate-90 w-5 h-5 inline' : 'w-5 h-5 inline'" />
              </DisclosureButton>
              <DisclosurePanel class="mt-6">
                <p>THIS IS A PRAYER RECORDED FOR PEOPLE STRUGGLING WITH ANXIETY AND DEPRESSION, FEAR AND I WILL ENCOURAGE YOU TO JUST PRAY ALONG. I’M JUST GOING TO PRAY AS THOUGH I’M STRUGGLING AND HOW I WOULD PROBABLY PRAY IN A SITUATION LIKE THIS. I JUST WANT TO ENCOURAGE YOU TO REPEAT THE WORDS AND JUST PERSONALISE THEM AND JUST PRAY THEM IN FAITH. DON’T WAIT FOR YOUR EMOTIONS TO LINE UP. YOU PRAY THEM IN FAITH. WE WALK BY FAITH AND NOT BY SIGHT [2 CORINTHIANS 5:7].</p><br>
                <p>FATHER, I THANK YOU FOR YOUR WORD IN HEBREWS 4:16 THAT SAYS I CAN COME BOLDLY BEFORE YOUR THRONE OF GRACE AND ASK FOR MERCY IN MY TIME OF NEED AND RIGHT NOW FATHER I NEED YOUR MERCY. I NEED YOU MORE THAN EVER. YOU SAID I SHOULD CAST ALL MY CARES UPON YOU BECAUSE YOU CARE FOR ME [1 PETER 5:7]. SO LORD I THANK YOU THAT YOU LOVE ME. I THANK YOU THAT YOU CARE FOR ME RIGHT NOW GOD, SO I CAST ALL MY CARES UPON YOU RIGHT NOW.</p><br>
                <p>FATHER, I BREAK MY AGREEMENT WITH THE SPIRIT OF FEAR, ANXIETY, CONFUSION AND DEPRESSION. I KNOW LORD YOUR WORD SAYS YOU ARE NOT THE AUTHOR OF CONFUSION [1 CORINTHIANS 14:33], SO LORD RIGHT NOW I SUBMIT MY EMOTIONS, I SUBMIT MY MIND. I SUBMIT EVERY ASPECT OF MY BODY TO YOU.</p><br>
                <p>FATHER, I THANK YOU THAT YOU HAVE MADE AVAILABLE FOR ME THE MIND OF CHRIST [1 CORINTHIANS 2:16] AND I RECEIVE THE MIND OF CHRIST RIGHT NOW AND I THANK YOU THAT THERE IS NO CONFUSION, DEPRESSION, ANXIETY IN THE MIND OF CHRIST. THEREFORE, I TAP INTO THAT REALITY RIGHT NOW, THAT MY MIND COMES INTO ALIGNMENT WITH THE MIND OF CHRIST.</p><br>
                <p>LORD, I TAKE CAPTIVE EVERY THOUGHT, EVERY IMAGINATION, EVERY FIERY DART THAT HAS BEEN RELEASED AGAINST ME FROM THE KINGDOM OF DARKNESS THAT HAS CAUSED MY EMOTIONS TO BE DOWNCAST. RIGHT NOW, IN JESUS NAME LORD, I SAY LET THERE BE THE REMOVAL OF EVERY ARROW THAT HAS BEEN STUCK IN ANY AREA OF MY HEART, MY SOULISH REALM OR MY EMOTIONS THAT’S CAUSING BLEEDING OR WOUNDING, RIGHT NOW IN JESUS NAME, LET THERE BE THE HEALING OF YOUR [MY] SPIRIT IN ANY AREA OF WOUNDEDNESS AND BROKENNESS.</p><br>
                <p>FATHER, I FORGIVE AND I LET GO OF ANYONE THAT HAS HURT ME EMOTIONALLY. ANYONE THAT HAS DONE ANYTHING IN ANY AREA OF MY LIFE THAT HAS BROUGHT LIMITATION, THAT’S CAUSING ME CONFUSION RIGHT NOW OR THAT’S CAUSING ME HEARTACHE. LORD, I SUBMIT THAT TO YOU RIGHT NOW. I SUBMIT MY RELATIONSHIPS TO YOU RIGHT NOW. I SUBMIT EVERY AREA OF RELATIONSHIPS THAT SURROUND MY LIFE RIGHT NOW TO YOU.</p><br>
                <p>I SAY FATHER, BE EXALTED. I DECLARE THERE WILL BE NO AREA WHERE I GIVE THE ENEMY ROOM FATHER, BY HARBOURING UNFORGIVENESS OR OFFENSE. LORD, I LET GO OF ANYONE THAT’S OFFENDED ME RIGHT NOW BECAUSE YOU HAVE FORGIVEN ME. THEREFORE, I FORGIVE ALL OTHERS RIGHT NOW AND GOD BY FORGIVING EVERYONE I LET GO. I CHOOSE NOT TO HOLD ON FATHER. I RECEIVE YOUR BLOOD. I RECEIVE YOUR PRESENCE TO BRING ABOUT HEALING IN ANY AREA OF MY HEART AND EMOTIONS WHERE THERE HAS BEEN WOUNDS THAT THE ENEMY HAS BEEN TAKING ADVANTAGE OF RIGHT NOW. LORD VULNERABLE AREAS OF MY HEART AND EMOTIONS LET THEM RIGHT NOW BE COVERED BY YOUR BLOOD, COVERED BY THE BLOOD OF JESUS.</p><br>
                <p>I DECLARE ACCESS DENIED TO ANY WORK OF DARKNESS THAT WILL SEEK TO MANIPULATE AREAS OF WOUND AND AREAS OF EMOTIONS FROM MY CHILDHOOD UP TO ANY AREA OF MY LIFE RECENTLY WHERE THE ENEMY WILL TRY TO MANIPULATE THOSE SITUATIONS TO BRING ABOUT BONDAGE IN MY MENTAL HEALTH, BONDAGE IN MY EMOTIONS, BONDAGE IN MY DREAMS, RIGHT NOW IN JESUS NAME I DECLARE ACCESS DENIED TO THE</p><br>
                <p>WORKS OF DARKNESS.</p><br>
                <p>AS I RIGHT NOW BEGIN TO PLEAD THE BLOOD OF JESUS OVER MY MIND, THE BLOOD OF JESUS OVER MY EMOTIONS, LORD, WHERE THERE HAS BEEN FIERY DARTS AND CONFUSION AND THE ENEMY HAS BEEN RELEASING THESE THOUGHTS, I TAKE THOSE THOUGHTS CAPTIVE RIGHT NOW IN JESUS NAME. I QUENCH THE FIERY DARTS OF THE ENEMY AND I DECLARE EVERY THOUGHT CAPTIVE. I PULL DOWN EVERY STRONGHOLD IN MY MIND. WHATEVER THE SOURCE HAS BEEN – BE IT GENERATIONAL. BE IT PSYCHOLOGICAL. BE IT EMOTIONAL. BE IT RELATIONAL. WHATEVER THE SOURCE OF STRONGHOLDS GOING ON IN MY BODY, IN MY MIND, IN MY EMOTIONS HAVE BEEN, RIGHT NOW FATHER, I PULL DOWN THOSE NEGATIVE STRONGHOLDS.</p><br>
                <p>FATHER AND I DECLARE THAT THE STRONGHOLD OF THE WORD OF GOD BEGINS TO BE ERECTED RIGHT NOW. WHERE THE ENEMY HAS COME IN LIKE A FLOOD INTO MY MIND, INTO MY EMOTIONS, INTO MY LIFE AND OVERWHELMED ME RIGHT NOW FATHER, I DECLARE THE STANDARD OF GOD IS BEING LIFTED RIGHT NOW. THE STANDARD OF GOD IS RAISED UP IN EVERY AREA OF MY LIFE AND I BLOCK THE ENEMY OUT. COMPLETE BLOCKAGE. NO ACCESS, IN THE NAME OF JESUS.</p><br>
                <p>LORD, I THANK YOU THAT YOU’VE GIVEN ME THE AUTHORITY. I THANK YOU THAT THROUGH YOU I CAN TREAD ON SNAKES AND SCORPIONS AND ALL THE POWERS OF THE ENEMY. SO RIGHT NOW LORD AS I HAVE SUBMITTED MYSELF TO YOU I RESIST THE ENEMY, I RESIST THE DARKNESS, THE SPIRIT OF DEPRESSION, THE SPIRIT OF ANXIETY, SPIRIT OF CONFUSION.</p><br>
                <p>IN THE NAME OF JESUS I BREAK YOUR HOLD OVER MY MIND. I BREAK YOUR HOLD OVER MY EMOTIONS. I BREAK YOU HOLD OVER MY RELATIONSHIPS. I COME AGAINST ANY WORK YOU HAVE DESIGNED AGAINST ME TO RESIST MY PROGRESS. RIGHT NOW I AM DECLARING AGAINST YOU NO WEAPON THAT YOU FORM AGAINST ME, AGAINST THE PURPOSES OF GOD IN LIFE WILL PROSPER AND WHAT EVER TONGUE THAT IS ARISING AGAINST ME IS CONDEMNED NOW IN JUDGEMENT, EVERY ASSOCIATION OF THE ENEMY AGAINST THE PURPOSES OF GOD IN MY LIFE TO RESTRICT MY PROGRESS RIGHT NOW I DECLARE YOU ARE DESTROYED BY THE FIRE OF GOD I DECLARE THE WORKS OF DARKNESS IN MY LIFE ARE UPROOTED AND COMPLETELY ERADICATED.</p><br>
                <p>FATHER, I THANK YOU FOR THE BLOOD OF JESUS RIGHT NOW BRINGING CLEANSING OVER MY EMOTIONS. FATHER, I PRAY MY EMOTIONS COME INTO ALIGNMENT WITH THE ORDER OF HEAVEN MY EMOTIONS COME INTO ALIGNMENT WITH THE EMOTIONS OF HEAVEN. LORD, I RECEIVE YOUR PEACE RIGHT NOW. WHERE THE ENEMY HAS STOLEN MY PEACE, WHERE THE ENEMY HAS STOLEN MY JOY, WHERE THE ENEMY HAS STOLEN MY SANITY, IN THE NAME OF JESUS I TAKE MY PEACE BACK, I TAKE MY JOY BACK, I TAKE MY SANITY BACK.</p><br>
                <p>IN THE NAME OF JESUS I SPEAK OVER THE ATMOSPHERE OF MY HOUSE, RIGHT NOW THAT HEAVEN BEGINS TO REIGN HERE. FATHER, WHERE DARKNESS HAS BEEN REIGNING AND RULING IN MY HOUSE WHERE FEAR AND OPPRESSION HAS BEEN RULING AND REIGNING. I DECLARE THE ATMOSPHERE OF THIS HOUSE BE RECALIBRATED NOW TO THE ATMOSPHERE OF HEAVEN. EVERY BIT OF LUKEWARMNESS, EVERY BIT OF DARKNESS, DEPRESSION AND OPPRESSION BE REPLACED BY FIRE RIGHT NOW IN JESUS NAME. FATHER, I THANK YOU FOR THE FIRE OF GOD INVADING THE ATMOSPHERE OF MY <a href="https://prayerstorm.org/">HOME</a> NOW IN JESUS NAME.</p><br>
                <p>LORD, AS I LIE IN MY BED I SLEEP IN PEACE. I THANK YOU THAT LORD, EVEN AS I GO TO SLEEP MY DREAM WORLD IS SUBMITTED TO YOU, THAT I BEGIN TO RECEIVE REVELATIONS FROM THE THRONE OF HEAVEN. I DECLARE THAT THERE WILL NOT BE ANY DEMONIC HIJACKING OF MY DREAM WORLD. AS I GO TO SLEEP LIKE JACOB WENT TO SLEEP AND THE HEAVENS WERE OPENED AND HE SAW ANGELS ASCENDING AND DESCENDING LORD, I DECLARE OVER MY HOUSE OVER MY ROOM THAT ANGELS ASCEND AND DESCEND THAT THIS BECOMES THE HOUSE OF GOD THE GATEWAY TO HEAVEN.</p><br>
                <p>THAT FATHER, YOU BEGIN TO HAVE FREE ACCESS IN MY HOUSE. LORD, IF THERE IS ANYTHING IN MY HOUSE OR IN MY LIFE THAT HAS MADE THE ENEMY HAVE EASY ACCESS INTO MY LIFE IN AN EASY WAY ANY OBJECTS, ANY VIDEOS, ANY GAMES, ANYTHING THAT I HAVE BOUGHT THAT BELONGS TO THE KINGDOM OF DARKNESS. FATHER I ASK THAT YOU BEGIN TO EXPOSE IT TO ME, BEGIN TO EXPOSE IT IN MY HOUSE. LORD I WILL TAKE THE NECESSARY STEPS TO GET RID OF IT FROM MY HOUSE FATHER THAT THIS HOUSE IS CLEANSED OF ANY EVIL OBJECTS IN THE NAME OF JESUS.</p><br>
                <p>FATHER, I THANK YOU FOR BRINGING CLARITY TO MY MIND RIGHT NOW JUST LIKE THE DISCIPLES WERE IN THE STORM AND YOU WERE ASLEEP IN THAT BOAT LORD, I THANK YOU THAT IN THE MIDDLE OF THIS STORM THAT I HAVE BEEN THROUGH YOUR PEACE BEGINS TO REST ON MY MIND. RIGHT NOW THE PEACE THAT SURPASSES ALL UNDERSTANDING BEGINS TO REST OVER ME RIGHT NOW IN THE NAME OF JESUS, LORD I AM YOURS MY MIND IS YOURS, MY LIFE IS YOURS. I DECARE THAT YOU ARE NOT JUST MY SAVIOUR, YOU ARE THE LORD OF MY LIFE. MY BODY IS THE TEMPLE OF THE HOLY SPIRIT THEREFORE, FEAR CANNOT RULE HERE. ANXIETY CANNOT RULE HERE. DEPRESSION CANNOT RULE HERE BECAUSE YOU HAVE NOT GIVEN ME THE SPIRIT OF FEAR OR DEPRESSION. YOU’VE GIVEN ME THE SPIRIT OF POWER, LOVE AND A SOUND MIND.</p><br>
                <p>SO LORD I RECEIVE YOUR SPIRIT AND I REJECT ANY OTHER SPIRIT. I BREAK THOSE AGREEMENTS NOW IN THE NAME OF JESUS. LORD YOU ARE MY FORTRESS. YOU ARE MY ROCK. YOU ARE MY STRONG TOWER. AND LORD I COME TO YOU WITH BOLDNESS AND CONFIDENCE RIGHT NOW BECAUSE I KNOW THAT YOU’RE THE ONE THAT SURROUNDS ME BY YOUR FIRE. YOU ARE THE WALL OF FIRE THAT SURROUNDS ME. YOU ARE THE GLORY IN MY MIDST. SO I SUBMIT MYSELF TO YOU. REGIN IN MY <a href="https://prayerstorm.org/">HOME</a>. REIGN IN MY FINANCES. REIGN IN MY RELATIONSHIPS. REIGN IN MY FAMILY. REIGN IN EVERY AREA OF BROKENNESS IN MY LIFE LORD.</p><br>
                <p>I THANK YOU FOR THE COVERING OF YOUR BLOOD RIGHT NOW, THE COVERING OF YOUR BLOOD. AND LORD, I THANK YOU THAT THE WORKS OF DARKNESS ARE DESTROYED, DESTROYED, DESTROYED. ANY RESIDE OF DARKNESS THAT WOULD SEEK TO HIDE IN ANY AREA OF MY EMOTIONS, IN MY SOULISH REALM, I UNCOVER THAT RIGHT NOW. I UPROOT THAT RIGHT NOW AND I DECLARE LORD THAT THERE WILL BE A DETOX, A COMPLETE CLEANSING TO THE ROOT, TO THE CORE, TO MY VERY CORE. A COMPLETE CLEANSING OF ANY RESIDUE, ANY SEED OF DARKNESS.</p><br>
                <p>LORD, WHERE THERE MIGHT BE GENERATIONAL CONNECTION TO NEGATIVE THINGS IN MY BLOODLINE RIGHT NOW FATHER I THANK YOU THAT MY BLOODLINE IS RECONNECTED TO YOUR BLOODLINE JESUS, THAT THERE IS A HOLY SPIRIT BLOOD TRANSFUSION. LET IT TAKE PLACE RIGHT NOW. THAT I BEGIN TO BE RECONNECTED TO YOUR BLOODLINE FATHER AND THAT THERE WILL BECOME A CLEANSING OF ANY NEGATIVE RESIDUE IN MY BLOODLINE. THIS BLOODLINE IS YOURS.</p><br>
                <p>AS FOR ME AND MY HOUSE WE WILL SERVE THE LORD. WHATEVER HAS BEEN GOING ON BEFORE I GOT HERE, IN MY BLOODLINE THAT’S NEGATIVE, LORD I DECLARE AN END TO IT RIGHT NOW. IT STOPS RIGHT HERE AND RIGHT NOW. IT WILL NOT TRANSFER TO THE NEXT GENERATION. I SAY LET THERE BE AN END TO ANY NEGATIVE CYCLE IN THIS BLOODLINE FATHER. WHETHER IT’S CHEMICAL IMBALANCES OR SPIRITUAL ISSUES GOING ON FATHER WHERE THE ENEMY HAS BEEN TARGETING ME IN ANY SPECIFIC WAY, LORD I DECLARE AN END TO IT NOW IN THE NAME OF JESUS.</p><br>
                <p>AS I COME INTO ALIGNMENT WITH THE ORDER OF HEAVEN, FATHER I RECEIVE YOUR PEACE. THANK YOU FOR YOUR PEACE. FATHER, I RECEIVE YOUR JOY. THANK YOU FOR YOUR JOY. FATHER, I RECEIVE YOUR SANITY IN MY MIND. I RECEIVE YOUR ORDER IN MY MIND. I GIVE YOU PRAISE GOD. I GIVE YOU PRAISE LORD AND I DECLARE EVERY CURSE IS REVERSED.</p><br>
                <p>NOW LORD, I WALK IN THE BLESSING BECAUSE I AM YOUR SON/DAUGHTER I AM DECLARING THAT THE BLESSING OF THE LORD IS MY REALITY RIGHT NOW. THE BLESSING OF THE LORD IS MY REALITY. I WALK IN THE BLESSING. I AM BLESSED IN MY GOING IN. I AM BLESSED IN MY GOING OUT. EVERYTHING I PUT MY HAND TO IS BLESSED.</p><br>
                <p>FATHER, YOUR PRESENCE GOES BEFORE ME. YOUR PRESENCE RESTS UPON ME. FATHER YOU LEAD ME BECAUSE I AM YOUR SON/DAUGHTER I HEAR YOUR VOICE AND I GO WHERE YOU GO. WHERE YOU GO I GO. I DO NOT FOLLOW THE VOICE OF A STRANGER. SO I SILENCE ALL THOSE VOICES OF DOUBT, ACCUSATION, CONDEMNATION THAT WOULD SEEK TO SHUT ME DOWN, CRITICISMS, THE VOICE OF THE ENEMY. I SILENCE THEM RIGHT NOW IN THE NAME OF JESUS.</p><br>
                <p>LORD, RIGHT NOW WHERE THE ENEMY IS CAMOUFLAGING HIS ACTIVITIES IN MY LIFE THROUGH RELATIONSHIPS, FAKE FRIENDS AND PEOPLE WHO ARE ACTUALLY AGENTS OF THE ENEMY, LORD I SAY, LET THERE BE LIGHT, AN EXPOSURE TO THE WORKS OF DARKNESS. RIGHT NOW, I EXPOSE THE WORKS OF THE ENEMY. I DECLARE THAT HE WILL HAVE NO ROOM IN MY LIFE RIGHT NOW BECAUSE OF THE LIGHT I CHOOSE TO WALK IN. I AM A CHILD OF THE LIGHT. THEREFORE I HAVE NO FELLOWSHIP WITH DARKNESS. I EXPOSE THE WORKS OF DARKNESS IN MY LIFE.</p><br>
                <p>I THANK YOU LORD FOR COVERING ME RIGHT NOW. YES, I WOULD ABIDE UNDER THE SHADOW OF THE ALMIGHTY BECAUSE I AM MAKING YOUR SECRET PLACE MY DWELLING PLACE. THANK YOU FATHER I SEEK YOU. THANK YOU FATHER I LOVE YOU. THANK YOU FATHER I CHOOSE YOUR PRESENCE ABOVE ALL OTHERS LORD.</p><br>
                <p>LORD, I THANK YOU FOR RENEWING MY HUNGER FOR YOU. RENEWING AN APPETITE TO SEEK YOUR FACE LORD. I FIX MY GAZE ON YOU. I THANK YOU FOR RENEWING MY VISION RIGHT NOW. THANK YOU FOR RENEWING MY HEART RIGHT NOW FATHER. THANK YOU FOR GIVING ME A NEW HEART THAT’S SENSITIVE TO YOUR TOUCH RIGHT NOW FATHER. THANK YOU LORD THAT IT’S NOT BY MY POWER. IT’S NOT BY MY MIGHT. IT’S BY YOUR SPIRIT. YES HOLY SPIRIT, THANK YOU. THANK YOU FOR EMPOWERING ME RIGHT NOW. THANK YOU FOR CLOTHING ME WITH YOUR PRESENCE RIGHT NOW IN THE NAME OF JESUS. AMEN. THANK YOU GOD. THANK YOU JESUS.</p>
              </DisclosurePanel>
            </Disclosure>
          </div>
        </div>
      </div>
    </div>
    <div style="background-image:linear-gradient(to left bottom, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2), rgba(42, 131, 161, 0.4), rgba(42, 131, 161, .6))" class="text-white py-6 text-xl font-bold">
      <marquee-text :repeat="10">
        'whom the son sets free, is free indeed' - John 8:36
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </marquee-text>
    </div>
  </div>
</main>

</template>
<script>
import {
  ChevronRightIcon
} from '@heroicons/vue/20/solid'
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel
} from '@headlessui/vue'
import MarqueeText from 'vue-marquee-text-component'
import LiveNow from '@/components/LiveNow.vue'
export default {
  components: {
    ChevronRightIcon,
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    MarqueeText,
    LiveNow
  },
  setup () {
    return {
    }
  }
}
</script>

<style scoped>
.video-container {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    height: 0;
}
.video-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
}
</style>
