<template>
  <div class="bg-white pb-8">
    <LiveNow></LiveNow>
    <HeroBar v-if="contentDetailState.content.item" :bgSrc="getContentImageUrl(contentDetailState.content.item)">
      <div class="relative max-w-7xl mx-auto md:p-6">
        <div class="max-w-3xl mx-auto">
          <div v-if="contentDetailState.content.liveSession">
            <a @click.prevent="navigateToItem(contentDetailState.content.liveSession)" class="relative card-zoom cursor-pointer md:rounded-xl">
              <div class="card-zoom-image" :style="{ 'background-image' : 'url(' + getContentImageUrl(contentDetailState.content.item) + ')' }"></div>
              <div class="card-zoom-text flex">
                <h1 class="m-auto">
                  <button class="bg-red-500 hover:bg-red-600 py-2 px-3 rounded-md text-sm text-white font-bold">
                    <PlayIcon class="w-4 h-4 text-white m-auto inline" />
                    {{$filters.contentStatus(contentDetailState.content.liveSession.status)}}
                  </button>
                </h1>
              </div>
            </a>
          </div>
          <div v-else>
            <img :src="getContentImageUrl(contentDetailState.content.item)" alt="" class="md:rounded-xl ">
          </div>
        </div>
      </div>
    </HeroBar>
    <div class="text-center py-4 bg-blue">
      <button v-if="$filters.arrayItemsExist(subscriptionState.inPersonETickets)" @click.prevent="navigateToTicketOptions()" class="cursor-pointer inline-block bg-white rounded-lg px-4 py-2 text-lg font-bold text-blue text-center hover:bg-blue-dark hover:text-white justify-content-center">
        Register Now
      </button>
      <!-- <div class="text-sm">
        <span>Attend In-Person access is available via:</span>
      </div>
      <div class="sm:flex space-x-2 justify-center flex-nowrap">
        <div v-if="$filters.arrayItemsExist(subscriptionState.inPersonETickets)" class="mt-2">
          <div v-for="eTicket in subscriptionState.inPersonETickets" :key="eTicket.id">
            <a v-if="attendTicketStatus(eTicket) === 'ok' || attendTicketStatus(eTicket) === 'waitinglist'" :href="attendPath(eTicket)" class="inline-block bg-blue border-2 border-blue rounded-xl pl-2 text-sm font-semibold text-white text-center hover:bg-blue-dark justify-content-center">
              {{eTicket.title}} £{{Number.parseFloat(eTicket.price).toFixed(2)}} <ArrowRightCircleIcon class="inline h-5 w-5 ml-1" aria-hidden="true" />
            </a>
            <a v-else-if="attendTicketStatus(eTicket) === 'paused'" @click.prevent="goToElementById('faqs-top')" class="inline-block bg-blue border-2 border-blue rounded-xl px-2 text-sm font-semibold text-white text-center hover:bg-blue-dark justify-content-center">
              {{eTicket.title}} ** PAUSED **
            </a>
            <a v-else-if="attendTicketStatus(eTicket) === 'full'" @click.prevent="goToElementById('faqs-top')" class="inline-block bg-blue border-2 border-blue rounded-xl px-2 text-sm font-semibold text-white text-center hover:bg-blue-dark justify-content-center">
              {{eTicket.title}} ** EVENT FULL **
            </a>
          </div>
        </div>
      </div> -->
    </div>

    <div v-if="contentDetailState.content.item" id="details-top" class="mx-auto max-w-7xl mt-12 px-6">
      <div class="mx-auto lg:mx-0 lg:max-w-5xl">
        <h2 class="font-display text-4xl font-bold tracking-tighter text-blue sm:text-5xl">{{
          contentDetailState.content.item.title }}
        </h2>
        <div>
          <p class="mt-4 font-display text-xl tracking-tight text-gray-500 sm:text-2xl">
            {{ contentDetailState.content.item.description }}
          </p>
          <!-- <p class="mt-2 font-display text-lg tracking-tight text-gray-500">
            Only the evening sessions will be streamed online - so come and join us!
          </p> -->
        </div>
      </div>
      <div class="mt-4">
        <button v-if="contentDetailState.content.showFAQs" class="text-xl font-semibold tracking-tight text-blue-dark" @click="goToElementById('faqs-top')">
          View FAQs
        </button>
      </div>
      <dl class="mt-4 sm:grid sm:grid-flow-col gap-x-10 gap-y-6 lg:auto-cols-auto lg:grid-flow-col lg:grid-cols-none lg:justify-start lg:text-left">
        <div v-if="contentDetailState.content.earliestSessionDate">
          <dt class="mt-1 font-mono text-sm text-blue">When</dt>
          <dd class="mt-0.5 text-xl sm:text-2xl font-semibold tracking-tight text-blue-dark">
            <span>{{ contentDetailState.content.earliestSessionDate.toFormat('d.MM.yy') }}</span>
            <span
              v-if="contentDetailState.content.latestSessionDate.startOf('day') > contentDetailState.content.earliestSessionDate.startOf('day')">
              - {{ contentDetailState.content.latestSessionDate.toFormat('d.MM.yy') }}</span>
          </dd>
        </div>
        <div v-if="contentDetailState.content.item.address">
          <dt class="mt-1 font-mono text-sm text-blue">Venue</dt>
          <dd v-html="$filters.contentAddress(contentDetailState.content.item.address)" class="mt-0.5 text-xl sm:text-2xl  font-semibold tracking-tight text-blue-dark"></dd>
        </div>
        <!-- <div>
          <dt class="font-mono text-sm text-blue">&nbsp;</dt>
          <dd class="mt-0.5 text-xl sm:text-2xl  font-semibold tracking-tight text-blue-dark">FAQs</dd>
        </div> -->
      </dl>
    </div>

    <div class="max-w-7xl mx-auto mt-12 px-6">
      <!-- Notify section -->
      <div v-if="contentDetailState.content.item && contentDetailState.content.item.notify && contentDetailState.content.item.notifyUrl">
        <div class="mx-auto mt-10 max-w-7xl sm:px-6 lg:px-8">
          <div
            class="relative isolate overflow-hidden bg-blue-dark px-6 py-24 shadow-2xl sm:rounded-3xl sm:px-24 xl:py-32">
            <h2 class="mx-auto max-w-2xl text-center text-3xl font-bold tracking-tight text-white sm:text-4xl">Be
              notified when registration opens.</h2>
            <p class="mx-auto mt-2 max-w-xl text-center text-lg leading-8 text-gray-300">Registration is not yet open,
              but we can let you know when it is!</p>
            <div class="mx-auto mt-10 flex justify-center max-w-md gap-x-4">
              <a :href="contentDetailState.content.item.notifyUrl"
                class="rounded-md bg-white px-3.5 py-2.5 text-sm font-semibold text-gray-900 shadow-sm hover:bg-blue hover:text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white">Notify
                me</a>
            </div>
            <!-- <svg viewBox="0 0 1024 1024" class="absolute left-1/2 top-1/2 -z-10 h-[64rem] w-[64rem] -translate-x-1/2" aria-hidden="true">
            <circle cx="512" cy="512" r="512" fill="url(#759c1415-0410-454c-8f7c-9a820de03641)" fill-opacity="0.7" />
            <defs>
              <radialGradient id="759c1415-0410-454c-8f7c-9a820de03641" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(512 512) rotate(90) scale(512)">
                <stop stop-color="#7775D6" />
                <stop offset="1" stop-color="#E935C1" stop-opacity="0" />
              </radialGradient>
            </defs>
          </svg> -->
          </div>
        </div>
      </div>
      <div v-else>
        <GroupSchedule></GroupSchedule>
        <!-- <ContentFAQsKids v-if="contentDetailState.content.item && contentDetailState.content.item.slug === 'kids-prayer-siege-saturday'"></ContentFAQsKids> -->
        <!-- <ContentFAQsLeaderConf v-if="contentDetailState.content.item && contentDetailState.content.item.slug === 'church-and-ministry-leaders-conference'"></ContentFAQsLeaderConf> -->
        <div id="faqs-top" class="mt-12">
          <ContentInfo v-if="contentDetailState.content.item && contentDetailState.content.item.slug === 'nazarite-school'"></ContentInfo>
          <ContentFAQsKidsConf v-if="contentDetailState.content.item && contentDetailState.content.item.slug === 'prayer-storm-kids-conference'"></ContentFAQsKidsConf>
          <ContentFAQsConf v-if="contentDetailState.content.item && contentDetailState.content.item.slug === 'summer-conference'"></ContentFAQsConf>
          <ContentFAQsNaz v-if="contentDetailState.content.item && contentDetailState.content.item.slug === 'nazarite-school'"></ContentFAQsNaz>
          <ContentTestimonials v-if="contentDetailState.content.item && contentDetailState.content.item.slug === 'nazarite-school'"></ContentTestimonials>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted, onUnmounted, ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useContent } from '@/composables/useContent'
import ContentInfo from '@/components/ContentInfo.vue'
import GroupSchedule from '@/components/GroupSchedule.vue'
import ContentFAQsKidsConf from '@/components/ContentFAQsKidsConf.vue'
import ContentFAQsConf from '@/components/ContentFAQsConf.vue'
// import ContentFAQsKids from '@/components/ContentFAQsKids.vue'
// import ContentFAQsLeaderConf from '@/components/ContentFAQsLeaderConf.vue'
import ContentFAQsNaz from '@/components/ContentFAQsNaz.vue'
import HeroBar from '@/components/HeroBar.vue'
import LiveNow from '@/components/LiveNow.vue'
import store from '../store'
import { PlayIcon } from '@heroicons/vue/20/solid'
import ContentTestimonials from '../components/ContentTestimonials.vue'
import { useMemberships } from '@/composables/useMemberships'
import { useETickets } from '@/composables/useETickets'
import { DateTime } from 'luxon'

export default defineComponent({
  name: 'ContentGroup',
  components: {
    ContentInfo,
    GroupSchedule,
    ContentFAQsKidsConf,
    ContentFAQsConf,
    // ContentFAQsKids,
    // ContentFAQsLeaderConf,
    ContentFAQsNaz,
    HeroBar,
    LiveNow,
    PlayIcon,
    // ArrowDownCircleIcon,
    ContentTestimonials
  },
  setup () {
    const x = Math.floor(Math.random() * 3) + 2
    const imgSrc = '/img/banners/ps-banner-' + x + '.jpg'

    const router = useRouter()
    const route = useRoute()
    const contentGuid = ref(route.params.guid)
    if (!route.params.type) {
      if (route.path.indexOf('conference') >= 0) {
        route.params.type = 'conference'
      }
      if (route.path.indexOf('school') >= 0) {
        route.params.type = 'school'
      }
      if (route.path.indexOf('event') >= 0) {
        route.params.type = 'event'
      }
    }

    const contentType = ref(route.params.type)
    const { subscriptionState, contentDetailState, searchContentDetail, getContentImageUrl, goContent } = useContent()
    const { searchMemberships } = useMemberships()
    const { searchETickets } = useETickets()

    onMounted(() => {
      loadScreen(true)
    })

    onUnmounted(() => {
      clearScreenPolling()
    })

    const clearScreenPolling = () => {
      if (window.screenPolling) {
        clearInterval(window.screenPolling)
      }
    }
    const startScreenPolling = () => {
      // check if there are link items - no point polling if not!
      if (contentDetailState.content.sessionItems && contentDetailState.content.sessionItems.length > 0) {
        // check if all are on demand - no point polling if so!
        let allOD = true
        contentDetailState.content.sessionItems.forEach(linkItem => {
          if (linkItem.status !== 4) {
            allOD = false
          }
        })
        // start polling if so!
        if (!allOD) {
          window.screenPolling = setInterval(() => {
            loadScreen(false)
          }, 60000) // every minute
        }
      }
    }

    const navigateToTicketOptions = () => {
      if (contentDetailState.content.item) {
        router.push('/checkout/event/' + contentDetailState.content.item.id + '/options')
      }
    }

    const loadScreen = (initialLoad) => {
      const searchReq = {
        type: contentType.value,
        guid: contentGuid.value,
        expectUser: store.getters.isLoggedIn
      }
      searchContentDetail(searchReq, initialLoad)
        .then((result) => {
          if (initialLoad) {
            clearScreenPolling()
            startScreenPolling()
          }
        }).catch(() => {
          if (initialLoad) {
            alert('Failed to load the screen, please refresh and try again.')
          }
        })
    }

    const goToSchedule = () => {
      const scrollTo = document.getElementById('schedule-top')
      scrollTo.scrollIntoView({ behavior: 'smooth' })
    }

    const navigateToItem = (item) => {
      goContent(item, router, route)
    }

    const goToElementById = (elementId) => {
      const scrollTo = document.getElementById(elementId)
      scrollTo.scrollIntoView({ behavior: 'smooth' })
    }

    const buildSubscriptions = async () => {
      let eTicketData
      let memberships
      const subscriptions = []
      const eTickets = []
      const inPersonETickets = []

      if (contentDetailState.content.onlineMemberships && contentDetailState.content.onlineMemberships.length > 0) {
        if (!memberships) {
          memberships = await searchMemberships()
        }
        if (memberships && memberships.length > 0) {
          memberships.forEach(membership => {
            if (contentDetailState.content.onlineMemberships.indexOf(membership.id) >= 0) {
              membership.isEnabled = false
              if (contentDetailState.content.activeMemberships.indexOf(membership.id) >= 0) {
                membership.isEnabled = true
              }
              subscriptions.push(membership)
            }
          })
        }
      }

      if (contentDetailState.content.onlineETickets && contentDetailState.content.onlineETickets.length > 0) {
        if (!eTicketData) {
          eTicketData = await searchETickets()
        }
        if (eTicketData && eTicketData.length > 0) {
          eTicketData.forEach(eTicket => {
            if (contentDetailState.content.onlineETickets.indexOf(eTicket.id) >= 0) {
              eTicket.earlyBirdDateTime = DateTime.fromISO(eTicket.earlyBirdEndsAt)
              const now = DateTime.now()
              if (eTicket.earlyBirdDateTime > now) {
                eTicket.isEarlyBird = true
              } else {
                eTicket.isEarlyBird = false
              }
              eTicket.isEnabled = false
              if (contentDetailState.content.activeETickets.indexOf(eTicket.id) >= 0) {
                eTicket.isEnabled = true
              }
              eTickets.push(eTicket)
            }
          })
        }
      }

      if (contentDetailState.content.inPersonETickets && contentDetailState.content.inPersonETickets.length > 0) {
        if (!eTicketData) {
          eTicketData = await searchETickets()
        }
        if (eTicketData && eTicketData.length > 0) {
          eTicketData.forEach(eTicket => {
            if (contentDetailState.content.inPersonETickets.indexOf(eTicket.id) >= 0) {
              eTicket.earlyBirdDateTime = DateTime.fromISO(eTicket.earlyBirdEndsAt)
              const now = DateTime.now()
              if (eTicket.earlyBirdDateTime > now) {
                eTicket.isEarlyBird = true
              } else {
                eTicket.isEarlyBird = false
              }
              eTicket.isEnabled = false
              if (contentDetailState.content.activeETickets.indexOf(eTicket.id) >= 0) {
                eTicket.isEnabled = true
              }
              inPersonETickets.push(eTicket)
            }
          })
        }
      }

      subscriptionState.subscriptions = subscriptions
      subscriptionState.eTickets = eTickets
      subscriptionState.inPersonETickets = inPersonETickets
    }

    watch(
      () => contentDetailState.content.onlineMemberships,
      () => {
        buildSubscriptions()
      }
    )
    watch(
      () => contentDetailState.content.onlineETickets,
      () => {
        buildSubscriptions()
      }
    )

    return {
      imgSrc,
      contentGuid,
      contentType,
      subscriptionState,
      contentDetailState,
      getContentImageUrl,
      goToSchedule,
      goToElementById,
      navigateToItem,
      navigateToTicketOptions
    }
  }
})
</script>

<style scoped>
.card-zoom {
  @apply relative flex items-center justify-center overflow-hidden shadow-xl w-full;
  padding-bottom: 56.25%; /* 16:9 */
}
.card-zoom-image {
  @apply absolute inset-0 transition-all duration-500 ease-in-out transform bg-center bg-cover;
}
.card-zoom-text {
  @apply absolute inset-0 text-3xl font-black transition-all duration-500 ease-in-out transform scale-110 text-gray-50;
}
.card-zoom:hover .card-zoom-image {
  @apply scale-110;
}
.card-zoom:hover .card-zoom-text {
  @apply scale-100;
}
</style>
