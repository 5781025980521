<template>
<footer class="bg-blue-dark noprint" aria-labelledby="footerHeading">
  <h2 id="footerHeading" class="sr-only">Footer</h2>
  <div class="mx-auto py-12 px-4 sm:px-6">
    <div class="xl:grid xl:grid-cols-2 xl:gap-8">
      <div class="grid grid-cols-2 gap-2 xl:col-span-2">
        <div class="md:grid md:grid-cols-2 md:gap-8">
          <div>
            <h3 class="text-sm font-semibold text-gray-300 tracking-wider uppercase">
              Prayer Storm
            </h3>
            <ul class="mt-4 space-y-4">
              <li>
                <router-link to="/about" class="text-base text-gray-400 hover:text-gray-300">
                   About Us
                </router-link>
              </li>

              <li>
                <router-link to="/furnace" class="text-base text-gray-400 hover:text-gray-300">
                   Furnace
                </router-link>
              </li>

              <li>
                <router-link to="/invite" class="text-base text-gray-400 hover:text-gray-300">
                   Invite Us
                </router-link>
              </li>

              <li>
                <router-link to="/volunteer" class="text-base text-gray-400 hover:text-gray-300">
                   Interested In Volunteering?
                </router-link>
              </li>

              <li>
                <router-link to="/mailing-list" class="text-base text-gray-400 hover:text-gray-300">
                  Join Our Mailing List
                </router-link>
              </li>

              <li>
                <a href="/donate" class="text-base text-gray-400 hover:text-gray-300 font-semibold">
                   Donate
                </a>
              </li>

              <li>
                <a href="/partner" class="text-base text-gray-400 hover:text-gray-300 font-semibold">
                  Partner
                </a>
              </li>
            </ul>
          </div>
          <div class="mt-12 md:mt-0">
            <h3 class="text-sm font-semibold text-gray-300 tracking-wider uppercase">
              Content
            </h3>
            <ul class="mt-4 space-y-4">
              <li>
                <router-link to="/coming-soon" class="text-base text-gray-400 hover:text-gray-300">
                  Upcoming Events
                </router-link>
              </li>
              <li>
                <router-link to="/events" class="text-base text-gray-400 hover:text-gray-300">
                  Recent Events
                </router-link>
              </li>
              <li>
                <router-link to="/schools" class="text-base text-gray-400 hover:text-gray-300">
                  Schools
                </router-link>
              </li>
              <li>
                <router-link to="/conferences" class="text-base text-gray-400 hover:text-gray-300">
                  Conferences
                </router-link>
              </li>
              <li>
                <router-link to="/audio" class="text-base text-gray-400 hover:text-gray-300">
                  Audio
                </router-link>
              </li>
            </ul>
          </div>
        </div>
        <div class="md:grid md:grid-cols-2 md:gap-8">
          <div>
            <h3 class="text-sm font-semibold text-gray-300 tracking-wider uppercase">
              Resources
            </h3>
            <ul class="mt-4 space-y-4">
              <li>
                <router-link to="/resources/life-on-fire" class="text-base text-gray-400 hover:text-gray-300">
                   Life on Fire - Book
                </router-link>
              </li>

              <li>
                <router-link to="/resources/we-declare-war" class="text-base text-gray-400 hover:text-gray-300">
                   We Declare War - Album
                </router-link>
              </li>

              <li>
                <router-link to="/resources/freedom-prayer" class="text-base text-gray-400 hover:text-gray-300">
                  Freedom Prayer
                </router-link>
              </li>

              <li>
                <a href="https://prayerstormshop.bigcartel.com/" class="text-base text-gray-400 hover:text-gray-300">
                  Shop
                </a>
              </li>
            </ul>
          </div>
          <div class="mt-12 md:mt-0">
            <h3 class="text-sm font-semibold text-gray-300 tracking-wider uppercase">
              Connect
            </h3>
            <ul class="mt-4 space-y-4">
              <li>
                <a href="https://www.youtube.com/prayerstormtv" class="text-base text-gray-400 hover:text-gray-300">
                  YouTube
                </a>
              </li>

              <li>
                <a href="https://www.facebook.com/prayerstorm/" class="text-base text-gray-400 hover:text-gray-300">
                  Facebook
                </a>
              </li>

              <li>
                <a href="https://www.instagram.com/prayerstorm" class="text-base text-gray-400 hover:text-gray-300">
                  Instagram
                </a>
              </li>

              <li>
                <a href="https://twitter.com/PrayerStormUK" class="text-base text-gray-400 hover:text-gray-300">
                  Twitter
                </a>
              </li>

              <li>
                <a href="https://open.spotify.com/artist/3WEOd3E3aU6CphoYJn2LhH" class="text-base text-gray-400 hover:text-gray-300">
                  Spotify
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="mt-8 border-t border-gray-700 pt-8 md:flex md:items-center md:justify-between">
      <div class="flex space-x-6 md:order-2">
        <a href="https://www.youtube.com/prayerstormtv" class="text-gray-400 hover:text-gray-300">
          <span class="sr-only">YouTube</span>
          <svg class="h-6 w-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
            <path fill-rule="evenodd" d="M19.615 3.184c-3.604-.246-11.631-.245-15.23 0-3.897.266-4.356 2.62-4.385 8.816.029 6.185.484 8.549 4.385 8.816 3.6.245 11.626.246 15.23 0 3.897-.266 4.356-2.62 4.385-8.816-.029-6.185-.484-8.549-4.385-8.816zm-10.615 12.816v-8l8 3.993-8 4.007z" clip-rule="evenodd" />
          </svg>
        </a>
        <a href="https://www.facebook.com/prayerstorm/" class="text-gray-400 hover:text-gray-300">
          <span class="sr-only">Facebook</span>
          <svg class="h-6 w-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
            <path fill-rule="evenodd" d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z" clip-rule="evenodd" />
          </svg>
        </a>
        <a href="https://www.instagram.com/prayerstorm" class="text-gray-400 hover:text-gray-300">
          <span class="sr-only">Instagram</span>
          <svg class="h-6 w-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
            <path fill-rule="evenodd" d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z" clip-rule="evenodd" />
          </svg>
        </a>
        <a href="https://twitter.com/PrayerStormUK" class="text-gray-400 hover:text-gray-300">
          <span class="sr-only">Twitter</span>
          <svg class="h-6 w-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
            <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
          </svg>
        </a>
        <a href="https://open.spotify.com/artist/3WEOd3E3aU6CphoYJn2LhH" class="text-gray-400 hover:text-gray-300">
          <span class="sr-only">Spotify</span>
          <svg class="h-6 w-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
            <path fill-rule="evenodd" d="M19.098 10.638c-3.868-2.297-10.248-2.508-13.941-1.387-.593.18-1.22-.155-1.399-.748-.18-.593.154-1.22.748-1.4 4.239-1.287 11.285-1.038 15.738 1.605.533.317.708 1.005.392 1.538-.316.533-1.005.709-1.538.392zm-.126 3.403c-.272.44-.847.578-1.287.308-3.225-1.982-8.142-2.557-11.958-1.399-.494.15-1.017-.129-1.167-.623-.149-.495.13-1.016.624-1.167 4.358-1.322 9.776-.682 13.48 1.595.44.27.578.847.308 1.286zm-1.469 3.267c-.215.354-.676.465-1.028.249-2.818-1.722-6.365-2.111-10.542-1.157-.402.092-.803-.16-.895-.562-.092-.403.159-.804.562-.896 4.571-1.045 8.492-.595 11.655 1.338.353.215.464.676.248 1.028zm-5.503-17.308c-6.627 0-12 5.373-12 12 0 6.628 5.373 12 12 12 6.628 0 12-5.372 12-12 0-6.627-5.372-12-12-12z" clip-rule="evenodd" />
          </svg>
        </a>
      </div>
      <p class="mt-8 text-base text-gray-400 md:mt-0 md:order-1">
        &copy; {{new Date().getFullYear()}} Prayer Storm. All rights reserved.<br />
        Registered Charity 1161853 (England & Wales)
      </p>
    </div>
  </div>
</footer>

</template>

<script>
export default {
  name: 'Footer',
  data: () => ({
  })
}
</script>

<style scoped>
</style>
