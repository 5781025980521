<template>
  <div class="press-page">
    <h1>This is the press page</h1>
  </div>
</template>

<script>
export default {
  name: 'Press'
}
</script>

<style scoped>
</style>
