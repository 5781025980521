import { reactive, computed } from 'vue'
import axios from 'axios'
import store from '../store'
import responseHelper from '../helpers/response.helper'

const membershipsState = reactive({
  loading: false,
  memberships: [],
  benefits: [],
  activeMemberships: []
})

const membershipState = reactive({
  loading: false,
  membership: undefined
})

export function useMemberships () {
  const searchMemberships = async () => {
    membershipsState.loading = true
    membershipsState.memberships = []
    membershipsState.benefits = []
    membershipsState.activeMemberships = []
    const response = await axios.get('pub/memberships')
    membershipsState.memberships = response.data.result[0]
    membershipsState.benefits = response.data.result[1]
    membershipsState.activeMemberships = response.data.result[2]
    membershipsState.loading = false
    return membershipsState.memberships
  }
  const searchMembership = async (membershipId) => {
    membershipState.loading = true
    membershipState.membership = undefined
    const response = await axios.get('pub/memberships/' + membershipId)
    membershipState.membership = response.data.result
    membershipState.loading = false
    return membershipState.membership
  }

  const checkoutMembershipState = reactive({
    membershipId: -1,
    return: undefined,
    selectedOption: undefined,
    email: store.getters.getEmail,
    firstName: store.getters.firstName,
    lastName: store.getters.lastName,
    executing: false,
    error: responseHelper.noError,
    result: undefined
  })
  const checkoutMembership = async () => {
    if (!checkoutMembershipState.firstName || !checkoutMembershipState.lastName) {
      checkoutMembershipState.error = responseHelper.createError('Details needed', ['First name and last name are needed to update your account details for subscriptions'])
    } else {
      if (!checkoutMembershipState.executing) {
        checkoutMembershipState.executing = true
        checkoutMembershipState.result = undefined
        checkoutMembershipState.error = responseHelper.noError
        try {
          const response = await axios.post('checkout/membership', {
            membershipId: checkoutMembershipState.membershipId,
            selectedOptionId: checkoutMembershipState.selectedOption.id,
            firstName: checkoutMembershipState.firstName,
            lastName: checkoutMembershipState.lastName,
            return: checkoutMembershipState.return
          })
          checkoutMembershipState.result = response.data.result
        } catch (error) {
          checkoutMembershipState.error = responseHelper.createError('Invalid request', ['The details submitted are not valid. Please check and complete the fields needed.'])
        }
        checkoutMembershipState.executing = false
      }
    }

    return checkoutMembershipState.result
  }

  const validateMembershipState = reactive({
    sessionId: undefined,
    executing: false,
    error: responseHelper.noError,
    result: undefined
  })
  const validateMembership = async () => {
    if (!validateMembershipState.sessionId) {
      validateMembershipState.error = responseHelper.createError('Invalid Session', ['Your session could not be found. If you do not think this is correct then please contact tech support.'])
    } else {
      if (!validateMembershipState.executing) {
        validateMembershipState.executing = true
        validateMembershipState.result = undefined
        validateMembershipState.error = responseHelper.noError
        try {
          const response = await axios.post('checkout/validate', {
            sessionId: validateMembershipState.sessionId
          })
          validateMembershipState.result = response.data.result
        } catch {
          validateMembershipState.error = responseHelper.createError('Invalid Session', ['Your session could not be found. If you do not think this is correct then please contact tech support.'])
        }
        validateMembershipState.executing = false
      }
    }
    return validateMembershipState.result
  }

  const getImage = computed(() => {
    return (imageUrl, title) => {
      if (imageUrl) {
        return process.env.VUE_APP_BLOB_CONTAINER + '/images/' + imageUrl
      } else if (title && title.toLowerCase() === 'gold') {
        return '/img/banners/membership-gold.jpg'
      }
      return '/img/banners/membership-silver.jpg'
    }
  })

  return {
    // global states
    membershipsState,
    membershipState,

    // local states
    checkoutMembershipState,
    validateMembershipState,

    // functions
    searchMemberships,
    searchMembership,
    checkoutMembership,
    validateMembership,

    // computed
    getImage
  }
}
