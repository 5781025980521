const currencies = [
  { value: 'GBP', text: 'GBP - Pound Sterling' },
  { value: 'EUR', text: 'EUR - Euro' },
  { value: 'USD', text: 'USD - U.S. Dollar' },
  { value: 'AED', text: 'AED - United Arab Emirates Dirham', minValue: 2 },
  { value: 'AFN', text: 'AFN - Afghan Afghani' },
  { value: 'ALL', text: 'ALL - Albanian Lek' },
  { value: 'AMD', text: 'AMD - Armenian Dram' },
  { value: 'ANG', text: 'ANG - Netherlands Antillean Gulden' },
  { value: 'AOA', text: 'AOA - Angolan Kwanza' },
  { value: 'ARS', text: 'ARS - Argentine Peso' },
  { value: 'AUD', text: 'AUD - Australian Dollar' },
  { value: 'AWG', text: 'AWG - Aruban Florin' },
  { value: 'AZN', text: 'AZN - Azerbaijani Manat' },
  { value: 'BAM', text: 'BAM - Bosnia &amp; Herzegovina Convertible Mark' },
  { value: 'BBD', text: 'BBD - Barbadian Dollar' },
  { value: 'BDT', text: 'BDT - Bangladeshi Taka' },
  { value: 'BGN', text: 'BGN - Bulgarian Lev' },
  { value: 'BIF', text: 'BIF - Burundian Franc', isZero: true },
  { value: 'BMD', text: 'BMD - Bermudian Dollar' },
  { value: 'BND', text: 'BND - Brunei Dollar' },
  { value: 'BOB', text: 'BOB - Bolivian Boliviano' },
  { value: 'BRL', text: 'BRL - Brazilian Real' },
  { value: 'BSD', text: 'BSD - Bahamian Dollar' },
  { value: 'BWP', text: 'BWP - Botswana Pula' },
  { value: 'BYN', text: 'BYN - Belarusian Ruble' },
  { value: 'BZD', text: 'BZD - Belize Dollar' },
  { value: 'CAD', text: 'CAD - Canadian Dollar' },
  { value: 'CDF', text: 'CDF - Congolese Franc' },
  { value: 'CHF', text: 'CHF - Swiss Franc' },
  { value: 'CLP', text: 'CLP - Chilean Peso', isZero: true },
  { value: 'CNY', text: 'CNY - Chinese Renminbi Yuan' },
  { value: 'COP', text: 'COP - Colombian Peso' },
  { value: 'CRC', text: 'CRC - Costa Rican Colón' },
  { value: 'CVE', text: 'CVE - Cape Verdean Escudo' },
  { value: 'CZK', text: 'CZK - Czech Koruna', minValue: 15 },
  { value: 'DJF', text: 'DJF - Djiboutian Franc', isZero: true },
  { value: 'DKK', text: 'DKK - Danish Krone', minValue: 2.5 },
  { value: 'DOP', text: 'DOP - Dominican Peso' },
  { value: 'DZD', text: 'DZD - Algerian Dinar' },
  { value: 'EGP', text: 'EGP - Egyptian Pound' },
  { value: 'ETB', text: 'ETB - Ethiopian Birr' },
  { value: 'FJD', text: 'FJD - Fijian Dollar' },
  { value: 'FKP', text: 'FKP - Falkland Islands Pound' },
  { value: 'GEL', text: 'GEL - Georgian Lari' },
  { value: 'GIP', text: 'GIP - Gibraltar Pound' },
  { value: 'GMD', text: 'GMD - Gambian Dalasi' },
  { value: 'GNF', text: 'GNF - Guinean Franc', isZero: true },
  { value: 'GTQ', text: 'GTQ - Guatemalan Quetzal' },
  { value: 'GYD', text: 'GYD - Guyanese Dollar' },
  { value: 'HKD', text: 'HKD - Hong Kong Dollar', minValue: 4 },
  { value: 'HNL', text: 'HNL - Honduran Lempira' },
  { value: 'HRK', text: 'HRK - Croatian Kuna' },
  { value: 'HTG', text: 'HTG - Haitian Gourde' },
  { value: 'HUF', text: 'HUF - Hungarian Forint', minValue: 175 },
  { value: 'IDR', text: 'IDR - Indonesian Rupiah' },
  { value: 'ILS', text: 'ILS - Israeli New Sheqel' },
  { value: 'INR', text: 'INR - Indian Rupee' },
  { value: 'ISK', text: 'ISK - Icelandic Króna' },
  { value: 'JMD', text: 'JMD - Jamaican Dollar' },
  { value: 'JPY', text: 'JPY - Japanese Yen', isZero: true, minValue: 50 },
  { value: 'KES', text: 'KES - Kenyan Shilling' },
  { value: 'KGS', text: 'KGS - Kyrgyzstani Som' },
  { value: 'KHR', text: 'KHR - Cambodian Riel' },
  { value: 'KMF', text: 'KMF - Comorian Franc', isZero: true },
  { value: 'KRW', text: 'KRW - South Korean Won', isZero: true },
  { value: 'KYD', text: 'KYD - Cayman Islands Dollar' },
  { value: 'KZT', text: 'KZT - Kazakhstani Tenge' },
  { value: 'LAK', text: 'LAK - Lao Kip' },
  { value: 'LBP', text: 'LBP - Lebanese Pound' },
  { value: 'LKR', text: 'LKR - Sri Lankan Rupee' },
  { value: 'LRD', text: 'LRD - Liberian Dollar' },
  { value: 'LSL', text: 'LSL - Lesotho Loti' },
  { value: 'MAD', text: 'MAD - Moroccan Dirham' },
  { value: 'MDL', text: 'MDL - Moldovan Leu' },
  { value: 'MGA', text: 'MGA - Malagasy Ariary', isZero: true },
  { value: 'MKD', text: 'MKD - Macedonian Denar' },
  { value: 'MMK', text: 'MMK - Myanmar Kyat' },
  { value: 'MNT', text: 'MNT - Mongolian Tögrög' },
  { value: 'MOP', text: 'MOP - Macanese Pataca' },
  { value: 'MRO', text: 'MRO - Mauritanian Ouguiya' },
  { value: 'MUR', text: 'MUR - Mauritian Rupee' },
  { value: 'MVR', text: 'MVR - Maldivian Rufiyaa' },
  { value: 'MWK', text: 'MWK - Malawian Kwacha' },
  { value: 'MXN', text: 'MXN - Mexican Peso', minValue: 10 },
  { value: 'MYR', text: 'MYR - Malaysian Ringgit', minValue: 2 },
  { value: 'MZN', text: 'MZN - Mozambican Metical' },
  { value: 'NAD', text: 'NAD - Namibian Dollar' },
  { value: 'NGN', text: 'NGN - Nigerian Naira' },
  { value: 'NIO', text: 'NIO - Nicaraguan Córdoba' },
  { value: 'NOK', text: 'NOK - Norwegian Krone', minValue: 3 },
  { value: 'NPR', text: 'NPR - Nepalese Rupee' },
  { value: 'NZD', text: 'NZD - New Zealand Dollar' },
  { value: 'PAB', text: 'PAB - Panamanian Balboa' },
  { value: 'PEN', text: 'PEN - Peruvian Nuevo Sol' },
  { value: 'PGK', text: 'PGK - Papua New Guinean Kina' },
  { value: 'PHP', text: 'PHP - Philippine Peso' },
  { value: 'PKR', text: 'PKR - Pakistani Rupee' },
  { value: 'PLN', text: 'PLN - Polish Zloty', minValue: 2 },
  { value: 'PYG', text: 'PYG - Paraguayan Guaraní', isZero: true },
  { value: 'QAR', text: 'QAR - Qatari Riyal' },
  { value: 'RON', text: 'RON - Romanian Leu', minValue: 2 },
  { value: 'RSD', text: 'RSD - Serbian Dinar' },
  { value: 'RUB', text: 'RUB - Russian Ruble' },
  { value: 'RWF', text: 'RWF - Rwandan Franc', isZero: true },
  { value: 'SAR', text: 'SAR - Saudi Riyal' },
  { value: 'SBD', text: 'SBD - Solomon Islands Dollar' },
  { value: 'SCR', text: 'SCR - Seychellois Rupee' },
  { value: 'SEK', text: 'SEK - Swedish Krona', minValue: 3 },
  { value: 'SGD', text: 'SGD - Singapore Dollar' },
  { value: 'SHP', text: 'SHP - Saint Helenian Pound' },
  { value: 'SLE', text: 'SLE - Sierra Leonean Leone (New Leone)' },
  { value: 'SLL', text: 'SLL - Sierra Leonean Leone (Old Leone)' },
  { value: 'SOS', text: 'SOS - Somali Shilling' },
  { value: 'SRD', text: 'SRD - Surinamese Dollar' },
  { value: 'STD', text: 'STD - São Tomé and Príncipe Dobra' },
  { value: 'SZL', text: 'SZL - Swazi Lilangeni' },
  { value: 'THB', text: 'THB - Thai Baht', minValue: 10 },
  { value: 'TJS', text: 'TJS - Tajikistani Somoni' },
  { value: 'TOP', text: 'TOP - Tongan Paʻanga' },
  { value: 'TRY', text: 'TRY - Turkish Lira' },
  { value: 'TTD', text: 'TTD - Trinidad and Tobago Dollar' },
  { value: 'TWD', text: 'TWD - Taiwan New Dollar' },
  { value: 'TZS', text: 'TZS - Tanzanian Shilling' },
  { value: 'UAH', text: 'UAH - Ukrainian Hryvnia' },
  { value: 'UGX', text: 'UGX - Ugandan Shilling', isZero: true },
  { value: 'UYU', text: 'UYU - Uruguayan Peso' },
  { value: 'UZS', text: 'UZS - Uzbekistani Som' },
  { value: 'VND', text: 'VND - Vietnamese Đồng', isZero: true },
  { value: 'VUV', text: 'VUV - Vanuatu Vatu', isZero: true },
  { value: 'WST', text: 'WST - Samoan Tala' },
  { value: 'XAF', text: 'XAF - Central African CFA Franc', isZero: true },
  { value: 'XCD', text: 'XCD - East Caribbean Dollar' },
  { value: 'XOF', text: 'XOF - West African CFA Franc', isZero: true },
  { value: 'XPF', text: 'XPF - CFP Franc', isZero: true },
  { value: 'YER', text: 'YER - Yemeni Rial' },
  { value: 'ZAR', text: 'ZAR - South African Rand' },
  { value: 'ZMW', text: 'ZMW - Zambian Kwacha' }
]

const currencyIndex = {}
currencies.forEach(element => {
  currencyIndex[element.value] = element
})

const stripeStatus = (value) => {
  switch (value) {
    case 'canceled' :
      return 'Cancelled'
    case 'past_due' :
      return 'Overdue'
  }
  return value
}
const stripeCurrencySymbol = (value) => {
  let returnValue = value.toLowerCase()
  switch (returnValue) {
    case 'gbp' :
      returnValue = '£'
      break
    case 'usd' :
      returnValue = '$'
      break
  }
  return returnValue
}
const stripeUnitAmountString = (currency, unit) => {
  if (currencyIndex[currency.toUpperCase()].isZero) {
    return unit
  }
  return (unit / 100).toFixed(2)
}

export function useCurrencies () {
  return {
    currencies,
    currencyIndex,

    stripeStatus,
    stripeCurrencySymbol,
    stripeUnitAmountString
  }
}
