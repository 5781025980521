<template>
<div class="flex flex-col justify-center py-12 sm:px-6 lg:px-8">
  <div class="sm:mx-auto sm:w-full sm:max-w-3xl">
    <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
      <form class="space-y-6" id="invite-form" @submit.prevent="invite">
        <p class="text-red-500 font-bold">Please attempt to complete all fields requested below.</p>
        <div v-show="additionalMessage">
          <h2 class="font-bold text-blue">Please Note</h2>
          <p class="font-bold mt-2">{{additionalMessage}}</p>
        </div>
        <h2 class="font-bold text-blue">Your Details</h2>
        <div class="grid grid-cols-4 gap-6">
          <div class="col-span-4 sm:col-span-2">
            <label for="firstName" class="block text-sm font-medium text-gray-700">First name</label>
            <input v-model="formState.inviteForm.firstName" type="text" name="firstName" id="firstName" autocomplete="off" class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-light-blue-500 focus:border-light-blue-500 sm:text-sm" />
          </div>

          <div class="col-span-4 sm:col-span-2">
            <label for="lastName" class="block text-sm font-medium text-gray-700">Last name</label>
            <input v-model="formState.inviteForm.lastName" type="text" name="lastName" id="lastName" autocomplete="off" class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-light-blue-500 focus:border-light-blue-500 sm:text-sm" />
          </div>

          <div class="col-span-4 sm:col-span-2">
            <label for="email" class="block text-sm font-medium text-gray-700">Email address</label>
            <input v-model="formState.inviteForm.email" type="email" name="email" id="email" autocomplete="email" class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm px-3 py-2 focus:outline-none focus:ring-light-blue-500 focus:border-light-blue-500 sm:text-sm" />
          </div>

          <div class="col-span-4 sm:col-span-2">
            <label for="phoneNumber" class="block text-sm font-medium text-gray-700">Phone number</label>
            <input v-model="formState.inviteForm.phoneNumber" type="tel" name="phoneNumber" id="phoneNumber" autocomplete="off" class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm px-3 py-2 focus:outline-none focus:ring-light-blue-500 focus:border-light-blue-500 sm:text-sm" />
          </div>

          <div class="col-span-4 sm:col-span-2">
            <label for="position" class="block text-sm font-medium text-gray-700">Position</label>
            <input v-model="formState.inviteForm.position" type="text" name="position" id="position" autocomplete="off" class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm px-3 py-2 focus:outline-none focus:ring-light-blue-500 focus:border-light-blue-500 sm:text-sm" />
          </div>

          <div class="col-span-4 sm:col-span-2">
            <label for="churchMinistry" class="block text-sm font-medium text-gray-700">Church/Ministry</label>
            <input v-model="formState.inviteForm.churchMinistry" type="text" name="churchMinistry" id="churchMinistry" autocomplete="off" class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm px-3 py-2 focus:outline-none focus:ring-light-blue-500 focus:border-light-blue-500 sm:text-sm" />
          </div>

          <div class="col-span-4 sm:col-span-2">
            <label for="seniorLeader" class="block text-sm font-medium text-gray-700">Senior Pastor/Leader</label>
            <input v-model="formState.inviteForm.seniorLeader" type="text" name="seniorLeader" id="seniorLeader" autocomplete="off" class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm px-3 py-2 focus:outline-none focus:ring-light-blue-500 focus:border-light-blue-500 sm:text-sm" />
          </div>

          <div class="col-span-4 sm:col-span-2">
            <label for="website" class="block text-sm font-medium text-gray-700">Website</label>
            <input v-model="formState.inviteForm.website" type="text" name="website" id="website" autocomplete="off" class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm px-3 py-2 focus:outline-none focus:ring-light-blue-500 focus:border-light-blue-500 sm:text-sm" />
          </div>
        </div>

        <h2 class="font-bold text-blue">Event Details</h2>
        <div class="grid grid-cols-4 gap-6">
          <div class="col-span-4 sm:col-span-2">
            <label for="eventDate" class="block text-sm font-medium text-gray-700">Event date</label>
            <input v-model="formState.inviteForm.eventDate" type="date" name="eventDate" id="eventDate" autocomplete="off" class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm px-3 py-2 focus:outline-none focus:ring-light-blue-500 focus:border-light-blue-500 sm:text-sm" />
          </div>

          <div class="col-span-4 sm:col-span-2">
            <label for="eventLocation" class="block text-sm font-medium text-gray-700">Event location</label>
            <input v-model="formState.inviteForm.eventLocation" type="text" name="eventLocation" id="eventLocation" autocomplete="off" class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm px-3 py-2 focus:outline-none focus:ring-light-blue-500 focus:border-light-blue-500 sm:text-sm" />
          </div>

          <div class="col-span-4 sm:col-span-2">
            <label for="eventStartTime" class="block text-sm font-medium text-gray-700">Event start time</label>
            <input v-model="formState.inviteForm.eventStartTime" type="time" name="eventStartTime" id="eventStartTime" autocomplete="off" class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm px-3 py-2 focus:outline-none focus:ring-light-blue-500 focus:border-light-blue-500 sm:text-sm" />
          </div>

          <div class="col-span-4 sm:col-span-2">
            <label for="eventEndTime" class="block text-sm font-medium text-gray-700">Event end time</label>
            <input v-model="formState.inviteForm.eventEndTime" type="time" name="eventEndTime" id="eventEndTime" autocomplete="off" class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm px-3 py-2 focus:outline-none focus:ring-light-blue-500 focus:border-light-blue-500 sm:text-sm" />
          </div>

          <div class="col-span-4 sm:col-span-2">
            <label for="timeSlot" class="block text-sm font-medium text-gray-700">Expected time {{nameShort}} will start</label>
            <input v-model="formState.inviteForm.timeSlot" type="time" name="timeSlot" id="timeSlot" autocomplete="off" class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm px-3 py-2 focus:outline-none focus:ring-light-blue-500 focus:border-light-blue-500 sm:text-sm" />
          </div>

          <div class="col-span-4 sm:col-span-2">
            <label for="eventAttendance" class="block text-sm font-medium text-gray-700">Number of people expected to attend</label>
            <input v-model="formState.inviteForm.eventAttendance" type="text" name="eventAttendance" id="eventAttendance" autocomplete="off" class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm px-3 py-2 focus:outline-none focus:ring-light-blue-500 focus:border-light-blue-500 sm:text-sm" />
          </div>

          <div v-show="isPrayerStormMusic" class="col-span-4">
            <label class="block text-sm font-medium text-gray-700">What would you like {{nameShort}} to minister in?</label>
            <div class="flex">
              <div>
                <input v-model="formState.inviteForm.preachingTeaching" id="preachingTeaching" name="preachingTeaching" type="checkbox" class="h-4 w-4 text-blue focus:ring-blue border-gray-300 rounded">
                <label for="preachingTeaching" class="ml-2 inline-block text-sm text-gray-900">
                  Preaching/Teaching
                </label>
              </div>
              <div class="pl-8">
                <input v-model="formState.inviteForm.singingWorshipLeading" id="singingWorshipLeading" name="singingWorshipLeading" type="checkbox" class="h-4 w-4 text-blue focus:ring-blue border-gray-300 rounded">
                <label for="singingWorshipLeading" class="ml-2 inline-block text-sm text-gray-900">
                  Singing/Worship Leading
                </label>
              </div>
            </div>
          </div>

          <div v-show="isJames" class="col-span-4">
            <label class="block text-sm font-medium text-gray-700">What would you like {{nameShort}} to minister in?</label>
            <div class="flex">
              <div>
                <input v-model="formState.inviteForm.preachingTeaching" id="preachingTeaching" name="preachingTeaching" type="checkbox" class="h-4 w-4 text-blue focus:ring-blue border-gray-300 rounded">
                <label for="preachingTeaching" class="ml-2 inline-block text-sm text-gray-900">
                  Preaching/Teaching
                </label>
              </div>
              <div class="pl-8">
                <input v-model="formState.inviteForm.prayerLeading" id="prayerLeading" name="prayerLeading" type="checkbox" class="h-4 w-4 text-blue focus:ring-blue border-gray-300 rounded">
                <label for="prayerLeading" class="ml-2 inline-block text-sm text-gray-900">
                  Prayer Leading
                </label>
              </div>
              <div class="pl-8">
                <input v-model="formState.inviteForm.isOtherMinistry" id="isOtherMinistry" name="isOtherMinistry" type="checkbox" class="h-4 w-4 text-blue focus:ring-blue border-gray-300 rounded">
                <label for="isOtherMinistry" class="ml-2 inline-block text-sm text-gray-900">
                  Other
                </label>
              </div>
            </div>
          </div>

          <div v-show="formState.inviteForm.isOtherMinistry" class="col-span-4 sm:col-span-2">
            <label for="otherMinistry" class="block text-sm font-medium text-gray-700">Please specify what other ministry you are requesting?</label>
            <input v-model="formState.inviteForm.otherMinistry" type="text" name="otherMinistry" id="otherMinistry" autocomplete="off" class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm px-3 py-2 focus:outline-none focus:ring-light-blue-500 focus:border-light-blue-500 sm:text-sm" />
          </div>

          <div class="col-span-4 sm:col-span-2">
            <label for="noSessions" class="block text-sm font-medium text-gray-700">How many sessions would you like {{nameShort}} to minister for?</label>
            <input v-model="formState.inviteForm.noSessions" type="text" name="noSessions" id="noSessions" autocomplete="off" class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm px-3 py-2 focus:outline-none focus:ring-light-blue-500 focus:border-light-blue-500 sm:text-sm" />
          </div>

          <div class="col-span-4 sm:col-span-2">
            <label for="speakingDuration" class="block text-sm font-medium text-gray-700">How long would you like {{nameShort}} to minister for?</label>
            <input v-model="formState.inviteForm.speakingDuration" type="text" name="speakingDuration" id="speakingDuration" autocomplete="off" class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm px-3 py-2 focus:outline-none focus:ring-light-blue-500 focus:border-light-blue-500 sm:text-sm" />
          </div>
        </div>

        <div v-show="formState.inviteForm.singingWorshipLeading">
          <label for="bandProvision" class="block text-sm font-medium text-gray-700">Are you providing a band to play with {{nameShort}}? If so, please provide details on instruments and singers you are expecting to be involved.</label>
          <textarea v-model="formState.inviteForm.bandProvision" id="bandProvision" name="bandProvision" rows="3" class="mt-1 shadow-sm block w-full focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border border-gray-300 rounded-md" />
        </div>

        <div v-show="formState.inviteForm.singingWorshipLeading">
          <label for="contactMusic" class="block text-sm font-medium text-gray-700">Please provide key contact details for music/worship. Name, position, email, telephone etc.</label>
          <textarea v-model="formState.inviteForm.contactMusic" id="contactMusic" name="contactMusic" rows="3" class="mt-1 shadow-sm block w-full focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border border-gray-300 rounded-md" />
        </div>

        <div v-show="formState.inviteForm.singingWorshipLeading">
          <label for="contactTech" class="block text-sm font-medium text-gray-700">Please provide key contact details for technical/sound. Name, position, email, telephone etc.</label>
          <textarea v-model="formState.inviteForm.contactTech" id="contactTech" name="contactTech" rows="3" class="mt-1 shadow-sm block w-full focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border border-gray-300 rounded-md" />
        </div>

        <div>
          <label for="eventType" class="block text-sm font-medium text-gray-700">What type of event is it?</label>
          <RadioGroup v-model="formState.inviteForm.eventType" name="eventType" id="eventType">
            <RadioGroupLabel class="sr-only">
              Event Type
            </RadioGroupLabel>
            <div class="mt-1 bg-white rounded-md -space-y-px">
              <RadioGroupOption as="template" v-for="(setting, settingIdx) in eventTypeOptions" :key="setting.name" :value="setting.name" v-slot="{ checked, active }">
                <div :class="[settingIdx === 0 ? 'rounded-tl-md rounded-tr-md' : '', settingIdx === eventTypeOptions.length - 1 ? 'rounded-bl-md rounded-br-md' : '', checked ? 'bg-indigo-50 border-indigo-200 z-10' : 'border-gray-200', 'relative border p-4 flex cursor-pointer focus:outline-none']">
                  <span :class="[checked ? 'bg-indigo-600 border-transparent' : 'bg-white border-gray-300', active ? 'ring-2 ring-offset-2 ring-indigo-500' : '', 'h-4 w-4 mt-0.5 cursor-pointer rounded-full border flex items-center justify-center']" aria-hidden="true">
                    <span class="rounded-full bg-white w-1.5 h-1.5" />
                  </span>
                  <div class="ml-3 flex flex-col">
                    <RadioGroupLabel as="span" :class="[checked ? 'text-indigo-900' : 'text-gray-900', 'block text-sm font-medium']">
                      {{ setting.name }}
                    </RadioGroupLabel>
                  </div>
                </div>
              </RadioGroupOption>
            </div>
          </RadioGroup>
        </div>

        <div v-show="formState.inviteForm.eventType == 'Online Event' || formState.inviteForm.eventType == 'Both - In-Person and Online Event'">
          <label for="online" class="block text-sm font-medium text-gray-700">Will this be a closed online meeting or public live-stream?</label>
          <RadioGroup v-model="formState.inviteForm.online" name="online" id="online">
            <RadioGroupLabel class="sr-only">
              Online Options
            </RadioGroupLabel>
            <div class="mt-1 bg-white rounded-md -space-y-px">
              <RadioGroupOption as="template" v-for="(setting, settingIdx) in onlineOptions" :key="setting.name" :value="setting.name" v-slot="{ checked, active }">
                <div :class="[settingIdx === 0 ? 'rounded-tl-md rounded-tr-md' : '', settingIdx === onlineOptions.length - 1 ? 'rounded-bl-md rounded-br-md' : '', checked ? 'bg-indigo-50 border-indigo-200 z-10' : 'border-gray-200', 'relative border p-4 flex cursor-pointer focus:outline-none']">
                  <span :class="[checked ? 'bg-indigo-600 border-transparent' : 'bg-white border-gray-300', active ? 'ring-2 ring-offset-2 ring-indigo-500' : '', 'h-4 w-4 mt-0.5 cursor-pointer rounded-full border flex items-center justify-center']" aria-hidden="true">
                    <span class="rounded-full bg-white w-1.5 h-1.5" />
                  </span>
                  <div class="ml-3 flex flex-col">
                    <RadioGroupLabel as="span" :class="[checked ? 'text-indigo-900' : 'text-gray-900', 'block text-sm font-medium']">
                      {{ setting.name }}
                    </RadioGroupLabel>
                  </div>
                </div>
              </RadioGroupOption>
            </div>
          </RadioGroup>
        </div>

        <div>
          <label for="recording" class="block text-sm font-medium text-gray-700">Will the meeting be recorded for use on-line at a later date?</label>
          <RadioGroup v-model="formState.inviteForm.recording" name="recording" id="recording">
            <RadioGroupLabel class="sr-only">
              Recording Options
            </RadioGroupLabel>
            <div class="mt-1 bg-white rounded-md -space-y-px">
              <RadioGroupOption as="template" v-for="(setting, settingIdx) in recordingOptions" :key="setting.name" :value="setting.name" v-slot="{ checked, active }">
                <div :class="[settingIdx === 0 ? 'rounded-tl-md rounded-tr-md' : '', settingIdx === recordingOptions.length - 1 ? 'rounded-bl-md rounded-br-md' : '', checked ? 'bg-indigo-50 border-indigo-200 z-10' : 'border-gray-200', 'relative border p-4 flex cursor-pointer focus:outline-none']">
                  <span :class="[checked ? 'bg-indigo-600 border-transparent' : 'bg-white border-gray-300', active ? 'ring-2 ring-offset-2 ring-indigo-500' : '', 'h-4 w-4 mt-0.5 cursor-pointer rounded-full border flex items-center justify-center']" aria-hidden="true">
                    <span class="rounded-full bg-white w-1.5 h-1.5" />
                  </span>
                  <div class="ml-3 flex flex-col">
                    <RadioGroupLabel as="span" :class="[checked ? 'text-indigo-900' : 'text-gray-900', 'block text-sm font-medium']">
                      {{ setting.name }}
                    </RadioGroupLabel>
                  </div>
                </div>
              </RadioGroupOption>
            </div>
          </RadioGroup>
        </div>

        <div>
          <label for="merch" class="block text-sm font-medium text-gray-700">Are you happy for {{nameShort}} to bring merchandise to sell? For example 'Life on Fire' and 'We Declare War'</label>
          <RadioGroup v-model="formState.inviteForm.merch" name="merch" id="merch">
            <RadioGroupLabel class="sr-only">
              Merchandise Options
            </RadioGroupLabel>
            <div class="mt-1 bg-white rounded-md -space-y-px">
              <RadioGroupOption as="template" v-for="(setting, settingIdx) in merchOptions" :key="setting.name" :value="setting.name" v-slot="{ checked, active }">
                <div :class="[settingIdx === 0 ? 'rounded-tl-md rounded-tr-md' : '', settingIdx === merchOptions.length - 1 ? 'rounded-bl-md rounded-br-md' : '', checked ? 'bg-indigo-50 border-indigo-200 z-10' : 'border-gray-200', 'relative border p-4 flex cursor-pointer focus:outline-none']">
                  <span :class="[checked ? 'bg-indigo-600 border-transparent' : 'bg-white border-gray-300', active ? 'ring-2 ring-offset-2 ring-indigo-500' : '', 'h-4 w-4 mt-0.5 cursor-pointer rounded-full border flex items-center justify-center']" aria-hidden="true">
                    <span class="rounded-full bg-white w-1.5 h-1.5" />
                  </span>
                  <div class="ml-3 flex flex-col">
                    <RadioGroupLabel as="span" :class="[checked ? 'text-indigo-900' : 'text-gray-900', 'block text-sm font-medium']">
                      {{ setting.name }}
                    </RadioGroupLabel>
                  </div>
                </div>
              </RadioGroupOption>
            </div>
          </RadioGroup>
        </div>

        <div v-show="formState.inviteForm.merch == 'Yes'">
          <label for="merch" class="block text-sm font-medium text-gray-700">Would you be able to provide someone at the event to help {{nameShort}} with the merchandise if he is travelling alone?</label>
          <RadioGroup v-model="formState.inviteForm.merchHelp" name="merchHelp" id="merchHelp">
            <RadioGroupLabel class="sr-only">
              Merchandise Help Options
            </RadioGroupLabel>
            <div class="mt-1 bg-white rounded-md -space-y-px">
              <RadioGroupOption as="template" v-for="(setting, settingIdx) in merchHelpOptions" :key="setting.name" :value="setting.name" v-slot="{ checked, active }">
                <div :class="[settingIdx === 0 ? 'rounded-tl-md rounded-tr-md' : '', settingIdx === merchHelpOptions.length - 1 ? 'rounded-bl-md rounded-br-md' : '', checked ? 'bg-indigo-50 border-indigo-200 z-10' : 'border-gray-200', 'relative border p-4 flex cursor-pointer focus:outline-none']">
                  <span :class="[checked ? 'bg-indigo-600 border-transparent' : 'bg-white border-gray-300', active ? 'ring-2 ring-offset-2 ring-indigo-500' : '', 'h-4 w-4 mt-0.5 cursor-pointer rounded-full border flex items-center justify-center']" aria-hidden="true">
                    <span class="rounded-full bg-white w-1.5 h-1.5" />
                  </span>
                  <div class="ml-3 flex flex-col">
                    <RadioGroupLabel as="span" :class="[checked ? 'text-indigo-900' : 'text-gray-900', 'block text-sm font-medium']">
                      {{ setting.name }}
                    </RadioGroupLabel>
                  </div>
                </div>
              </RadioGroupOption>
            </div>
          </RadioGroup>
        </div>

        <div>
          <label for="intendedAudience" class="block text-sm font-medium text-gray-700">Who is the event aimed at? Please share a little about intended audience.</label>
          <textarea v-model="formState.inviteForm.intendedAudience" id="intendedAudience" name="intendedAudience" rows="3" class="mt-1 shadow-sm block w-full focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border border-gray-300 rounded-md" />
        </div>

        <div>
          <label for="ministryTime" class="block text-sm font-medium text-gray-700">Are you intending for {{nameShort}} to be involved in a prayer/ministry session?  If so, then please give details of what you are planning and details of any ministry team you have available to help with this.</label>
          <textarea v-model="formState.inviteForm.ministryTime" id="ministryTime" name="ministryTime" rows="3" class="mt-1 shadow-sm block w-full focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border border-gray-300 rounded-md" />
        </div>

        <div class="grid grid-cols-4 gap-6">
          <div class="col-span-4 sm:col-span-2">
            <label for="budget" class="block text-sm font-medium text-gray-700">Would your budget cover someone travelling with {{nameShort}}?</label>
            <input v-model="formState.inviteForm.budget" type="text" name="budget" id="budget" autocomplete="off" class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm px-3 py-2 focus:outline-none focus:ring-light-blue-500 focus:border-light-blue-500 sm:text-sm" />
          </div>

          <div class="col-span-4 sm:col-span-2">
            <label for="knowBy" class="block text-sm font-medium text-gray-700">When will you need to know if {{nameShort}} is able to attend? </label>
            <input v-model="formState.inviteForm.knowBy" type="date" name="knowBy" id="knowBy" autocomplete="off" class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm px-3 py-2 focus:outline-none focus:ring-light-blue-500 focus:border-light-blue-500 sm:text-sm" />
          </div>
        </div>

        <div>
          <label for="expenses" class="block text-sm font-medium text-gray-700">When inviting Prayer Storm it is understood that any and all expenses will be covered by the inviting organisation plus an honorarium.</label>
          <RadioGroup v-model="formState.inviteForm.expenses" name="expenses" id="expenses">
            <RadioGroupLabel class="sr-only">
              Expense Agreement
            </RadioGroupLabel>
            <div class="mt-1 bg-white rounded-md -space-y-px">
              <RadioGroupOption as="template" v-for="(setting, settingIdx) in expenseOptions" :key="setting.name" :value="setting.name" v-slot="{ checked, active }">
                <div :class="[settingIdx === 0 ? 'rounded-tl-md rounded-tr-md' : '', settingIdx === expenseOptions.length - 1 ? 'rounded-bl-md rounded-br-md' : '', checked ? 'bg-indigo-50 border-indigo-200 z-10' : 'border-gray-200', 'relative border p-4 flex cursor-pointer focus:outline-none']">
                  <span :class="[checked ? 'bg-indigo-600 border-transparent' : 'bg-white border-gray-300', active ? 'ring-2 ring-offset-2 ring-indigo-500' : '', 'h-4 w-4 mt-0.5 cursor-pointer rounded-full border flex items-center justify-center']" aria-hidden="true">
                    <span class="rounded-full bg-white w-1.5 h-1.5" />
                  </span>
                  <div class="ml-3 flex flex-col">
                    <RadioGroupLabel as="span" :class="[checked ? 'text-indigo-900' : 'text-gray-900', 'block text-sm font-medium']">
                      {{ setting.name }}
                    </RadioGroupLabel>
                  </div>
                </div>
              </RadioGroupOption>
            </div>
          </RadioGroup>
        </div>

        <div>
          <label for="otherDetails" class="block text-sm font-medium text-gray-700">Are there any other details you think we should know? </label>
          <textarea v-model="formState.inviteForm.otherDetails" id="otherDetails" name="otherDetails" rows="3" class="mt-1 shadow-sm block w-full focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border border-gray-300 rounded-md" />
        </div>

        <ErrorMessage :formError="formState.formError" class="mt-6"></ErrorMessage>
        <SuccessMessage :successMessage="formState.successMessage" class="mt-6"></SuccessMessage>

        <div>
          <button type="submit" :disabled="formState.formSubmitted" class="disabled:opacity-50 w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue hover:bg-blue-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue">
            Invite {{nameShort}}
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
</template>

<script>
import axios from 'axios'
import { defineComponent, reactive } from 'vue'
import responseHelper from '@/helpers/response.helper'
import SuccessMessage from '@/components/SuccessMessage.vue'
import ErrorMessage from '@/components/ErrorMessage.vue'

import { RadioGroup, RadioGroupLabel, RadioGroupOption } from '@headlessui/vue'

const expenseOptions = [
  { name: 'Agree' },
  { name: 'Disagree' }
]

const eventTypeOptions = [
  { name: 'In-Person Event' },
  { name: 'Online Event' },
  { name: 'Both - In-Person and Online Event' }
]

const onlineOptions = [
  { name: 'Closed Online Meeting' },
  { name: 'Public Live-Stream' }
]
const recordingOptions = [
  { name: 'No - it will not be recorded for later use' },
  { name: 'Yes - we intend to record and use at a later date' }
]
const merchOptions = [
  { name: 'Yes' },
  { name: 'No' }
]
const merchHelpOptions = [
  { name: 'Yes - we can provide someone to help' },
  { name: 'No - we cannot provide someone to help' }
]
export default defineComponent({
  name: 'InviteForm',
  props: [
    'isJames',
    'isPrayerStormMusic',
    'nameFull',
    'nameShort',
    'additionalMessage'
  ],
  components: {
    RadioGroup,
    RadioGroupLabel,
    RadioGroupOption,
    SuccessMessage,
    ErrorMessage
  },
  setup (props) {
    const formState = reactive({
      inviteForm: {
        nameFull: props.nameFull,
        nameShort: props.nameShort,

        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        position: '',
        churchMinistry: '',
        seniorLeader: '',
        website: '',
        eventDate: '',
        eventLocation: '',
        eventStartTime: '',
        eventEndTime: '',
        timeSlot: '',
        eventAttendance: '',
        isPrayerStormMusic: props.isPrayerStormMusic,
        isJames: props.isJames,
        preachingTeaching: false,
        singingWorshipLeading: false,
        prayerLeading: false,
        otherMinistry: '',
        isOtherMinistry: false,
        noSessions: '',
        speakingDuration: '',
        bandProvision: '',
        contactMusic: '',
        contactTech: '',
        eventType: '',
        online: '',
        recording: '',
        merch: '',
        merchHelp: '',
        intendedAudience: '',
        ministryTime: '',
        budget: '',
        knowBy: '',
        expenses: '',
        otherDetails: ''
      },
      formSubmitted: false,
      formError: responseHelper.noError,
      successMessage: ''
    })
    const invite = () => {
      formState.formError = responseHelper.noError
      if (!formState.formSubmitted && !formState.successMessage) {
        formState.formSubmitted = true
        axios.post(
          'pub/invites',
          formState.inviteForm
        ).then((response) => {
          if (response?.data?.success && response?.data?.result) {
            formState.successMessage = response.data.result.message
          } else {
            formState.formError = responseHelper.unexpectedResult
          }
        }).catch((error) => {
          formState.formError = responseHelper.getMessage(error)
        }).finally(() => {
          formState.formSubmitted = false
        })
      }
    }
    return {
      expenseOptions,
      eventTypeOptions,
      onlineOptions,
      recordingOptions,
      merchOptions,
      merchHelpOptions,
      formState,
      invite
    }
  }
})
</script>
