<template>
  <div class="max-w-7xl mx-auto py-6 px-4 sm:px-6">
    <div class="flex justify-between">
      <h2 class="text-xl sm:text-2xl font-semibold tracking-tight text-blue-dark">Conference Support</h2>
    </div>
    <div class="pt-2 grid lg:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-6 ">
      <!-- <div class="bg-white rounded-md shadow-box">
        <a href="/support/2/prayer-storm-kids-conference" class="">
          <img src="https://pslive.blob.core.windows.net/images/event/5bdae490-fb0e-11ee-a2f7-4d43311ae5c8-event.jpg" alt="Fundraiser item" class="w-full rounded-md" width="320" height="190">
        </a>
        <div class="p-4">
          <a class="font-bold text-lg hover:underline" href="/support/2/prayer-storm-kids-conference">
            Prayer Storm Kids Conference 2024 Support
          </a>
          <div class="overflow-hidden rounded-full bg-gray-200 mt-4">
            <div class="h-2 rounded-full bg-blue" style="width: 40%" />
          </div>
          <div class="flex my-4">
            <div class="mr-6">
              <div class="text-lg font-bold">£2000</div>
              <div>Raised</div>
            </div>
            <div>
              <div class="text-lg font-bold">£5000</div>
              <div>Goal</div>
            </div>
          </div>
          <a class="w-full bg-blue hover:bg-blue-dark rounded-md px-6 py-3 text-md font-semibold text-white text-center flex justify-center" href="/support/2/prayer-storm-kids-conference">
            Support Now
          </a>
        </div>
      </div> -->
      <div class="bg-white rounded-lg shadow-box">
        <div class="pt-4 px-4">
          <a href="/support/3/summer-conference">
            <img src="https://pslive.blob.core.windows.net/images/event/62551e70-032a-11ef-8bdb-0999b124e13e-event.jpg" class="w-full rounded-lg">
          </a>
        </div>
        <div class="p-4">
          <a class="font-bold text-lg hover:underline" href="/support/3/summer-conference">
            Prayer Storm Summer Conference Support
          </a>
          <div class="overflow-hidden rounded-full bg-gray-200 mt-4">
            <div class="h-2 rounded-full bg-blue" style="width: 100%" />
          </div>
          <div class="flex my-4">
            <div class="mr-6">
              <div class="text-lg font-bold">£55,000</div>
              <div>Raised</div>
            </div>
            <div>
              <div class="text-lg font-bold">£55,000</div>
              <div>Goal</div>
            </div>
          </div>
          <a class="w-full bg-blue hover:bg-blue-dark rounded-md px-6 py-3 text-md font-semibold text-white text-center flex justify-center" href="/support/3/summer-conference">
            Support Now
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
