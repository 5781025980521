<template>
  <LiveNow></LiveNow>
  <div class="max-w-7xl mx-auto pb-8 px-4 sm:px-6 lg:px-8">
    <div class="relative">
      <div>
        <div class="pt-16 px-4 max-w-xl mx-auto sm:px-6 lg:max-w-none lg:mx-0 lg:px-0 text-center">
          <div>
            <h2 class="text-3xl font-bold text-blue">Nazarite School of Prayer</h2>
          </div>
        </div>
      </div>
      <div class="lg:grid lg:grid-cols-2 lg:grid-flow-col-dense gap-8 xl:gap-24">
        <div class="mt-8 px-4 max-w-xl mx-auto sm:px-6 lg:max-w-none lg:mx-0 lg:px-0">
          <div class="relative video-container">
            <iframe title="PS - NSOP 2023 video 1" src="https://player.vimeo.com/video/815278343?h=7b2c9c6975&badge=0&autopause=0&player_id=0&app_id=58479/embed" class="w-full h-full" frameborder="0" allow="autoplay; fullscreen"></iframe>
          </div>
        </div>
        <div class="mt-8 px-4 max-w-xl mx-auto sm:px-6 lg:max-w-none lg:mx-0 lg:px-0">
          <div class="relative video-container">
            <iframe title="PS - NSOP 2023 video 2" src="https://player.vimeo.com/video/815277575?h=82f7c6aa78&badge=0&autopause=0&player_id=0&app_id=58479/embed" class="w-full h-full" frameborder="0" allow="autoplay; fullscreen"></iframe>
          </div>
        </div>
      </div>
    </div>
    <div class="relative">
      <div>
        <div class="pt-16 px-4 max-w-xl mx-auto sm:px-6 lg:max-w-none lg:mx-0 lg:px-0 text-center">
          <div>
            <h2 class="text-3xl font-bold text-blue">The Vow</h2>
          </div>
        </div>
      </div>
      <div class="lg:grid lg:grid-cols-2 lg:grid-flow-col-dense gap-8 xl:gap-24">
        <div class="mt-8 px-4 max-w-xl mx-auto sm:px-6 lg:max-w-none lg:mx-0 lg:px-0">
          <div class="relative video-container">
            <iframe title="PS - NSOP 2023 video 3 (Vow 1)" src="https://player.vimeo.com/video/815276185?h=a8c4032554&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" class="w-full h-full" frameborder="0" allow="autoplay; fullscreen"></iframe>
          </div>
        </div>
        <div class="mt-8 px-4 max-w-xl mx-auto sm:px-6 lg:max-w-none lg:mx-0 lg:px-0">
          <div class="relative video-container">
            <iframe title="PS - NSOP 2023 video 4 (Vow 2)" src="https://player.vimeo.com/video/815274956?h=005156fcbf&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" class="w-full h-full" frameborder="0" allow="autoplay; fullscreen"></iframe>
          </div>
        </div>
      </div>
      <div class="lg:grid lg:grid-cols-2 lg:grid-flow-col-dense gap-8 xl:gap-24">
        <div class="mt-8 px-4 max-w-xl mx-auto sm:px-6 lg:max-w-none lg:mx-0 lg:px-0">
          <div class="relative video-container">
            <iframe title="PS - NSOP 2023 video 5 (Vow 3)" src="https://player.vimeo.com/video/815273411?h=08a4950d5a&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" class="w-full h-full" frameborder="0" allow="autoplay; fullscreen"></iframe>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import LiveNow from '@/components/LiveNow.vue'
export default {
  components: {
    LiveNow
  },
  setup () {
    return {
    }
  }
}
</script>

<style scoped>
.video-container {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    height: 0;
}
.video-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
}
</style>
