import { reactive } from 'vue'
import axios from 'axios'
import store from '../store'
import responseHelper from '../helpers/response.helper'
import { DateTime } from 'luxon'

const eTicketsState = reactive({
  loading: false,
  eTickets: [],
  activeETickets: []
})

const eTicketState = reactive({
  loading: false,
  eTicket: undefined,
  profile: undefined
})
const attendState = reactive({
  loading: false,
  eTicket: undefined,
  profile: undefined,
  attendanceOptions: []
})

export function useETickets () {
  const searchETickets = async () => {
    eTicketsState.loading = true
    eTicketsState.eTickets = []
    eTicketsState.activeETickets = []
    const response = await axios.get('pub/etickets')
    eTicketsState.eTickets = response.data.result[0]
    eTicketsState.activeETickets = response.data.result[1]
    eTicketsState.loading = false
    return eTicketsState.eTickets
  }
  const searchETicket = async (req) => {
    eTicketState.loading = true
    eTicketState.eTicket = undefined
    const response = await axios.post('pub/content/eticket', req)
    if (response.data.result) {
      response.data.result.earlyBirdDateTime = DateTime.fromISO(response.data.result.earlyBirdEndsAt)
      const now = DateTime.now()
      if (response.data.result.earlyBirdDateTime > now) {
        response.data.result.isEarlyBird = true
      } else {
        response.data.result.isEarlyBird = false
      }
    }
    eTicketState.eTicket = response.data.result.eTicket
    eTicketState.profile = response.data.result.profile

    eTicketState.loading = false
    return eTicketState
  }
  const searchAttend = async (req) => {
    attendState.loading = true
    attendState.eTicket = undefined
    attendState.attendanceOptions = []
    const response = await axios.post('pub/content/attend', req)
    if (response.data.result) {
      response.data.result.earlyBirdDateTime = DateTime.fromISO(response.data.result.earlyBirdEndsAt)
      const now = DateTime.now()
      if (response.data.result.earlyBirdDateTime > now) {
        response.data.result.isEarlyBird = true
      } else {
        response.data.result.isEarlyBird = false
      }
    }
    attendState.eTicket = response.data.result.eTicket
    if (attendState.eTicket && attendState.eTicket.imageUrl) {
      attendState.eTicket.imageUrl = process.env.VUE_APP_BLOB_CONTAINER + '/images/' + attendState.eTicket.imageUrl
    }
    attendState.delegates = []
    attendState.childDelegates = []
    attendState.profile = response.data.result.profile
    attendState.attendanceOptions = response.data.result.attendanceOptions
    for (let index = 0; index < attendState.attendanceOptions.length; index++) {
      attendState.attendanceOptions[index].attend = false
    }

    attendState.loading = false
    return attendState
  }
  const searchAttendViaPaymentLink = async (req) => {
    attendState.loading = true
    attendState.eTicket = undefined
    attendState.attendanceOptions = []
    const response = await axios.post('pub/content/attend-link', req)
    if (response.data.result) {
      response.data.result.earlyBirdDateTime = DateTime.fromISO(response.data.result.earlyBirdEndsAt)
      const now = DateTime.now()
      if (response.data.result.earlyBirdDateTime > now) {
        response.data.result.isEarlyBird = true
      } else {
        response.data.result.isEarlyBird = false
      }
    }
    attendState.eTicket = response.data.result.eTicket
    if (attendState.eTicket && attendState.eTicket.imageUrl) {
      attendState.eTicket.imageUrl = process.env.VUE_APP_BLOB_CONTAINER + '/images/' + attendState.eTicket.imageUrl
    }
    attendState.delegates = []
    attendState.childDelegates = []
    attendState.profile = response.data.result.profile
    attendState.attendanceOptions = response.data.result.attendanceOptions
    for (let index = 0; index < attendState.attendanceOptions.length; index++) {
      attendState.attendanceOptions[index].attend = false
    }

    attendState.loading = false
    return attendState
  }

  const checkoutETicketState = reactive({
    email: store.getters.getEmail,
    firstName: store.getters.firstName,
    lastName: store.getters.lastName,
    executing: false,
    error: responseHelper.noError,
    result: undefined
  })
  const checkoutETicket = async (eTicketId) => {
    if (!checkoutETicketState.firstName || !checkoutETicketState.lastName) {
      checkoutETicketState.error = responseHelper.createError('Details needed', ['First name and last name are needed to update your account details for eTickets'])
    } else {
      if (!checkoutETicketState.executing) {
        checkoutETicketState.executing = true
        checkoutETicketState.result = undefined
        checkoutETicketState.error = responseHelper.noError
        try {
          const response = await axios.post('checkout/eticket', {
            eTicketId: eTicketId,
            firstName: checkoutETicketState.firstName,
            lastName: checkoutETicketState.lastName
          })
          checkoutETicketState.result = response.data.result
        } catch (error) {
          checkoutETicketState.error = responseHelper.createError('Invalid request', ['The details submitted are not valid. Please check and complete the fields needed.'])
        }
        checkoutETicketState.executing = false
      }
    }

    return checkoutETicketState.result
  }

  const checkoutAttendState = reactive({
    email: store.getters.getEmail,
    firstName: store.getters.firstName,
    lastName: store.getters.lastName,
    tel: '',
    country: 'GB',
    addressOne: '',
    addressTwo: '',
    townCity: '',
    county: '',
    postCode: '',
    heardFrom: 0,
    attendanceNotes: '',
    executing: false,
    error: responseHelper.noError,
    result: undefined,
    quantity: 0,
    childQuantity: 0,
    delegates: [],
    childDelegates: [],
    attendanceOptions: [],
    donationType: '',
    isPartner: '',
    paymentLinkId: undefined
  })
  const checkoutAttend = async (eTicket) => {
    if (!checkoutAttendState.firstName || !checkoutAttendState.lastName) {
      checkoutAttendState.error = responseHelper.createError('Details needed', ['First name and last name are needed for the order information'])
    } else if (eTicket.donation && !checkoutAttendState.donationType) {
      checkoutAttendState.error = responseHelper.createError('Details needed', ['Please let us know if you would like to support Prayer Storm'])
    } else if (!checkoutAttendState.heardFrom || (parseInt(checkoutAttendState.heardFrom) < 1)) {
      checkoutAttendState.error = responseHelper.createError('Details needed', ['Please let us know where you heard about this event'])
    } else {
      // check the attendances have atleast one session selected
      var attendValid = false
      if (eTicket.sessionAttendance) {
        if (checkoutAttendState.attendanceOptions) {
          for (let index = 0; index < checkoutAttendState.attendanceOptions.length; index++) {
            if (checkoutAttendState.attendanceOptions[index].attend) {
              attendValid = true
            }
          }
        }
      } else {
        attendValid = true
      }

      if (!attendValid) {
        checkoutAttendState.error = responseHelper.createError('Session Attendance Required', ['Please select the sessions that you would like to attend'])
      } else {
        if (!checkoutAttendState.executing) {
          checkoutAttendState.executing = true
          checkoutAttendState.result = undefined
          checkoutAttendState.error = responseHelper.noError

          var donationType = 0
          if (checkoutAttendState.donationType !== '') {
            donationType = parseInt(checkoutAttendState.donationType)
          }

          var isPartner = checkoutAttendState.isPartner === '1'

          try {
            const response = await axios.post('checkout/attend', {
              eTicketId: eTicket.id,
              email: checkoutAttendState.email,
              firstName: checkoutAttendState.firstName,
              lastName: checkoutAttendState.lastName,
              tel: checkoutAttendState.tel,
              country: checkoutAttendState.country,
              addressOne: checkoutAttendState.addressOne,
              addressTwo: checkoutAttendState.addressTwo,
              townCity: checkoutAttendState.townCity,
              county: checkoutAttendState.county,
              postCode: checkoutAttendState.postCode,
              heardFrom: checkoutAttendState.heardFrom,
              attendanceNotes: checkoutAttendState.attendanceNotes,
              quantity: checkoutAttendState.quantity,
              childQuantity: checkoutAttendState.childQuantity,
              delegates: checkoutAttendState.delegates,
              childDelegates: checkoutAttendState.childDelegates,
              attendanceOptions: checkoutAttendState.attendanceOptions,
              donationType: donationType,
              isPartner: isPartner,
              paymentLinkId: checkoutAttendState.paymentLinkId
            })
            checkoutAttendState.result = response.data.result
          } catch (error) {
            checkoutAttendState.error = responseHelper.getMessage(error)
          }
          checkoutAttendState.executing = false
        }
      }
    }

    return checkoutAttendState.result
  }

  return {
    // global states
    eTicketsState,
    eTicketState,
    attendState,

    // local states
    checkoutAttendState,
    checkoutETicketState,

    // functions
    searchETickets,
    searchETicket,
    searchAttend,
    searchAttendViaPaymentLink,
    checkoutETicket,
    checkoutAttend
  }
}
