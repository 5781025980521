<template>
  <div>
    <div class="relative bg-gray-900">
      <div class="absolute inset-0 bg-cover" aria-hidden="true" style="background-image: linear-gradient(to left bottom, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8));" />
      <!-- <img src="" alt="" class="absolute inset-0 -z-10 h-full w-full object-cover" /> -->
      <div class="relative px-6 lg:px-8">
        <div class="mx-auto max-w-4xl py-12 sm:py-24">
          <div class="text-center">
            <h1 class="text-4xl font-bold tracking-tight text-white sm:text-5xl">Announcement</h1>
            <p class="mt-6 text-lg leading-8 text-white font-bold">Church & Ministry Leaders Conference</p>
            <p class="mt-3 text-lg leading-8 text-white">16th March 2024 (10am & 2pm - UK Time) Venue:</p>
            <p class="mt-3 text-lg leading-8 text-white font-bold">The Message Trust, Harper Road, Sharston, Manchester, M22 4RG, UK</p>

            <div class="mt-6">
              <div style="padding:56.25% 0 0 0;position:relative;"><iframe src="https://player.vimeo.com/video/919911145?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" style="position:absolute;top:0;left:0;width:100%;height:100%;" title="Prayer Storm Conf. 2024 Update"></iframe></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
