import { createApp } from 'vue'
import { DateTime } from 'luxon'
import axios from 'axios'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/tailwind.css'
import { useCurrencies } from './composables/useCurrencies'
const { currencyIndex } = useCurrencies()

axios.interceptors.request.use(
  async config => {
    // Set the base url for api requests
    config.baseURL = process.env.VUE_APP_API_URL
    // Set the Auth token
    if (store.state.token) {
      config.headers = {
        Authorization: store.state.token
      }
    }
    config.rawData = config.data
    return config
  }, (error) => {
    return Promise.reject(error)
  })

axios.interceptors.response.use(response => response, error => {
  const originalRequest = error.config
  if (error.response.status === 401 && (originalRequest.url.indexOf('auth/refresh') >= 0)) {
    store.dispatch('signOut').then(() => {
      router.push('/sign-in')
    })
    return Promise.reject(error)
  } else {
    if (error.response.status === 401 && store.getters.isLoggedIn && !originalRequest._retry) {
      originalRequest._retry = true
      return store.dispatch('refreshToken')
        .then(_ => {
          originalRequest.data = originalRequest.rawData
          originalRequest.headers.Authorization = store.state.token
          return axios.request(originalRequest)
        })
    }
    return Promise.reject(error)
  }
})

const app = createApp(App)
  .use(store)
  .use(router)

const DATETIME_MED_WITH_TIMEZONE = { ...DateTime.DATETIME_MED, timeZoneName: 'short' }

app.config.globalProperties.$filters = {
  addressFormat (value) {
    if (value) {
      return value.replace(/(?:\r\n|\r|\n)/g, ', ')
    }
    return ''
  },
  arrayItemsExist (arrayItems) {
    return (arrayItems && arrayItems.length > 0)
  },
  dateMed (value) {
    const dt = DateTime.fromISO(value)
    return dt.toLocaleString(DateTime.DATE_MED)
  },
  dateTimeMed (value) {
    const dt = DateTime.fromISO(value)
    return dt.toLocaleString(DATETIME_MED_WITH_TIMEZONE)
  },
  dateLon (value) {
    const dt = DateTime.fromISO(value)
    return dt.toLocaleString(DateTime.DATETIME_FULL)
  },
  contentAddress (value) {
    if (value) {
      return value.replace(/(?:\r\n|\r|\n)/g, ', ')
    }
    return ''
  },
  contentDescription (value) {
    if (value) {
      return value.replace(/(?:\r\n|\r|\n)/g, '<br>')
    }
    return ''
  },
  contentStatus (value) {
    let returnValue = 'Unknown'
    switch (value) {
      case 0 :
        returnValue = 'Disabled'
        break
      case 1 :
        returnValue = 'Coming Soon'
        break
      case 2 :
        returnValue = 'LIVE NOW'
        break
      case 3 :
        returnValue = 'Finished'
        break
      case 4 :
        returnValue = 'On Demand'
        break
    }
    return returnValue
  },
  stripeStatus (value) {
    switch (value) {
      case 'canceled' :
        return 'Cancelled'
      case 'past_due' :
        return 'Overdue'
    }
    return value
  },
  stripeCurrencySymbol (value) {
    let returnValue = value.toLowerCase()
    switch (returnValue) {
      case 'gbp' :
        returnValue = '£'
        break
      case 'usd' :
        returnValue = '$'
        break
    }
    return returnValue
  },
  stripeUnitAmountString (currency, unit) {
    if (currencyIndex[currency.toUpperCase()].isZero) {
      return unit
    }
    return (unit / 100).toFixed(2)
  }
}

app.mount('#app')
