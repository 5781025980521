import { reactive } from 'vue'
import axios from 'axios'

const contentMessagesState = reactive({
  loading: false,
  messages: [],
  newMessage: '',
  sending: false
})

const resetContentMessagesState = () => {
  contentMessagesState.loading = false
  contentMessagesState.messages = []
  contentMessagesState.newMessage = ''
  contentMessagesState.sending = false
}

export function useContentMessages (reset) {
  if (reset) {
    resetContentMessagesState()
  }

  const loadContentMessages = async (req) => {
    contentMessagesState.loading = true
    try {
      const response = await axios.post('contentMessages/search', req)
      if (response.data.result.messages && response.data.result.messages.length > 0) {
        response.data.result.messages.forEach(message => {
          if (message.p === 1 && message.a) {
            message.a = `${process.env.VUE_APP_BLOB_CONTAINER}/images/${message.a}`
          }
        })
        contentMessagesState.messages = response.data.result.messages
      }
      contentMessagesState.loading = false
      return response.data.result
    } catch (err) {
      contentMessagesState.loading = false
      throw err
    }
  }

  const sendContentMessage = async (req) => {
    contentMessagesState.sending = true
    try {
      const response = await axios.post('contentMessages', req)
      contentMessagesState.message = ''
      contentMessagesState.sending = false
      return response.data.result
    } catch (err) {
      contentMessagesState.sending = false
      throw err
    }
  }

  return {
    contentMessagesState,
    loadContentMessages,
    sendContentMessage
  }
}
