<template>
<main>
  <LiveNow></LiveNow>
  <div class="bg-black bg-contain bg-no-repeat bg-right-top" style="background-image:linear-gradient(to left bottom, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2), rgba(42, 131, 161, 0.4), rgba(42, 131, 161, .6));">
    <div class="max-w-7xl mx-auto py-24 px-4 sm:px-6 lg:px-8">
      <div class="sm:flex sm:flex-col align-center">
        <h1 class="text-3xl font-extrabold text-white text-center">Mighty Ones</h1>
      </div>
    </div>
  </div>
  <div class="flex flex-col justify-center py-12 sm:px-6 lg:px-8">
    <div class="sm:mx-auto sm:w-full sm:max-w-3xl">
      <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">

        <p class="lyrics">Bless the Lord, o mighty ones<br>
                                                        Bless the Lord, you heavenly hosts<br>
                                                        Bless the Lord, all you, his angels<br>
                                                        Let all the earth be his dominion</p>

        <p class="lyrics">C’mon, c’mon and bless him<br>
                                                        C’mon and praise his name</p>

        <p class="lyrics">For the Lord delights in showing mercy</p>

        <p class="lyrics">When I move my body, when I move my feet<br>
                                                        When I open my mouth then the darkness flees</p>

        <p class="lyrics">Worship is my warfare<br>
                                                        My praise is a weapon<br>
                                                        Worship is my warfare<br>
                                                        My dance is a weapon<br>
                                                        Worship is my warfare<br>
                                                        My shout is a weapon</p>

        <p class="composer">Randy Martinez<br>
                                                        © 2014 Furnace Media Publishing / Watershed Music Publishing (BMI) (adm. by Watershed Music Group). All rights reserved. Used by permission.</p>

      </div>
    </div>
  </div>
</main>

</template>
<script>
import LiveNow from '@/components/LiveNow.vue'
export default {
  components: {
    LiveNow
  },
  setup () {
    return {
    }
  }
}
</script>

<style scoped>
.lyrics {
  @apply mt-8 font-medium text-gray-700
}
.composer {
  @apply mt-8 text-sm mb-10 text-gray-700
}
</style>
