import { helpers, minLength, maxLength, minValue, maxValue, required, integer } from '@vuelidate/validators'

export const required$ = helpers.withMessage('This is required', required)
export const integer$ = helpers.withMessage('Number required', integer)
export const fieldMinLength$ = (min) => helpers.withMessage(
  ({ $params }) => `min. length (${$params.min})`, minLength(min)
)
export const fieldMaxLength$ = (max) => helpers.withMessage(
  ({ $params }) => `max. length (${$params.max})`, maxLength(max)
)
export const fieldMinValue$ = (min) => helpers.withMessage(
  ({ $params }) => `min. value (${$params.min})`, minValue(min)
)
export const fieldMaxValue$ = (max) => helpers.withMessage(
  ({ $params }) => `max. value (${$params.max})`, maxValue(max)
)

export const phoneMinLength$ = (min) => helpers.withMessage(
  ({ $params }) => `Phone number should contain ${$params.min} digits.`, minLength(min)
)
