<template>
<div class="rounded-xl bg-yellow-300 p-4 mb-2 border border-yellow-400" v-show="message">
  <div class="flex">
    <div class="flex-shrink-0">
      <InformationCircleIcon class="h-5 w-5 text-black" />
    </div>
    <div class="ml-3">
      <h3 class="text-sm font-medium text-black">
        {{message}}
      </h3>
    </div>
  </div>
</div>
</template>

<script>
import { InformationCircleIcon } from '@heroicons/vue/20/solid'
export default {
  components: {
    InformationCircleIcon
  },
  props: [
    'message'
  ]
}
</script>

<style scoped>
</style>
