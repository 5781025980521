<template>
<section aria-labelledby="deleteHeading">
  <form id="delete-form" @submit.prevent="deleteAccount">
    <div class="shadow sm:rounded-md sm:overflow-hidden">
      <div class="bg-white py-6 px-4 sm:p-6">
        <div>
          <h2 id="deleteHeading" class="text-lg leading-6 font-medium text-red">Delete Account</h2>
          <p class="mt-1 text-sm text-gray-500">To delete your account, please confirm your password below.</p>
          <p class="mt-1 text-sm text-gray-500">If you have any active subscriptions (i.e. Gold Membership or Partnership) that you also wish to discontinue, the please cancel them first.</p>
          <p class="mt-1 text-sm text-gray-500">Your account will become inaccessible, but will have around 30 days to contact tech@prayerstorm.org should you change your mind. After which your account data will be removed from our systems.</p>
        </div>

        <div class="mt-6 grid grid-cols-4 gap-6">
          <div class="col-span-4 sm:col-span-2">
            <label for="currentPassword" class="block text-sm font-medium text-gray-700">Your Password</label>
            <input v-model="deleteForm.password" type="password" name="password" id="password" autocomplete="off" class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-light-blue-500 focus:border-light-blue-500 sm:text-sm" />
          </div>
        </div>

        <ErrorMessage :formError="formError" class="mt-6"></ErrorMessage>
        <SuccessMessage :successMessage="successMessage" class="mt-6"></SuccessMessage>
      </div>
      <div class="px-4 py-3 bg-gray-50 text-right sm:px-6">
        <button type="submit" :disabled="deleteFormSubmitted || loadData" class="disabled:opacity-50 bg-red-500 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue">
          Delete Account
        </button>
      </div>
    </div>
  </form>
</section>
</template>

<script>
import axios from 'axios'
import router from '@/router'
import store from '@/store'
import responseHelper from '@/helpers/response.helper'
import SuccessMessage from '@/components/SuccessMessage.vue'
import ErrorMessage from '@/components/ErrorMessage.vue'

export default {
  components: {
    SuccessMessage,
    ErrorMessage
  },
  data: () => ({
    successMessage: '',
    formError: responseHelper.noError,
    deleteFormSubmitted: false,
    loadData: false,
    loadError: responseHelper.noError,
    deleteForm: {
      password: ''
    }
  }),
  methods: {
    deleteAccount () {
      this.successMessage = ''
      this.deleteFormSubmitted = true
      this.formError = responseHelper.noError
      axios.post('account/delete', this.deleteForm)
        .then((response) => {
          if (response?.data?.success && response?.data?.result) {
            store.dispatch('signOut').then(() => {
              router.push('/')
            })
          } else {
            this.successMessage = ''
            this.formError = responseHelper.unexpectedResult
          }
          // Handle the response
        }).catch(() => {
          this.formError = responseHelper.createError('Invalid request', ['Please check your password is correct.'])
        }).finally(() => {
          this.deleteFormSubmitted = false
        })
    }
  }
}
</script>
