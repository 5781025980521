<template>
<main>
  <LiveNow></LiveNow>
  <div class="max-w-7xl mx-auto pt-10 px-4 sm:px-6 lg:px-8">
    <div class="flex justify-between">
      <h2 class="text-3xl font-bold text-blue">Latest Videos</h2>
    </div>
    <div class="pt-4 grid grid-cols-2 gap-3 md:grid-cols-3 lg:grid-cols-4">
      <div class="flex flex-col" v-for="item in latestVideosState.content.latestVideos" :key="item.guid">
        <div @click.prevent="goContent(item, router, route)" class="relative bg-card-img cursor-pointer" v-bind:style="{ 'backgroundImage': getContentImage(item) }">
          <div class="absolute inset-x-0 bottom-0 text-white pl-3 pb-2">
            &nbsp;
          </div>
        </div>
        <div>
          <div class="pt-2">
            <a @click.prevent="goContent(item, router, route)" href="#" class="font-semibold text-blue block text-ellipsis-2 hover:underline">{{item.title}}</a>
          </div>
          <div>
            <span class="text-base text-gray-400">{{$filters.dateMed(item.publishedAt)}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <MembershipList class="mt-20"></MembershipList>
</main>
</template>

<script>
import { onMounted } from 'vue'
import { useContent } from '@/composables/useContent'
import MembershipList from '@/components/MembershipList.vue'
import LiveNow from '@/components/LiveNow.vue'
import { useRouter, useRoute } from 'vue-router'
export default {
  components: {
    MembershipList,
    LiveNow
  },
  setup () {
    const router = useRouter()
    const route = useRoute()
    const { latestVideosState, searchLatestVideos, getContentImage, getContentLink, goContent } = useContent()

    onMounted(() => {
      searchLatestVideos()
    })

    return {
      router,
      route,
      getContentLink,
      getContentImage,
      goContent,
      latestVideosState
    }
  }
}
</script>
