const storeKeys = {
  TOKEN: 'ps_token',
  PROFILE: 'ps_profile'
}

// The order and number of these records should never change.
// Only additions should be added in continuation matched to server
const dataTypes = {
  USER: 1,
  EVENT: 2,
  SCHOOL: 3,
  AUDIO: 4,
  MUSIC: 5,
  POST: 6,
  CATEGORY: 7,
  CONFERENCE: 8,
  YOUTUBE_VIDEO: 9,
  AZURE_VIDEO: 10,
  MEMBERSHIP: 11,
  ETICKET: 12,
  PROMOTION: 13,
  PRAYER_WALK: 14,
  PRAYER_SESSION: 15,
  PRAYER_CIRCLE: 16,
  DEVICE: 17,
  REPORT: 18,
  INPERSON: 19
}

const ticketTypes = {
  ETICKET: dataTypes.ETICKET,
  INPERSON: dataTypes.INPERSON
}

export {
  storeKeys,
  dataTypes,
  ticketTypes
}
