<template>
<div class="bg-black" style="background-image:linear-gradient(to left bottom, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6), rgba(42, 131, 161, 0.4), rgba(42, 131, 161, .6)), url('/img/banners/ps-banner-4.jpg');">
  <div class="max-w-7xl mx-auto py-24 px-4 sm:px-6 lg:px-8">
    <div class="sm:flex sm:flex-col align-center">
      <h1 class="text-3xl font-extrabold text-white text-center">Invite Prayer Storm</h1>
      <p class="mt-5 text-xl text-gray-400 text-center">If you would like to invite James Aladiran, Rebecca Aladiran or Matt Varah Wilson then please select below.</p>
    </div>
    <div class="mt-12 space-y-4 sm:mt-16 sm:space-y-0 sm:grid sm:grid-cols-2 md:grid-cols-3 sm:gap-6 lg:max-w-4xl lg:mx-auto">
      <div class="shadow-sm">
        <div class="p-6 bg-cover border border-gray-700 rounded-lg bg-right" :style="{ 'background-image': 'linear-gradient(to left bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0), rgba(0, 0, 0, 0), rgba(42, 131, 161, 0.2), rgba(42, 131, 161, .6)), url(/img/invite/james-alt.jpg)' }">
          <div class="pt-96">
            <a href="https://docs.google.com/forms/d/e/1FAIpQLSf9E71g2TuX5u-RzWH-BH_hV3lrApyVudcg3bxwR1TBCPMH6Q/viewform" target="_blank" class="mt-8 block w-full bg-blue border-2 border-blue rounded-md py-2 text-sm font-semibold text-white text-center hover:border-white flex justify-center">Invite James <ArrowRightCircleIcon class="flex-shrink-0 h-5 w-5 ml-2" aria-hidden="true" /></a>
          </div>
        </div>
      </div>
      <div class="shadow-sm">
        <div class="p-6 bg-cover border border-gray-700 rounded-lg bg-right" :style="{ 'background-image': 'linear-gradient(to left bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0), rgba(0, 0, 0, 0), rgba(42, 131, 161, 0.2), rgba(42, 131, 161, .6)), url(/img/invite/rebecca-alt.jpg)' }">
          <div class="pt-96">
            <a href="https://docs.google.com/forms/d/e/1FAIpQLScoe_vWTvf2Ql1qL92hu5j8ip5hnO0LffWhFO9WbmVY5li9Nw/viewform" target="_blank" class="mt-8 block w-full bg-blue border-2 border-blue rounded-md py-2 text-sm font-semibold text-white text-center hover:border-white flex justify-center">Invite Rebecca <ArrowRightCircleIcon class="flex-shrink-0 h-5 w-5 ml-2" aria-hidden="true" /></a>
          </div>
        </div>
      </div>
      <div class="shadow-sm">
        <div class="p-6 bg-cover border border-gray-700 rounded-lg bg-right" :style="{ 'background-image': 'linear-gradient(to left bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0), rgba(0, 0, 0, 0), rgba(42, 131, 161, 0.2), rgba(42, 131, 161, .6)), url(/img/invite/matt.jpg)' }">
          <div class="pt-96">
            <a href="https://docs.google.com/forms/d/e/1FAIpQLSd3CvYvV6kKEfaWAU5X-nLQksX409Bd6ar6rv1LqsYePfNP3g/viewform" target="_blank" class="mt-8 block w-full bg-blue border-2 border-blue rounded-md py-2 text-sm font-semibold text-white text-center hover:border-white flex justify-center">Invite Matt <ArrowRightCircleIcon class="flex-shrink-0 h-5 w-5 ml-2" aria-hidden="true" /></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import { defineComponent } from 'vue'
import { ArrowRightCircleIcon } from '@heroicons/vue/20/solid'
export default defineComponent({
  name: 'InviteList',
  components: {
    ArrowRightCircleIcon
  },
  setup () {
    return {
    }
  }
})
</script>

<style scoped>
</style>
