<template>
<div class="lg:grid lg:grid-cols-12">
  <div class="lg:col-span-8">
    <iframe  v-if="contentType === 'audio'" height="200px" width="100%" frameborder="no" scrolling="no" seamless :src="src"></iframe>
    <div class="youtube-container" v-if="contentType === 'youtube'">
      <YouTube
        :src="src"
        @ready="onReady"
        ref="youtube"
        width="100%"
        height="100%"
        style="position:absolute; top:0; bottom:0; right:0; left:0;"
      />
    </div>
    <div v-show="contentType === 'vimeo'" id="vimeoReplay"></div>
    <div v-show="contentType === 'azure'" style="padding:56.25% 0 0 0;position:relative;">
      <video id="vid1" ref="ampVideo" class="azuremediaplayer amp-default-skin" autoplay style="position:absolute;top:0;left:0;width:100%;height:100%;">
        <p class="amp-no-js">To view this video please enable JavaScript, and consider upgrading to a web browser that supports HTML5 video</p>
      </video>
    </div>

    <!-- <iframe :src="iframeSrc" id="yt-player" type="text/html" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;"></iframe> -->
    <!-- <iframe src="https://vimeo.com/event/191149/embed/abb731e7f0" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;"></iframe> -->

    <div v-if="contentDetailState.content.item" class="bg-white hidden lg:block lg:border-r-2 border-gray-100">
      <div class="p-6">
        <div>
          <div>
            <div>
              <div class="md:flex md:items-center md:justify-between md:space-x-4 xl:border-b xl:pb-6">
                <div>
                  <h1 class="text-xl font-bold text-gray-900">{{contentDetailState.content.item.title}}</h1>
                  <p class="mt-2 text-sm text-gray-500">
                    {{$filters.dateMed(contentDetailState.content.item.publishedAt)}}
                  </p>
                </div>
              </div>
              <div class="py-3 xl:pt-6 xl:pb-0">
                <h2 class="sr-only">Description</h2>
                <div class="prose max-w-none">
                  <p v-html="$filters.contentDescription(contentDetailState.content.item.description)">
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="h-96 lg:col-span-4 lg:h-screen">
    <ChatContent @sendChatMessage="sendChatMessage" :state="contentMessagesState" :time="time" ref="chatComments"></ChatContent>
  </div>

  <div v-if="contentDetailState.content.item" class="bg-white lg:hidden border-gray-100">
    <div class="p-6">
      <div>
        <div>
          <div>
            <div class="md:flex md:items-center md:justify-between md:space-x-4 xl:border-b xl:pb-6">
              <div>
                <h1 class="text-xl font-bold text-gray-900">{{contentDetailState.content.item.title}}</h1>
                <p class="mt-2 text-sm text-gray-500">
                  {{$filters.dateMed(contentDetailState.content.item.publishedAt)}}
                </p>
              </div>
            </div>
            <div class="py-3 xl:pt-6 xl:pb-0">
              <h2 class="sr-only">Description</h2>
              <div class="prose max-w-none">
                <p v-html="$filters.contentDescription(contentDetailState.content.item.description)">
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import { defineComponent, onMounted, onUnmounted, ref } from 'vue'
import { useRoute } from 'vue-router'
import ChatContent from '@/components/ChatContent.vue'
import YouTube from 'vue3-youtube'
import { useContent } from '@/composables/useContent'
import { useContentMessages } from '@/composables/useContentMessages'
import store from '../store'

export default defineComponent({
  name: 'Content',
  components: {
    ChatContent,
    YouTube
  },
  setup () {
    const route = useRoute()
    const contentGuid = ref(route.params.guid)
    const contentType = ref(route.params.type)
    const { contentDetailState, searchContentDetail } = useContent()
    const { contentMessagesState, sendContentMessage, loadContentMessages } = useContentMessages(true)
    const ampVideo = ref(null)
    const vimVideo = ref(null)

    const youtube = ref(null)
    const chatComments = ref(null)
    const time = ref(0)

    const clearAmp = () => {
      if (window.ampPlayer) {
        window.ampPlayer.pause()
        window.ampPlayer.dispose()
        window.ampPlayer = undefined
      }
    }

    const clearVim = () => {
      if (window.vimPlayer) {
        window.vimPlayer.pause()
        window.vimPlayer.destroy()
        window.vimPlayer = undefined
      }
    }

    let contentSrc
    if (route.params.type === 'audio') {
      contentSrc = `https://player.simplecast.com/${route.params.guid}?dark=false`
    } else if (route.params.type === 'youtube') {
      contentSrc = `https://www.youtube.com/watch?v=${route.params.guid}`
    } else if (route.params.type === 'event' || route.params.type === 'session') {
      // loaded onMounted
    }

    const src = ref(contentSrc)
    const title = ref(contentSrc)

    const viewingInfo = async () => {
      const info = {
        player: 'unknown',
        time: -1000
      }
      if (window.vimPlayer) {
        info.player = 21 // VIM VID
        info.time = await window.vimPlayer.getCurrentTime()
      } else if (window.ampPlayer) {
        info.player = 10 // AMP VID
        info.time = window.ampPlayer.currentTime()
      } else if (youtube.value) {
        info.player = 9 // YT VID
        info.time = youtube.value.getCurrentTime()
      } else if (contentType.value === 'audio') {
        info.player = 4 // AUDIO
        info.time = 0
      }

      return info
    }

    const timePoll = async () => {
      const info = await viewingInfo()
      if (time.value !== info.time) {
        time.value = info.time
        if (chatComments.value) {
          chatComments.value.scrollToBase()
        }
      }
    }

    // let fromSeconds = (0 - (60 * 15))
    const timePolling = setInterval(timePoll, 1000)

    const onReady = () => {
      console.log('\\0// we are ready')
    }

    onMounted(() => {
      const searchReq = {
        type: contentType.value,
        guid: contentGuid.value,
        expectUser: store.getters.isLoggedIn
      }
      searchContentDetail(searchReq, true)
        .then((result) => {
          if ((route.params.type === 'session' && contentDetailState.content.item.vimeo)) {
            contentType.value = 'vimeo'
            clearVim()
            src.value = `https://player.vimeo.com/video/${contentDetailState.content.item.videoUrl}`
            title.value = contentDetailState.content.item.title

            // eslint-disable-next-line no-undef
            window.vimPlayer = new Vimeo.Player(
              'vimeoReplay',
              {
                id: src.value,
                autoplay: true,
                responsive: true
              }
            )
          } else if (route.params.type === 'event' || (route.params.type === 'session' && contentDetailState.content.item.videoUrl)) {
            contentType.value = 'azure'
            clearAmp()
            // eslint-disable-next-line no-undef
            window.ampPlayer = amp(
              ampVideo.value,
              { /* Options */
                techOrder: ['azureHtml5JS', 'flashSS', 'html5FairPlayHLS', 'silverlightSS', 'html5'],
                // eslint-disable-next-line quote-props
                'nativeControlsForTouch': false,
                autoplay: true,
                controls: true,
                // width: '640',
                // height: '400',
                fluid: true,
                poster: ''
              },
              function () {
                // add an event listener
                this.addEventListener('ended', function () {
                  console.log('Video Finished!')
                })
              }
            )

            window.ampPlayer.src([{
              src: contentDetailState.content.item.videoUrl,
              type: 'application/vnd.ms-sstr+xml'
            }])
          } else if (route.params.type === 'session' && contentDetailState.content.item.youtubeGuid) {
            contentType.value = 'youtube'
            src.value = `https://www.youtube.com/watch?v=${contentDetailState.content.item.youtubeGuid}`
          }

          if (store.getters.isLoggedIn) {
            loadContentMessages({
              dataType: contentDetailState.content.item.dataType,
              recordId: contentDetailState.content.item.id
            }).catch(() => {
              alert('Failed to load comments, please try again')
            })
          }
        }).catch(() => {
          alert('Failed to load the screen, please refresh and try again.')
        })
    })

    onUnmounted(() => {
      if (youtube.value) {
        youtube.value.destroy()
      }
      if (timePolling) {
        clearInterval(timePolling)
      }
    })

    const sendChatMessage = async (message) => {
      if (contentDetailState.content.item && !contentMessagesState.sending) {
        const info = await viewingInfo()
        sendContentMessage({
          message: message,
          type: contentType.value,
          guid: contentGuid.value,
          dataType: contentDetailState.content.item.dataType,
          recordId: contentDetailState.content.item.id,
          player: info.player,
          time: info.time
        }).then((result) => {
          if (result.p === 1 && result.a) {
            result.a = `${process.env.VUE_APP_BLOB_CONTAINER}/images/${result.a}`
          }
          if (contentMessagesState.messages && contentMessagesState.messages.length > 0) {
            let index
            for (index = 0; index < contentMessagesState.messages.length; index++) {
              if (contentMessagesState.messages[index].s > result.s) {
                break
              }
            }
            contentMessagesState.messages.splice(index, 0, result)
          } else {
            contentMessagesState.messages.push(result)
          }
        }).catch(() => {
          alert('Failed to send comment, please try again')
        })
      }
    }

    return {
      time,
      contentGuid,
      contentType,
      src,
      youtube,
      onReady,
      contentDetailState,
      sendChatMessage,
      contentMessagesState,
      chatComments,
      ampVideo,
      vimVideo
    }
  }
})
</script>

<style scoped>
.youtube-container {
  padding: 56.25% 0 0 0;
  position: relative;
  width:100%;
}
</style>
