import { reactive } from 'vue'
import axios from 'axios'
import responseHelper from '@/helpers/response.helper'

export function useAuth () {
  const resetPasswordState = reactive({
    key: '',
    newPassword: '',
    newPasswordConfirmation: '',
    resetSubmitted: false,
    load: {
      executing: true,
      data: undefined,
      error: responseHelper.noError
    },
    reset: {
      executing: false,
      data: undefined,
      error: responseHelper.noError,
      complete: false,
      successMessage: ''
    }
  })
  const resetPasswordCheck = async (req) => {
    try {
      resetPasswordState.load.error = responseHelper.noError
      resetPasswordState.load.executing = true
      const response = await axios.post('auth/resetpasswordcheck', req)
      resetPasswordState.load.data = response.data
    } catch (error) {
      const statusCode = responseHelper.getStatusCode(error)
      if (statusCode === 404) {
        resetPasswordState.load.error = responseHelper.createError('Invalid link', [
          'Links are only valid for 2 hours',
          'Each link can only be used once'
        ])
      } else {
        resetPasswordState.load.error = responseHelper.getMessage(error)
      }
    }
    resetPasswordState.load.executing = false
  }
  const resetPasswordConfirm = async (req) => {
    try {
      resetPasswordState.reset.error = responseHelper.noError
      resetPasswordState.reset.executing = true
      const response = await axios.post('auth/resetpasswordconfirm', req)
      if (response.status !== 200) {
        throw Error('Unexpected result, please try again')
      }
      resetPasswordState.reset.complete = true
      resetPasswordState.reset.successMessage = 'Your password has been successfully updated. You can now try to sign in.'
      resetPasswordState.newPassword = ''
      resetPasswordState.newPasswordConfirmation = ''
      resetPasswordState.reset.data = response.data
    } catch (error) {
      resetPasswordState.reset.error = responseHelper.getMessage(error)
    }
    resetPasswordState.reset.executing = false
  }

  return {
    resetPasswordState,
    resetPasswordCheck,
    resetPasswordConfirm
  }
}
