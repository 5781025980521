<template>
  <WeDeclareWarVideo></WeDeclareWarVideo>

 <div class="bg-gray-100">
    <div class="max-w-2xl mx-auto py-6 px-4 md:py-12 sm:px-6 lg:max-w-7xl lg:px-8 bg-white">
      <div class="lg:grid lg:grid-cols-2 lg:gap-x-12 lg:items-start">
        <!-- Product info -->
        <div class="mt-6 px-4 sm:px-0 lg:mt-0">
          <a class="relative card-zoom cursor-pointer" @click.prevent="showVideo">
            <div class="card-zoom-image" style="background-image: url('/img/resources/we-declare-war-trailer.jpg');"></div>
            <div class="card-zoom-text flex">
              <h1 class="m-auto"><PlayIcon class="w-20 h-20 text-white" /></h1>
            </div>
          </a>

          <form class="mt-6 mb-10">
            <div class="mt-10 grid grid-cols-2 gap-4">
              <a href="https://itunes.apple.com/gb/album/we-declare-war/id1230954553" class="max-w-xs bg-blue border border-transparent rounded-md py-3 px-8 flex items-center justify-center text-base font-medium text-white hover:bg-blue-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-blue-light sm:w-full text-center">Buy on iTunes</a>

              <a href="https://www.amazon.co.uk/gp/product/B071YP2ZRL/ref=dm_ws_sp_ps_dp" class="max-w-xs bg-blue border border-transparent rounded-md py-3 px-8 flex items-center justify-center text-base font-medium text-white hover:bg-blue-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-blue-light sm:w-full text-center">Buy on Amazon</a>

              <a href="https://prayerstormshop.bigcartel.com/" class="max-w-xs bg-blue border border-transparent rounded-md py-3 px-8 flex items-center justify-center text-base font-medium text-white hover:bg-blue-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-blue-light sm:w-full">Buy Now</a>

              <div class="text-center text-sm italic flex text-gray-500 font-bold">
                <span class="m-auto">Buy via our shop to order a hard copy.</span>
              </div>
            </div>
          </form>
        </div>

        <div class="mt-6 px-4 sm:px-0 lg:mt-0">
          <h1 class="text-3xl font-extrabold tracking-tight text-blue">{{ product.name }}</h1>
          <div class="mt-6">
            <h3 class="sr-only">Description</h3>

            <div class="text-base text-gray-700 space-y-6" v-html="product.description" />
            <div class="text-base text-gray-700 space-y-6 mt-6">
              <router-link :to="'/lyrics'" class="text-lg text-blue font-bold">View Album Lyrics</router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <TransitionRoot as="template" :show="open">
    <Dialog as="div" class="fixed z-10 inset-0 overflow-y-auto" @close="open = false">
      <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
          <DialogOverlay class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
          <div class="inline-block my-auto bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 align-middle w-full sm:max-w-5xl sm:w-full sm:p-6">
            <div class="relative" style="padding-top: 56.25%">
              <iframe class="absolute inset-0 w-full h-full" src="https://www.youtube.com/embed/k_30jtwrIGc?autoplay=1&rel=0" frameborder="0" allow="fullscreen;"></iframe>
            </div>
            <div class="relative text-right pt-3">
              <a class="font-bold" href="#" @click.prevent="open = false">X CLOSE</a>
            </div>
          </div>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import { defineComponent, ref } from 'vue'
import WeDeclareWarVideo from '@/components/WeDeclareWarVideo.vue'
import { PlayIcon } from '@heroicons/vue/20/solid'
import {
  Dialog,
  DialogOverlay,
  TransitionChild,
  TransitionRoot
} from '@headlessui/vue'

const product = {
  name: 'We Declare War',
  price: '$140',
  rating: 4,
  images: [
    {
      id: 1,
      name: 'Endorsement 1',
      src: '/img/resources/endorsement-le.jpg',
      alt: ''
    },
    {
      id: 2,
      name: 'Endorsement 2',
      src: '/img/resources/endorsement-kw.jpg',
      alt: ''
    },
    {
      id: 3,
      name: 'Endorsement 3',
      src: '/img/resources/endorsement-jg.jpg',
      alt: ''
    },
    {
      id: 4,
      name: 'Endorsement 4',
      src: '/img/resources/endorsement-rh.jpg',
      alt: ''
    }
  ],
  description: `
    <p class="font-bold">Rebecca Aladiran, is storming the heavens through her devote life of worship.</p>
    <p>On the 27th April 2017 Prayer Storm released their first album, entitled <i>‘We Declare War.’</i> Yet, unlike any conventional worship album. Rebecca Aladiran states “The album <i>‘We Declare War’</i> is not just a worship album but an album with songs of encounter, warfare and prayer."</p>
    <p>She explains how “We cannot coexist with an enemy we are called to evict. We are declaring war on prayerlessness, fear, depression, anything that tries to oppose God. This may present itself differently to the individual, it is a personal response.”</p>
    <p>Rebecca sensed the need for Prayer Storm to release an album, one unique to the movement’s call and ‘assignment,’ set for Kingdom advancement. Additionally, their track entitled <i>‘We Declare War’</i> powerfully resonates their hearts cry to the UK, for an awakening.</p>
  `,
  details: [
    {
      name: 'Features',
      items: [
        'Multiple strap configurations',
        'Spacious interior with top zip',
        'Leather handle and tabs',
        'Interior dividers',
        'Stainless strap loops',
        'Double stitched construction',
        'Water-resistant'
      ]
    }
  ]
}

export default defineComponent({
  name: 'WeDeclareWar',
  components: {
    WeDeclareWarVideo,
    Dialog,
    DialogOverlay,
    TransitionChild,
    TransitionRoot,
    PlayIcon
  },
  setup () {
    const open = ref(false)

    const showVideo = () => {
      open.value = true
    }
    return {
      product,
      open,
      showVideo
    }
  }
})
</script>

<style scoped>
.video-container {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    height: 0;
}
.video-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
}

.card-zoom {
  @apply relative flex items-center justify-center overflow-hidden shadow-xl w-full rounded-2xl;
  padding-bottom: 56.25%; /* 16:9 */
}
.card-zoom-image {
  @apply absolute inset-0 transition-all duration-500 ease-in-out transform bg-center bg-cover;
}
.card-zoom-text {
  @apply absolute inset-0 text-3xl font-black transition-all duration-500 ease-in-out transform scale-110 text-gray-50 opacity-60;
}
.card-zoom:hover .card-zoom-image {
  @apply scale-110;
}
.card-zoom:hover .card-zoom-text {
  @apply scale-100;
}
</style>
