<template>
<LiveNow></LiveNow>
<AboutVideo></AboutVideo>

  <div class="max-w-7xl mx-auto pt-8 pb-4 md:pt-12 lg:pt-16 px-4 sm:px-6 lg:px-8">

    <div class="relative">
      <div class="lg:grid lg:grid-cols-2 lg:grid-flow-col-dense gap-8 xl:gap-24">
        <div class="mt-4 px-4 max-w-xl mx-auto sm:px-6 lg:max-w-none lg:mx-0 lg:px-0">
          <div class="text-center">
            <h2 class="text-3xl font-bold text-blue">The Vision</h2>
            <p class="mt-4 text-lg text-gray-500 font-bold">
              We gather people together around divine encounters and equip them to be increasingly fervent and strategic in prayer.
            </p>
            <p class="mt-4 text-md text-gray-500">
              We have a vision for the mass mobilisation of a prayer army who are consecrated to a lifestyle of prayer because they are so gripped with the conviction that only a revival of prayer in the church can revive the heart of a nation. As a result, many come to Prayer Storm with distractions and addictions and leave re-fired with a new focus to take awakening prayer back to their churches.
            </p>
          </div>
        </div>
        <div class="mt-4 px-4 max-w-xl mx-auto sm:px-6 lg:max-w-none lg:mx-0 lg:px-0">
          <div class="relative video-container">
            <iframe title="The Vision - Prayer Storm" src="https://player.vimeo.com/video/64574819?dnt=1&amp;app_id=122963" class="w-full h-full" frameborder="0" allow="autoplay; fullscreen"></iframe>
          </div>
        </div>
      </div>
    </div>

    <div class="relative">
      <div>
        <div class="pt-16 px-4 max-w-xl mx-auto sm:px-6 lg:max-w-none lg:mx-0 lg:px-0 text-center">
          <div>
            <h2 class="text-3xl font-bold text-blue">Pioneers That Have Shaped Us</h2>
          </div>
        </div>
      </div>
      <div class="lg:grid lg:grid-cols-2 lg:grid-flow-col-dense gap-8 xl:gap-24">
        <div class="mt-8 px-4 max-w-xl mx-auto sm:px-6 lg:max-w-none lg:mx-0 lg:px-0">
          <div class="relative video-container">
            <iframe title="Karen Wheaton Talks About Prayer Storm" src="https://player.vimeo.com/video/106978713?dnt=1&amp;app_id=122963" class="w-full h-full" frameborder="0" allow="autoplay; fullscreen"></iframe>
          </div>
        </div>
        <div class="mt-8 px-4 max-w-xl mx-auto sm:px-6 lg:max-w-none lg:mx-0 lg:px-0">
          <div class="relative video-container">
            <iframe title="Lou Engle Talks About Prayer Storm" src="https://player.vimeo.com/video/111016653?dnt=1&amp;app_id=122963" class="w-full h-full" frameborder="0" allow="autoplay; fullscreen"></iframe>
          </div>
        </div>
      </div>
    </div>

    <div class="relative">
      <div>
        <div class="pt-16 px-4 max-w-xl mx-auto sm:px-6 lg:max-w-none lg:mx-0 lg:px-0 text-center">
          <h2 class="text-3xl font-bold text-blue">What We Do</h2>
          <p class="mt-4 text-lg text-gray-500 font-bold lg:px-52">
            Convinced that the message of prayer will precede a great revival, just as John the Baptist preceded Jesus; we seek to spread awakening prayer in four main ways.
          </p>
        </div>
      </div>
      <div class="mt-4">
        <div class="lg:grid grid-cols-2 gap-6">
          <div class="pt-6">
            <div class="flow-root bg-white rounded-lg px-6 pb-8">
              <div class="mt-4">
                <!-- Heroicon name: outline/cloud-upload -->
                <h3 class="mt-8 text-2xl font-medium text-gray-900 font-bold tracking-tight"><CalendarIcon class="flex-shrink-0 h-6 w-6 mr-2 inline text-blue" aria-hidden="true" /> Events</h3>
                <p class="mt-5 text-base font-medium text-gray-500">Regular events in Manchester like the Gathering, Boot Camp, Furnace, and Night Watch, we gather people together for life-changing God encounters.</p>
              </div>
            </div>
          </div>
          <div class="pt-6">
            <div class="flow-root bg-white rounded-lg px-6 pb-8">
              <div class="mt-4">
                <h3 class="mt-8  text-2xl font-medium text-gray-900 font-bold tracking-tight"><PlayIcon class="flex-shrink-0 h-6 w-6 mr-2 inline text-blue" aria-hidden="true" /> Media</h3>
                <p class="mt-5 text-base font-medium text-gray-500">Utilising modern media outlets, like our weekly TBN program, Prayer Strom TV, and social media, we hope to capture the hearts of a younger generation for God.</p>
              </div>
            </div>
          </div>
          <div class="pt-6">
            <div class="flow-root bg-white rounded-lg px-6 pb-8">
              <div class="mt-4">
                <h3 class="mt-8  text-2xl font-medium text-gray-900 font-bold tracking-tight"><SpeakerWaveIcon class="flex-shrink-0 h-6 w-6 mr-2 inline text-blue" aria-hidden="true" /> Preaching</h3>
                <p class="mt-5 text-base font-medium text-gray-500">Preaching across the nation, igniting hearts through the Prayer Storm Music, and our annual Awakening tours, we spread the message of prayer and fasting across the UK churches.</p>
              </div>
            </div>
          </div>
          <div class="pt-6">
            <div class="flow-root bg-white rounded-lg px-6 pb-8">
              <div class="mt-4">
                <h3 class="mt-8  text-2xl font-medium text-gray-900 font-bold tracking-tight"><ShoppingCartIcon class="flex-shrink-0 h-6 w-6 mr-2 inline text-blue" aria-hidden="true" /> Resources</h3>
                <p class="mt-5 text-base font-medium text-gray-500">Through our resources, we seek to achieve the ultimate goal of inspiring and assisting people to start their own prayer meetings right where they are.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="relative">
      <div>
        <div class="pt-16 px-4 max-w-xl mx-auto sm:px-6 lg:max-w-none lg:mx-0 lg:px-0 text-center">
          <h2 class="text-3xl font-bold text-blue">Church Partnership</h2>
          <p class="mt-4 text-lg text-gray-500 font-bold lg:px-52">
            Prayer Storm exists to get Christians praying.
          </p>
        </div>
      </div>
      <div class="mt-4">
        <div class="lg:grid grid-cols-2 gap-6">
          <div class="pt-6">
            <div class="flow-root bg-white rounded-lg px-6 pb-8">
              <div class="mt-4">
                <!-- Heroicon name: outline/cloud-upload -->
                <h3 class="mt-8 text-2xl font-medium text-gray-900 font-bold tracking-tight"><LinkIcon class="flex-shrink-0 h-6 w-6 mr-2 inline text-blue" aria-hidden="true" /> The Local Church</h3>
                <p class="mt-5 text-base font-medium text-gray-500">We believe that the local church is the greatest vehicle for achieving this and also that prayer is the life blood of a healthy church. As a result, we intentionally develop strategic relationships with churches and ministries in order to enrich the prayer lives of Gods people and advance the kingdom of God.</p>
              </div>
            </div>
          </div>
          <div class="pt-6">
            <div class="flow-root bg-white rounded-lg px-6 pb-8">
              <div class="mt-4">
                <h3 class="mt-8  text-2xl font-medium text-gray-900 font-bold tracking-tight"><HandRaisedIcon class="flex-shrink-0 h-6 w-6 mr-2 inline text-blue" aria-hidden="true" /> Fostering Prayer Cultures</h3>
                <p class="mt-5 text-base font-medium text-gray-500">Practically this means that we regularly pray for our church partners, develop prayer material for local churches, offer prayer bespoke workshops and training, foster relationships with the pastors and prayer co-ordinators, and prioritise the invitations we get for our partners.</p>
              </div>
            </div>
          </div>
          <div class="pt-6">
            <div class="flow-root bg-white rounded-lg px-6 pb-8">
              <div class="mt-4">
                <h3 class="mt-8  text-2xl font-medium text-gray-900 font-bold tracking-tight"><CreditCardIcon class="flex-shrink-0 h-6 w-6 mr-2 inline text-blue" aria-hidden="true" /> Partner Generosity</h3>
                <p class="mt-5 text-base font-medium text-gray-500">We also appreciate that our partners give financially into the vision of Prayer Storm and help rally people to our annual prayer Gathering events where their people can catch a heart for prayer.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="relative">
      <div>
        <div class="pt-16 px-4 max-w-xl mx-auto sm:px-6 lg:max-w-none lg:mx-0 lg:px-0 text-center">
          <h2 class="text-3xl font-bold text-blue">How You Can Get Involved</h2>
          <p class="mt-4 text-lg text-gray-500 font-bold lg:px-52">
            Prayer Storm exists to get Christians praying.
          </p>
        </div>
      </div>
      <div class="mt-4">
        <div class="lg:grid grid-cols-2 gap-6">
          <div class="pt-6">
            <div class="flow-root bg-white rounded-lg px-6 pb-8">
              <div class="mt-8">
                <!-- Heroicon name: outline/cloud-upload -->
                <p class="mt-5 text-base font-medium text-gray-500"><GiftIcon class="flex-shrink-0 h-6 w-6 mr-2 inline text-blue" aria-hidden="true" /> Prayer Storm is only made possible by the generosity of so many people who give into the cause with their time, resources, and money; but most importantly by joining in their prayers, faith, and heart with ours.</p>
                <p class="mt-5 text-base font-medium text-gray-500">People often ask how they can get involved and there are numerous ways to join in with our growing community in-person and online. </p>
              </div>
            </div>
          </div>
          <div class="pt-6">
            <div class="flow-root bg-white rounded-lg px-6 pb-8">
              <div class="mt-8">
                <p class="mt-5 text-base font-medium text-gray-500"><UserGroupIcon class="flex-shrink-0 h-6 w-6 mr-2 inline text-blue" aria-hidden="true" /> Whether it is attending, volunteering or inviting people to events, giving as a one-off or a regular partner, joining us in praying or simply sharing something on Facebook to get the message out, we recognise this is a mission bigger than a couple of individuals and appreciate all who get involved.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <MembershipList class="mt-20"></MembershipList>
</template>
<script>
import AboutVideo from '@/components/AboutVideo.vue'
import LiveNow from '@/components/LiveNow.vue'
import MembershipList from '@/components/MembershipList.vue'
import { CalendarIcon, PlayIcon, SpeakerWaveIcon, ShoppingCartIcon, LinkIcon, HandRaisedIcon, CreditCardIcon, GiftIcon, UserGroupIcon } from '@heroicons/vue/20/solid'
export default {
  name: 'About',
  components: {
    MembershipList,
    AboutVideo,
    LiveNow,
    CalendarIcon,
    PlayIcon,
    SpeakerWaveIcon,
    ShoppingCartIcon,
    LinkIcon,
    HandRaisedIcon,
    CreditCardIcon,
    GiftIcon,
    UserGroupIcon
  },
  setup () {
    return {
    }
  }
}
</script>

<style scoped>
.video-container {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    height: 0;
}
.video-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
}
</style>
