<template>
<main>
  <LiveNow></LiveNow>
  <div class="bg-black bg-contain bg-no-repeat bg-right-top" style="background-image:linear-gradient(to left bottom, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2), rgba(42, 131, 161, 0.4), rgba(42, 131, 161, .6));">
    <div class="max-w-7xl mx-auto py-24 px-4 sm:px-6 lg:px-8">
      <div class="sm:flex sm:flex-col align-center">
        <h1 class="text-3xl font-extrabold text-white text-center">We Declare War - Album Lyrics</h1>
      </div>
    </div>
  </div>
  <div class="flex flex-col justify-center py-12 sm:px-6 lg:px-8">
    <div class="sm:mx-auto sm:w-full sm:max-w-3xl">
      <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">

        <h2 class="mt-4 text-lg text-blue font-bold"><router-link :to="'/lyrics/we-arise'">We Arise</router-link></h2>
        <h2 class="mt-4 text-lg text-blue font-bold"><router-link :to="'/lyrics/love-of-god'">Love Of God</router-link></h2>
        <h2 class="mt-4 text-lg text-blue font-bold"><router-link :to="'/lyrics/we-declare-war'">We Declare War</router-link></h2>
        <h2 class="mt-4 text-lg text-blue font-bold"><router-link :to="'/lyrics/eyes-of-fire'">Eyes of Fire</router-link></h2>
        <h2 class="mt-4 text-lg text-blue font-bold"><router-link :to="'/lyrics/mighty-ones'">Mighty Ones</router-link></h2>
        <h2 class="mt-4 text-lg text-blue font-bold"><router-link :to="'/lyrics/sound-of-heaven'">Sound Of Heaven</router-link></h2>
        <h2 class="mt-4 text-lg text-blue font-bold"><router-link :to="'/lyrics/the-king-is-coming'">The King Is Coming</router-link></h2>
        <h2 class="mt-4 text-lg text-blue font-bold"><router-link :to="'/lyrics/falling-in-love'">Falling in Love</router-link></h2>
        <h2 class="mt-4 text-lg text-blue font-bold"><router-link :to="'/lyrics/big-ben'">Big Ben</router-link></h2>
        <h2 class="mt-4 text-lg text-blue font-bold"><router-link :to="'/lyrics/strike-the-ground'">Strike the Ground</router-link></h2>

      </div>
    </div>
  </div>
</main>

</template>
<script>
import LiveNow from '@/components/LiveNow.vue'
export default {
  name: 'AlbumLyrics',
  components: {
    LiveNow
  },
  setup () {
    return {
    }
  }
}
</script>
