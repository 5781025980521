<template>
<section aria-labelledby="passwordHeading">
  <form id="password-form" @submit.prevent="changePassword">
    <div class="shadow sm:rounded-md sm:overflow-hidden">
      <div class="bg-white py-6 px-4 sm:p-6">
        <div>
          <h2 id="passwordHeading" class="text-lg leading-6 font-medium text-gray-900">Change Password</h2>
          <p class="mt-1 text-sm text-gray-500">Change your password using the form below.</p>
        </div>

        <div class="mt-6 grid grid-cols-4 gap-6">
          <div class="col-span-4 sm:col-span-2">
            <label for="newPassword" class="block text-sm font-medium text-gray-700">New Password</label>
            <input v-model="passwordForm.newPassword" type="password" name="newPassword" id="newPassword" autocomplete="off" class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-light-blue-500 focus:border-light-blue-500 sm:text-sm" />
          </div>

          <div class="col-span-4 sm:col-span-2">
            <label for="newPasswordConfirmation" class="block text-sm font-medium text-gray-700">Confirm New Password</label>
            <input v-model="passwordForm.newPasswordConfirmation" type="password" name="newPasswordConfirmation" id="newPasswordConfirmation" autocomplete="off" class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-light-blue-500 focus:border-light-blue-500 sm:text-sm" />
          </div>

          <div class="col-span-4 sm:col-span-2">
            <label for="currentPassword" class="block text-sm font-medium text-gray-700">Current Password</label>
            <input v-model="passwordForm.currentPassword" type="password" name="currentPassword" id="currentPassword" autocomplete="off" class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-light-blue-500 focus:border-light-blue-500 sm:text-sm" />
          </div>
        </div>

        <ErrorMessage :formError="formError" class="mt-6"></ErrorMessage>
        <SuccessMessage :successMessage="successMessage" class="mt-6"></SuccessMessage>
      </div>
      <div class="px-4 py-3 bg-gray-50 text-right sm:px-6">
        <button type="submit" :disabled="passwordFormSubmitted" class="disabled:opacity-50 bg-blue border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-blue-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue">
          Save
        </button>
      </div>
    </div>
  </form>
</section>
</template>

<script>
import axios from 'axios'
import responseHelper from '@/helpers/response.helper'
import SuccessMessage from '@/components/SuccessMessage.vue'
import ErrorMessage from '@/components/ErrorMessage.vue'

export default {
  components: {
    SuccessMessage,
    ErrorMessage
  },
  data: () => ({
    successMessage: '',
    formError: responseHelper.noError,
    passwordFormSubmitted: false,
    passwordForm: {
      currentPassword: '',
      newPassword: '',
      newPasswordConfirmation: ''
    }
  }),
  methods: {
    changePassword () {
      this.successMessage = ''
      this.passwordFormSubmitted = true
      this.formError = responseHelper.noError
      axios.post('settings/password', this.passwordForm)
        .then((response) => {
          if (response?.data?.success && response?.data?.result) {
            this.passwordForm = {
              currentPassword: '',
              newPassword: '',
              newPasswordConfirmation: ''
            }
            this.successMessage = response.data.result.message
          } else {
            this.successMessage = ''
            this.formError = responseHelper.unexpectedResult
          }
          // Handle the response
        }).catch((error) => {
          this.formError = responseHelper.getMessage(error)
        }).finally(() => {
          this.passwordFormSubmitted = false
        })
    }
  }
}
</script>
