<template>
  <!-- TABS -->
  <div v-if="contentDetailState.content.item && $filters.arrayItemsExist(contentDetailState.content.attendItems) && $filters.arrayItemsExist(contentDetailState.content.sessionItems)" class="mt-12">
    <div class="border border-gray-200 rounded-xl p-2">
      <nav class="-mb-px flex flex-row-reverse" aria-label="Tabs">
        <a @click.prevent="changeView(tab)" v-for="tab in contentDetailState.tabs" :key="tab.name" :href="tab.href" :class="[tab.current ? 'bg-blue text-white rounded-lg' : 'border-transparent text-gray-500 hover:text-blue-dark', 'w-1/2 py-2 px-1 text-center text-lg font-bold cursor-pointer']" :aria-current="tab.current ? 'page' : undefined">{{ tab.name }}</a>
      </nav>
    </div>
  </div>

  <!-- ATTEND SCHEDULE -->
  <div v-if="contentDetailState.tabs[1].current && $filters.arrayItemsExist(contentDetailState.content.attendItems)" class="mt-6">
    <ContentScheduleKidsConf v-if="contentDetailState.content.item && contentDetailState.content.item.slug === 'prayer-storm-kids-conference'"></ContentScheduleKidsConf>
    <ListPanel v-else title="Attend Sessions" :allLink="attendSessionsPath" :panels="contentDetailState.content.attendItems"></ListPanel>
  </div>

  <!-- WATCH SCHEDULE -->
  <div v-if="contentDetailState.tabs[0].current && $filters.arrayItemsExist(contentDetailState.content.sessionItems)" class="mt-6">
    <div v-if="!isLoggedIn" class="py-3 text-sm text-center">
      <router-link :to="signInPath" class="text-blue font-bold">Log In</router-link>
      <span class="text-gray-900"> or </span>
      <router-link :to="signUpPath" class="text-blue font-bold">Sign Up</router-link>
      <span class="text-gray-900"> to see your access options</span>
    </div>
    <div v-else>
      <div v-if="contentDetailState.content.item && contentDetailState.content.watchOnline && !contentDetailState.content.item.publicAccess">
        <div v-if="isLoggedIn && contentDetailState.content.activeSubscription || contentDetailState.content.activeETicket" class="py-3 border border-gray-200 text-center">
          <CheckCircleIcon class="h-5 w-5 text-blue inline mr-2"></CheckCircleIcon>
          <span class="text-gray-900 text-sm font-medium">Your account has watch online access</span>
        </div>
        <div v-if="!contentDetailState.content.activeSubscription && !contentDetailState.content.activeETicket && ($filters.arrayItemsExist(subscriptionState.subscriptions) || $filters.arrayItemsExist(subscriptionState.eTickets))" class="py-3 border-b border-gray-200 text-center">
          <div class="text-sm">
            <span>Watch Online access is available via:</span>
          </div>
          <div class="sm:flex space-x-2 justify-center flex-nowrap">
            <div v-if="$filters.arrayItemsExist(subscriptionState.subscriptions)" class="mt-2">
              <router-link v-for="subscription in subscriptionState.subscriptions" :key="subscription.id" :to="membershipPath" class="inline-block bg-blue border-2 border-blue rounded-xl pl-2 text-sm font-semibold text-white text-center hover:bg-blue-dark justify-content-center">
                {{subscription.title}} Membership
                <ArrowRightCircleIcon class="inline h-5 w-5 ml-1" aria-hidden="true" />
              </router-link>
            </div>
            <div v-if="$filters.arrayItemsExist(subscriptionState.eTickets)" class="mt-2">
              <router-link v-for="eTicket in subscriptionState.eTickets" :key="eTicket.id" :to="eTicketPath(eTicket)" class="inline-block bg-blue border-2 border-blue rounded-xl pl-2 text-sm font-semibold text-white text-center hover:bg-blue-dark justify-content-center">
                {{eTicket.title}} £{{Number.parseFloat(eTicket.price).toFixed(2)}}
                <ArrowRightCircleIcon class="inline h-5 w-5 ml-1" aria-hidden="true" />
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <div v-else-if="contentDetailState.content.item && contentDetailState.content.watchOnline">
        <div class="py-3 text-center">
          <CheckCircleIcon class="h-5 w-5 text-blue inline mr-2"></CheckCircleIcon>
          <span class="text-gray-900 text-sm font-medium">Your account has watch online access</span>
        </div>
      </div>
    </div>

    <div v-if="$filters.arrayItemsExist(contentDetailState.content.onDemandSessionItems)">
      <ListPanel title="Latest Videos" :allLink="latestVideosPath" :panels="contentDetailState.content.onDemandSessionItems"></ListPanel>
    </div>
    <div v-if="$filters.arrayItemsExist(contentDetailState.content.nextUpSessionItems)">
      <ListPanel title="Coming Soon" :allLink="comingSoonPath" :panels="contentDetailState.content.nextUpSessionItems"></ListPanel>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { mapGetters } from 'vuex'
import {
  ArrowRightCircleIcon,
  CheckCircleIcon
} from '@heroicons/vue/20/solid'
import { useContent } from '@/composables/useContent'
import ContentScheduleKidsConf from '@/components/ContentScheduleKidsConf.vue'
import ListPanel from '@/components/ListPanel.vue'
// import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue'
// import { MinusSmallIcon, PlusSmallIcon } from '@heroicons/vue/24/outline'

export default defineComponent({
  name: 'GroupSchedule',
  components: {
    ContentScheduleKidsConf,
    ListPanel,
    CheckCircleIcon,
    // UserPlusIcon,
    // Disclosure,
    // DisclosureButton,
    // DisclosurePanel,
    ArrowRightCircleIcon
    // MinusSmallIcon,
    // PlusSmallIcon
  },
  setup () {
    const goToElementById = (elementId) => {
      const scrollTo = document.getElementById(elementId)
      scrollTo.scrollIntoView({ behavior: 'smooth' })
    }

    const router = useRouter()
    const route = useRoute()
    const { subscriptionState, contentDetailState, getContentImage, goContent } = useContent()

    const signInPath = ref('/sign-in?return=' + encodeURIComponent(route.path))
    const signUpPath = ref('/sign-up?return=' + encodeURIComponent(route.path))
    const membershipPath = ref('/membership?return=' + encodeURIComponent(route.path))

    const latestVideosPath = route.path + '/latest-videos'
    const comingSoonPath = route.path + '/coming-soon'
    const attendSessionsPath = route.path + '/attend-sessions'

    const attendPath = (eTicket) => {
      return '/attend/' + eTicket.id + route.path
    }
    const eTicketPath = (eTicket) => {
      return '/eticket/' + eTicket.id + route.path
    }
    const attendTicketStatus = (eTicket) => {
      if (eTicket.status === 1) return 'paused'
      else if (eTicket.status === 2) return 'waitinglist'
      else if (eTicket.status === 3) return 'full'
      else return 'ok'
    }

    const toMemberPrice = (eTicket) => {
      let priceString
      if (eTicket.memberPrice > 0) {
        priceString = '£' + Number.parseFloat(eTicket.memberPrice).toFixed(2)
      } else {
        priceString = 'FREE'
      }
      return priceString + ' - In-Person'
    }

    const navigateToItem = (item) => {
      goContent(item, router, route)
    }

    // const attendSchedule = computed(() => {
    //   if (contentDetailState.content.attendItems && contentDetailState.content.attendItems.length > 0) {
    //     return contentDetailState.content.attendItems.reduce((acc, attendItem) => {
    //       const dateStr = DateTime.fromISO(attendItem.publishedAt).toFormat('yyyy-MM-dd')
    //       const categoryIndex = acc.findIndex(item => item.name === dateStr)
    //       if (categoryIndex > -1) {
    //         acc[categoryIndex].items.push(attendItem)
    //       } else {
    //         acc.push({ name: dateStr, items: [attendItem] })
    //       };
    //       return acc
    //     }, [])
    //   }
    //   return []
    // })

    const changeView = (seletedTab) => {
      contentDetailState.tabs.forEach((tab) => {
        if (seletedTab.name === tab.name) {
          tab.current = true
        } else {
          tab.current = false
        }
      })
    }

    return {
      goToElementById,
      // attendSchedule,
      getContentImage,
      contentDetailState,
      navigateToItem,
      subscriptionState,
      signInPath,
      signUpPath,
      membershipPath,
      attendPath,
      eTicketPath,
      attendTicketStatus,
      toMemberPrice,
      changeView,
      latestVideosPath,
      comingSoonPath,
      attendSessionsPath
    }
  },
  computed: {
    ...mapGetters([
      'isLoggedIn'
    ])
  }
})
</script>
