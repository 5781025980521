<template>
<div class="flex flex-col justify-center py-12 sm:px-6 lg:px-8">
  <div class="sm:mx-auto sm:w-full sm:max-w-md">
    <img class="mx-auto h-12 w-auto" src="@/assets/logo-icon.svg" alt="Workflow">
    <h2 class="mt-6 text-center text-3xl font-bold text-gray-900">
      Reset your password
    </h2>
    <p class="mt-2 text-center text-sm text-gray-600 max-w">
      Or
      <router-link to="/sign-in" class="font-medium text-blue hover:text-blue-dark">
        sign in here
      </router-link>
    </p>
  </div>

  <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
    <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
      <form class="space-y-6" id="password-reset-form" @submit.prevent="passwordReset">
        <div>
          <label for="email" class="block text-sm font-medium text-gray-700">
            Email address
          </label>
          <div class="mt-1">
            <input v-model="passwordResetForm.email" id="email" name="email" type="email" autocomplete="email" required class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
          </div>
        </div>

        <div class="rounded-md bg-red-50 p-4" v-show="formError.message !== ''">
          <div class="flex">
            <div class="flex-shrink-0">
              <!-- Heroicon name: solid/x-circle -->
              <svg class="h-5 w-5 text-red-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clip-rule="evenodd" />
              </svg>
            </div>
            <div class="ml-3">
              <h3 class="text-sm font-medium text-red-800">
                {{formError.message}}
              </h3>
              <div class="mt-2 text-sm text-red-700">
                <ul class="list-disc pl-5 space-y-1">
                  <li v-for="(err, index) in formError.errors" :key="index">
                    {{err.error}}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div class="rounded-md bg-green-50 p-4" v-show="successMessage !== ''">
          <div class="flex">
            <div class="flex-shrink-0">
              <!-- Heroicon name: solid/check-circle -->
              <svg class="h-5 w-5 text-green-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
              </svg>
            </div>
            <div class="ml-3">
              <h3 class="text-sm font-medium text-green-800">
                Success
              </h3>
              <div class="mt-2 text-sm text-green-700">
                <p>
                  {{successMessage}}
                </p>
              </div>
            </div>
          </div>
        </div>

        <div>
          <button type="submit" :disabled="passwordResetSubmitted" class="disabled:opacity-50 w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue hover:bg-blue-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
            Reset
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
</template>

<script>
import axios from 'axios'
import responseHelper from '../helpers/response.helper'
export default {
  name: 'PasswordReset',
  data: () => ({
    successMessage: '',
    formError: responseHelper.noError,
    passwordResetSubmitted: false,
    passwordResetForm: {
      email: ''
    }
  }),
  methods: {
    passwordReset () {
      this.passwordResetSubmitted = true
      this.formError = responseHelper.noError
      this.successMessage = ''
      axios.post('auth/passwordreset', {
        email: this.passwordResetForm.email
      }).then((response) => {
        if (response?.data?.success && response?.data?.result) {
          this.successMessage = response.data.result.message
        } else {
          this.successMessage = ''
          this.formError = responseHelper.unexpectedResult
        }
        // Handle the response
      }).catch((error) => {
        this.successMessage = ''
        this.formError = responseHelper.getMessage(error)
        this.passwordResetSubmitted = false
      })
    }
  }
}
</script>
