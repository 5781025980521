<template>
<main>
  <LiveNow></LiveNow>
  <div class="bg-black bg-contain bg-no-repeat bg-right-top" style="background-image:linear-gradient(to left bottom, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2), rgba(42, 131, 161, 0.4), rgba(42, 131, 161, .6));">
    <div class="max-w-7xl mx-auto py-24 px-4 sm:px-6 lg:px-8">
      <div class="sm:flex sm:flex-col align-center">
        <h1 class="text-3xl font-extrabold text-white text-center">Sound of Heaven</h1>
      </div>
    </div>
  </div>
  <div class="flex flex-col justify-center py-12 sm:px-6 lg:px-8">
    <div class="sm:mx-auto sm:w-full sm:max-w-3xl">
      <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">

        <p class="lyrics">We release the sound of heaven</p>

        <p class="lyrics">There’s a sound we carry<br>
                                                        When we live in you<br>
                                                        When we open our mouths<br>
                                                        We release the truth</p>

        <p class="lyrics">There’s a sound we carry<br>
                                                        That makes demons flee<br>
                                                        Where our words are spoken<br>
                                                        Captives are set free</p>

        <p class="lyrics">There’s a sound we carry<br>
                                                        We pray, we sing<br>
                                                        It’s the sound of heaven<br>
                                                        It’s awakening</p>

        <p class="lyrics">We declare war over darkness<br>
                                                        We release the sound of heaven</p>

        <p class="lyrics">There’s a sound we carry<br>
                                                        And it shakes the ground<br>
                                                        It’s a revolution<br>
                                                        Can you hear the sound?</p>

        <p class="composer">Rebecca Aladiran, Victoria Aladiran, James Aladiran<br>
                                                                © 2017 Prayer Storm Music</p>

      </div>
    </div>
  </div>
</main>

</template>
<script>
import LiveNow from '@/components/LiveNow.vue'
export default {
  components: {
    LiveNow
  },
  setup () {
    return {
    }
  }
}
</script>

<style scoped>
.lyrics {
  @apply mt-8 font-medium text-gray-700
}
.composer {
  @apply mt-8 text-sm mb-10 text-gray-700
}
</style>
