<template>
  <div v-if="liveItem.record" class="relative noprint bg-red-600">
    <div class="max-w-7xl mx-auto py-3 px-3 sm:px-6 lg:px-8">
      <div class="text-center px-2 text-sm">
        <p class="text-white">
          <span class="font-bold">
            | LIVE |
          </span>
          <span class="ml-2">
            <router-link :to="getContentLink(liveItem.record)"> Prayer Storm is Live - Go Now <span aria-hidden="true">&rarr;</span></router-link>
          </span>
        </p>
      </div>
    </div>
  </div>
  <div v-else class="relative noprint border-b bg-white">
    <div class="max-w-7xl mx-auto py-3 px-3 sm:px-6 lg:px-8">
      <div class="text-center px-2 text-sm">
        <p class="text-gray-500">
          <span class="md:hidden inline">
            'Life on Fire' by James Aladiran.
          </span>
          <span class="hidden md:inline">
            'Life on Fire' a book by James Aladiran.
          </span>
          <span class="ml-2 inline-block text-blue">
            <router-link to="/resources/life-on-fire" class="text-blue font-medium"> Order now <span aria-hidden="true">&rarr;</span></router-link>
          </span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted, onUnmounted, reactive } from 'vue'
import { useRoute } from 'vue-router'
import { useContent } from '@/composables/useContent'
import axios from 'axios'
export default defineComponent({
  components: {
  },
  setup () {
    const route = useRoute()
    const { getContentLink } = useContent()
    const liveItem = reactive({
      record: undefined
    })
    onUnmounted(() => {
      clearScreenPolling()
    })
    const clearScreenPolling = () => {
      if (window.livePolling) {
        clearInterval(window.livePolling)
      }
    }

    onMounted(() => {
      clearInterval(window.livePolling)
      window.livePolling = setInterval(() => {
        loadLive()
      }, 60000) // every minute
      loadLive()
    })

    const loadLive = async () => {
      try {
        const response = await axios.post('pub/content/live', {})
        if (response.data.result) {
          if (response.status === 404) {
            liveItem.record = undefined
          } else if (response.data.result) {
            liveItem.record = response.data.result
          }
        }
      } catch (error) {
      }
    }
    return {
      route,
      getContentLink,
      liveItem
    }
  }
})
</script>
