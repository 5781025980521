<template>
  <div class="bg-gray-50">
    <div class="max-w-2xl mx-auto pt-16 pb-24 px-4 sm:px-6 lg:max-w-7xl lg:px-8">
      <h2 class="sr-only">Checkout</h2>

      <form class="lg:grid lg:grid-cols-2 lg:gap-x-12 xl:gap-x-16" v-if="membershipState.membership">
        <div>
          <h2 class="text-lg font-bold text-blue">{{ membershipState.membership.title }} {{ membershipState.membership.membershipType }}</h2>
          <p>This subscription grants access to the account holder to watch the content available to {{ membershipState.membership.title }} members whilst the subscription is active.</p>
          <router-link :to="changeMembershipLink" class="text-sm mt-2 inline-block"><ArrowLeftCircleIcon class="text-gray-500 h-5 w-5 inline"></ArrowLeftCircleIcon> See what's included</router-link>
          <div class="mt-10 border-t border-gray-200 pt-10">
            <RadioGroup v-model="checkoutMembershipState.selectedOption">
              <RadioGroupLabel class="text-lg font-medium text-gray-900">
                Select subscription
              </RadioGroupLabel>

              <div class="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-4">
                <RadioGroupOption as="template" v-for="option in membershipState.membership.options" :key="option.id" :value="option" v-slot="{ checked, active }">
                  <div :class="[checked ? 'border-transparent' : 'border-gray-300', active ? 'ring-2 ring-blue' : '', 'relative bg-white border rounded-lg shadow-sm p-4 flex cursor-pointer focus:outline-none']">
                    <div class="flex-1 flex">
                      <div class="flex flex-col">
                        <RadioGroupLabel as="span" class="block text-sm font-medium text-gray-900">
                          {{ option.title }}
                        </RadioGroupLabel>
                        <RadioGroupDescription as="span" class="mt-1 flex items-center text-sm text-gray-500">
                          {{ option.description }}
                        </RadioGroupDescription>
                        <RadioGroupDescription as="span" class="mt-6 text-sm font-medium text-gray-900">
                          £{{ Number.parseFloat(option.price).toFixed(2) }}
                        </RadioGroupDescription>
                      </div>
                    </div>
                    <CheckCircleIcon v-if="checked" class="h-5 w-5 text-blue" aria-hidden="true" />
                    <div :class="[active ? 'border' : 'border-2', checked ? 'border-blue' : 'border-transparent', 'absolute -inset-px rounded-lg pointer-events-none']" aria-hidden="true" />
                  </div>
                </RadioGroupOption>
              </div>
            </RadioGroup>
          </div>

          <div class="mt-10 border-t border-gray-200 pt-10">
            <h2 class="text-lg font-medium text-gray-900">Account information</h2>

            <div class="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-4">
              <div class="sm:col-span-2">
                <label for="email-address" class="block text-sm font-medium text-gray-700">Email address</label>
                <div class="mt-1">
                    <input v-model="checkoutMembershipState.email" type="email" id="email-address" name="email-address" autocomplete="email" class="block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm bg-gray-100" disabled/>
                </div>
              </div>

              <div>
                <label for="first-name" class="block text-sm font-medium text-gray-700">First name</label>
                <div class="mt-1">
                  <input v-model="checkoutMembershipState.firstName" type="text" id="first-name" name="first-name" autocomplete="given-name" class="block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
                </div>
              </div>

              <div>
                <label for="last-name" class="block text-sm font-medium text-gray-700">Last name</label>
                <div class="mt-1">
                  <input v-model="checkoutMembershipState.lastName" type="text" id="last-name" name="last-name" autocomplete="family-name" class="block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Order summary -->
        <div class="mt-10 lg:mt-0">
          <h2 class="text-lg font-medium text-gray-900">Summary</h2>

          <div v-if="checkoutMembershipState.selectedOption" class="mt-4 bg-white border border-gray-200 rounded-lg shadow-sm">
            <h3 class="sr-only">Items in your cart</h3>
            <dl class="py-6 px-4 space-y-6 sm:px-6">
              <div class="flex items-center justify-between">
                <dt class="text-sm">
                  {{ membershipState.membership.title }}
                  {{ membershipState.membership.membershipType }}
                  - {{ checkoutMembershipState.selectedOption.title }} Plan
                </dt>
                <dd class="text-sm font-medium text-gray-900">

                </dd>
              </div>
              <div class="flex items-center justify-between border-t border-gray-200 pt-6">
                <dt class="text-base font-medium">
                  {{ checkoutMembershipState.selectedOption.title }} Total
                </dt>
                <dd class="text-base font-medium text-gray-900">
                  £{{ Number.parseFloat(checkoutMembershipState.selectedOption.price).toFixed(2) }}
                </dd>
              </div>
            </dl>

            <div class="border-t border-gray-200 py-6 px-4 sm:px-6">
              <ErrorMessage :formError="checkoutMembershipState.error"></ErrorMessage>
              <button @click.prevent="checkout" :disabled="checkoutMembershipState.executing" type="submit" class="disabled:opacity-50 w-full bg-blue border border-transparent rounded-md shadow-sm py-3 px-4 text-base font-medium text-white hover:bg-blue-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-indigo-500">Setup Payment</button>
            </div>
          </div>

          <div v-else class="mt-4 bg-white border border-gray-200 rounded-lg shadow-sm">
            <p class="py-6 px-4">Please select a subscription</p>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { defineComponent, ref, onMounted } from 'vue'
import store from '../store'
import { useMemberships } from '@/composables/useMemberships'
import { useRoute, useRouter } from 'vue-router'
import { RadioGroup, RadioGroupDescription, RadioGroupLabel, RadioGroupOption } from '@headlessui/vue'
import { CheckCircleIcon, ArrowLeftCircleIcon } from '@heroicons/vue/20/solid'
import ErrorMessage from '@/components/ErrorMessage.vue'

export default defineComponent({
  components: {
    RadioGroup,
    RadioGroupDescription,
    RadioGroupLabel,
    RadioGroupOption,
    CheckCircleIcon,
    ArrowLeftCircleIcon,
    ErrorMessage
  },
  setup () {
    const route = useRoute()
    const router = useRouter()
    const { membershipState, searchMembership, checkoutMembershipState, checkoutMembership } = useMemberships()

    if (!store.getters.isLoggedIn) {
      router.push('/sign-in?return=' + encodeURIComponent(route.path))
    }

    let membershipLink = '/membership'
    if (route.query.return) {
      membershipLink += '?return=' + route.query.return
    }
    const changeMembershipLink = ref(membershipLink)

    onMounted(() => {
      if (route.params.id === '1') {
        if (route.query.return) {
          router.push(route.query.return)
        } else {
          router.push('/')
        }
      } else {
        checkoutMembershipState.membershipId = parseInt(route.params.id)
        checkoutMembershipState.return = route.query.return ? route.query.return : undefined
        searchMembership(route.params.id)
      }
    })

    const checkout = () => {
      checkoutMembership()
        .then((result) => {
          if (result) {
            window.location.replace(result.sessionUrl)
          } else {
            console.log('invalid result')
          }
        })
        .catch(() => { })
    }

    return {
      checkoutMembershipState,
      membershipState,
      changeMembershipLink,
      checkout
    }
  },
  computed: {
    ...mapGetters([
      'isLoggedIn'
    ])
  }
})
</script>
