<template>
  <div class="bg-blue-dark">
    <div class="relative">
    <!-- <div class="relative bg-no-repeat bg-cover" style="background-image:url('/img/banners/ps-banner-2.jpg');"> -->
      <div class="relative">
      <!-- <div class="relative backdrop-filter backdrop-blur-md"> -->
        <div class="relative max-w-7xl mx-auto">
          <div class="video-container">
            <iframe src="/background.html" frameborder="0" class="w-full"></iframe>
            <div class="absolute flex flex-wrap bottom-0 md:bottom-20 lg:bottom-0 lg:top-0 left-0 right-0 text-white pointer-events-none" >
              <div class="m-auto w-3/4 content-center bg-black bg-opacity-20 p-5 rounded-lg text-center">
                <h1 class="text-lg md:text-3xl font-bold">What is Prayer Storm?</h1>
                <p class="text-md pb-3">
                  Prayer Storm is a movement of worship, prayer and fasting which exists to provoke a spiritual awakening by raising an army of praying warriors.
                </p>
                <button class="hidden md:block m-auto pointer-events-auto font-bold text-md border border-white p-2 rounded-md" @click="more">More</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div id="video-foot"></div>
</template>

<script>
import { defineComponent } from '@vue/runtime-core'

export default defineComponent({
  name: 'AboutVideo',
  components: {
  },
  setup () {
    const more = () => {
      const scrollTo = document.getElementById('video-foot')
      scrollTo.scrollIntoView({ behavior: 'smooth' })
    }
    return {
      more
    }
  }
})
</script>

<style scoped>
.video-container {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    height: 0;
}
.video-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
}
</style>
