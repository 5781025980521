<template>
<div class="bg-white pb-8">
  <LiveNow></LiveNow>
  <div v-if="contentDetailState.content.item" class="max-w-7xl mx-auto pt-10 px-4 sm:px-6 lg:px-8">
    <div class="">
      <router-link :to="{ name: 'Event', params: { type: contentDetailState.content.item.type, guid: contentDetailState.content.item.guid, slug: contentDetailState.content.item.slug } }" class="text-xl font-bold text-blue"><ArrowLeftCircleIcon class="h-6 w-6 inline"></ArrowLeftCircleIcon> {{contentDetailState.content.item.title}}</router-link>
      <h2 class="text-lg font-bold text-gray-400">Latest Videos</h2>
    </div>
    <div v-if="contentDetailState.content.onDemandSessionItems && contentDetailState.content.onDemandSessionItems.length > 0" class="pt-4 grid grid-cols-2 gap-3 md:grid-cols-3 lg:grid-cols-4">
      <div class="flex flex-col" v-for="item in contentDetailState.content.onDemandSessionItems" :key="item.guid">
        <div @click.prevent="navigateToItem(item)" class="relative bg-card-img cursor-pointer" v-bind:style="{ 'backgroundImage': 'url(' + getContentImageUrl(item) + ')' }">
          <div class="absolute inset-x-0 bottom-0 text-white pl-3 pb-2">
            &nbsp;
          </div>
        </div>
        <div>
          <div class="pt-2">
            <a @click.prevent="navigateToItem(item)" href="#" class="font-semibold text-blue block text-ellipsis-2 hover:underline">{{item.title}}</a>
          </div>
          <div>
            <span class="text-sm text-gray-400">{{$filters.dateMed(item.publishedAt)}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import { defineComponent, onMounted, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useContent } from '@/composables/useContent'
import LiveNow from '@/components/LiveNow.vue'
import store from '../store'
import { ArrowLeftCircleIcon } from '@heroicons/vue/20/solid'

export default defineComponent({
  components: {
    LiveNow,
    ArrowLeftCircleIcon
  },
  setup () {
    const router = useRouter()
    const route = useRoute()
    const contentGuid = ref(route.params.guid)
    const contentType = ref('event')
    const { contentDetailState, searchContentDetail, getContentImageUrl, goContent } = useContent()

    onMounted(() => {
      loadScreen(true)
    })

    const loadScreen = (initialLoad) => {
      const searchReq = {
        type: contentType.value,
        guid: contentGuid.value,
        expectUser: store.getters.isLoggedIn
      }
      searchContentDetail(searchReq, initialLoad)
        .then((result) => {
          if (initialLoad) {
          }
        }).catch(() => {
          if (initialLoad) {
            alert('Failed to load the screen, please refresh and try again.')
          }
        })
    }

    const navigateToItem = (item) => {
      goContent(item, router, route)
    }

    return {
      contentGuid,
      contentType,
      contentDetailState,
      getContentImageUrl,
      navigateToItem,
      ArrowLeftCircleIcon
    }
  }
})
</script>
