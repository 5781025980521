<template>
<main>
  <LiveNow></LiveNow>
  <div class="bg-black bg-contain bg-no-repeat bg-right-top" style="background-image:linear-gradient(to left bottom, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2), rgba(42, 131, 161, 0.4), rgba(42, 131, 161, .6)), url('/img/banners/ps-banner-1.jpg');">
    <div class="max-w-7xl mx-auto py-24 px-4 sm:px-6 lg:px-8">
      <div class="sm:flex sm:flex-col align-center">
        <h1 class="text-3xl font-extrabold text-white text-center">Furnace</h1>
      </div>
    </div>
  </div>
  <div class="max-w-7xl mx-auto py-8 pb-4 md:py-12 px-4 sm:px-6 lg:px-8">
    <div class="relative">
      <div class="mt-4">
        <div class="pt-6">
          <div class="flow-root px-6 pb-8">
            <div>
              <p class="text-center font-medium text-gray-500 text-2xl italic">
                Now there was no blacksmith to be found throughout all the land of Israel, for the Philistines said, “Lest the Hebrews make swords or spears.” 1 Samuel 13:19
              </p>
            </div>
          </div>
        </div>

        <div class="lg:grid grid-cols-2 bg-white rounded-lg">
          <div class="p-8">
            <p>The enemy was not afraid that the people of God had a King or a Prophet but the enemy was afraid of the people of God having a Blacksmith! Someone who will stand in a hot place and make weapons of war! These meeting are called ‘Furnace’ because we stand in a hot place where God does not just make weapons of war for the body of Christ but He also also raises up blacksmiths!</p>
          </div>

          <div class="p-8 lg:pl-0 pt-0 lg:pt-8">
            <p>Prayer Storm Furnace generally tend to be held on the second Thursday every month 7:30 – 10pm. December and August there is no Furnace.  Occasionally when we have other events we may change the date for Prayer Storm Furnace so be sure to keep updated with us on Facebook & Twitter.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</main>
</template>

<script>
import { defineComponent } from 'vue'
import LiveNow from '@/components/LiveNow.vue'

export default defineComponent({
  name: 'Furnace',
  components: {
    LiveNow
  },
  setup () {
    return {
    }
  }
})
</script>
