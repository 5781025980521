<template>
<main class="max-w-7xl mx-auto pb-10 lg:py-12 lg:px-8">
  <div class="lg:grid lg:grid-cols-12 lg:gap-x-5">
    <SettingsNavBar></SettingsNavBar>
    <div class="space-y-6 sm:px-6 lg:px-0 lg:col-span-9">
      <router-view></router-view>
    </div>
  </div>
</main>
</template>

<script>
import SettingsNavBar from '@/components/SettingsNavBar.vue'
export default {
  components: {
    SettingsNavBar
  }
}
</script>
