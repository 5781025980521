import store from '@/store'

function ensureLoggedInOrRedirect (router, route) {
  if (!store.getters.isLoggedIn) {
    router.push('/sign-in?return=' + encodeURIComponent(route.path))
  }
}

export {
  ensureLoggedInOrRedirect
}
