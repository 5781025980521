<template>
<!-- <main class="max-w-7xl mx-auto pb-10 lg:py-12 lg:px-8"> -->
<main>
  <div class="lg:grid lg:grid-cols-12">
    <div class="lg:col-span-8">
      <div v-show="playerType === 'vimeo' || playerType === 'azure'" style="padding:56.25% 0 0 0;position:relative;">
        <iframe ref="vimVideo" src="https://vimeo.com/event/168142/embed" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;"></iframe>
      </div>
      <div v-if="contentDetailState.content.item" class="bg-white hidden lg:block lg:border-r-2 border-gray-100">
        <div class="p-6">
          <div>
            <div>
              <div>
                <div class="md:flex md:items-center md:justify-between md:space-x-4 xl:border-b xl:pb-6">
                  <div>
                    <h1 class="text-xl font-bold text-gray-900">{{contentDetailState.content.item.title}}</h1>
                    <p class="mt-2 text-sm text-gray-500">
                      {{$filters.dateMed(contentDetailState.content.item.publishedAt)}}
                    </p>
                  </div>
                </div>
                <div class="py-3 xl:pt-6 xl:pb-0">
                  <h2 class="sr-only">Description</h2>
                  <div class="prose max-w-none">
                    <p v-html="$filters.contentDescription(contentDetailState.content.item.description)">
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="h-96 lg:col-span-4 lg:h-screen">
      <ChatLive @sendChatMessage="sendChatMessage"></ChatLive>
    </div>
  </div>
</main>
</template>

<script>
import { defineComponent, onMounted, onUnmounted, ref } from 'vue'
import { useRoute } from 'vue-router'
import { mapGetters } from 'vuex'
import ChatLive from '@/components/ChatLive.vue'
import { useContent } from '@/composables/useContent'
import { useContentMessages } from '@/composables/useContentMessages'
import store from '../store'

export default defineComponent({
  name: 'Live',
  components: {
    ChatLive
  },
  setup () {
    const route = useRoute()
    const contentGuid = ref(route.params.guid)
    const contentType = ref(route.params.type)
    const playerType = ref('')
    const { contentDetailState, searchContentDetail } = useContent()
    const { contentMessagesState, sendContentMessage } = useContentMessages(true)
    const ampVideo = ref(null)
    const vimVideo = ref(null)

    onMounted(() => {
      loadScreen(true)
    })

    onUnmounted(() => {
      clearScreenPolling()
    })

    const clearScreenPolling = () => {
      if (window.screenPolling) {
        clearInterval(window.screenPolling)
      }
    }

    const clearVim = () => {
      if (window.vimPlayer) {
        window.vimPlayer.pause()
        window.vimPlayer.destroy()
        window.vimPlayer = undefined
      }
    }

    const startScreenPolling = () => {
      window.screenPolling = setInterval(() => {
        loadScreen(false)
      }, 60000) // every minute
    }

    const loadScreen = (initialLoad) => {
      const searchReq = {
        type: contentType.value,
        guid: contentGuid.value,
        expectUser: store.getters.isLoggedIn
      }
      searchContentDetail(searchReq, initialLoad)
        .then((result) => {
          if (initialLoad) {
            if (route.params.type === 'session' && (contentDetailState.content.item.vimeo || contentDetailState.content.item.videoUrl)) {
              playerType.value = 'vimeo'
              clearVim()
              // var iframe = document.querySelector('iframe');

              // eslint-disable-next-line no-undef
              window.vimPlayer = new Vimeo.Player(vimVideo.value)
            }

            clearScreenPolling()
            startScreenPolling()
          } else {
            console.log('REFRESH ***')
          }
        }).catch((e) => {
          if (initialLoad) {
            alert('Failed to load the screen, please refresh and try again.')
          }
        })
    }
    const viewingInfo = async () => {
      const info = {
        player: 'unknown',
        time: -1000
      }
      if (window.vimPlayer) {
        info.player = 21 // VIM VID
        info.time = await window.vimPlayer.getCurrentTime()
      }

      return info
    }

    const sendChatMessage = async (message) => {
      if (contentDetailState.content.item && !contentMessagesState.sending) {
        const info = await viewingInfo()
        sendContentMessage({
          message: message,
          type: contentType.value,
          guid: contentGuid.value,
          dataType: contentDetailState.content.item.dataType,
          recordId: contentDetailState.content.item.id,
          player: info.player,
          time: info.time
        }).catch(() => {
          alert('Failed to send comment, please try again')
        })
      }
    }

    return {
      ampVideo,
      vimVideo,
      contentGuid,
      contentType,
      playerType,
      contentDetailState,
      contentMessagesState,
      sendChatMessage
    }
  },
  computed: {
    ...mapGetters([
      'isLoggedIn'
    ])
  }
})
</script>
