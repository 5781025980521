<template>
<section aria-labelledby="membershipHeading">
  <div class="shadow sm:rounded-md sm:overflow-hidden">
    <div class="bg-white py-6 px-4 sm:p-6">
      <div>
        <h2 id="membershipHeading" class="text-lg leading-6 font-medium text-gray-900">Recurring Donations</h2>
        <p class="mt-1 text-sm text-gray-500">Manage your recurring donations.</p>
      </div>

      <div class="mt-12 space-y-16 sm:mt-16">
        <section aria-labelledby="subscriptions-heading">
          <div class="space-y-1 md:flex md:items-baseline md:space-y-0 md:space-x-4">
            <h2 id="subscriptions-heading" class="text-lg font-medium text-gray-900 md:flex-shrink-0">Your Subscriptions</h2>
          </div>

          <div class="mt-6 -mb-6 flow-root divide-y divide-gray-200 border-t border-gray-200">
            <div v-for="subscription in subscriptions" :key="subscription.id" class="py-6 sm:flex">
              <div class="flex space-x-4 sm:min-w-0 sm:flex-1 sm:space-x-6 lg:space-x-8">
                <img :src="subscription.image ?? 'https://files.stripe.com/links/fl_live_YhXxBt7H4rsSqz1AWxgmgO0u'" :alt="subscription.image" class="h-20 w-20 flex-none rounded-md object-cover object-center sm:h-32 sm:w-32" />
                <div class="min-w-0 flex-1 pt-1.5 sm:pt-0">
                  <h3 class="text-sm font-medium text-gray-900">
                    <a href="#">{{ subscription.name }}</a>
                  </h3>
                  <p class="mt-1 font-medium text-gray-900">
                    {{ $filters.stripeCurrencySymbol(subscription.currency) }} {{ $filters.stripeUnitAmountString(subscription.currency, subscription.unit_amount) }}
                    <span class="ml-2 inline-flex items-center rounded-full bg-gray-100 px-2.5 py-0.5 text-xs font-medium text-gray-800">{{ $filters.stripeStatus(subscription.status) }}</span>
                  </p>
                  <p class="mt-1 truncate text-sm text-gray-500">
                    <span>{{ $filters.dateMed(subscription.createdAt) }}</span>
                    <span class="mx-1 text-gray-400" aria-hidden="true"> - </span>
                    <span>{{ $filters.dateMed(subscription.currentPeriodEndsAt) }}</span>
                  </p>
                </div>
              </div>
              <div class="mt-6 space-y-4 sm:mt-0 sm:ml-6 sm:w-40 sm:flex-none">
                <button type="button" @click.prevent="manageSubscription(subscription)" class="flex w-full items-center justify-center rounded-md bg-blue py-2 px-2.5 text-sm font-medium text-white shadow-sm hover:bg-blue-dark focus:outline-none focus:ring-2 focus:ring-blue focus:ring-offset-2 sm:w-full sm:flex-grow-0">Manage</button>
                <!-- <button type="button" class="flex w-full items-center justify-center rounded-md border border-transparent bg-blue py-2 px-2.5 text-sm font-medium text-white shadow-sm hover:bg-blue-dark focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-full sm:flex-grow-0">Manage</button> -->
                <!-- <div v-if="(subscription.status !== 'canceled') && !subscription.requestCancel" class="inline-flex rounded-md shadow-sm">
                  <button type="button" class="relative inline-flex items-center rounded-l-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500">Options</button>
                  <Menu as="div" class="relative -ml-px block">
                    <MenuButton class="relative inline-flex items-center rounded-r-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500">
                      <span class="sr-only">Open options</span>
                      <ChevronDownIcon class="h-5 w-5" aria-hidden="true" />
                    </MenuButton>
                    <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
                      <MenuItems class="absolute right-0 z-10 mt-2 -mr-1 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <div class="py-1">
                          <MenuItem>
                            <a @click.prevent="cancelSubscription(subscription)" href="#" class="text-red-700 block px-4 py-2 text-sm hover:bg-gray-100">Cancel Subscription</a>
                          </MenuItem>
                          <MenuItem>
                            <a @click.prevent="manageSubscription(subscription)" href="#" class="text-red-700 block px-4 py-2 text-sm hover:bg-gray-100">Manage Subscription</a>
                          </MenuItem>
                        </div>
                      </MenuItems>
                    </transition>
                  </Menu>
                </div>
                <button v-if="(subscription.status !== 'canceled') && subscription.requestCancel" type="button" class="flex w-full items-center justify-center rounded-md bg-white py-2 px-2.5 text-sm font-medium text-red-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue focus:ring-offset-2 sm:w-full sm:flex-grow-0">Cancelling</button> -->
             </div>
            </div>
            <div v-if="subscriptions && (subscriptions.length === 0)" class="py-6 sm:flex">
              <span class="ml-2 inline-flex items-center rounded-full bg-gray-100 px-2.5 py-0.5 text-xs font-medium text-gray-800">None found</span>
            </div>
            <div v-if="subscriptions === undefined" class="py-6 sm:flex">
              <span class="ml-2 inline-flex items-center rounded-full bg-gray-100 px-2.5 py-0.5 text-xs font-medium text-gray-800">Loading...</span>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</section>

<TransitionRoot as="template" :show="openCancelSubscription">
  <Dialog as="div" class="relative z-10" @close="closeCancelSubscription">
    <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
      <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
    </TransitionChild>

    <div class="fixed inset-0 z-10 overflow-y-auto">
      <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
          <div class="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
            <div class="sm:flex sm:items-start">
              <div class="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                <ExclamationCircleIcon class="h-6 w-6 text-red-600" aria-hidden="true" />
              </div>
              <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                <DialogTitle as="h3" class="text-lg font-medium leading-6 text-gray-900">Cancel subscription</DialogTitle>
                <div class="mt-2">
                  <p class="text-sm text-gray-500">Are you sure you want to cancel your subscription? One of our team will request further confirmation via email within 48 hours.</p>
                </div>
              </div>
            </div>
            <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
              <button type="button" class="inline-flex w-full justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm" @click.prevent="cancelSubscriptionConfirm()">Yes - Cancel</button>
              <button type="button" class="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm" @click="closeCancelSubscription" ref="cancelButtonRef">No - Don't Cancel</button>
            </div>
          </div>
        </TransitionChild>
      </div>
    </div>
  </Dialog>
</TransitionRoot>
</template>

<script>
import { defineComponent, computed, ref } from 'vue'
import { useStore } from 'vuex'
import { Dialog, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { ExclamationCircleIcon } from '@heroicons/vue/24/outline'

export default defineComponent({
  components: {
    Dialog,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    // Menu,
    // MenuButton,
    // MenuItem,
    // MenuItems,
    ExclamationCircleIcon
    // ChevronDownIcon
  },
  setup () {
    const store = useStore()
    const openCancelSubscription = ref(false)
    const cancelSubscriptionId = ref('')

    // load the donations/subscriptions
    store.dispatch('membership/loadDonations')

    // generate state items
    const subscriptions = computed(() => store.state.membership.donationSubscriptions)

    const closeCancelSubscription = () => {
      cancelSubscriptionId.value = ''
      openCancelSubscription.value = false
    }
    const cancelSubscription = (subscription) => {
      cancelSubscriptionId.value = subscription.id
      openCancelSubscription.value = true
    }
    const cancelSubscriptionConfirm = () => {
      store.dispatch('membership/cancelSubscription', {
        subscriptionId: cancelSubscriptionId.value
      }).then(() => {
        closeCancelSubscription()
      })
    }
    const manageSubscription = async (subscription) => {
      try {
        // show the loading modal
        store.commit('global/setLoadingModal', {
          open: true
        })

        const billingSession = await store.dispatch('membership/manageSubscription', {
          subscriptionId: subscription.id
        })
        if (billingSession && billingSession.sessionUrl) {
          window.location.href = billingSession.sessionUrl
        } else {
          throw new Error('Unexpected result from manageSubscription')
        }
      } catch (error) {
        store.commit('global/setErrorModal', {
          title: 'Manage Subscription',
          description: 'Failed of get the subscription details. Please try again.',
          open: true
        })
      } finally {
        store.commit('global/setLoadingModal', {
          open: false
        })
      }
    }

    return {
      subscriptions,
      cancelSubscription,
      cancelSubscriptionConfirm,
      closeCancelSubscription,
      openCancelSubscription,
      manageSubscription
    }
  }
})
</script>
