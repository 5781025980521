<template>
  <div v-if="panels" class="max-w-7xl mx-auto pt-6">
    <div class="flex justify-between">
      <h2 class="text-xl sm:text-2xl font-semibold tracking-tight text-blue-dark">{{title}}</h2>
      <a v-if="externalLink" :href="allLink" class="self-end text-gray-400 hover:text-gray-500 font-bold inline">
        View all
      </a>
      <router-link v-else :to="allLink" class="self-end text-gray-400 hover:text-gray-500 font-bold inline">
        View all
      </router-link>
    </div>

    <div class="pt-2">
      <swiper
        :slides-per-view="3"
        :space-between="15"
        :breakpoints="swiperOptions.breakpoints"
        @swiper="onSwiper"
        @slideChange="onSlideChange"
      >
        <swiper-slide v-for="item in panels" :key="item.guid">
          <div @click.prevent="goContent(item, router, route)" class="relative bg-card-img cursor-pointer" v-bind:style="{ 'backgroundImage': getContentImage(item) }">
            <div class="absolute inset-x-0 bottom-0 text-white pl-3 pb-2">
              &nbsp;
            </div>
            <div v-if="item.status == 2" class="absolute inset-x-0 bottom-0 right-0 pr-1 pb-1 text-right">
              <button class="bg-red-500 py-1 px-2 rounded-md text-sm text-white font-bold">{{$filters.contentStatus(item.status)}}</button>
            </div>
          </div>
          <div>
            <div class="pt-2">
              <a @click.prevent="goContent(item, router, route)" href="#" class="font-semibold text-blue block text-ellipsis-2 hover:underline">{{item.title}}</a>
            </div>
            <div>
              <span class="text-sm text-gray-400">{{$filters.dateTimeMed(item.publishedAt)}}</span>
            </div>
          </div>
        </swiper-slide>
      </swiper>
    </div>
  </div>
</template>
<script>
import { useContent } from '@/composables/useContent'
import { Swiper, SwiperSlide } from 'swiper/vue'
import { useRouter, useRoute } from 'vue-router'
import 'swiper/swiper-bundle.min.css'

export default {
  name: 'ListPanel',
  props: [
    'title',
    'externalLink',
    'allLink',
    'panels'
  ],
  components: {
    Swiper,
    SwiperSlide
  },
  setup () {
    const router = useRouter()
    const route = useRoute()
    const { getContentImage, getContentLink, goContent } = useContent()

    const swiperOptions = {
      breakpoints: {
        0: {
          slidesPerView: 2,
          spaceBetween: 12
        },
        768: {
          slidesPerView: 3,
          spaceBetween: 12
        },
        1024: {
          slidesPerView: 4,
          spaceBetween: 12
        }
      }
    }

    const onSwiper = (swiper) => {
      // console.log(swiper)
    }
    const onSlideChange = () => {
      // console.log('slide change')
    }

    return {
      router,
      route,
      getContentImage,
      getContentLink,
      goContent,
      onSwiper,
      onSlideChange,
      swiperOptions
    }
  }
}
</script>
