<template>
  <div class="relative ">
    <div class="absolute inset-0">
      <!-- <video id="bg-vid" autoplay loop muted playsinline>
        <source src="/video/home-bg.mp4" type="video/mp4" />
      </video> -->
      <img class="w-full h-full object-cover" :src="bgSrc" alt="" />
      <div class="absolute inset-0" aria-hidden="true" style="background-image: linear-gradient(to left bottom, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8))" />
    </div>
    <slot></slot>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'HeroBar',
  props: [
    'bgSrc'
  ]
})
</script>

<style scoped>
#bg-vid {
  object-fit: cover;
  top: 0;
  right: 0;
  position: static;
  height: 100%;
  z-index: -99;
}
</style>
