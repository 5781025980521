import { reactive } from 'vue'
import { useRoute } from 'vue-router'

export function useCommon () {
  const getSignInPaths = () => {
    const route = useRoute()
    return reactive({
      signIn: '/sign-in?return=' + encodeURIComponent(route.path),
      signUp: '/sign-up?return=' + encodeURIComponent(route.path)
    })
  }

  return {
    getSignInPaths
  }
}
