<template>
<div class="flex flex-col justify-center py-12 sm:px-6 lg:px-8">
  <div class="sm:mx-auto sm:w-full sm:max-w-md">
    <img class="mx-auto h-12 w-auto" src="@/assets/logo-icon.svg" alt="Workflow">
    <h2 class="mt-6 text-center text-3xl font-bold text-gray-900">
      Order complete
    </h2>
  </div>
  <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
    <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
      <div v-if="validateMembershipState.result && validateMembershipState.result.record">
        <p>Thank you, your order was successful.</p>
        <p class="mt-2">If you ordered an <span class="font-bold">online membership or online eTicket</span>, this should now be active on your account.</p>
        <p class="mt-2">If you ordered <span class="font-bold">tickets to attend an in-person event</span>, then you will receive an email asking you to CONFIRM your ticket details. Please kindly confirm the details as requested. <em>If you do not receive the email in the next few hours, then please check your junk mail. Failing that, please contact tech@prayerstorm.org for assistance.</em></p>
        <router-link :to="redirectUrl" class="mt-4 w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue hover:bg-blue-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue">
          Continue
        </router-link>
      </div>
      <div v-else>
        <p>Validating. Please wait...</p>
        <ErrorMessage :formError="validateMembershipState.error"></ErrorMessage>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import { defineComponent, onMounted } from 'vue'
import { useRoute } from 'vue-router'
import { useMemberships } from '@/composables/useMemberships'
import ErrorMessage from '@/components/ErrorMessage.vue'

export default defineComponent({
  name: 'CheckoutSuccess',
  components: {
    ErrorMessage
  },
  setup () {
    const route = useRoute()
    const { validateMembershipState, validateMembership } = useMemberships()

    // set the sessionId
    validateMembershipState.sessionId = route.params.sessionId

    const redirectUrl = route.query.return ? route.query.return : '/'

    onMounted(() => {
      validateMembership()
        .then(() => {
        }).catch(() => {
        })
    })

    return {
      validateMembershipState,
      redirectUrl
    }
  }
})
</script>
