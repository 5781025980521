import axios from 'axios'
const donationStore = {
  namespaced: true,
  state: () => ({
  }),
  mutations: {
  },
  actions: {
    async setupDonation (context, payload) {
      const response = await axios.post('pub/donations/setup', {
        session: payload.session
      })
      if (response.data && response.data.result) {
        return response.data.result
      }
      return undefined
    },
    async submitDonation (context, payload) {
      const response = await axios.post('pub/donations', {
        firstName: payload.firstName,
        lastName: payload.lastName,
        email: payload.email,
        tel: payload.tel,
        country: payload.country,
        addressOne: payload.addressOne,
        addressTwo: payload.addressTwo,
        townCity: payload.townCity,
        county: payload.county,
        postCode: payload.postCode,
        currency: payload.currency,
        donationType: payload.donationType,
        amount: payload.amount,
        method: payload.method,
        message: payload.message,
        previous: payload.previous,
        ukTax: payload.ukTax,
        giftAid: payload.giftAid,
        agreeOne: payload.agreeOne,
        agreeTwo: payload.agreeTwo,
        agreeThree: payload.agreeThree,
        checkoutId: payload.checkoutId
      })
      if (response.data && response.data.result) {
        return response.data.result
      }
      return undefined
    },
    async submitDonationCampaign (context, payload) {
      const response = await axios.post('pub/donations/campaign', {
        firstName: payload.firstName,
        lastName: payload.lastName,
        email: payload.email,
        tel: payload.tel,
        country: payload.country,
        addressOne: payload.addressOne,
        addressTwo: payload.addressTwo,
        townCity: payload.townCity,
        county: payload.county,
        postCode: payload.postCode,
        currency: payload.currency,
        donationType: payload.donationType,
        amount: payload.amount,
        method: payload.method,
        message: payload.message,
        previous: payload.previous,
        ukTax: payload.ukTax,
        giftAid: payload.giftAid,
        agreeOne: payload.agreeOne,
        agreeTwo: payload.agreeTwo,
        agreeThree: payload.agreeThree,
        agreeFour: payload.agreeFour,
        checkoutId: payload.checkoutId,
        campaignId: payload.campaignId
      })
      if (response.data && response.data.result) {
        return response.data.result
      }
      return undefined
    },
    async submitGiftAidDeclaration (context, payload) {
      const response = await axios.post('pub/giftaiddeclarations', {
        firstName: payload.firstName,
        lastName: payload.lastName,
        email: payload.email,
        tel: payload.tel,
        country: payload.country,
        addressOne: payload.addressOne,
        addressTwo: payload.addressTwo,
        townCity: payload.townCity,
        county: payload.county,
        postCode: payload.postCode,
        previous: payload.previous,
        ukTax: payload.ukTax,
        giftAid: payload.giftAid,
        notes: payload.notes,
        agreeOne: payload.agreeOne,
        agreeTwo: payload.agreeTwo,
        agreeThree: payload.agreeThree
      })
      if (response.data && response.data.result) {
        return response.data.result
      }
      return undefined
    }
  }
}
export default donationStore
