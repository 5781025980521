<template>
<div class="flex flex-col justify-center py-12 sm:px-6 lg:px-8">
  <div class="sm:mx-auto sm:w-full sm:max-w-md">
    <img class="mx-auto h-12 w-auto" src="@/assets/logo-icon.svg" alt="Workflow">
    <h2 class="mt-6 text-center text-3xl font-bold text-gray-900">
      {{supportType}} Setup Complete
    </h2>
  </div>
  <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
    <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
      <div>
        <p>Thank you for your {{supportType}} setup, to support the mission of Prayer Storm.</p>
        <p class="mt-2">We really value and appreciate you and the support that you give.</p>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import { defineComponent, ref } from 'vue'
import { useRoute } from 'vue-router'

export default defineComponent({
  name: 'CheckoutSuccess',
  components: {
  },
  setup () {
    const route = useRoute()
    const supportType = ref('Donation')
    if (route.fullPath.includes('partner')) {
      supportType.value = 'Partnership'
    }

    return {
      supportType
    }
  }
})
</script>
