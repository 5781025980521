<template>
  <div class="overflow-hidden bg-white py-16 px-4 sm:px-6 lg:px-8 lg:py-24">
    <div class="relative mx-auto max-w-xl">
      <svg class="absolute left-full translate-x-1/2 transform" width="404" height="404" fill="none" viewBox="0 0 404 404" aria-hidden="true">
        <defs>
          <pattern id="85737c0e-0916-41d7-917f-596dc7edfa27" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
            <rect x="0" y="0" width="4" height="4" class="text-gray-200" fill="currentColor" />
          </pattern>
        </defs>
        <rect width="404" height="404" fill="url(#85737c0e-0916-41d7-917f-596dc7edfa27)" />
      </svg>
      <svg class="absolute right-full bottom-0 -translate-x-1/2 transform" width="404" height="404" fill="none" viewBox="0 0 404 404" aria-hidden="true">
        <defs>
          <pattern id="85737c0e-0916-41d7-917f-596dc7edfa27" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
            <rect x="0" y="0" width="4" height="4" class="text-gray-200" fill="currentColor" />
          </pattern>
        </defs>
        <rect width="404" height="404" fill="url(#85737c0e-0916-41d7-917f-596dc7edfa27)" />
      </svg>
      <div class="text-center">
        <h2 class="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Gift Aid</h2>
        <p class="mt-4 text-lg leading-6 text-gray-500">Please use the following form to submit or update your Gift Aid declaration details with Prayer Storm. Gift Aid is only available for UK residents.</p>
      </div>
      <div class="mt-3">
        <img src="/img/gift-aid/gift-aid-it.png" class="w-1/4 m-auto"/>
      </div>
      <div class="center mt-3">
        <p class="text-red-500 text-right text-sm font-bold">
          All fields are required
        </p>
      </div>
      <div class="mt-6">
        <form class="grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8" id="donate-form" @submit.prevent="submitForm">
          <div>
            <label for="first-name" class="block text-sm font-medium text-gray-700">First name</label>
            <div class="mt-1">
              <input v-model="declarationState.firstName" type="text" name="first-name" id="first-name" autocomplete="given-name" class="block w-full rounded-md border-gray-300 py-3 px-4 shadow-sm focus:border-blue focus:ring-blue" />
            </div>
          </div>
          <div>
            <label for="last-name" class="block text-sm font-medium text-gray-700">Last name</label>
            <div class="mt-1">
              <input v-model="declarationState.lastName" type="text" name="last-name" id="last-name" autocomplete="family-name" class="block w-full rounded-md border-gray-300 py-3 px-4 shadow-sm focus:border-blue focus:ring-blue" />
            </div>
          </div>
          <div class="sm:col-span-2">
            <label for="email" class="block text-sm font-medium text-gray-700">Email</label>
            <div class="mt-1">
              <input v-model="declarationState.email" id="email" name="email" type="email" autocomplete="email" class="block w-full rounded-md border-gray-300 py-3 px-4 shadow-sm focus:border-blue focus:ring-blue" />
            </div>
          </div>
          <div class="sm:col-span-2">
            <label for="tel" class="block text-sm font-medium text-gray-700">Telephone</label>
            <div class="mt-1">
              <input v-model="declarationState.tel" id="tel" name="tel" type="tel" autocomplete="tel" class="block w-full rounded-md border-gray-300 py-3 px-4 shadow-sm focus:border-blue focus:ring-blue" />
            </div>
          </div>
          <div class="sm:col-span-2">
            <label for="country" class="block text-sm font-medium text-gray-700">Country</label>
            <div class="mt-1">
              <select v-model="declarationState.country" id="country" name="country" class="mt-1 block w-full border border-gray-300 rounded-md py-3 px-4 focus:outline-none focus:ring-blue focus:border-blue" disabled>
                <option value="GB">United Kingdom of Great Britain and Northern Ireland</option>
              </select>
            </div>
          </div>
          <div class="sm:col-span-2">
            <label for="address-one" class="block text-sm font-medium text-gray-700">Address Line 1</label>
            <div class="mt-1">
              <input v-model="declarationState.addressOne" type="text" name="address-one" id="address-one" autocomplete="off" class="block w-full rounded-md border-gray-300 py-3 px-4 shadow-sm focus:border-blue focus:ring-blue" />
            </div>
          </div>
          <div class="sm:col-span-2">
            <label for="address-two" class="block text-sm font-medium text-gray-700">Address Line 2</label>
            <div class="mt-1">
              <input v-model="declarationState.addressTwo" type="text" name="address-two" id="address-two" autocomplete="off" class="block w-full rounded-md border-gray-300 py-3 px-4 shadow-sm focus:border-blue focus:ring-blue" />
            </div>
          </div>
          <div class="sm:col-span-2">
            <label for="town-city" class="block text-sm font-medium text-gray-700">Town/City</label>
            <div class="mt-1">
              <input v-model="declarationState.townCity" type="text" name="town-city" id="town-city" autocomplete="off" class="block w-full rounded-md border-gray-300 py-3 px-4 shadow-sm focus:border-blue focus:ring-blue" />
            </div>
          </div>
          <div class="sm:col-span-2">
            <label for="county" class="block text-sm font-medium text-gray-700">County/State</label>
            <div class="mt-1">
              <input v-model="declarationState.county" type="text" name="county" id="county" autocomplete="off" class="block w-full rounded-md border-gray-300 py-3 px-4 shadow-sm focus:border-blue focus:ring-blue" />
            </div>
          </div>
          <div class="sm:col-span-2">
            <label for="post-code" class="block text-sm font-medium text-gray-700">Post/Zip Code</label>
            <div class="mt-1">
              <input v-model="declarationState.postCode" type="text" name="post-code" id="post-code" autocomplete="off" class="block w-full rounded-md border-gray-300 py-3 px-4 shadow-sm focus:border-blue focus:ring-blue" />
            </div>
          </div>
          <div class="sm:col-span-2">
            <label for="previous" class="block text-sm font-medium text-gray-700">Have you previously submitted a Gift Aid declaration to Prayer Storm? This could be online, or via a giving envelope.</label>
            <div class="mt-1">
              <select v-model="declarationState.previous" id="previous" name="previous" class="mt-1 block w-full border border-gray-300 rounded-md py-3 px-4 focus:outline-none focus:ring-blue focus:border-blue">
                <option value="">Please specify...</option>
                <option value="1">Yes - I have submitted Gift Aid details before</option>
                <option value="0">No - This is my first Gift Aid declaration</option>
              </select>
            </div>
          </div>
          <div class="sm:col-span-2">
            <label for="donation-tax-payer" class="block text-sm font-medium text-gray-700">Are you a U.K. (United Kingdom) tax payer?</label>
            <div class="relative mt-1 rounded-md shadow-sm">
              <select v-model="declarationState.ukTax" autocomplete="off" id="donation-tax-payer" name="donation-tax-payer" class="mt-1 block w-full border border-gray-300 rounded-md py-3 px-4 focus:outline-none focus:ring-blue focus:border-blue">
                <option value="">Please specify...</option>
                <option value="1">Yes - I am a UK tax payer</option>
                <option value="0">No - I am not</option>
              </select>
            </div>
          </div>
          <div v-show="declarationState.ukTax === '1'" class="sm:col-span-2">
            <label for="donation-gift-aid" id="donation-gift-aid-label"  class="block text-sm font-medium text-gray-700">Would you like to <strong>Gift Aid</strong> your donations? <br/> It will increase your donation by <strong>25% at no extra cost to you</strong>?</label>
            <div class="relative mt-1 rounded-md shadow-sm">
              <select v-model="declarationState.giftAid" @change="scrollToInput('donation-gift-aid-label')" id="donation-gift-aid" name="donation-gift-aid" class="mt-1 block w-full border border-gray-300 rounded-md py-3 px-4 focus:outline-none focus:ring-blue focus:border-blue">
                <option value="">Please specify...</option>
                <option value="1">Yes - add Gift Aid</option>
                <option value="0">No - don't add Gift Aid</option>
              </select>
            </div>
          </div>
          <div v-show="declarationState.giftAid === '1' && declarationState.ukTax === '1'" class="sm:col-span-2 rounded-md bg-gray-100 p-5">
            <div class="flex items-end mt-3">
              <p class="flex-1 text-lg leading-6 text-gray-500 font-bold">
                Prayer Storm<br />
                Reg. Charity No: 1161853
              </p>
              <img src="/img/gift-aid/gift-aid-it.png" class="w-1/4 object-right"/>
            </div>
            <p class='text-sm text-gray-700 mt-6 font-bold'>BOOST YOUR DONATION BY 25P OF GIFT AID FOR EVERY £1 YOU DONATE</p>
            <p class="text-sm text-gray-700 pt-1">Gift Aid is reclaimed by the charity from the tax you pay in the current tax year.</p>
            <p class='text-sm text-gray-700 pt-1 font-bold'>YOUR ADDRESS IS NEEDED ABOVE TO IDENTIFY YOU AS A CURRENT UK TAXPAYER.</p>
            <p class='text-lg text-gray-700 mt-6'><strong>Gift Aid Declaration</strong></p>
            <p class='text-sm text-gray-700 pt-3'>I want to Gift Aid my donation and any donations I make in the future or have made in the past 4 years to Prayer Storm.</p>
            <p class='text-sm text-gray-700 pt-5'>I am a UK tax payer and understand that if I pay less Income Tax and/or Capital Gains Tax than the amount of Gift Aid claimed on all my donations in that tax year it is my responsibility to pay any difference.</p>
            <p class='text-sm text-gray-700 pt-3'>I will notify Prayer Storm if I want to cancel this declaration, if I change my name or home address, or if I no longer pay sufficient tax on my income and/or capital gains.</p>
            <p class='text-sm text-gray-700 pt-3'>If you pay Income Tax at the higher or additional rate and want to receive the additional tax relief due to you, you must include all your Gift Aid donations on your Self-Assessment tax return or ask HM Revenue and Customs to adjust your tax code.</p>
            <p class='text-sm text-gray-700 pt-5 font-bold'>In order to Gift Aid, you must complete the form above and confirm the following statements.</p>
            <fieldset class="space-y-5">
              <legend class="sr-only">Gift Aid Declaration</legend>
              <div class="relative flex items-start">
                <div class="flex h-5 items-center">
                  <input v-model="declarationState.agreeOne" id="agree-one" aria-describedby="agree-one-description" name="agree-one" type="checkbox" class="h-4 w-4 rounded border-gray-300 text-blue focus:ring-blue" />
                </div>
                <div class="ml-3 text-sm">
                  <p id="agree-one-description" class="text-gray-700">This donation is my own money. It has not come from anyone else e.g. a business, friends, family or a collection.</p>
                </div>
              </div>
              <div class="relative flex items-start">
                <div class="flex h-5 items-center">
                  <input v-model="declarationState.agreeTwo" id="agree-two" aria-describedby="agree-two-description" name="agree-two" type="checkbox" class="h-4 w-4 rounded border-gray-300 text-blue focus:ring-blue" />
                </div>
                <div class="ml-3 text-sm">
                  <p id="agree-two-description" class="text-gray-700">This donation is not the proceeds from the sale of goods or provision of services e.g. a cake sale, auction or car wash.</p>
                </div>
              </div>
              <div class="relative flex items-start">
                <div class="flex h-5 items-center">
                  <input v-model="declarationState.agreeThree" id="agree-three" aria-describedby="agree-three-description" name="agree-three" type="checkbox" class="h-4 w-4 rounded border-gray-300 text-blue focus:ring-blue" />
                </div>
                <div class="ml-3 text-sm">
                  <p id="agree-three-description" class="text-gray-700">I am not receiving any benefits from this donation e.g. entry to an event, raffle or sweepstakes.</p>
                </div>
              </div>
            </fieldset>
          </div>
          <div class="sm:col-span-2">
            <label for="message" class="block text-sm font-medium text-gray-700">Are there any additional notes that you would like to make in relation to past donations, or further information that you think may help us in identifying your previous donations or gift aid declarations?</label>
            <div class="mt-1">
              <textarea v-model="declarationState.notes" id="notes" name="notes" rows="4" class="block w-full rounded-md border-gray-300 py-3 px-4 shadow-sm focus:border-blue focus:ring-blue" />
            </div>
          </div>
          <div class="sm:col-span-2">
            <ErrorMessage :formError="declarationState.error" class="mb-6"></ErrorMessage>
            <SuccessMessage :successMessage="declarationState.result" class="mb-6"></SuccessMessage>
            <button @click.prevent="submitForm" :disabled="declarationState.executing || declarationState.result !== ''" class="disabled:opacity-50 inline-flex w-full items-center justify-center rounded-md border border-transparent bg-blue px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-blue-dark focus:outline-none focus:ring-2 focus:ring-blue focus:ring-offset-2">Submit</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, reactive } from 'vue'
import ErrorMessage from '@/components/ErrorMessage.vue'
import SuccessMessage from '@/components/SuccessMessage.vue'
import responseHelper from '../helpers/response.helper'
import store from '../store'

export default defineComponent({
  components: {
    ErrorMessage,
    SuccessMessage
  },
  setup () {
    const declarationState = reactive({
      firstName: store.getters.getFirstName,
      lastName: store.getters.getLastName,
      email: store.getters.getEmail,
      tel: '',
      country: 'GB',
      addressOne: '',
      addressTwo: '',
      townCity: '',
      county: '',
      postCode: '',
      previous: '',
      ukTax: '',
      giftAid: '',
      notes: '',
      agreeOne: false,
      agreeTwo: false,
      agreeThree: false,
      executing: false,
      error: responseHelper.noError,
      result: ''
    })

    const submitForm = () => {
      // gift aid should not be set if not uk tax payer
      if (declarationState.ukTax === '0') {
        declarationState.giftAid = '0'
      }

      // if gift aid, then check all agreements have been made
      if (declarationState.giftAid === '1') {
        if (!declarationState.agreeOne || !declarationState.agreeTwo || !declarationState.agreeThree) {
          declarationState.error = responseHelper.createError('Agreement Needed', ['Please read and confirm the Gift Aid declarations.'])
          return
        }
      }

      // check not executing action already
      if (declarationState.executing) {
        return
      }

      // execute the remote action
      declarationState.executing = true
      declarationState.error = responseHelper.noError
      store.dispatch('donation/submitGiftAidDeclaration', declarationState)
        .then((response) => {
          if (response?.id > 0) {
            declarationState.result = 'Thank you, your gift aid declaration has been submitted.'
          } else {
            declarationState.result = ''
            declarationState.error = responseHelper.unexpectedResult
          }
        }).catch((error) => {
          declarationState.error = responseHelper.getMessage(error)
        }).finally(() => {
          declarationState.executing = false
        })
    }

    const scrollToInput = (elementId) => {
      setTimeout(() => {
        const scrollTo = document.getElementById(elementId)
        scrollTo.scrollIntoView({ behavior: 'smooth' })
      }, 200)
    }

    return {
      scrollToInput,
      declarationState,
      submitForm
    }
  }
})
</script>
