<template>
  <div class="bg-white py-12 sm:py-6">
    <div class="mx-auto max-w-5xl px-6">
      <div class="mx-auto max-w-xl text-center">
        <h2 class="text-2xl font-bold">FAQs</h2>
      </div>
      <dl class="mt-4 space-y-6 divide-y divide-gray-900/10">
        <Disclosure as="div" v-for="faq in faqs" :key="faq.question" class="pt-6" v-slot="{ open }">
          <dt>
            <DisclosureButton class="flex w-full items-start justify-between text-left text-gray-900">
              <span class="text-base font-semibold leading-7">{{ faq.question }}</span>
              <span class="ml-6 flex h-7 items-center">
                <PlusSmallIcon v-if="!open" class="h-6 w-6" aria-hidden="true" />
                <MinusSmallIcon v-else class="h-6 w-6" aria-hidden="true" />
              </span>
            </DisclosureButton>
          </dt>
          <DisclosurePanel as="dd" class="mt-2 pr-12">
            <p class="text-base leading-7 text-gray-600" v-html="faq.answer"></p>
          </DisclosurePanel>
        </Disclosure>
      </dl>
    </div>
  </div>
</template>

<script setup>
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue'
import { MinusSmallIcon, PlusSmallIcon } from '@heroicons/vue/24/outline'

const faqs = [
  {
    question: 'Is this just for kids? Can I attend if I\'m an adult?',
    answer: 'This event is designed for children! Parents/guardians with their children are permitted and required to attend with their children for supervision. Children/Youth workers are also permitted entrance with a valid ticket to observe and learn from the event. Please note adults without children will be allocate to sit in a specific area in the venue.'
  },
  {
    question: 'Do I need to stay with my children?',
    answer: 'Children must be accompanied by a responsible parent/guardian at all times during this event.'
  },
  {
    question: 'If I have children under 7, can they still attend?',
    answer: 'Yes they can attend, however they will have to stay with their parent the whole time. During the conference only 7+ can be at the front during worship and prayer times.'
  },
  {
    question: 'Where is the venue?',
    answer: 'The Lighthouse Venue, 12 Centenary Park, Coronet Way, Eccles, Salford, M50 1RE, UK'
  },
  {
    question: 'When is this taking place?',
    answer: '7-8th June 2024. 4 Sessions: 7th June 2024 (7pm). 8th June 2024 (10am / 2pm / 6pm).'
  },
  {
    question: 'Can I get a refund?',
    answer: 'Please refer to our <a href="https://docs.prayerstorm.org/reference/events/tickets/cancellations-and-refunds" target="_blank" rel="noopener noreferrer" class="text-blue underline font-bold">Cancellations and Refunds Policy</a>.'
  }
]
</script>
