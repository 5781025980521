const globalStore = {
  namespaced: true,
  state: () => ({
    accessModal: {
      open: false,
      title: '',
      description: '',
      contentDetail: undefined
    },
    errorModal: {
      open: false,
      title: '',
      description: ''
    },
    loadingModal: {
      open: false,
      style: 'background: rgba(0, 0, 0, 0.3)'
    },
    watchOnModal: {
      open: false,
      prayerstorm: false,
      prayerstormGuid: '',
      youtube: false,
      facebook: false
    }
  }),
  mutations: {
    setAccessModal (state, payload) {
      state.accessModal = {
        title: payload.title ?? '',
        description: payload.description ?? '',
        contentDetail: payload.contentDetail ?? null,
        open: payload.open ?? false
      }
    },
    setErrorModal (state, payload) {
      state.errorModal.title = payload.title
      state.errorModal.description = payload.description
      state.errorModal.open = payload.open
    },
    setLoadingModal (state, payload) {
      state.loadingModal.style = payload.style ?? 'background: rgba(0, 0, 0, 0.3)'
      state.loadingModal.open = payload.open
    },
    setWatchOnModal (state, payload) {
      state.watchOnModal = {
        open: payload.open ?? false,
        prayerstorm: payload.prayerstorm ?? false,
        prayerstormGuid: payload.prayerstormGuid ?? '',
        youtube: payload.youtube ?? false,
        facebook: payload.facebook ?? false
      }
    }
  },
  actions: {
  }
}
export default globalStore
