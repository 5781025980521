<template>
  <div class="music-page">
    <h1>This is the music page</h1>
  </div>
</template>

<script>
export default {
  name: 'Music'
}
</script>

<style scoped>
</style>
