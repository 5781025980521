import axios from 'axios'
const checkoutStore = {
  namespaced: true,
  state: () => ({
    checkouts: {}
  }),
  mutations: {
  },
  actions: {
    async getCheckoutInfo (context, payload) {
      const response = await axios.get('pub/checkouts/' + payload.checkoutId)
      if (response.data && response.data.result) {
        return response.data.result
      }
      return undefined
    },
    async getTicketInfo (context, payload) {
      const response = await axios.get('pub/tickets/' + payload.ticketId)
      if (response.data && response.data.result) {
        return response.data.result
      }
      return undefined
    },
    async getEventOptions (context, payload) {
      const response = await axios.get('pub/checkouts/events/' + payload.eventId + '/options')
      if (response.data && response.data.result) {
        return response.data.result
      }
      return undefined
    },
    async getOptionTickets (context, payload) {
      const response = await axios.get('pub/checkouts/options/' + payload.optionId + '/tickets')
      if (response.data && response.data.result) {
        return response.data.result
      }
      return undefined
    }
  }
}
export default checkoutStore
