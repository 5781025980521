<template>
  <div class="bg-gray-50">
    <div class="max-w-2xl mx-auto pt-16 pb-24 px-4 sm:px-6 lg:max-w-7xl lg:px-8">
      <h2 class="sr-only">Checkout</h2>
      <form class="lg:grid lg:grid-cols-2 lg:gap-x-12 xl:gap-x-16" v-if="eTicketState.eTicket">
        <div>
          <h2 class="text-xl font-bold text-blue">{{ eTicketState.eTicket.eventTitle }} [{{ eTicketState.eTicket.title }}]</h2>

          <p class="text-sm text-gray-600 mt-2" v-if="eTicketState.eTicket.ticketType === ticketTypes.ETICKET">
            eTickets are a one time purchase, granting the account holder access to a single event. Enabling them to watch any Live Stream or OnDemand videos for that event without a membership subscription.
          </p>
          <p class="text-sm text-gray-600 mt-2" v-if="eTicketState.eTicket.ticketType === ticketTypes.INPERSON">
            This ticket grants the account holder in-person attendance to this event at the location advertised.
          </p>

          <!-- <router-link :to="changeMembershipLink" class="text-sm mt-2 inline-block"><ArrowLeftCircleIcon class="text-gray-500 h-5 w-5 inline"></ArrowLeftCircleIcon> Select a different membership</router-link> -->

          <div class="mt-10 border-t border-gray-200 pt-10">
            <h2 class="text-lg font-medium text-gray-900">Account Information</h2>
            <p class="text-sm text-gray-600 mt-2">This is information linked to the account you are currently logged in to.</p>
            <p class="text-sm text-gray-600 font-bold">eTickets are assigned to this account.</p>

            <div class="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-4">
              <div class="sm:col-span-2">
                <label for="email-address" class="block text-sm font-medium text-gray-700">Email address</label>
                <div class="mt-1">
                    <input v-model="checkoutETicketState.email" type="email" id="email-address" name="email-address" autocomplete="email" class="block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm bg-gray-100" disabled/>
                </div>
              </div>

              <div>
                <label for="first-name" class="block text-sm font-medium text-gray-700">First name</label>
                <div class="mt-1">
                  <input v-model="checkoutETicketState.firstName" type="text" id="first-name" name="first-name" autocomplete="given-name" class="block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
                </div>
              </div>

              <div>
                <label for="last-name" class="block text-sm font-medium text-gray-700">Last name</label>
                <div class="mt-1">
                  <input v-model="checkoutETicketState.lastName" type="text" id="last-name" name="last-name" autocomplete="family-name" class="block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Order summary -->
        <div class="mt-10 lg:mt-0">
          <h2 class="text-lg font-medium text-gray-900">Summary</h2>

          <div class="mt-4 bg-white border border-gray-200 rounded-lg shadow-sm">
            <h3 class="sr-only">Items in your cart</h3>
            <dl class="py-6 px-4 space-y-6 sm:px-6">
              <div v-if="eTicketState.eTicket.hasMemberPrice && $filters.arrayItemsExist(eTicketState.profile.activeMemberships)">
                <div class="flex items-center justify-between">
                  <dt class="text-sm mb-6">
                    {{ eTicketState.eTicket.title }} - Subscriber
                    <p class="text-sm text-gray-600 text-center mt-1">available as you are a current subscriber</p>
                  </dt>
                  <dd class="text-sm font-medium text-gray-900">
                    {{toMemberPrice(eTicketState.eTicket)}}
                  </dd>
                </div>
                <div class="flex items-center justify-between border-t border-gray-200 pt-6">
                  <dt class="text-base font-medium">
                    Total
                  </dt>
                  <dd class="text-base font-medium text-gray-900">
                    {{toMemberPrice(eTicketState.eTicket)}}
                  </dd>
                </div>
              </div>
              <div v-else-if="eTicketState.eTicket.isEarlyBird">
                <div class="flex items-center justify-between">
                  <dt class="text-sm mb-6">
                    {{ eTicketState.eTicket.title }} - Early Bird
                    <p class="text-sm text-gray-600 text-center mt-1">available until {{eTicketState.eTicket.earlyBirdDateTime.toLocaleString(DateTime.DATE_MED)}} then £{{Number.parseFloat(eTicketState.eTicket.price).toFixed(2)}}</p>
                  </dt>
                  <dd class="text-sm font-medium text-gray-900">
                    £{{Number.parseFloat(eTicketState.eTicket.earlyBirdPrice).toFixed(2)}}
                  </dd>
                </div>
                <div class="flex items-center justify-between border-t border-gray-200 pt-6">
                  <dt class="text-base font-medium">
                    Total
                  </dt>
                  <dd class="text-base font-medium text-gray-900">
                    £{{ Number.parseFloat(eTicketState.eTicket.earlyBirdPrice).toFixed(2) }}
                  </dd>
                </div>
              </div>
              <div v-else>
                <div class="flex items-center justify-between">
                  <dt class="text-sm mb-6">
                    {{ eTicketState.eTicket.eventTitle }} [{{ eTicketState.eTicket.title }}]
                  </dt>
                  <dd class="text-sm font-medium text-gray-900">
                    £{{Number.parseFloat(eTicketState.eTicket.price).toFixed(2)}}
                  </dd>
                </div>
                <div class="flex items-center justify-between border-t border-gray-200 pt-6">
                  <dt class="text-base font-medium">
                    Total
                  </dt>
                  <dd class="text-base font-medium text-gray-900">
                    £{{ Number.parseFloat(eTicketState.eTicket.price).toFixed(2) }}
                  </dd>
                </div>
              </div>
            </dl>

            <div class="border-t border-gray-200 py-6 px-4 sm:px-6">
              <ErrorMessage :formError="checkoutETicketState.error"></ErrorMessage>
              <button @click.prevent="checkout" :disabled="checkoutETicketState.executing" type="submit" class="disabled:opacity-50 w-full bg-blue border border-transparent rounded-md shadow-sm py-3 px-4 text-base font-medium text-white hover:bg-blue-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-indigo-500">Purchase</button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { defineComponent, onMounted } from 'vue'
import { useETickets } from '@/composables/useETickets'
import { useRoute, useRouter } from 'vue-router'
import ErrorMessage from '@/components/ErrorMessage.vue'
import store from '../store'
import { DateTime } from 'luxon'
import { ticketTypes } from '../lib/constants'

// import {
//   ArrowRightCircleIcon
// } from '@heroicons/vue/20/solid'

export default defineComponent({
  components: {
    // ArrowRightCircleIcon,
    ErrorMessage
  },
  setup () {
    const route = useRoute()
    const router = useRouter()

    if (!store.getters.isLoggedIn) {
      router.push('/sign-in?return=' + encodeURIComponent(route.path))
    }

    const { eTicketState, searchETicket, checkoutETicketState, checkoutETicket } = useETickets()

    onMounted(() => {
      searchETicket({
        type: route.params.type,
        guid: route.params.guid,
        id: route.params.id
      }).then((result) => {
        if (result) {
          checkoutETicketState.email = result.profile.email
          checkoutETicketState.firstName = result.profile.firstName
          checkoutETicketState.lastName = result.profile.lastName
        }
      })
    })

    const checkout = () => {
      checkoutETicket(eTicketState.eTicket.id)
        .then((result) => {
          if (result) {
            window.location.replace(result.sessionUrl)
          } else {
            console.log('invalid result')
          }
        })
        .catch(() => { })
    }

    const toMemberPrice = (eTicket) => {
      let priceString
      if (eTicket.memberPrice > 0) {
        priceString = '£' + Number.parseFloat(eTicket.memberPrice).toFixed(2)
      } else {
        priceString = 'FREE'
      }
      return priceString
    }

    return {
      ticketTypes,
      checkoutETicketState,
      eTicketState,
      checkout,
      DateTime,
      toMemberPrice
    }
  },
  computed: {
    ...mapGetters([
      'isLoggedIn'
    ])
  }
})
</script>
