<template>
<div class="flex flex-col h-96 lg:h-screen lg:pb-28">
  <div class="flex-none">
    <div class="px-3 py-2 align-middle">
      <span @click="changeView('bible')" class="inline-block align-middle relative mr-3 text-gray-400 hover:text-blue cursor-pointer">
        <BookOpenIcon class="h-10 w-10" aria-hidden="true" />
      </span>
      <span @click="changeView('chat')" class="inline-block align-middle relative mr-3 cursor-pointer">
        <img class="h-8 w-8 rounded-full" src="@/assets/logo-icon.svg" alt="">
      </span>
      <!-- <span class="inline-block relative mr-3">
        <img class="h-10 w-10 rounded-full" src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixqx=9nSWiDeoZT&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80" alt="">
        </span>
      <span class="inline-block relative">
        <img class="h-10 w-10 rounded-full" src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixqx=9nSWiDeoZT&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80" alt="">
      </span> -->
    </div>
    <div class="flex items-center border-t border-b border-gray-200">
      <h2 class="font-bold pl-3 py-1 flex-none">{{ sectionTitle }}</h2>
      <div class="flex-1 text-right">
        <router-link class="text-blue font-bold hover:text-blue-dark pr-5" to="/settings/profile">Profile <CogIcon class="h-5 w-5 inline"/></router-link>
      </div>
    </div>
  </div>
  <div v-show="bible" class="bg-white flex-1 overflow-hidden">
    <iframe src="https://chop.bible.com/en/bible/111/GEN.1.niv" height="100%" width="100%" style=""></iframe>
  </div>
  <div v-show="!bible" class="bg-white flex-1 overflow-y-scroll overflow-x-hidden" ref="commentsContainer">
    <div class="pt-2">
      <div v-for="(message, i) in messages" :key="i">
        <div class="flex items-center py-1 hover:bg-gray-100 cursor-pointer">
          <span class="px-3 relative flex-none self-start">
            <img v-if="message.a" class="h-7 w-7 rounded-full" :src="message.a" alt="">
            <UserIcon v-else class="h-7 w-7 rounded-full" aria-hidden="true" />
            <!-- <span class="absolute top-0 right-0 block h-2 w-2 rounded-full ring-2 ring-white bg-red-400"></span> -->
          </span>
          <div class="flex-grow leading-tight pr-2">
            <span class="font-bold text-sm align-middle">{{ message.n }} &nbsp;</span>
            <span class="text-sm align-middle">{{ message.m }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-show="!bible" class="flex-none">
    <div v-if="isLoggedIn" class="flex items-center">
      <div class="flex-none p-2 pt-3">
        <button @click="setupEmoji" class="emoji-select focus:outline-none text-gray-400 focus:text-gray-600">
          <FaceSmileIcon class="w-7 h-7 cursor-pointer" aria-hidden="true" />
        </button>
      </div>
      <div class="flex-grow pt-3 pb-3">
        <textarea v-model="contentMessagesState.message" :disabled="contentMessagesState.sending" rows="1" ref="newMessage" class="emoji-input resize-none shadow-sm focus:ring-blue focus:border-blue block w-full sm:text-sm border-gray-300 rounded-md px-1"></textarea>
      </div>
      <div class="flex-none p-2 pt-3">
        <button :disabled="contentMessagesState.sending" @click="send" class="disabled:opacity-50 focus:outline-none text-blue focus:text-blue-dark">
          <PaperAirplaneIcon class="w-7 h-7 cursor-pointer" aria-hidden="true" />
        </button>
      </div>
    </div>
    <div v-else >
      <div class="p-4">
        <span>To comment please <router-link class="font-bold text-blue" :to="signInPaths.signIn">Log In</router-link> or <router-link class="font-bold text-blue" :to="signInPaths.signUp">Sign Up</router-link></span>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import { defineComponent, ref, onMounted, onUnmounted } from 'vue'
import store from '../store'
import { mapGetters } from 'vuex'
import { useCommon } from '@/composables/useCommon'
import io from 'socket.io-client'
import * as fgEmoji from '@/assets/emoji/fgEmojiPicker.js'
import { useContentMessages } from '@/composables/useContentMessages'
import { useContent } from '@/composables/useContent'
import {
  CogIcon,
  FaceSmileIcon,
  PaperAirplaneIcon,
  BookOpenIcon,
  UserIcon
} from '@heroicons/vue/24/outline'

export default defineComponent({
  name: 'ChatLive',
  components: {
    CogIcon,
    FaceSmileIcon,
    PaperAirplaneIcon,
    BookOpenIcon,
    UserIcon
  },
  setup (props, context) {
    const common = useCommon()
    const signInPaths = common.getSignInPaths()
    const { contentMessagesState } = useContentMessages(false)
    const { contentDetailState } = useContent()

    let socket = io.connect(process.env.VUE_APP_SOCKET_URL, {
      extraHeaders: { Authorization: store.state.token }
    })
    let initialLoad = false
    const messages = ref([])
    const commentsTitle = 'Live Comments'
    const sectionTitle = ref(commentsTitle)
    const bible = ref(false)
    const commentsContainer = ref(null)
    const newMessage = ref(null)
    let emoji

    const isScrollBase = () => {
      if (commentsContainer.value) {
        const scrollCalc = commentsContainer.value.scrollTop + commentsContainer.value.offsetHeight
        if (scrollCalc >= (commentsContainer.value.scrollHeight - 50)) {
          return true
        }
      }
      return false
    }
    const scrollToBase = () => {
      if (commentsContainer.value && commentsContainer.value.scrollHeight) {
        setTimeout(() => {
          commentsContainer.value.scrollTop = commentsContainer.value.scrollHeight
        },
        200)
      }
    }

    socket.on('connect_error', (err) => {
      console.log(err.message) // prints the message associated with the error
    })

    socket.on('chat-message', (data) => {
      const message = JSON.parse(data)
      if (message.action === 'heartbeat') {
        console.log('heartbeat')
      } else if (message.action === 'removeitem') {
        console.log('removeitem')
        if (messages.value && messages.value.length > 0) {
          messages.value = messages.value.filter((element) => {
            return (element.id !== message.id)
          })
          scrollToBase()
        }
      } else if (message.action === 'updateitem') {
        console.log('updateitem')
        if (messages.value && messages.value.length > 0) {
          messages.value.forEach((element) => {
            if (element.id === message.id) {
              // eslint-disable-next-line no-param-reassign
              element.m = message.m
            }
          })
        }
      } else if (message.action === 'avatar') {
        console.log('avatar')
        if (messages.value && messages.value.length > 0) {
          if (message.p === 1 && message.a) {
            message.a = `${process.env.VUE_APP_BLOB_CONTAINER}/images/${message.a}`
          }
          messages.value.forEach((element) => {
            if (element.u === message.u) {
              // eslint-disable-next-line no-param-reassign
              element.a = message.a
            }
          })
        }
      } else if (message.action === 'displayname') {
        console.log('displayname')
        if (messages.value && messages.value.length > 0) {
          messages.value.forEach((element) => {
            if (element.u === message.u) {
              // eslint-disable-next-line no-param-reassign
              element.n = message.n
            }
          })
        }
      } else if (message.u) {
        console.log('message')
        if (message.p === 1 && message.a) {
          message.a = process.env.VUE_APP_BLOB_CONTAINER + '/images/' + message.a
        }
        if (commentsContainer.value) {
          const scrollBase = isScrollBase()
          messages.value.push(message)
          if (scrollBase) {
            scrollToBase()
          }
        } else {
          // just push the message
          messages.value.push(message)
        }
      }
    })

    socket.on('load-latest', (data) => {
      if (data && data.length > 0) {
        data.forEach((message) => {
          if (message.p === 1 && message.a) {
            // eslint-disable-next-line no-param-reassign
            message.a = process.env.VUE_APP_BLOB_CONTAINER + '/images/' + message.a
          }
        })
        messages.value.push(...data)
        scrollToBase()
      }
    })

    socket.on('udpate-name', (data) => {
      messages.value.push(data)
    })

    // load latest items on socket connect
    socket.on('connect', () => {
      if (!initialLoad) {
        initialLoad = true
        setTimeout(() => {
          if (contentDetailState && contentDetailState.content && contentDetailState.content.item) {
            socket.emit('loadlatest', {
              dataType: contentDetailState.content.item.dataType,
              recordId: contentDetailState.content.item.id
            })
          }
        },
        1000)
      }
    })

    async function send () {
      const message = newMessage.value.value
      if (message && message.trim()) {
        context.emit('sendChatMessage', message.trim())
      }
    }

    function setupEmoji () {
      // eslint-disable-next-line no-unused-vars
      if (!emoji) {
        emoji = new fgEmoji.FgEmojiPicker({
          trigger: ['.emoji-select'],
          position: ['top', 'right'],
          dir: '/json/',
          insertInto: document.querySelector('.emoji-input')
        })
      }
    }

    function changeView (view) {
      if (view === 'bible') {
        bible.value = true
        sectionTitle.value = 'Bible (YouVersion)'
      } else {
        bible.value = false
        sectionTitle.value = commentsTitle
      }
    }

    onMounted(() => {
    })

    onUnmounted(() => {
      if (socket) {
        socket.disconnect()
        socket = null
      }
    })

    return {
      signInPaths,
      newMessage,
      send,
      messages,
      setupEmoji,
      bible,
      changeView,
      sectionTitle,
      commentsContainer,
      contentMessagesState
    }
  },
  computed: {
    ...mapGetters([
      'isLoggedIn'
    ])
  }
})
</script>
