import { createStore } from 'vuex'
import { storeKeys } from '../lib/constants'
import axios from 'axios'
import checkoutStore from './modules/checkout.store'
import contentStore from './modules/content.store'
import donationStore from './modules/donation.store'
import eventStore from './modules/event.store'
import gloablStore from './modules/global.store'
import membershipStore from './modules/membership.store'

function epochSecondsNow () {
  return Math.floor(Date.now() / 1000)
}
function parseJwt (token) {
  var base64Url = token.split('.')[1]
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  var jsonPayload = decodeURIComponent(Buffer.from(base64, 'base64').toString('ascii').split('').map(function (c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
  }).join(''))

  return JSON.parse(jsonPayload)
}

export default createStore({
  state: {
    token: localStorage.getItem(storeKeys.TOKEN) || null,
    profile: localStorage.getItem(storeKeys.PROFILE) ? JSON.parse(localStorage.getItem(storeKeys.PROFILE)) : null,
    auth: {
      isRefreshing: false,
      refreshingCall: undefined
    }
  },
  modules: {
    checkout: checkoutStore,
    content: contentStore,
    donation: donationStore,
    event: eventStore,
    global: gloablStore,
    membership: membershipStore
  },
  mutations: {
    login (state, result) {
      // Set the token
      state.token = result.token
      state.profile = result.profile
      localStorage.setItem(storeKeys.TOKEN, result.token)
      localStorage.setItem(storeKeys.PROFILE, JSON.stringify(result.profile))
    },
    setSignOut (state) {
      // Clear the token
      state.token = null
      state.profile = null
      localStorage.removeItem(storeKeys.TOKEN)
      localStorage.removeItem(storeKeys.PROFILE)
    },
    setAuthRefreshingCall (state, payload) {
      state.auth.refreshingCall = payload
    },
    setAuthRefreshingState (state, payload) {
      state.auth.isRefreshing = payload
    },
    setAvatar (state, payload) {
      if (state.profile) {
        state.profile.imageUrl = payload
        localStorage.setItem(storeKeys.PROFILE, JSON.stringify(state.profile))
      }
    }
  },
  actions: {
    async signOut (context) {
      context.commit('setSignOut')
      axios.post('auth/logout', null, { withCredentials: true })
        .catch((error) => {
          console.log(error)
        })
    },
    refreshToken (context) {
      if (context.state.auth.isRefreshing) {
        return context.state.auth.refreshingCall
      }
      context.commit('setAuthRefreshingState', true)
      const refreshingCall = axios.post('auth/refresh', {}, { withCredentials: true })
        .then(res => {
          var refreshSuccess = false
          if (res.data && res.data.success && res.data.result) {
            context.commit('login', res.data.result)
            refreshSuccess = true
          }
          context.commit('setAuthRefreshingState', false)
          context.commit('setAuthRefreshingCall', undefined)
          return Promise.resolve(refreshSuccess)
        })
      context.commit('setAuthRefreshingCall', refreshingCall)
      return refreshingCall
    }
  },
  getters: {
    isAdmin (state) {
      if (state.profile && state.profile.p.indexOf('admin') >= 0) {
        return true
      }
      return false
    },
    validToken (state) {
      if (state.token) {
        var jwtParsed = parseJwt(state.token)
        var epoch = epochSecondsNow()
        return (jwtParsed.exp > epoch)
      }
      return false
    },
    isLoggedIn (state) {
      if (state.token) {
        return true
      }
      return false
    },
    getUsername (state) {
      if (state.profile && state.profile.username) {
        return state.profile.username
      }
      return ''
    },
    getAvatar (state) {
      if (state.profile && state.profile.imageUrl) {
        return process.env.VUE_APP_BLOB_CONTAINER + '/images/' + state.profile.imageUrl
      }
      return ''
    },
    getFirstName (state) {
      if (state.profile && state.profile.firstName) {
        return state.profile.firstName
      }
      return ''
    },
    getLastName (state) {
      if (state.profile && state.profile.lastName) {
        return state.profile.lastName
      }
      return ''
    },
    getEmail (state) {
      if (state.profile && state.profile.email) {
        return state.profile.email
      }
      return ''
    }
  }
})
