<template>
<section aria-labelledby="privacyHeading">
  <form id="password-form" @submit.prevent="saveForm">
    <div class="shadow sm:rounded-md sm:overflow-hidden">
      <div class="bg-white py-6 px-4 sm:p-6">
        <div>
          <h2 id="privacyHeading" class="text-lg leading-6 font-medium text-gray-900">Privacy Settings</h2>
          <p class="mt-1 text-sm text-gray-500">Change your privacy settings using the form below.</p>
        </div>

        <div class="mt-6 grid grid-cols-4 gap-6">
        </div>
      </div>
      <div class="px-4 py-3 bg-gray-50 text-right sm:px-6">
        <button type="submit" class="disabled:opacity-50 bg-blue border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-blue-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue">
          Save
        </button>
      </div>
    </div>
  </form>
</section>
</template>

<script>
export default {
  components: {
  },
  setup () {
    const saveForm = () => {
    }
    return {
      saveForm
    }
  }
}
</script>
