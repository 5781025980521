<template>
<main>
  <LiveNow></LiveNow>
  <div class="bg-black bg-contain bg-no-repeat bg-right-top" style="background-image:linear-gradient(to left bottom, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2), rgba(42, 131, 161, 0.4), rgba(42, 131, 161, .6));">
    <div class="max-w-7xl mx-auto py-24 px-4 sm:px-6 lg:px-8">
      <div class="sm:flex sm:flex-col align-center">
        <h1 class="text-3xl font-extrabold text-white text-center">Falling in Love</h1>
      </div>
    </div>
  </div>
  <div class="flex flex-col justify-center py-12 sm:px-6 lg:px-8">
    <div class="sm:mx-auto sm:w-full sm:max-w-3xl">
      <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">

        <p class="lyrics">With all of my heart I will say, ‘I love you’<br>
                                                        With all of my mind I will say, ‘I love you, Lord’<br>
                                                        I love you, Lord, I love you, Lord<br>
                                                        I love you, Lord</p>

        <p class="lyrics">I keep falling in love with you<br>
                                                        Falling in love with you<br>
                                                        I’m falling in love with you<br>
                                                        Again and again</p>

        <p class="composer">Ben Woodward, Jaye Thomas<br>
                                                               © 2009 Watershed Publishing Group (ASCAP) (adm. by Watershed Music Group). Redeem The Music (ASCAP). All rights reserved. Used by permission.</p>

      </div>
    </div>
  </div>
</main>

</template>
<script>
import LiveNow from '@/components/LiveNow.vue'
export default {
  components: {
    LiveNow
  },
  setup () {
    return {
    }
  }
}
</script>

<style scoped>
.lyrics {
  @apply mt-8 font-medium text-gray-700
}
.composer {
  @apply mt-8 text-sm mb-10 text-gray-700
}
</style>
