<template>
<main>
  <LiveNow></LiveNow>
  <div class="max-w-7xl mx-auto px-4 sm:px-6">
    <ListPanel title="Latest" allLink="/audio" :panels="contentState.content.audio"></ListPanel>
  </div>
  <div class="max-w-7xl mx-auto pt-10 px-4 sm:px-6 lg:px-8">
    <div class="flex justify-between">
      <h2 class="text-3xl font-bold text-blue">Audio</h2>
    </div>
    <div class="pt-4">
      <table class="min-w-full">
        <thead>
          <tr class="border-t border-gray-300 bg-gray-200">
            <th class="px-3 py-3 uppercase tracking-wider text-left" >
            Episode
            </th>
            <th class=""></th>
          </tr>
        </thead>
        <tbody class="divide-y divide-gray-200">
          <tr class="bg-white hover:bg-gray-100 cursor-pointer" v-for="item in audioState.content.audio" :key="item.guid" @click="navigateAudio(item.guid)">
            <td class="pl-3 py-3 text-left">
              {{item.title}} <br/>
              <span class="text-xs text-gray-500">{{$filters.dateMed(item.publishedAt)}}</span>
            </td>
            <td class="pr-3 text-right">
              <span class="inline-block"><ArrowRightCircleIcon class="flex-shrink-0 h-5 w-5 ml-2" aria-hidden="true" /></span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <MembershipList class="mt-20"></MembershipList>
</main>
</template>

<script>
import { onMounted } from 'vue'
import { useRouter } from 'vue-router'
import { useContent } from '@/composables/useContent'
import MembershipList from '@/components/MembershipList.vue'
import ListPanel from '@/components/ListPanel.vue'
import LiveNow from '@/components/LiveNow.vue'
import { ArrowRightCircleIcon } from '@heroicons/vue/20/solid'
export default {
  name: 'Audio',
  components: {
    MembershipList,
    ListPanel,
    LiveNow,
    ArrowRightCircleIcon
  },
  setup () {
    const { contentState, audioState, searchContent, searchAudio, getContentImageUrl } = useContent()
    const router = useRouter()

    onMounted(() => {
      searchContent()
      searchAudio()
    })

    const navigateAudio = (itemGuid) => {
      router.push('/content/audio/' + itemGuid)
    }

    return {
      contentState,
      audioState,
      getContentImageUrl,
      navigateAudio
    }
  }
}
</script>
