<template>
  <Popover class="relative bg-white" v-slot="{ close }">
    <!-- Top navigation -->
    <div class="bg-blue">
      <div class="mx-auto flex h-10 items-center justify-between px-4 sm:px-6">
        <div class="flex items-center space-x-4 sm:space-x-6">
          <a href="/donate" class="text-sm font-medium text-white hover:bg-blue-dark hover:bg-opacity-20 px-1 rounded-md">Donate</a>
          <a href="/partner" class="text-sm font-medium text-white hover:bg-blue-dark hover:bg-opacity-20 px-1 rounded-md">Partner</a>
        </div>
        <div v-show="!isLoggedIn" class="flex items-center space-x-4 sm:space-x-6">
          <router-link to="/sign-in" class="text-sm font-medium text-white hover:bg-blue-dark hover:bg-opacity-20 px-1 rounded-md">Log In</router-link>
          <router-link to="/sign-up" class="text-sm font-medium text-white hover:bg-blue-dark hover:bg-opacity-20 px-1 rounded-md">Sign Up</router-link>
        </div>
        <div v-show="isLoggedIn" class="flex items-center space-x-4 sm:space-x-6">
          <a href="#" @click.prevent="signOut(close)" class="text-sm font-medium text-white hover:bg-blue-dark hover:bg-opacity-20 px-1 rounded-md">
            Sign Out
          </a>
          <router-link to="/settings/profile" class="text-sm font-medium text-white hover:bg-blue-dark hover:bg-opacity-20 px-1 rounded-md items-center -mr-1 flex">
            <img v-if="getAvatar" class="h-6 w-6 rounded-full" :src="getAvatar" alt="">
            <UserIcon v-else class="h-6 w-6" aria-hidden="true" />
            <span class="pl-2">Account</span>
          </router-link>
        </div>
      </div>
    </div>
    <div class="border-b">
      <div class="mx-auto px-4 sm:px-6">
        <div class="flex justify-between items-center py-3 md:space-x-10">
          <div class="flex justify-start lg:w-0 md:flex-1">
            <router-link to="/">
              <span class="sr-only">Prayer Storm</span>
              <img class="block h-8 w-auto" src="@/assets/logo.svg" alt="Prayer Storm">
            </router-link>
          </div>
          <div class="-mr-2 -my-2 sm:hidden">
            <PopoverButton class="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue">
              <span class="sr-only">Open menu</span>
              <Bars3Icon class="h-6 w-6" aria-hidden="true" />
            </PopoverButton>
          </div>
          <PopoverGroup as="nav" class="hidden sm:flex space-x-2">
            <Popover class="relative" v-slot="{ open, close }">
              <PopoverButton class="pl-1 group bg-white rounded-md inline-flex items-center text-base font-medium hover:text-blue focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue">
                <span>Prayer Storm</span>
                <ChevronDownIcon :class="[open ? 'text-gray-600' : 'text-gray-400', 'ml-1 h-5 w-5 group-hover:text-gray-500']" aria-hidden="true" />
              </PopoverButton>

              <transition enter-active-class="transition ease-out duration-200" enter-from-class="opacity-0 translate-y-1" enter-to-class="opacity-100 translate-y-0" leave-active-class="transition ease-in duration-150" leave-from-class="opacity-100 translate-y-0" leave-to-class="opacity-0 translate-y-1">
                <PopoverPanel class="absolute left-1/2 transform z-20 -translate-x-1/2 mt-3 px-2 w-screen max-w-sm sm:px-0">
                  <div class="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                    <div class="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
                      <router-link v-for="item in details" :key="item.name" :to="item.href" @click="close" class="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50">
                        <component :is="item.icon" class="flex-shrink-0 h-6 w-6 text-blue" aria-hidden="true" />
                        <div class="ml-4">
                          <p class="text-base font-medium">
                            {{ item.name }}
                          </p>
                        </div>
                      </router-link>
                      <hr />
                      <div class="flex space-x-6 md:order-2">
                        <a href="https://www.youtube.com/prayerstormtv" class="text-blue hover:text-blue-dark">
                          <span class="sr-only">YouTube</span>
                          <svg class="h-6 w-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                            <path fill-rule="evenodd" d="M19.615 3.184c-3.604-.246-11.631-.245-15.23 0-3.897.266-4.356 2.62-4.385 8.816.029 6.185.484 8.549 4.385 8.816 3.6.245 11.626.246 15.23 0 3.897-.266 4.356-2.62 4.385-8.816-.029-6.185-.484-8.549-4.385-8.816zm-10.615 12.816v-8l8 3.993-8 4.007z" clip-rule="evenodd" />
                          </svg>
                        </a>
                        <a href="https://www.facebook.com/prayerstorm/" class="text-blue hover:text-blue-dark">
                          <span class="sr-only">Facebook</span>
                          <svg class="h-6 w-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                            <path fill-rule="evenodd" d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z" clip-rule="evenodd" />
                          </svg>
                        </a>
                        <a href="https://www.instagram.com/prayerstorm" class="text-blue hover:text-blue-dark">
                          <span class="sr-only">Instagram</span>
                          <svg class="h-6 w-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                            <path fill-rule="evenodd" d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z" clip-rule="evenodd" />
                          </svg>
                        </a>
                        <a href="https://twitter.com/PrayerStormUK" class="text-blue hover:text-blue-dark">
                          <span class="sr-only">Twitter</span>
                          <svg class="h-6 w-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                            <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
                          </svg>
                        </a>
                        <a href="https://open.spotify.com/artist/3WEOd3E3aU6CphoYJn2LhH" class="text-blue hover:text-blue-dark">
                          <span class="sr-only">Spotify</span>
                          <svg class="h-6 w-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                            <path fill-rule="evenodd" d="M19.098 10.638c-3.868-2.297-10.248-2.508-13.941-1.387-.593.18-1.22-.155-1.399-.748-.18-.593.154-1.22.748-1.4 4.239-1.287 11.285-1.038 15.738 1.605.533.317.708 1.005.392 1.538-.316.533-1.005.709-1.538.392zm-.126 3.403c-.272.44-.847.578-1.287.308-3.225-1.982-8.142-2.557-11.958-1.399-.494.15-1.017-.129-1.167-.623-.149-.495.13-1.016.624-1.167 4.358-1.322 9.776-.682 13.48 1.595.44.27.578.847.308 1.286zm-1.469 3.267c-.215.354-.676.465-1.028.249-2.818-1.722-6.365-2.111-10.542-1.157-.402.092-.803-.16-.895-.562-.092-.403.159-.804.562-.896 4.571-1.045 8.492-.595 11.655 1.338.353.215.464.676.248 1.028zm-5.503-17.308c-6.627 0-12 5.373-12 12 0 6.628 5.373 12 12 12 6.628 0 12-5.372 12-12 0-6.627-5.372-12-12-12z" clip-rule="evenodd" />
                          </svg>
                        </a>
                      </div>
                    </div>
                  </div>
                </PopoverPanel>
              </transition>
            </Popover>

            <Popover class="relative" v-slot="{ open, close }">
              <PopoverButton class="pl-1 group bg-white rounded-md inline-flex items-center text-base font-medium hover:text-blue focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue">
                <span>Content</span>
                <ChevronDownIcon :class="[open ? 'text-gray-600' : 'text-gray-400', 'ml-1 h-5 w-5 group-hover:text-gray-500']" aria-hidden="true" />
              </PopoverButton>

              <transition enter-active-class="transition ease-out duration-200" enter-from-class="opacity-0 translate-y-1" enter-to-class="opacity-100 translate-y-0" leave-active-class="transition ease-in duration-150" leave-from-class="opacity-100 translate-y-0" leave-to-class="opacity-0 translate-y-1">
                <PopoverPanel class="absolute left-1/2 transform z-20 -translate-x-3/4 mt-3 px-2 w-screen max-w-sm sm:px-0">
                  <div class="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                    <div class="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
                      <router-link v-for="item in contents" :key="item.name" :to="item.href" @click="close" class="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50">
                        <component :is="item.icon" class="flex-shrink-0 h-6 w-6 text-blue" aria-hidden="true" />
                        <div class="ml-4">
                          <p class="text-base font-medium text-gray-900">
                            {{ item.name }}
                          </p>
                        </div>
                      </router-link>
                    </div>
                  </div>
                </PopoverPanel>
              </transition>
            </Popover>

            <Popover class="relative" v-slot="{ open, close }">
              <PopoverButton class="pl-1 group bg-white rounded-md inline-flex items-center text-base font-medium hover:text-blue focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue">
                <span>Resources</span>
                <ChevronDownIcon :class="[open ? 'text-gray-600' : 'text-gray-400', 'ml-1 h-5 w-5 group-hover:text-gray-500']" aria-hidden="true" />
              </PopoverButton>

              <transition enter-active-class="transition ease-out duration-200" enter-from-class="opacity-0 translate-y-1" enter-to-class="opacity-100 translate-y-0" leave-active-class="transition ease-in duration-150" leave-from-class="opacity-100 translate-y-0" leave-to-class="opacity-0 translate-y-1">
                <PopoverPanel class="absolute right-0 transform z-20 mt-3 px-2 w-screen max-w-sm sm:px-0">
                  <div class="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                    <div class="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
                      <router-link v-for="item in resources" :key="item.name" :to="item.href" @click="close" class="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50">
                        <component :is="item.icon" class="flex-shrink-0 h-6 w-6 text-blue" aria-hidden="true" />
                        <div class="ml-4">
                          <p class="text-base font-medium text-gray-900">
                            {{ item.name }}
                          </p>
                        </div>
                      </router-link>
                      <a href="https://prayerstormshop.bigcartel.com/" class="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50">
                        <component :is="shoppingBagIcon" class="flex-shrink-0 h-6 w-6 text-blue" aria-hidden="true" />
                        <div class="ml-4">
                          <p class="text-base font-medium text-gray-900">
                            Shop
                          </p>
                        </div>
                      </a>
                    </div>
                  </div>
                </PopoverPanel>
              </transition>
            </Popover>
          </PopoverGroup>
        </div>
      </div>
    </div>

    <transition enter-active-class="duration-200 ease-out" enter-from-class="opacity-0 scale-95" enter-to-class="opacity-100 scale-100" leave-active-class="duration-100 ease-in" leave-from-class="opacity-100 scale-100" leave-to-class="opacity-0 scale-95">
      <PopoverPanel focus class="absolute top-0 inset-x-0 p-2 transition z-20 transform origin-top-right md:hidden">
        <div class="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
          <div class="pt-5 pb-6 px-5">
            <div class="flex items-center justify-between">
              <div>
                <img class="h-8 w-auto" src="@/assets/logo-icon.svg" alt="Workflow" />
              </div>
              <div class="-mr-2">
                <PopoverButton class="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue">
                  <span class="sr-only">Close menu</span>
                  <XMarkIcon class="h-6 w-6" aria-hidden="true" />
                </PopoverButton>
              </div>
            </div>
          </div>
          <div class="py-2 px-5 space-y-6">
            <div class="grid gap-y-8">
              <div class="flex space-x-6 md:order-2">
                <a href="/donate" class="p-3 flex items-center rounded-md hover:bg-gray-50">
                  <span class="text-base font-medium text-gray-900">
                    Donate
                  </span>
                </a>
                <a href="/partner" class="p-3 flex items-center rounded-md hover:bg-gray-50">
                  <span class="text-base font-medium text-gray-900">
                    Partner
                  </span>
                </a>
              </div>
            </div>
          </div>
          <div class="py-6 px-5 space-y-6">
            <nav class="grid gap-y-8">
              <router-link v-for="item in contents" :key="item.name" :to="item.href" @click="close" class="-m-3 p-3 flex items-center rounded-md hover:bg-gray-50">
                <component :is="item.icon" class="flex-shrink-0 h-6 w-6 text-blue" aria-hidden="true" />
                <span class="ml-3 text-base font-medium text-gray-900">
                  {{ item.name }}
                </span>
              </router-link>
            </nav>
          </div>
          <div class="py-6 px-5 space-y-6">
            <div class="grid gap-y-8">
              <router-link v-for="item in details" :key="item.name" :to="item.href" @click="close" class="-m-3 p-3 flex items-center rounded-md hover:bg-gray-50">
                <component :is="item.icon" class="flex-shrink-0 h-6 w-6 text-blue" aria-hidden="true" />
                <span class="ml-3 text-base font-medium text-gray-900">
                  {{ item.name }}
                </span>
              </router-link>
            </div>
          </div>
          <div class="py-6 px-5 space-y-6">
            <div class="grid gap-y-8">
              <router-link v-for="item in resources" :key="item.name" :to="item.href" @click="close" class="-m-3 p-3 flex items-center rounded-md hover:bg-gray-50">
                <component :is="item.icon" class="flex-shrink-0 h-6 w-6 text-blue" aria-hidden="true" />
                <span class="ml-3 text-base font-medium text-gray-900">
                  {{ item.name }}
                </span>
              </router-link>
              <a href="https://prayerstormshop.bigcartel.com/" class="-m-3 p-3 flex items-center rounded-md hover:bg-gray-50">
                <component :is="shoppingBagIcon" class="flex-shrink-0 h-6 w-6 text-blue" aria-hidden="true" />
                <span class="ml-3 text-base font-medium text-gray-900">
                  Shop
                </span>
              </a>
            </div>
          </div>
          <div class="py-6 px-5 space-y-6">
            <div class="flex space-x-6 md:order-2">
              <a href="https://www.youtube.com/prayerstormtv" class="text-blue hover:text-blue-dark">
                <span class="sr-only">YouTube</span>
                <svg class="h-6 w-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                  <path fill-rule="evenodd" d="M19.615 3.184c-3.604-.246-11.631-.245-15.23 0-3.897.266-4.356 2.62-4.385 8.816.029 6.185.484 8.549 4.385 8.816 3.6.245 11.626.246 15.23 0 3.897-.266 4.356-2.62 4.385-8.816-.029-6.185-.484-8.549-4.385-8.816zm-10.615 12.816v-8l8 3.993-8 4.007z" clip-rule="evenodd" />
                </svg>
              </a>
              <a href="https://www.facebook.com/prayerstorm/" class="text-blue hover:text-blue-dark">
                <span class="sr-only">Facebook</span>
                <svg class="h-6 w-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                  <path fill-rule="evenodd" d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z" clip-rule="evenodd" />
                </svg>
              </a>
              <a href="https://www.instagram.com/prayerstorm" class="text-blue hover:text-blue-dark">
                <span class="sr-only">Instagram</span>
                <svg class="h-6 w-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                  <path fill-rule="evenodd" d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z" clip-rule="evenodd" />
                </svg>
              </a>
              <a href="https://twitter.com/PrayerStormUK" class="text-blue hover:text-blue-dark">
                <span class="sr-only">Twitter</span>
                <svg class="h-6 w-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                  <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
                </svg>
              </a>
              <a href="https://open.spotify.com/artist/3WEOd3E3aU6CphoYJn2LhH" class="text-blue hover:text-blue-dark">
                <span class="sr-only">Spotify</span>
                <svg class="h-6 w-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                  <path fill-rule="evenodd" d="M19.098 10.638c-3.868-2.297-10.248-2.508-13.941-1.387-.593.18-1.22-.155-1.399-.748-.18-.593.154-1.22.748-1.4 4.239-1.287 11.285-1.038 15.738 1.605.533.317.708 1.005.392 1.538-.316.533-1.005.709-1.538.392zm-.126 3.403c-.272.44-.847.578-1.287.308-3.225-1.982-8.142-2.557-11.958-1.399-.494.15-1.017-.129-1.167-.623-.149-.495.13-1.016.624-1.167 4.358-1.322 9.776-.682 13.48 1.595.44.27.578.847.308 1.286zm-1.469 3.267c-.215.354-.676.465-1.028.249-2.818-1.722-6.365-2.111-10.542-1.157-.402.092-.803-.16-.895-.562-.092-.403.159-.804.562-.896 4.571-1.045 8.492-.595 11.655 1.338.353.215.464.676.248 1.028zm-5.503-17.308c-6.627 0-12 5.373-12 12 0 6.628 5.373 12 12 12 6.628 0 12-5.372 12-12 0-6.627-5.372-12-12-12z" clip-rule="evenodd" />
                </svg>
              </a>
            </div>
          </div>
          <div class="py-6 px-5 space-y-6">
            <div v-if="!isLoggedIn">
              <p class="text-center text-base font-medium text-gray-500">
                Existing member?
                {{ ' ' }}
                <router-link to="/sign-in" @click="close" class="text-blue hover:text-blue-dark">
                  Sign in
                </router-link>
              </p>
              <router-link to="/sign-up" @click="close" class="mt-6 w-full flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-blue hover:bg-blue-dark">
                Sign up
              </router-link>
            </div>
            <div v-else>
              <p class="text-center text-base font-medium text-gray-500">
                <a href="#" @click.prevent="signOut(close)" class="text-blue hover:text-blue-dark">
                  Sign out
                </a>
              </p>
              <router-link to="/settings/profile" @click="close" class="mt-6 w-full flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-blue hover:bg-blue-dark">
                Account
              </router-link>
            </div>
          </div>
        </div>
      </PopoverPanel>
    </transition>
  </Popover>
</template>

<script>
import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'
import { Popover, PopoverButton, PopoverGroup, PopoverPanel } from '@headlessui/vue'
import {
  AcademicCapIcon,
  AtSymbolIcon,
  CalendarIcon,
  CheckIcon,
  ClockIcon,
  DevicePhoneMobileIcon,
  FireIcon,
  InboxArrowDownIcon,
  InformationCircleIcon,
  ArrowLeftOnRectangleIcon,
  Bars3Icon,
  MusicalNoteIcon,
  UserPlusIcon,
  UserGroupIcon,
  XMarkIcon,
  GiftIcon,
  BookOpenIcon,
  PlayCircleIcon,
  ShoppingBagIcon
} from '@heroicons/vue/24/outline'
import {
  ChevronDownIcon,
  UserIcon
} from '@heroicons/vue/20/solid'

const contents = [
  {
    name: 'Upcoming Events',
    href: '/coming-soon',
    icon: ClockIcon
  },
  {
    name: 'Recent Events',
    href: '/events',
    icon: CalendarIcon
  },
  {
    name: 'Schools',
    href: '/schools',
    icon: AcademicCapIcon
  },
  {
    name: 'Conferences',
    href: '/conferences',
    icon: UserGroupIcon
  },
  {
    name: 'Audio',
    href: '/audio',
    icon: MusicalNoteIcon
  }
]

const callsToAction = [
  // { name: 'Watch Demo', href: '#', icon: PlayIcon },
  // { name: 'Contact Sales', href: '#', icon: PhoneIcon }
]

const details = [
  {
    name: 'About Us',
    href: '/about',
    icon: InformationCircleIcon
  },
  {
    name: 'Furnace',
    href: '/furnace',
    icon: FireIcon
  },
  {
    name: 'Invite Us',
    href: '/invite',
    icon: InboxArrowDownIcon
  },
  {
    name: 'Interested In Volunteering?',
    href: '/volunteer',
    icon: UserPlusIcon
  },
  {
    name: 'Join Our Mailing List',
    href: '/mailing-list',
    icon: AtSymbolIcon
  },
  { name: 'Prayer Storm App', href: '/app', icon: DevicePhoneMobileIcon },
  { name: 'Prayer Circles App', href: '/app/circles', icon: DevicePhoneMobileIcon },
  { name: 'Gift Aid Declaration', href: '/gift-aid-declaration', icon: GiftIcon }
]

const resources = [
  {
    name: 'Life On Fire - Book',
    href: '/resources/life-on-fire',
    icon: BookOpenIcon
  },
  {
    name: 'We Declare War - Album',
    href: '/resources/we-declare-war',
    icon: MusicalNoteIcon
  },
  {
    name: 'Freedom Prayer',
    href: '/resources/freedom-prayer',
    icon: PlayCircleIcon
  }
]

const socials = [
  { name: 'YouTube', href: 'https://www.youtube.com/prayerstormtv' },
  { name: 'Facebook', href: 'https://www.facebook.com/prayerstorm/' },
  { name: 'Instagram', href: 'https://www.instagram.com/prayerstorm' },
  { name: 'Twitter', href: 'https://twitter.com/PrayerStormUK' },
  { name: 'Spotify', href: 'https://open.spotify.com/artist/3WEOd3E3aU6CphoYJn2LhH' }
]

const recentPosts = [
  { id: 1, name: '', href: '#' },
  { id: 2, name: '', href: '#' },
  { id: 3, name: '', href: '#' }
]

export default defineComponent({
  components: {
    Popover,
    PopoverButton,
    PopoverGroup,
    PopoverPanel,
    CalendarIcon,
    CheckIcon,
    ChevronDownIcon,
    ArrowLeftOnRectangleIcon,
    Bars3Icon,
    UserIcon,
    XMarkIcon
  },
  setup () {
    const shoppingBagIcon = ShoppingBagIcon

    return {
      shoppingBagIcon,
      contents,
      callsToAction,
      resources,
      recentPosts,
      details,
      socials
    }
  },
  methods: {
    toggleProfileMenu () {
      this.profileMenu = !this.profileMenu
    },
    signOut (close) {
      close()
      this.$store.dispatch('signOut').then(() => {
        this.$router.push({ path: '/' })
      })
    }
  },
  computed: {
    ...mapGetters([
      'isLoggedIn',
      'getUsername',
      'getAvatar'
    ])
  }
})
</script>

<style scoped>
</style>
