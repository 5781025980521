<template>
  <div class="bg-white py-8 sm:py-6">
    <div class="mx-auto max-w-5xl px-6">
      <div class="mx-auto max-w-xl text-center">
        <h2 class="text-xl font-bold">Testimonials</h2>
        <!-- <p class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">From those that have done the Nazirite School of Prayer</p> -->
      </div>
      <div class="mx-auto mt-4 flow-root max-w-2xl sm:mt-4 lg:mx-0 lg:max-w-none">
        <div class="-mt-6 sm:-mx-4 columns-3 sm:text-[0]">
          <div v-for="(testimonial, index) in testimonials" :key="index" class="pt-8 sm:inline-block sm:w-full sm:px-4">
            <figure class="rounded-2xl bg-gray-50 p-6 text-sm leading-6">
              <blockquote class="text-gray-900">
                <p>{{ `“${testimonial.body}”` }}</p>
              </blockquote>
              <figcaption class="mt-2 flex items-center gap-x-4">
                <div>
                  <div class="font-semibold text-gray-900">{{ testimonial.author.name }}</div>
                </div>
              </figcaption>
            </figure>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
const testimonials = [
  {
    body: 'The Nazarite process helped me to gain a deeper understanding of God\'s character. I went from living a life of just avoiding sin, to living in holiness in order to experience the deeper truths of God.',
    author: {
      name: '- Chloe',
      handle: '',
      imageUrl: ''
    }
  },
  {
    body: 'Originally, I was skeptical towards the idea of being a Nazarite; but God was after my whole heart and I was just struggling to surrender. The Nazarite process was a key part of my journey into wholeheartedness for God. It showed me how to attentively listen to the voice of the Holy Spirit.',
    author: {
      name: '- Noah',
      handle: '',
      imageUrl: ''
    }
  },
  {
    body: 'This has totally transformed my life! I now have victory over mental addictions, allowing my mind to focus on God. I continually sense His presence and I\'m better equipped to fulfill His calling on my life.',
    author: {
      name: '- Robert',
      handle: '',
      imageUrl: ''
    }
  }
]
</script>
