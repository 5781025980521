<template>
<main>
  <LiveNow></LiveNow>
  <div class="bg-black bg-contain bg-no-repeat bg-right-top" style="background-image:linear-gradient(to left bottom, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2), rgba(42, 131, 161, 0.4), rgba(42, 131, 161, .6));">
    <div class="max-w-7xl mx-auto py-24 px-4 sm:px-6 lg:px-8">
      <div class="sm:flex sm:flex-col align-center">
        <h1 class="text-3xl font-extrabold text-white text-center">Strike The Ground</h1>
      </div>
    </div>
  </div>
  <div class="flex flex-col justify-center py-12 sm:px-6 lg:px-8">
    <div class="sm:mx-auto sm:w-full sm:max-w-3xl">
      <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">

        <p class="lyrics">We strike the ground on the wells of revival<br>
                                                        Burst forth, burst forth<br>
                                                        We strike the ground on the wells of revival<br>
                                                        Burst forth, burst forth</p>

        <p class="lyrics">There’s a war going on in the heavenlies<br>
                                                        And we’re tearing down wicked principalities<br>
                                                        There’s a war going on in the heavenlies<br>
                                                        And we’re raising up righteousness
                                                        </p>

        <p class="lyrics">And we’re raising up righteousness</p>

        <p class="lyrics">Our hearts are crying out<br>
                                                        We need to see you now<br>
                                                        Your kingdom come in power<br>
                                                        Send the rain</p>

        <p class="lyrics">Send the rain</p>

        <p class="lyrics">I see Satan fall like lightning<br>
                                                        I see Jesus lifted higher<br>
                                                        Fear can’t stop us<br>
                                                        Death can’t stop us<br>
                                                        We go forth in victory by the blood of the lamb</p>

        <p class="composer">Seth Yates<br>
                                                            © Seth Yates Publishing Designee. Michael Ketterer Publishing Designee. Nayomi Thomas Publishing Designee. All rights reserved.</p>
      </div>
    </div>
  </div>
</main>

</template>
<script>
import LiveNow from '@/components/LiveNow.vue'
export default {
  components: {
    LiveNow
  },
  setup () {
    return {
    }
  }
}
</script>

<style scoped>
.lyrics {
  @apply mt-8 font-medium text-gray-700
}
.composer {
  @apply mt-8 text-sm mb-10 text-gray-700
}
</style>
