<template>
<main>
  <LiveNow></LiveNow>
  <div class="bg-black bg-contain bg-no-repeat bg-right-top" style="background-image:linear-gradient(to left bottom, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2), rgba(42, 131, 161, 0.4), rgba(42, 131, 161, .6));">
    <div class="max-w-7xl mx-auto py-24 px-4 sm:px-6 lg:px-8">
      <div class="sm:flex sm:flex-col align-center">
        <h1 class="text-3xl font-extrabold text-white text-center">Eyes of Fire</h1>
      </div>
    </div>
  </div>
  <div class="flex flex-col justify-center py-12 sm:px-6 lg:px-8">
    <div class="sm:mx-auto sm:w-full sm:max-w-3xl">
      <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">

        <p class="lyrics">I could get lost in those eyes of fire<br>
                                                        I could get lost in those eyes of fire<br>
                                                        I could get lost in those eyes of fire<br>
                                                        That burn with love for me</p>

        <p class="lyrics">I’m drawn to your presence<br>
                                                        I’m drawn by your love<br>
                                                        I’m drawn to those eyes of fire<br>
                                                        That burn with love for me</p>

        <p class="lyrics">They burn with love<br>
                                                        They burn with love<br>
                                                        They burn with love<br>
                                                        For me</p>

        <p class="lyrics">All consuming fire<br>
                                                        Your eyes burn with fire<br>
                                                        Your throne is on fire<br>
                                                        Burning one</p>

        <p class="lyrics">All consuming fire<br>
                                                        You answer by fire<br>
                                                        Baptise us with fire<br>
                                                        Burning one</p>

        <p class="composer">Rebecca Aladiran, James Aladiran<br>
                                                            © 2017 Prayer Storm Music</p>
      </div>
    </div>
  </div>
</main>

</template>
<script>
import LiveNow from '@/components/LiveNow.vue'
export default {
  components: {
    LiveNow
  },
  setup () {
    return {
    }
  }
}
</script>

<style scoped>
.lyrics {
  @apply mt-8 font-medium text-gray-700
}
.composer {
  @apply mt-8 text-sm mb-10 text-gray-700
}
</style>
