import axios from 'axios'
const contentStore = {
  namespaced: true,
  state: () => ({
  }),
  mutations: {
  },
  actions: {
    async viewContent (context, payload) {
      return axios.post('pub/content/details', payload)
    }
  }
}
export default contentStore
