<template>
<div class="max-w-7xl mx-auto py-10 px-4 sm:px-6 lg:px-8">
  <div class="flex justify-between">
    <h2 class="text-3xl font-bold text-blue">Membership</h2>
  </div>
  <div class="pt-4">
    <GoldStats></GoldStats>
  </div>
</div>
<div class="max-w-7xl mx-auto pt-10 px-4 sm:px-6 lg:px-8" id="membership-included">
  <div class="flex justify-between">
    <h2 class="text-3xl font-bold text-blue">What's Included</h2>
  </div>
  <div class="pt-4 grid grid-cols-2 gap-3 md:grid-cols-3 lg:grid-cols-4">
    <div class="flex flex-col" v-for="item in events" :key="item.guid">
      <router-link :to="getContentLink(item)" custom v-slot="{ navigate }">
        <div @click="navigate" class="relative bg-card-img cursor-pointer" v-bind:style="{ 'backgroundImage': getContentImage(item) }">
          <div class="absolute inset-x-0 bottom-0 text-white pl-3 pb-2">
            &nbsp;
          </div>
        </div>
      </router-link>
      <div>
        <div class="pt-2">
          <router-link :to="getContentLink(item)" class="font-semibold text-blue block text-ellipsis-2 hover:underline">{{item.title}}</router-link>
        </div>
        <div>
          <span class="text-base text-gray-400">{{$filters.dateMed(item.publishedAt)}}</span>
        </div>
      </div>
    </div>
  </div>
</div>
<div id="membership-upgrade" class="mt-20">
  <MembershipList></MembershipList>
</div>
</template>

<script>
import { defineComponent, computed } from 'vue'
import { useContent } from '@/composables/useContent'
import MembershipList from '@/components/MembershipList.vue'
import GoldStats from '@/components/GoldStats.vue'
import store from '../store'
export default defineComponent({
  name: 'Membership',
  components: {
    MembershipList,
    GoldStats
  },
  setup () {
    // gold
    const categoryId = 10

    const { getContentImage, getContentLink } = useContent()

    // load the events for category
    store.dispatch('event/eventsForCategory', {
      categoryId: categoryId
    })

    // generate state items
    const events = computed(() => store.state.event.eventsForCategory[categoryId])

    return {
      events,
      categoryId,
      getContentImage,
      getContentLink
    }
  }
})
</script>

<style scoped>
</style>
