import { createRouter, createWebHistory } from 'vue-router'
import store from '../store'

import LiveBackup from '../views/LiveBackup.vue'
import About from '../views/About.vue'
import Nazarite from '../views/Nazarite.vue'
import Donate from '../views/Donate.vue'
import Announcement from '../views/Announcement.vue'
import DonateAnnouncement from '../views/DonateAnnouncement.vue'
import CampaignPSKC from '../views/CampaignPSKC.vue'
import CampaignPSSC from '../views/CampaignPSSC.vue'
import DonateCampaign from '../views/DonateCampaign.vue'
import DonateCampaignCancel from '../views/DonateCampaignCancel.vue'
import DonateCampaignSuccess from '../views/DonateCampaignSuccess.vue'
import DonateCancel from '../views/DonateCancel.vue'
import DonateSuccess from '../views/DonateSuccess.vue'
import Partner from '../views/Partner.vue'
import GiftAid from '../views/GiftAid.vue'
import Privacy from '../views/Privacy.vue'
import WeDeclareWar from '../views/WeDeclareWar.vue'
import AlbumLyrics from '../views/AlbumLyrics.vue'
import Audio from '../views/Audio.vue'
import AppPrayerStorm from '../views/AppPrayerStorm.vue'
import AppPrayerCircles from '../views/AppPrayerCircles.vue'
import LyricsBigBen from '../views/LyricsBigBen.vue'
import LyricsWeArise from '../views/LyricsWeArise.vue'
import LyricsSoundOfHeaven from '../views/LyricsSoundOfHeaven.vue'
import LyricsStrikeTheGround from '../views/LyricsStrikeTheGround.vue'
import LyricsEyesOfFire from '../views/LyricsEyesOfFire.vue'
import LyricsFallingInLove from '../views/LyricsFallingInLove.vue'
import LyricsMightyOnes from '../views/LyricsMightyOnes.vue'
import LyricsWeDeclareWar from '../views/LyricsWeDeclareWar.vue'
import LyricsTheKingIsComing from '../views/LyricsTheKingIsComing.vue'
import LyricsLoveOfGod from '../views/LyricsLoveOfGod.vue'
import Conferences from '../views/Conferences.vue'
import CheckoutOptions from '../views/checkout/CheckoutOptions.vue'
import CheckoutQuantity from '../views/checkout/CheckoutQuantity.vue'
import CheckoutCancel from '../views/CheckoutCancel.vue'
import CheckoutSuccess from '../views/CheckoutSuccess.vue'
import Content from '../views/Content.vue'
import ContentGroup from '../views/ContentGroup.vue'
import Attend from '../views/Attend.vue'
import AttendViaPaymentLink from '../views/AttendViaPaymentLink.vue'
import ETicket from '../views/ETicket.vue'
import Profile from '../views/Profile.vue'
import Home from '../views/Home.vue'
import SignIn from '../views/SignIn.vue'
import SignUp from '../views/SignUp.vue'
import MailingList from '../views/MailingList.vue'
import Membership from '../views/Membership.vue'
import Music from '../views/Music.vue'
import Schools from '../views/Schools.vue'
import Events from '../views/Events.vue'
import ComingSoon from '../views/ComingSoon.vue'
import LatestVideos from '../views/LatestVideos.vue'
import FreedomPrayer from '../views/FreedomPrayer.vue'
import Furnace from '../views/Furnace.vue'
import Invite from '../views/Invite.vue'
import InvitePS from '../views/InvitePS.vue'
import LifeOnFire from '../views/LifeOnFire.vue'
import Posts from '../views/Posts.vue'
import Live from '../views/Live.vue'
import Press from '../views/Press.vue'
import PasswordReset from '../views/PasswordReset.vue'
import RegistrationTicket from '../views/RegistrationTicket.vue'
import RegistrationTicketVerify from '../views/RegistrationTicketVerify.vue'
import RegistrationTicketList from '../views/RegistrationTicketList.vue'
import ResetPassword from '../views/ResetPassword.vue'
import Subscription from '../views/Subscription.vue'
import Team from '../views/Team.vue'
import Volunteer from '../views/Volunteer.vue'

import EventLatestVideos from '../views/EventLatestVideos.vue'
import EventComingSoon from '../views/EventComingSoon.vue'
import EventAttendSessions from '../views/EventAttendSessions.vue'

import Settings from '../views/Settings.vue'
import SettingsAvatar from '../views/settings/Avatar.vue'
import SettingsDonations from '../views/settings/Donations.vue'
import SettingsMembership from '../views/settings/Membership.vue'
import SettingsPassword from '../views/settings/Password.vue'
import SettingsPrivacy from '../views/settings/Privacy.vue'
import SettingsProfile from '../views/settings/Profile.vue'
import SettingsDelete from '../views/settings/Delete.vue'

const createRoute = function (path, name, component, guest, admin) {
  return {
    path: path,
    name: name,
    component: component,
    meta: {
      guest: guest,
      admin: admin
    }
  }
}
const createRouteWithChildren = function (path, name, component, guest, admin, children) {
  const route = createRoute(path, name, component, guest, admin)
  route.children = children
  return route
}

const settingsRoutes = [
  createRoute('avatar', 'SettingsAvatar', SettingsAvatar, false, false),
  createRoute('donations', 'SettingsDonations', SettingsDonations, false, false),
  createRoute('membership', 'SettingsMembership', SettingsMembership, false, false),
  createRoute('password', 'SettingsPassword', SettingsPassword, false, false),
  createRoute('privacy', 'SettingsPrivacy', SettingsPrivacy, false, false),
  createRoute('profile', 'SettingsProfile', SettingsProfile, false, false),
  createRoute('delete', 'SettingsDelete', SettingsDelete, false, false)
]

const routes = [
  createRoute('/', 'Home', Home, true, false),
  createRoute('/live-event/036f81dd-140e-455e-96de-a504d2eab49c', 'LiveBackup', LiveBackup, true, false),
  { path: '/album', redirect: '/resources/we-declare-war' },
  { path: '/life-on-fire', redirect: '/resources/life-on-fire' },
  { path: '/prayer-storm-volunteer-form', redirect: '/volunteer' },
  { path: '/ministry-invite-requests', redirect: '/invite' },
  { path: '/james-aladiran-ministry-invite-request', redirect: '/invite/james' },
  { path: '/rebecca-aladiran-ministry-invite-request', redirect: '/invite/rebecca' },
  createRoute('/about', 'About', About, true, false),
  createRoute('/nazarite-school-of-prayer', 'Nazarite', Nazarite, true, false),
  createRoute('/app', 'AppPrayerStorm', AppPrayerStorm, true, false),
  createRoute('/app/circles', 'AppPrayerCircles', AppPrayerCircles, true, false),
  createRoute('/audio', 'Audio', Audio, true, false),
  createRoute('/donate', 'Donate', Donate, true, false),
  createRoute('/support/3/checkout/:checkoutSession', 'CampaignPSSCCheckout', CampaignPSSC, true, false),
  createRoute('/support/3/summer-conference', 'CampaignPSSC', CampaignPSSC, true, false),
  createRoute('/support/2/prayer-storm-kids-conference', 'CampaignPSKC', CampaignPSKC, true, false),
  createRoute('/announcement/2/:slug', 'Announcement', Announcement, true, false),
  createRoute('/announcement/1/:slug', 'DonateAnnouncement', DonateAnnouncement, true, false),
  createRoute('/support/:campaignId/:slug', 'DonateCampaign', DonateCampaign, true, false),
  createRoute('/support/cancel', 'DonateCampaignCancel', DonateCampaignCancel, true, false),
  createRoute('/support/success/:sessionId', 'DonateCampaignSuccess', DonateCampaignSuccess, true, false),
  createRoute('/donate/checkout/:checkoutSession', 'DonateCheckout', Donate, true, false),
  createRoute('/donate/cancel', 'DonateCancel', DonateCancel, true, false),
  createRoute('/donate/success/:sessionId', 'DonateSuccess', DonateSuccess, true, false),
  createRoute('/partner', 'Partner', Partner, true, false),
  createRoute('/partner/checkout/:checkoutSession', 'PartnerCheckout', Partner, true, false),
  createRoute('/partner/cancel', 'PartnerCancel', DonateCancel, true, false),
  createRoute('/partner/success/:sessionId', 'PartnerSuccess', DonateSuccess, true, false),
  createRoute('/gift-aid-declaration', 'GiftAid', GiftAid, true, false),
  createRoute('/privacy', 'Privacy', Privacy, true, false),
  createRoute('/lyrics', 'AlbumLyrics', AlbumLyrics, true, false),
  createRoute('/lyrics/big-ben', 'LyricsBigBen', LyricsBigBen, true, false),
  createRoute('/lyrics/we-arise', 'LyricsWeArise', LyricsWeArise, true, false),
  createRoute('/lyrics/we-declare-war', 'LyricsWeDeclareWar', LyricsWeDeclareWar, true, false),
  createRoute('/lyrics/mighty-ones', 'LyricsMightyOnes', LyricsMightyOnes, true, false),
  createRoute('/lyrics/eyes-of-fire', 'LyricsEyesOfFire', LyricsEyesOfFire, true, false),
  createRoute('/lyrics/falling-in-love', 'LyricsFallingInLove', LyricsFallingInLove, true, false),
  createRoute('/lyrics/love-of-god', 'LyricsLoveOfGod', LyricsLoveOfGod, true, false),
  createRoute('/lyrics/sound-of-heaven', 'LyricsSoundOfHeaven', LyricsSoundOfHeaven, true, false),
  createRoute('/lyrics/strike-the-ground', 'LyricsStrikeTheGround', LyricsStrikeTheGround, true, false),
  createRoute('/lyrics/the-king-is-coming', 'LyricsTheKingIsComing', LyricsTheKingIsComing, true, false),
  createRoute('/checkout/:dataType/:recordId/options', 'CheckoutOptions', CheckoutOptions, true, false),
  createRoute('/checkout/:dataType/:recordId/options/:optionId', 'CheckoutQuantity', CheckoutQuantity, true, false),
  createRoute('/checkout/cancel', 'CheckoutCancel', CheckoutCancel, true, false),
  createRoute('/checkout/success/:sessionId', 'CheckoutSuccess', CheckoutSuccess, true, false),
  createRoute('/coming-soon', 'ComingSoon', ComingSoon, true, false),
  createRoute('/latest-videos', 'LatestVideos', LatestVideos, true, false),
  createRoute('/conferences', 'Conferences', Conferences, true, false),
  createRoute('/content/:type/:guid', 'Content', Content, true, false),
  createRoute('/attend/:id/:type/:guid/:slug', 'Attend', Attend, true, false),
  createRoute('/attend-link/:id', 'AttendViaPaymentLink', AttendViaPaymentLink, true, false),
  createRoute('/eticket/:id/:type/:guid/:slug', 'ETicket', ETicket, true, false),
  createRoute('/events', 'Events', Events, true, false),
  createRoute('/furnace', 'Furnace', Furnace, true, false),
  createRoute('/invite', 'Invite', Invite, true, false),
  createRoute('/invite/:name', 'InvitePS', InvitePS, true, false),
  createRoute('/live/:type/:guid', 'Live', Live, true, false),
  createRoute('/mailing-list', 'MailingList', MailingList, true, false),
  createRoute('/membership', 'Membership', Membership, true, false),
  createRoute('/music', 'Music', Music, true, false),
  createRoute('/password-reset', 'PasswordReset', PasswordReset, true, false),
  createRoute('/posts', 'Posts', Posts, true, false),
  createRoute('/press', 'Press', Press, true, false),
  createRoute('/profile', 'Profile', Profile, true, false),
  createRoute('/registration-ticket/list/:recordId', 'RegistrationTicketList', RegistrationTicketList, false, false),
  createRoute('/registration-ticket/verify/:guid', 'RegistrationTicketVerify', RegistrationTicketVerify, false, false),
  createRoute('/registration-ticket/:guid', 'RegistrationTicket', RegistrationTicket, true, false),
  createRoute('/reset-password/:key', 'ResetPassword', ResetPassword, true, false),
  createRoute('/resources/freedom-prayer', 'FreedomPrayer', FreedomPrayer, true, false),
  createRoute('/resources/life-on-fire', 'LifeOnFire', LifeOnFire, true, false),
  createRoute('/resources/we-declare-war', 'WeDeclareWar', WeDeclareWar, true, false),
  createRoute('/schools', 'Schools', Schools, true, false),
  createRoute('/sign-in', 'SignIn', SignIn, true, false),
  createRoute('/sign-up', 'SignUp', SignUp, true, false),
  createRoute('/subscription/:id', 'Subscription', Subscription, true, false),
  createRoute('/team', 'Team', Team, true, false),
  createRoute('/volunteer', 'Volunteer', Volunteer, true, false),

  createRoute('/event/:guid/:slug', 'Event', ContentGroup, true, false),
  createRoute('/event/:guid/:slug/latest-videos', 'EventLatestVideos', EventLatestVideos, true, false),
  createRoute('/event/:guid/:slug/coming-soon', 'EventComingSoon', EventComingSoon, true, false),
  createRoute('/event/:guid/:slug/attend-sessions', 'EventAttendSessions', EventAttendSessions, true, false),

  createRouteWithChildren('/settings', 'Settings', Settings, false, false, settingsRoutes)
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0)
  if (window.ampPlayer) {
    window.ampPlayer.pause()
    window.ampPlayer.dispose()
    window.ampPlayer = undefined
  }
  if (window.screenPolling) {
    clearInterval(window.screenPolling)
  }
  const admin = to.matched.some(route => route.meta.admin)
  if (admin && !store.getters.isAdmin) {
    next({ path: '/' })
  } else {
    next()
  }
})

router.beforeEach((to, from, next) => {
  const guest = to.matched.some(route => route.meta.guest)
  if (guest) {
    if (to.path === '/sign-in' && store.getters.isLoggedIn) {
      if (to.query && to.query.return) {
        next({ path: to.query.return })
      } else {
        next({ path: '/' })
      }
    } else {
      next()
    }
  } else {
    if (store.getters.isLoggedIn) {
      if (!store.getters.validToken) {
        store.dispatch('refreshToken')
          .then((result) => {
            if (!store.getters.validToken) {
              // TODO: require update of login
            } else {
              // refreshed
              next()
            }
          })
          .catch((e) => {
            next(e)
          })
      } else {
        next()
      }
    } else {
      next({ path: '/sign-in', query: { return: to.path } })
    }
  }
})

router.onError((e) => {
  console.log('router.onError')
  console.log('<!-- router error: ' + e.message + ' -->')
})

export default router
