<template>
  <div class="max-w-7xl mx-auto p-8 md:p-12 lg:p-16">
    <div class="relative">
      <div class="">
        <div class="my-4 px-4 max-w-xl mx-auto sm:px-6 lg:max-w-none lg:mx-0 lg:px-0">
          <div class="privacy-details">
              <h1>Data Protection and Privacy Policy</h1>

<h2>Overview</h2>
<p>Prayer Storm holds personal data about individuals for general administration and communication. We acknowledge a responsibility in our treatment of your personal data. Whether digital, on paper or any other form, this data is subject to the requirements specified in the General Data Protection Regulation 2017.</p>

<h2>General Data Protection Regulation</h2>
<p>Prayer Storm endorses the eight principles of the GDPR in relation to all personal data that is acquired, stored and processed. Staff and any other individuals who would have due reason to access this data are required to adhere to the 8 Principles of the GDPR.</p>

<p>In summary, personal data collected:</p>

<ul>
<li>will be processed fairly and lawfully.</li>
<li>will have specified purposes.</li>
<li>will be relevant and not excessive to the intended purposes.</li>
<li>will be accurate and kept up to date.</li>
<li>will not be kept longer than necessary for the intended purposes.</li>
<li>will be processed in accordance with the rights of the data subject.</li>
<li>will be kept secure using appropriate levels of technical and organisational measures.</li>
<li>will not be transferred to a country or territory outside the EEA unless that country or territory ensures an adequate level of protection for the rights and freedoms of data subjects in relation to the processing of personal data.</li>
</ul>

<h2>How We Collect Data and Personal Information</h2>
<p>Personal information is usually collected by Prayer Storm as a response to your contact or involvement with us. For example, when you:</p>

<ul>
<li>Register for an account on the website or apps we provide;</li>
<li>Register interest or attendance for events/activities;</li>
<li>Provide your details for office and staff usage, such as invite requests;</li>
<li>When you make donations;</li>
<li>When you purchase goods or services we sell;</li>
<li>Access our website, apps or social media channels such as Facebook, YouTube etc.</li>
</ul>

<h2>Confidentiality</h2>
<p>Prayer Storm considers your personal information to be private and confidential. We will not disclose your information to anyone other than the trustees, staff, or appropriate individuals for the purposes of our day-to-day function. The only time this is not the case will be in exceptional circumunstance, for example when we are legally required to do so.</p>

<p>We do not sell any of your personal information to any other organisations and/or individuals.</p>

<p>We will not pass your personal information to any other organisations and/or individuals without your express consent. The only exceptions to this are:</p>

<ul>
<li>When it is for use in systems that are for Prayer Storm activity or function, and under the usage and monitoring of staff or an appropriate individual. For example, systems such as cloud emailing solutions that may be used for sending emails.</li>
<li>By providing us with your details you are giving Prayer Storm your express permission to transfer your data to service providers including cloud emailing solutions, to enable fulfilment of the purpose for collection.</li>
</ul>

<h2>Use of Personal Information</h2>
<p>Prayer Storm will use your data for three main purposes:</p>
<ul>
<li>Prayer Storm Function; e.g. events, rota generation for those who volunteer, financial recording of supporters.</li>
<li>Prayer Storm Marketing; e.g. events and related resources.</li>
<li>Analysis; so we can better understand people interacting with our services, in an effort to serve you better.</li>
</ul>

<h2>Rights to Access Your Information</h2>
<p>Prayer Storm recognises your right to request the information we have about you. Please make contact should you wish to request data removal, this facility will also be available online and via the apps soon too.</p>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  setup () {
  }
}
</script>

<style>
.privacy-details h1 {
  @apply text-lg md:text-3xl font-bold text-blue pt-10
}

.privacy-details h2 {
  @apply md:text-xl font-bold text-blue pt-10
}

.privacy-details ul {
  padding-left: 20px;
  list-style: disc;
}
</style>
