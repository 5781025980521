<template>
<main>
  <LiveNow></LiveNow>
  <div class="bg-black bg-contain bg-no-repeat bg-right-top" style="background-image:linear-gradient(to left bottom, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2), rgba(42, 131, 161, 0.4), rgba(42, 131, 161, .6));">
    <div class="max-w-7xl mx-auto py-24 px-4 sm:px-6 lg:px-8">
      <div class="sm:flex sm:flex-col align-center">
        <h1 class="text-3xl font-extrabold text-white text-center">The King is Coming</h1>
      </div>
    </div>
  </div>
  <div class="flex flex-col justify-center py-12 sm:px-6 lg:px-8">
    <div class="sm:mx-auto sm:w-full sm:max-w-3xl">
      <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">

        <p class="lyrics">We stand in awe of you, Jesus, Jesus<br>
                                                        Your glory fills this room, Jesus, Jesus<br>
                                                        We have heard of your great fame, Lord<br>
                                                        How you moved with power and grace<br>
                                                        God of miracles and wonders</p>

        <p class="lyrics">Repeat them in our days Lord Jesus<br>
                                                        Come and take Your place our saviour</p>

        <p class="lyrics">We stand in awe of you, Jesus, Jesus<br>
                                                        The king is coming soon, Jesus, Jesus<br>
                                                        We, your people called by Your name<br>
                                                        We bow down and seek Your face<br>
                                                        As our prayers rise up to heaven</p>

        <p class="lyrics">You will come and heal our land Lord<br>
                                                        Send revival to our nation</p>

        <p class="composer">Mark Beswick, Sam Hocking, Lloyd Gordon, Rebecca Aladiran<br>
                                                                © 2017 Prayer Storm Music</p>
    </div>
    </div>
  </div>
</main>

</template>
<script>
import LiveNow from '@/components/LiveNow.vue'
export default {
  name: 'LyricsTheKingIsComing',
  components: {
    LiveNow
  },
  setup () {
    return {
    }
  }
}
</script>

<style scoped>
.lyrics {
  @apply mt-8 font-medium text-gray-700
}
.composer {
  @apply mt-8 text-sm mb-10 text-gray-700
}
</style>
